import { click } from '@testing-library/user-event/dist/click';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import FileDownload from 'js-file-download';

import {
  getGiveUpReasonAction,
  getProductList,
  getRelatories,
  getRelatoryCSV,
  startLoadingTable,
  endLoadingTable,
  startLoadingModal,
  endLoadingModal,
  startLoadingProducts,
  endLoadingProducts,
  startLoadingCSV,
  endLoadingCSV,
  clearCSV,
  startDownLoading,
  endDownLoading,
} from 'actions/relatoryAction';
import { message } from 'antd';
import api from 'config/httpRequest/api';
import { AppDispatch } from 'config/store';

interface Relatory {
  inscriptionId: string;
  entityId: string;
  entityName: string;
  programId: string;
  programName: string;
  productId: number | null;
  productName: string;
  personId: string;
  personName: string;
  statusId: number;
  statusDescription: string;
  giveUpReasonId: number | null;
  giveUpReasonName: number | null;
  otherGiveUpReasonDescription: number | null;
}

export interface SearchPaginated {
  // eslint-disable-next-line
  data: Relatory[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export interface SearchCSV {
  // eslint-disable-next-line
  data: string;
}

export interface GetCertificate {
  // eslint-disable-next-line
  payload: any;
}

export interface Option {
  id: string;
  name: string;
}

export interface OptionList {
  data: Option[];
}

export interface ProductsOptionList {
  success: boolean;
  message: string;
  data: ProductOption[];
}

export interface ProductOption {
  id: string;
  name: string;
}

export interface DownloadFlag {
  id: number;
}

export const downloadRelatory = (
  entityName = '',
  personCPF = '',
  programName = '',
  productId = null,
  initialDate = '',
  endDate = '',
  classId = null,
  presence = null,
  isKit = null,
  pageNumber = 1,
  pageSize = 5,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoadingCSV());
    api
      .get<SearchCSV>('/Monitoring/Report', {
        params: {
          EntityName: entityName,
          PersonCpf: personCPF,
          ProgramName: programName,
          ProductId: productId,
          InitialDate: initialDate,
          EndDate: endDate,
          ClassId: classId,
          ProductPlace: '',
          Presence: presence,
          IsKit: isKit,
          PageNumber: pageNumber,
          PageSize: pageSize,
        },
        timeout: 180000,
      })
      .then(async ({ data: payload }) => {
        dispatch(getRelatoryCSV(payload));
        dispatch(endLoadingCSV);
        const link = document.getElementById('download-button') as Element;
        click(link);
      })
      // eslint-disable-next-line
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
        dispatch(endLoadingCSV());
      });
  };
};

export const searchRelatory = (
  entityName = '',
  personCPF = '',
  programName = '',
  classId = null,
  productId = null,
  initialDate = '',
  endDate = '',
  isKit = false,
  pageNumber = 1,
  pageSize = 5,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoadingTable());
    api
      .get<SearchPaginated>('/Monitoring', {
        params: {
          EntityName: entityName,
          PersonCpf: personCPF,
          ProgramName: programName,
          ClassId: classId,
          ProductId: productId,
          IsKit: productId ? isKit : '',
          InitialDate: initialDate,
          EndDate: endDate,
          PageNumber: pageNumber,
          PageSize: pageSize,
        },
      })
      .then(async ({ data: payload }) => {
        dispatch(getRelatories(payload));
        dispatch(endLoadingTable());
        dispatch(clearCSV());
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
        dispatch(endLoadingTable());
      });
  };
};

export const getGiveUpReason = () => {
  return async (dispatch: AppDispatch) => {
    api
      .get<OptionList>('/GiveUpReasonType', {})
      .then(async ({ data: payload }) => {
        dispatch(getGiveUpReasonAction(payload.data));
      })
      // eslint-disable-next-line no-console
      .catch(console.log);
  };
};

export const setGiveUpReason = async (formData: FormData) => {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoadingModal());
    await api
      .put<FormData>(`/Monitoring/EditGiveUpReason`, formData)
      .then(async ({ data: payload }) => {
        // eslint-disable-next-line no-console
        console.log('updateGiveUpReason', payload);
        dispatch(endLoadingModal());
        message.success({
          content: 'Motivo de Desistência atualizado com sucesso',
          className: 'success',
          duration: 10,
        });
      })
      // eslint-disable-next-line
      .catch(err => {
        dispatch(endLoadingModal());
        message.error({
          content: 'Erro ao atualizar motivo da desistência',
          className: 'error',
          duration: 10,
        });
      });
  };
};

export const getProductsList = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoadingProducts());
    api
      .get<ProductsOptionList>('/Entity/AdheredProductsLoggedUser', {})
      .then(async ({ data: payload }) => {
        dispatch(getProductList(payload.data));
        dispatch(endLoadingProducts());
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch(endLoadingProducts());
      });
  };
};

const { env } = window;
const downloadURL = env && env.REACT_APP_URL_CERTIFICATE;
export const downloadCertificate = (
  raeInscriptionId = 0,
  classId = 0,
  isKit = false,
  idExecutionKit = 0,
  idAuth = '',
  //   _click = 0,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(startDownLoading({ id: raeInscriptionId }));
    try {
      const payload = {
        IdAtendimentoInscricao: raeInscriptionId,
        ClassId: classId,
        IsKit: isKit,
        IdExecutionKit: idExecutionKit,
        IdAuth: idAuth,
      };
      const params =
        isKit === false
          ? { idAtendimentoInscricao: raeInscriptionId }
          : { idInscricaoKit: raeInscriptionId };
      const url =
        isKit === false ? `Certificado/Gerar` : `CertificadoKit/Gerar`;
      console.log('download', downloadURL);
      const response = await axios({
        method: 'get',
        url: `${downloadURL}${url}`,
        params,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          IdSistemaOrigem: '5461ba40-68d6-4cd2-ab43-64b84d5b0dc8',
          SistemaOrigem: 'Sistema de Inclusao Produtiva',
        },
        auth: {
          username: '_SIP',
          password: idAuth,
        },
        validateStatus() {
          return true;
        },
      });
      if (response.status === 200) {
        console.log('response => ', response);
        dispatch(endDownLoading());
        FileDownload(response.data, `certificado-${raeInscriptionId}.pdf`);
      } else {
        dispatch(endDownLoading());
        console.log('response => ', response);

        message.warning({
          content: 'A inscrição informada não possui direito a um certificado.',
          className: 'warning',
          duration: 10,
          onClose: true,
        });
      }
    } catch (error) {
      dispatch(endDownLoading());
      message.error({
        content: `Ocorreu um erro ao realizar o download do certificado ${error}`,
        className: 'error',
        duration: 10,
      });
    }
  };
};
