import Labels from 'helpers/enum/Labels';

export const subRoutesAdmin = [
   {
      path: '/programs',
      label: () => Labels.SUBMENU_OPTION_1,
      id: 1000,
   },
   {
      path: '/terms',
      label: () => Labels.SUBMENU_OPTION_2,
      id: 1001,
   },
   {
      path: '/profiles',
      label: () => Labels.SUBMENU_OPTION_3,
      id: 1002,
   },
];

export const subRoutesEntity = [
   {
      path: '/entities',
      label: () => Labels.MENU_OPTION_2,
      id: 1003,
   },
];

export const subRoutesSocprofile = [
   {
      path: '/socprofiles',
      label: () => Labels.MENU_OPTION_4,
      id: 1004,
   },
];

export const subRoutesInscribePerson = [
   {
      path: '/inscribePerson',
      label: () => Labels.MENU_OPTION_4,
      id: 1004,
   },
];

export const subRoutesEntrepreneur = [
   {
      path: '/entrepreneurs',
      label: () => Labels.MENU_OPTION_5,
      id: 1005,
   },
];

export const routeRegisterColaborator = [
   {
      path: '/naturalPerson',
      label: () => Labels.MENU_OPTION_3,
      id: 1006,
   },
];
