import { AxiosInstance } from 'axios';
import AuthService from 'services/authService';
import Utils from '../../helpers/Utils';
import Labels from '../../helpers/enum/Labels';

interface AxiosErrorResponse {
   response: {
      data: {
         error: string[];
      };
      config: {
         url: string;
      };
   };
   request: {
      status: number;
   };
}

export const addInterceptors = (http: AxiosInstance) => {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   http.interceptors.request.use((request: any) => {
      if (Utils.hasTokenValid()) {
         request.headers.Authorization = `Bearer ${Utils.getToken()}`;
      }
      return request;
   });

   http.interceptors.response.use(
      response => {
         return response;
      },
      (error: AxiosErrorResponse) => {
         if (!error.response) {
            Utils.showToast('error', Labels.SERVICE_UNAVAILABLE);
         } else if (
            error.response.config.url.includes('InscriptionInClass') &&
            error.request.status >= 400 &&
            error.request.status < 500
            // eslint-disable-next-line no-empty
         ) {
            return Promise.reject(error);
         } else {
            switch (error.request.status) {
               case 400:
                  error.response.data.error.forEach(errorDescription => {
                     Utils.showToast('error', errorDescription, false);
                  });
                  break;
               case 401:
                  Utils.showToast('warning', Labels.UNAUTHORIZED);
                  if (Utils.hasTokenValid()) {
                     Utils.cleanLocalStorage();
                  }
                  setTimeout(() => {
                     AuthService.doLogout();
                  }, 4000);
                  break;
               case 403:
                  Utils.showToast('error', Labels.FORBIDDEN);
                  break;
               case 405:
                  Utils.showToast('error', Labels.METHOD_NOT_ALLOWED);
                  break;
               case 500:
                  Utils.showToast('error', Labels.INTERNAL_SERVER_ERROR);
                  break;
               case 503:
                  Utils.showToast('error', Labels.SERVICE_UNAVAILABLE);
                  break;
               case 504:
                  Utils.showToast('error', Labels.GATEWAY_TIMEOUT);
                  break;
               default:
                  break;
            }
         }

         return Promise.reject(error);
      },
   );

   return http;
};
