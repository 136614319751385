/* eslint-disable prettier/prettier */
import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Input, SubmitButton, Select, Radio } from 'formik-antd';
import { Button, Layout, PageHeader, Col, Row, message, Tooltip } from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/common/multiSelect';
import InputMask from 'react-input-mask';
import Utils from 'helpers/Utils';
// import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../config/store';

import {
   searchEntities,
   Entity,
   clearEntities,
} from '../../services/entitiesService';
import {
   TypeProfile,
   searchTypeProfiles,
} from '../../services/typeProfilesService';
import {
   setProfile,
   clearProgramReduce,
   updateProfile,
   getProfile,
} from '../../services/profilesService';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import constants from '../../helpers/enum/Constants';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
const { Option } = Select;

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

export interface EntitySelect {
   cnpj: string;
   name: string;
}

interface BoxProps {
   name: string;
   cnpj: string;
   remove: (name: string, cnpj?: string) => void;
   id: string;
}

export const Box = ({ name, cnpj, remove, id }: BoxProps) => {
   const { t } = useTranslation();

   return (
      <>
         <div className="spacing-top">
            <div className="productViewFirstLevel">
               <div className="spacing-ent">{name}</div>
               {cnpj?.length > 0 && (
                  <>
                     <div>{`  | ${t(Labels.COMMON_CNPJ)}: ${cnpj}`}</div>
                  </>
               )}

               <div className="productView3">
                  <Icon
                     style={{ fontSize: 14 }}
                     component={Close}
                     onClick={() => (id ? remove(id) : remove(name, cnpj))}
                  />
               </div>
            </div>
         </div>
      </>
   );
};

export const DropDown = (props: any) => {
   const {
      name,
      placeholder,
      handleSearch,
      onChange,
      showArrow,
      children,
      disabled,
      multiple,
   } = props;

   return (
      <Select
         name={name}
         mode={multiple}
         className="select"
         placeholder={placeholder}
         size="large"
         onSearch={handleSearch}
         showSearch
         showArrow={showArrow}
         filterOption={showArrow}
         notFoundContent={showArrow}
         onChange={onChange}
         disabled={disabled}
      >
         {children}
      </Select>
   );
};

export const RadioButton = (props: any) => {
   const { name, defaultValue, data } = props;

   return (
      <Radio.Group name={name} defaultValue={defaultValue}>
         {data.map((radio: any) => {
            return (
               <Radio name={radio.name} value={radio.value}>
                  {radio.label}
               </Radio>
            );
         })}
      </Radio.Group>
   );
};

const AddProgram = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: any }>();
   const { entities } = useSelector((state: RootState) => state.entities);
   const {
      profile,
      success,
      message: messageResponse,
   } = useSelector((state: RootState) => state.profiles);

   const { typeProfiles } = useSelector(
      (state: RootState) => state.typeProfiles,
   );

   const [valuesList, setValuesList] = useState([] as Entity[]);
   const [reload, setReload] = useState(false);

   useEffect(() => {
      dispatch(searchTypeProfiles());
      if (id) dispatch(getProfile(id));
   }, [dispatch, id]);

   useEffect(() => {
      return function cleanup() {
         dispatch(clearProgramReduce());
      };
   }, [dispatch]);

   useEffect(() => {
      if (success && reload) {
         message.success({
            content: messageResponse,
            className: 'success',
            duration: 10,
         });
         if (!id) {
            dispatch(clearProgramReduce());
         }
         setReload(false);
      }
   }, [dispatch, id, messageResponse, reload, success]);
   const handleSearch = (value: string) => {
      dispatch(searchEntities(value));
      if (value) {
         // fetch(value, data => this.setState({ data }));
         if (value.length > 0) {
            const teste: Entity[] = entities?.data;
            setValuesList(teste);
         } else {
            setValuesList([]);
         }
      } else {
         // this.setState({ data: [] });
      }
   };

   // const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 500);

   const schema = Yup.object().shape(
      {
         name: Yup.string().required(),
         cpf: Yup.string()
            .transform(value =>
               Utils.stripDotsParenthesisWhitespacesAndHifens(value),
            )
            .required()
            .min(11, '*CPF invalido')
            .test('cpf-valid', '*CPF invalido', value =>
               Utils.isValidCPF(value),
            ),
         profileId: Yup.number().nullable().required(),
         toEntities: Yup.array().when(['profileId', 'entities'], {
            is: (profileId: number, entites: Array<Entity>) =>
               profileId === 1 && entites?.length === 0,
            then: Yup.array()
               .min(1, '*Nenhuma entidade selecionada')
               .required(),
            otherwise: Yup.array().notRequired(),
         }),
         entities: Yup.array().when(['profileId', 'toEntities'], {
            is: (
               profileId: number,
               toEntities: Array<EntitySelect> | undefined,
            ) => profileId <= 2 && toEntities?.length === 0,
            then: Yup.array()
               .min(1, '*Nenhuma entidade selecionada')
               .required(),
            otherwise: Yup.array().notRequired(),
         }),
         entityText: Yup.string().when(['profileId', 'cnpj'], {
            is: (profileId: number, cnpj: string) =>
               profileId === 1 && cnpj?.length > 0,
            then: Yup.string().required('*Nome não pode estar em branco'),
         }),
      },
      [['entities', 'toEntities']],
   );

   const validateEntity = (values: any) => {
      let isValid = true;
      if (!values?.entityText) {
         isValid = false;
         // Utils.showToast('error', Labels.ENTITIES_ERROR_NO_NAME);
      }
      const aux = values?.toEntities.forEach((entity: { name: any; cnpj: any }) => {
         if (entity.name === values.entityText && entity.cnpj === values.cnpj) {
            isValid = false;
            Utils.showToast(
               'error',
               Labels.ENTITIES_ERROR_SAME_NAME_AND_SAME_CNPJ,
            );
         }
         return null;
      });
      console.log(aux);

      return isValid;
   };

   return (
      <div className="profiles-search-container">
         <Layout className="site-layout">
            <PageHeader
               title={
                  id ? t(Labels.PROFILE_EDIT_NAME) : t(Labels.PROFILE_ADD_NAME)
               }
            />
            <div className="subtitle">{t(Labels.COMMON_REQUIRED_TITLE)}</div>
            <Content
               className="site-layout-background"
               style={{ marginTop: 0, paddingTop: 0 }}
            >
               <Formik
                  onSubmit={async (e: FormikValues, { resetForm }: any) => {
                     if (id) {
                        await dispatch(
                           await updateProfile(
                              {
                                 name: e.name,
                                 cpf: Utils.stripDotsParenthesisWhitespacesAndHifens(
                                    e.cpf,
                                 ),
                                 profileId: e.profileId,
                                 entities: e.entities.map(
                                    (entity: Entity) => entity.id,
                                 ),
                                 toEntities: e.toEntities.map(
                                    (element: EntitySelect) => {
                                       return {
                                          cnpj: Utils.stripDotsParenthesisWhitespacesAndHifens(
                                             element.cnpj,
                                          ),
                                          name: element.name,
                                       };
                                    },
                                 ),
                                 entity: [],
                                 cnpj: '',
                                 entityText: '',
                                 entityList: [],
                              },
                              id,
                           ),
                        );
                        setReload(true);
                     } else {
                        await dispatch(
                           await setProfile(
                              {
                                 name: e.name,
                                 cpf: Utils.stripDotsParenthesisWhitespacesAndHifens(
                                    e.cpf,
                                 ),
                                 profileId: e.profileId,
                                 entities: e.entities?.map(
                                    (entity: Entity) => entity.id,
                                 ),
                                 toEntities: e.toEntities?.map(
                                    (element: EntitySelect) => {
                                       return {
                                          cnpj: Utils.stripDotsParenthesisWhitespacesAndHifens(
                                             element.cnpj,
                                          ),
                                          name: element.name,
                                       };
                                    },
                                 ),
                                 entity: [],
                                 cnpj: '',
                                 entityText: '',
                                 entityList: [],
                              },
                              resetForm,
                           ),
                        );
                        dispatch(clearProgramReduce());
                        setReload(true);
                     }
                  }}
                  initialValues={profile}
                  enableReinitialize
                  validateOnBlur
                  validationSchema={schema}
                  render={({ setFieldValue, values, errors, touched }) => {
                     // if (values.profileId === 2 && values.toEntities.length > 0) {
                     //    setFieldValue('toEntities', []);
                     // } else if (
                     //    values.profileId === 1 &&
                     //    values.entities.length > 0
                     // )
                     //    setFieldValue('entities', []);

                     const addEntity = (idEntity: string) => {
                        const finder = valuesList.find(
                           element => element.cnpj === idEntity,
                        );

                        if (finder && values?.entities) {
                           setFieldValue('entities', [
                              ...values?.entities,
                              finder,
                           ]);
                        } else {
                           setFieldValue('entities', [finder]);
                        }
                        dispatch(clearEntities());
                     };

                     const addToEntities = () => {
                        // handleReset();
                        if (validateEntity(values)) {
                           setFieldValue('cnpj', '');
                           setFieldValue('entityText', '');
                           setFieldValue('toEntities', [
                              ...values?.toEntities,
                              {
                                 name: values.entityText,
                                 cnpj: values.cnpj,
                              },
                           ]);
                        }
                     };
                     return (
                        <Form style={{ width: '100%' }}>
                           <Row className="line-field-height">
                              <Col span={24} md={{ span: 8 }} className="col">
                                 <div className="title">
                                    {`${t(Labels.COMMON_CPF)} *`}
                                 </div>
                                 <InputMask
                                    alwaysShowMask={false}
                                    mask="999.999.999-99"
                                    onChange={e => {
                                       const { value } = e.target || '';
                                       const { name } = e.target;
                                       setFieldValue(name, value);
                                    }}
                                    value={values.cpf || ''}
                                 >
                                    {() => (
                                       <Input
                                          name="cpf"
                                          placeholder={t(Labels.COMMON_CPF)}
                                          className="input"
                                          data-testid="cpf"
                                          role="textbox"
                                       />
                                    )}
                                 </InputMask>
                                 <div className="errorText">
                                    {touched.cpf && errors.cpf}
                                 </div>
                              </Col>
                           </Row>
                           <Row className="line-field-height">
                              <Col span={24} md={{ span: 8 }} className="col">
                                 <div className="title">
                                    {`${t(Labels.COMMON_NAME)} *`}
                                 </div>
                                 <Input
                                    name="name"
                                    placeholder={t(Labels.COMMON_NAME)}
                                    style={{
                                       height: 40,
                                       fontSize: 20,
                                    }}
                                 />
                                 <div className="errorText">
                                    {touched.name && errors.name}
                                 </div>
                              </Col>
                           </Row>
                           <Row className="line-field-height">
                              <Col span={24} md={{ span: 8 }} className="col">
                                 <div className="title">
                                    {`${t(Labels.COMMON_PROFILE)} *`}
                                 </div>
                                 <DropDown
                                    name="profileId"
                                    placeholder={t(Labels.COMMON_PROFILE)}
                                    onChange={(x: number) => {
                                       setFieldValue('toEntities', []);
                                       setFieldValue('profileId', x);

                                    }}
                                 >
                                    {typeProfiles?.data?.map(
                                       (form: TypeProfile) => {
                                          return (
                                             <Option value={form.id}>
                                                {form.name}
                                             </Option>
                                          );
                                       },
                                    )}
                                 </DropDown>
                                 <div className="errorText">
                                    {touched.profileId && errors.profileId}
                                 </div>
                              </Col>
                           </Row>
                           {/* eslint-disable-next-line prettier/prettier */}
                           {constants.LIST_PROFILE_ID.includes(
                              Number(values.profileId),
                           ) && (
                                 <Row className="multi-row">
                                    <Col
                                       span={24}
                                       md={{ span: 8 }}
                                       className="col"
                                    >
                                       <div className="title title-description">
                                          Por favor, indique qual entidade será
                                          representada por este perfil
                                       </div>
                                       <div className="title">{`${t(
                                          Labels.COMMON_ENTITY,
                                       )} *`}</div>
                                       <MultiSelect
                                          nameSelect="entityList"
                                          placeholder={t(Labels.COMMON_ENTITY)}
                                          onTheEnd={() => console.log('teste')}
                                          handleSearch={handleSearch}
                                          showArrow={false}
                                          onChange={addEntity}
                                          disabled={false}
                                          sizeSelect="large"
                                          className="select line-field-height"
                                          horizontal={false}
                                          testid=""
                                          itens={values?.entities?.map(
                                             (p: any) => {
                                                return (
                                                   <Box
                                                      name={p?.name}
                                                      cnpj=""
                                                      id={p?.id}
                                                      remove={() => {
                                                         const last =
                                                            values.entities.filter(
                                                               (element: any) =>
                                                                  element.cnpj !==
                                                                  p.cnpj,
                                                            );
                                                         setFieldValue(
                                                            'entities',
                                                            last,
                                                         );
                                                      }}
                                                   />
                                                );
                                             },
                                          )}
                                          listValues={valuesList.map(
                                             (vL: Entity) => {
                                                return { ...vL, id: vL.cnpj };
                                             },
                                          )}
                                       />
                                       <div className="errorText multi-select-error-text">
                                          {touched.entityList && errors.entities}
                                       </div>
                                    </Col>
                                 </Row>
                              )}
                           {values.profileId === 1 && (
                              <>
                                 <div className="title title-description">
                                    <p
                                       style={{
                                          marginBottom: '0em',
                                       }}
                                    >
                                       OU, informe os dados de Pré-Cadastro da
                                       Entidade a ser representada por este
                                       perfil:
                                    </p>
                                    <p
                                       style={{
                                          marginBottom: '0em',
                                       }}
                                    >
                                       Insira os dados e pressione &quot;+&quot;
                                       para adicionar o registro.
                                    </p>
                                 </div>
                                 <Row className="multi-row">
                                    <Col
                                       span={24}
                                       md={{ span: 8 }}
                                       className="col"
                                    >
                                       <div className="title">
                                          {`${t(Labels.COMMON_ENTITY)} *`}
                                       </div>
                                       <Input
                                          name="entityText"
                                          placeholder={t(Labels.COMMON_ENTITY)}
                                          className="line-field-height"
                                          style={{
                                             height: 40,
                                             fontSize: 20,
                                          }}
                                       />
                                    </Col>
                                    <Col
                                       span={24}
                                       md={{ span: 8 }}
                                       className="col"
                                    >
                                       <div className="title">
                                          {`${t(Labels.COMMON_CNPJ)}`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="99.999.999/9999-99"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={values.cnpj || ''}
                                          >
                                             {() => (
                                                <Input
                                                   name="cnpj"
                                                   placeholder={t(
                                                      Labels.COMMON_CNPJ,
                                                   )}
                                                   className="input"
                                                   data-testid="cnpj"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                          <Col className="custom-col">
                                             <Tooltip title="Adicionar">
                                                <Button
                                                   className="add"
                                                   onClick={addToEntities}
                                                >
                                                   <PlusOutlined />
                                                </Button>
                                             </Tooltip>
                                          </Col>
                                       </div>
                                    </Col>
                                 </Row>
                                 <div className="errorText to-entity-error">
                                    {errors.toEntities}
                                 </div>
                                 {errors.entityText && (
                                    <div className="errorText to-entity-error">
                                       {errors?.entityText}
                                    </div>
                                 )}
                              </>
                           )}
                           <Row>
                              {values?.toEntities?.map((p: EntitySelect) => {
                                 return (
                                    <Box
                                       name={p.name}
                                       cnpj={p.cnpj}
                                       id=""
                                       remove={(name, cnpj) => {
                                          const last = values.toEntities.filter(
                                             (element: any) =>
                                                !(
                                                   element.cnpj === cnpj &&
                                                   element.name === name
                                                ),
                                          );
                                          setFieldValue('toEntities', last);
                                       }}
                                    />
                                 );
                              })}
                           </Row>
                           <Row>
                              <Button.Group>
                                 <Button
                                    onClick={() => {
                                       history.goBack();
                                    }}
                                    className="button-goback"
                                    type="primary"
                                 >
                                    {t(Labels.COMMON_BUTTON_BACK)}
                                 </Button>
                                 <SubmitButton className="default-button">
                                    {t(Labels.COMMON_BUTTON_SAVE)}
                                 </SubmitButton>
                              </Button.Group>
                           </Row>
                        </Form>
                     );
                  }}
               />
            </Content>
         </Layout>
      </div>
   );
};

export default AddProgram;
