import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchSocialCause } from '../services/socialCauseService';

interface InitialStateType {
   socialCause: SearchSocialCause;
}

const INITIAL_STATE: InitialStateType = {
   socialCause: {
      data: [],
      success: true,
   }, // {} as SearchSocialCause,
};

export const getSocialCause =
   createAction<SearchSocialCause>('GET_SOCIAL_CAUSE');

export default createReducer(INITIAL_STATE, {
   [getSocialCause.type]: (state, action: PayloadAction<SearchSocialCause>) => {
      return {
         ...state,
         socialCause: action.payload,
      };
   },
});
