import BaseLayout from './components/app/layout/BaseLayout';
import Routers from './components/routes/Routes';

const Authenticated = () => {
   return (
      <BaseLayout.BaseLayout>
         <Routers.Routes />
      </BaseLayout.BaseLayout>
   );
};

export default Authenticated;
