/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
   ClassDetails,
   EntityPerson,
   KitClassDetails,
   SearchClasses,
} from 'services/classesService';

interface InitialStateType {
   classes: SearchClasses;
   loading: boolean;
   classDetails: ClassDetails;
   kitClassDetails: KitClassDetails | null;
   kitClasses: any[];
   loadingClassDetails: boolean;
   loadingEntityPersonList: boolean;
   entityPersonsList: EntityPerson[] | [];
   entityPersonListTotalRecords: number;
   inscribeClassErrorMessage: string | null;
   reloadClassDetails: boolean;
   loadingInscribeClass: boolean;
   modals: {
      areYouSure: boolean;
      errorMessage: boolean;
      paymentWarningMessage: string | null;
   };
}

const INITIAL_STATE: InitialStateType = {
   classes: {} as SearchClasses,
   loading: true,
   classDetails: {
      idProduct: null,
      idClass: null,
      initialDate: null,
      endDate: null,
      city: null,
      neighborhood: null,
      street: null,
      streetType: null,
      number: null,
      modality: null,
      executionDates: [],
   },
   kitClassDetails: null,
   kitClasses: [],
   loadingClassDetails: false,
   loadingEntityPersonList: false,
   entityPersonsList: [],
   entityPersonListTotalRecords: 0,
   inscribeClassErrorMessage: null,
   reloadClassDetails: false,
   loadingInscribeClass: false,
   modals: {
      areYouSure: false,
      errorMessage: false,
      paymentWarningMessage: null,
   },
};

export const getClasses = createAction<SearchClasses>('GET_CLASSES');

export const setLoading = createAction<boolean>('SET_CLASSES_LOADING');

export const setLoadingClassDetails = createAction<boolean>(
   'SET_CLASS_DETAILS_LOADING',
);

export const setClassDetails = createAction<ClassDetails>('SET_CLASS_DETAILS');

export const setKitClassDetails = createAction<KitClassDetails>(
   'SET_KIT_CLASS_DETAILS',
);
export const setKitClasses = createAction<any[]>('SET_KIT_CLASSES');
export const setEntityPersonsList = createAction<EntityPerson[]>(
   'SET_ENTITY_PERSON_LIST',
);
export const setLoadingEntityPersonList = createAction<boolean>(
   'SET_ENTITY_PERSON_LIST_LOADING',
);
export const setEntityPersonListTotalRecords = createAction<number>(
   'SET_ENTITY_PERSON_LIST_TOTAL_RECORDS',
);
export const setInscribeClassErrorMessage = createAction<string>(
   'SET_INSCRIBE_CLASS_ERROR_MESSAGE',
);
export const setLoadingInscribeClass = createAction<boolean>(
   'SET_LOADING_INSCRIBE_CLASS',
);
export const setAreYouSureModal = createAction<boolean>(
   'SET_INSCRIBE_AREYOUSURE_MODAL',
);
export const setReloadClassDetails = createAction<boolean>(
   'SET_RELOAD_CLASS_DETAILS',
);
export const setPaymentWarningMessage = createAction<string | null>(
   'SET_PAYMENT_WARNING_MESSAGE',
);

export const setErrorModal = createAction<boolean>('SET_INSCRIBE_ERROR_MODAL');

export default createReducer(INITIAL_STATE, {
   [getClasses.type]: (state, action: PayloadAction<SearchClasses>) => {
      return {
         ...state,
         classes: action.payload,
         loading: false,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
         loading: true,
      };
   },
   [setLoadingClassDetails.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingClassDetails: action.payload,
      };
   },
   [setClassDetails.type]: (state, action: PayloadAction<ClassDetails>) => {
      return {
         ...state,
         classDetails: action.payload,
      };
   },
   [setKitClassDetails.type]: (
      state,
      action: PayloadAction<KitClassDetails>,
   ) => {
      return {
         ...state,
         kitClassDetails: action.payload,
      };
   },
   [setKitClasses.type]: (state, action: PayloadAction<any[]>) => {
      return {
         ...state,
         kitClasses: action.payload,
      };
   },
   [setEntityPersonsList.type]: (
      state,
      action: PayloadAction<EntityPerson[]>,
   ) => {
      return {
         ...state,
         entityPersonsList: action.payload,
      };
   },
   [setLoadingEntityPersonList.type]: (
      state,
      action: PayloadAction<boolean>,
   ) => {
      return {
         ...state,
         loadingEntityPersonList: action.payload,
      };
   },
   [setEntityPersonListTotalRecords.type]: (
      state,
      action: PayloadAction<number>,
   ) => {
      return {
         ...state,
         entityPersonListTotalRecords: action.payload,
      };
   },
   [setInscribeClassErrorMessage.type]: (
      state,
      action: PayloadAction<string>,
   ) => {
      return {
         ...state,
         inscribeClassErrorMessage: action.payload,
      };
   },
   [setLoadingInscribeClass.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingInscribeClass: action.payload,
      };
   },
   [setAreYouSureModal.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         modals: { ...state.modals, areYouSure: action.payload },
      };
   },
   [setErrorModal.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         modals: { ...state.modals, errorMessage: action.payload },
      };
   },
   [setPaymentWarningMessage.type]: (
      state,
      action: PayloadAction<string | null>,
   ) => {
      return {
         ...state,
         modals: {
            ...state.modals,
            paymentWarningMessage: action.payload,
         },
      };
   },
   [setReloadClassDetails.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         reloadClassDetails: action.payload,
      };
   },
});
