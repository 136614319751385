import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Option, SocProfile } from 'services/socprofilesService';

interface InitialStateType {
   howDidKnowOptionsList: Option[];
   maritalStatusOptionsList: Option[];
   targetAudienceOptionsList: Option[];
   monthlyIncomeRangeOptionsList: Option[];
   registeredCadUnicoOptionsList: Option[];
   financialBenefitOptionsList: Option[];
   currentOccupationOptionsList: Option[];
   // loading: boolean;
   socprofile: SocProfile;
}

const INITIAL_STATE: InitialStateType = {
   howDidKnowOptionsList: [],
   maritalStatusOptionsList: [],
   targetAudienceOptionsList: [],
   monthlyIncomeRangeOptionsList: [],
   registeredCadUnicoOptionsList: [],
   financialBenefitOptionsList: [],
   currentOccupationOptionsList: [],
   socprofile: {
      idHowDidKnow: 0,
      idMaritalStatus: 0,
      idMonthlyIncomeRange: 0,
      idRegisteredCadUnico: 0,
      idFinancialBenefit: 0,
      idCurrentOccupation: 0,
      haveChildren: undefined,
      numberChildren: 0,
      numberPeopleLive: 0,
      numberPeopleWork: null,
      propertyOwner: null,
      egressPrisonSystem: undefined,
      egressCasaFoundation: undefined,
      descriptionHowDidKnow: '',
      descriptionCurrentOccupation: '',
      idsTargetAudiences: [],
      descriptionTargetAudiences: '',
   },
};
// loading: true,;

export const getHowDidKnowOptionsList =
   createAction<Option[]>('GET_HOW_DID_KNOW');
export const getMaritalStatusOptionsList =
   createAction<Option[]>('GET_MARITAL_STATUS');
export const getTargetAudienceOptionsList = createAction<Option[]>(
   'GET_TARGET_AUDIENCE',
);
export const getMonthlyIncomeRangeOptionsList = createAction<Option[]>(
   'GET_MONTHLY_INCOME_RANGE',
);
export const getRegisteredCadUnicoOptionsList = createAction<Option[]>(
   'GET_REGISTERED_CAD_UNICO',
);
export const getFinancialBenefitOptionsList = createAction<Option[]>(
   'GET_FINANCIAL_BENEFIT',
);
export const getCurrentOccupationOptionsList = createAction<Option[]>(
   'GET_CURRENT_OCCUPATION',
);
export const getSocprofile = createAction<SocProfile>('GET_SOCPROFILE');
export const clearSocprofile = createAction('CLEAR_SOCPROFILE');
export const setLoading = createAction<boolean>('SET_LOADING');

export default createReducer(INITIAL_STATE, {
   [getHowDidKnowOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         howDidKnowOptionsList: action.payload,
      };
   },
   [getMaritalStatusOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         maritalStatusOptionsList: action.payload,
      };
   },
   [getTargetAudienceOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         targetAudienceOptionsList: action.payload,
      };
   },
   [getMonthlyIncomeRangeOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         monthlyIncomeRangeOptionsList: action.payload,
      };
   },
   [getRegisteredCadUnicoOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         registeredCadUnicoOptionsList: action.payload,
      };
   },
   [getFinancialBenefitOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         financialBenefitOptionsList: action.payload,
      };
   },
   [getCurrentOccupationOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         currentOccupationOptionsList: action.payload,
      };
   },
   [getSocprofile.type]: (state, action: PayloadAction<SocProfile>) => {
      return {
         ...state,
         socprofile: action.payload,
      };
   },
   [clearSocprofile.type]: state => {
      return {
         ...state,
         socprofile: INITIAL_STATE.socprofile,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
      };
   },
});
