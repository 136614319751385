/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/prefer-as-const */
import { Layout, PageHeader, Table, Collapse, List, Typography } from 'antd';
import {
   PlusOutlined,
   MinusOutlined,
   ArrowLeftOutlined,
} from '@ant-design/icons';
import './styles.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'config/store';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/hooks';
import {
   clearProductReduce,
   Product,
   searchProductsByKit,
} from 'services/productsService';
import { getEntitys } from 'services/entitesSelectService';
import { getProgramAndEntityName } from 'actions/programsAction';
import { getInscribeAdheredPrograms } from 'services/inscribeService';
import Labels from 'helpers/enum/Labels';
import { t } from 'config/language/i18n';
import {
   setSelectedIsKit,
   setSelectedProductName,
   setSelectedProgramId,
   setSelectedProgramName,
} from 'actions/inscribeAction';

const { Content } = Layout;
const { Panel } = Collapse;
const { Title } = Typography;

const columns = [
   {
      title: 'Nome do programa',
      dataIndex: 'programs',
      key: 'name',
      render: (programs: { name: string }) => {
         return programs.name;
      },
   },
];

const InscribeEntityCollaboratorParticipant = () => {
   const dispatch = useAppDispatch();
   const history = useHistory();
   const { id } = useParams<{ id?: string }>();
   const { adheredPrograms, loadingAdheredPrograms } = useSelector(
      (state: RootState) => state.inscribe,
   );
   const { product, loadingProducts } = useSelector(
      (state: RootState) => state.products,
   );
   const { entity } = useSelector((state: RootState) => state.entities);
   const [expandedRowKeys, setExpandedRowKeys] = useState([]);

   useEffect(() => {
      dispatch(getInscribeAdheredPrograms(id));
      dispatch(getEntitys(id as string));
   }, [dispatch, id]);

   const handleOnClick = (products: Product) => {
      dispatch(clearProductReduce());
      if (products.isKit) {
         dispatch(searchProductsByKit(products.id));
      }
   };

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const onTableRowExpand = (expanded: any, record: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const keys = [] as never | any;
      if (expanded) {
         keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
      }

      setExpandedRowKeys(keys);
   };

   const handleProductClick = (
      idProduct: string,
      programName: string,
      isKit: boolean,
   ) => {
      history.push(`/inscribePerson/productDetail/${idProduct}`);
      const data = {
         programName,
         entityName: entity.name,
         isKit,
      };
      dispatch(getProgramAndEntityName(data));
   };

   return (
      <div className="inscribe-entity-collaborator-participant-container">
         <Layout>
            <Content className="site-layout-background">
               <div className="title">
                  <ArrowLeftOutlined
                     onClick={() => {
                        history.goBack();
                     }}
                  />
                  <PageHeader title="Inscrever Colaborador ou Participante da Entidade" />
               </div>
               <p className="warning-text">
                  Selecione o programa e o produto da inscrição:{' '}
               </p>
               <Title level={5}>Você Selecionou: {entity.name}</Title>
               <Table
                  columns={columns}
                  dataSource={adheredPrograms}
                  loading={loadingAdheredPrograms}
                  locale={
                     loadingAdheredPrograms
                        ? {
                             emptyText: t(Labels.LOADING),
                          }
                        : {
                             emptyText: t(Labels.NO_DATA),
                          }
                  }
                  rowKey={record => record.id}
                  expandedRowKeys={expandedRowKeys}
                  onExpand={onTableRowExpand}
                  expandable={{
                     // eslint-disable-next-line react/prop-types
                     expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                           <MinusOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                           <PlusOutlined onClick={e => onExpand(record, e)} />
                        ),
                     expandIconColumnIndex: 4,
                     rowExpandable: record =>
                        record.adheredProduct.length !== 0,
                     expandedRowRender: record => {
                        return (
                           <Collapse accordion>
                              {record.adheredProduct.map(
                                 (adhProduct, index) => (
                                    <Panel
                                       className="product-panel"
                                       header={
                                          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                                          <div
                                             role="button"
                                             onClick={() =>
                                                handleOnClick(
                                                   adhProduct.products,
                                                )
                                             }
                                          >
                                             <h5>{adhProduct.products.name}</h5>
                                          </div>
                                       }
                                       extra={
                                          <button
                                             type="button"
                                             onClick={() => {
                                                handleProductClick(
                                                   adhProduct.products.id,
                                                   record.programs.name,
                                                   adhProduct.products.isKit,
                                                );
                                                dispatch(
                                                   setSelectedProgramName(
                                                      record.programs.name,
                                                   ),
                                                );
                                                dispatch(
                                                   setSelectedProgramId(
                                                      record.idPrograms,
                                                   ),
                                                );
                                                dispatch(
                                                   setSelectedIsKit(
                                                      adhProduct.products.isKit,
                                                   ),
                                                );
                                                dispatch(
                                                   setSelectedProductName(
                                                      adhProduct.products.name,
                                                   ),
                                                );
                                             }}
                                          >
                                             Detalhes do Produto
                                          </button>
                                       }
                                       collapsible="header"
                                       key={index}
                                       showArrow={false}
                                    >
                                       {adhProduct.products.isKit ? (
                                          <List
                                             dataSource={product}
                                             loading={loadingProducts}
                                             renderItem={p => {
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                return p.data.map((e: any) => {
                                                   return (
                                                      <List.Item>
                                                         <p>{e.name}</p>
                                                      </List.Item>
                                                   );
                                                });
                                             }}
                                          />
                                       ) : null}
                                    </Panel>
                                 ),
                              )}
                           </Collapse>
                        );
                     },
                  }}
               />
            </Content>
         </Layout>
      </div>
   );
};

export default InscribeEntityCollaboratorParticipant;
