import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  Option,
  ProductOption,
  SearchCSV,
  SearchPaginated,
  GetCertificate,
  DownloadFlag
} from 'services/relatoryService';

export interface InitialStateType {
  relatories: SearchPaginated;
  relatoryCsv: string;
  loading: boolean;
  loadingCSV: boolean;
  loadingModal: boolean;
  giveUpReasonList: Option[];
  productsOptionsList: ProductOption[];
  isLoading: boolean;
  downLoading: number;
}

const INITIAL_STATE: InitialStateType = {
  relatories: {} as SearchPaginated,
  productsOptionsList: [],
  relatoryCsv: '',
  giveUpReasonList: [],
  loading: false,
  loadingCSV: false,
  loadingModal: false,
  isLoading: false,
  downLoading: 0,
};

export const getRelatories = createAction<SearchPaginated>('GET_RELATORIES');

export const getCertificate = createAction<GetCertificate>('GET_CERTIFICATE');

export const getRelatoryCSV = createAction<SearchCSV>('GET_RELATORY_CSV');

export const getGiveUpReasonAction = createAction<Option[]>(
  'GET_GIVE_UP_REASON_ACTION',
);

export const startLoadingCSV = createAction('START_LOADING_CSV');
export const endLoadingCSV = createAction('END_LOADING_CSV');

export const startDownLoading = createAction<DownloadFlag>('START_LOADING_CERT');
export const endDownLoading = createAction('END_LOADING_CERT');

export const startLoadingTable = createAction('START_LOADING_TABLE');
export const endLoadingTable = createAction('END_LOADING_TABLE');

export const startLoadingModal = createAction('START_LOADING_MODAL');
export const endLoadingModal = createAction('END_LOADING_MODAL');

export const startLoadingProducts = createAction('START_LOADING_PRODUCTS');
export const endLoadingProducts = createAction('END_LOADING_PRODUCTS');

export const getProductList =
  createAction<ProductOption[]>('GET_PRODUCTS_LIST');

export const clearCSV = createAction('CLEAR_CSV');

export default createReducer(INITIAL_STATE, {
  [getRelatories.type]: (
    state: any,
    action: PayloadAction<SearchPaginated>,
  ) => {
    return {
      ...state,
      relatories: action.payload,
      loading: false,
    };
  },
  [getRelatoryCSV.type]: (state, action: PayloadAction<string>) => {
    return {
      ...state,
      relatoryCsv: action.payload,
      loadingCSV: false,
    };
  },
  [getGiveUpReasonAction.type]: (state, action: PayloadAction<Option[]>) => {
    return {
      ...state,
      giveUpReasonList: action.payload,
    };
  },
  [startLoadingCSV.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'loadingCSV' : payload]: true,
    };
  },
  [endLoadingCSV.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'loadingCSV' : payload]: false,
    };
  },
  [startDownLoading.type]: (state, action:  PayloadAction<DownloadFlag>) => {
    return {
      ...state,
      downLoading: action.payload.id,
    };
  },
  [endDownLoading.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'downLoading' : payload]: 0,
    };
  },
  [startLoadingTable.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'loading' : payload]: true,
    };
  },
  [endLoadingTable.type]: (state, { payload }) => ({
    ...state,
    [!payload ? 'loading' : payload]: false,
  }),
  [startLoadingModal.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'loadingModal' : payload]: true,
    };
  },
  [endLoadingModal.type]: (state, { payload }) => ({
    ...state,
    [!payload ? 'loadingModal' : payload]: false,
  }),
  [getProductList.type]: (state, action: PayloadAction<ProductOption[]>) => {
    return {
      ...state,
      productsOptionsList: action.payload,
    };
  },
  [startLoadingProducts.type]: (state, { payload }) => {
    return {
      ...state,
      [!payload ? 'isLoading' : payload]: true,
    };
  },
  [endLoadingProducts.type]: (state, { payload }) => ({
    ...state,
    [!payload ? 'isLoading' : payload]: false,
  }),
  [clearCSV.type]: state => {
    return {
      ...state,
      relatoryCsv: INITIAL_STATE.relatoryCsv,
    };
  },
});
