import { message } from 'antd';
import {
   getScholaritiesOptionsList,
   getEthnicitiesOptionsList,
   getDisabilitiesOptionsList,
   getStreetTypeOptionsList,
   getStateAddressOptionsList,
   getCityOptionsList,
   getPerson,
   getPersonTypeOptionsList,
   getEntityOptionsList,
   setLoadingEntities,
   clearPersonAction,
   startLoading,
   endLoading,
   disableCpf,
   enableCpf,
   getPersons,
   setLoadingSearch,
   getLgpdConsentAction,
   disableDisability,
   enableDisability,
   startLoadingCities,
   endLoadingCities,
   startLoadingEntities,
   endLoadingEntities,
} from '../actions/naturalPersonAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';
import {
   Entrepreneurs,
   handleEntrepreneurs,
   postEntrepreneurs,
} from './entrepreneursService';
import {
   getSocprofileService,
   postSocprofile,
   SocProfile,
} from './socprofilesService';
import { getLastLgpdConsentTerm } from './termsService';

export interface Option {
   id: number;
   name: string;
}

export interface OptionList {
   success: boolean;
   message: string;
   data: Option[];
}

export interface StateAddressOptionList {
   success: boolean;
   message: string;
   data: StateAddressOption[];
}
export interface StateAddressOption {
   initials: string;
}

export interface PersonAddress {
   neighborhood: string;
   cep: string;
   complement: string;
   idCity: string | null;
   idStreetType: number | null;
   street: string;
   number: string;
   uf: string;
}

export interface Person {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   entities: any;
   name: string;
   cpf: string;
   birthDate: string;
   socialName: string;
   idDisability: number | null;
   idScholarity: number | null;
   idEthnicity: number | null;
   sex: string;
   personAddress: PersonAddress;
   cellphone: string;
   cellphone2: string;
   telephone: string;
   telephone2: string;
   mail: string | null;
   hasMail: boolean;
   acceptDate: string;
   uf: string;
   state: string;
   idPersonType: number | null;
   accept: boolean | null;
   userCpfAccept: string | null;
   usernameRegisteredConsent: string | null;
   otherName: boolean | null;
   disability: boolean | null;
}

export interface SearchPerson {
   // eslint-disable-next-line
   data: Person;
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

interface Lgpd {
   accept: boolean;
   acceptDate: string;
   idLgpdConsentRae: number;
   usernameRegisteredConsent: string;
   uf: string;
}
export interface SearchLgpd {
   success: boolean;
   message: string;
   data: Lgpd;
}

interface PersonSearch {
   cpf: string;
   name: string;
   entities: PersonEntities[];
}

interface PersonEntities {
   id: string;
   name: string;
   cnpj: string;
   corporateName: string;
}

export interface SearchPersons {
   data: PersonSearch[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

export interface SearchEntitiesApiResponse {
   // eslint-disable-next-line
   success: boolean;
   message: string;
   data: Entity[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

export interface Entity {
   id: string;
   name: string;
   cnpj: string;
}

export const getPersonTypes = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/PersonType', {})
         .then(async ({ data: payload }) => {
            dispatch(getPersonTypeOptionsList(payload.data));
            dispatch(getLastLgpdConsentTerm());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getStateAddress = () => {
   return async (dispatch: AppDispatch) => {
      api.get<StateAddressOptionList>('/Address/states', {})
         .then(async ({ data: payload }) => {
            dispatch(getStateAddressOptionsList(payload.data));
            dispatch(getPersonTypes());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getStreetTypes = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/Address/streetTypes', {})
         .then(async ({ data: payload }) => {
            dispatch(getStreetTypeOptionsList(payload.data));
            dispatch(getStateAddress());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getScholarities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/Person/scholarities', {})
         .then(async ({ data: payload }) => {
            dispatch(getScholaritiesOptionsList(payload.data));
            dispatch(getStreetTypes());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getEthnicities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/Person/ethnicities', {})
         .then(async ({ data: payload }) => {
            dispatch(getEthnicitiesOptionsList(payload.data));
            dispatch(getScholarities());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getDisabilities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/Person/disabilities', {})
         .then(async ({ data: payload }) => {
            dispatch(getDisabilitiesOptionsList(payload.data));
            dispatch(getEthnicities());
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const searchCities = (cityPartialName: string, addressState: string) => {
   const searchCity = {
      uf: addressState,
      name: cityPartialName,
      pageNumber: 1,
      pageSize: 30,
   };
   return async (dispatch: AppDispatch) => {
      dispatch(startLoadingCities());
      api.get<OptionList>('/Address/cities', { params: searchCity })
         .then(async ({ data: payload }) => {
            dispatch(getCityOptionsList(payload.data));
            dispatch(endLoadingCities());
         })
         // eslint-disable-next-line no-console
         .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
            dispatch(endLoadingCities());
         });
   };
};

export const searchEntities = (entityPartialName: string) => {
   const searchEntitiesParams = {
      name: entityPartialName,
      pageNumber: 1,
      pageSize: 30,
   };
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingEntities(true));
      dispatch(startLoadingEntities());
      api.get<SearchEntitiesApiResponse>('/Entity', {
         params: searchEntitiesParams,
      })
         .then(async ({ data: payload }) => {
            dispatch(getEntityOptionsList(payload.data));
            dispatch(setLoadingEntities(false));
            dispatch(endLoadingEntities());
         })
         .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
            dispatch(endLoadingEntities());
         });
   };
};

export const searchPersonCpf = async (cpf = '') => {
   return async (dispatch: AppDispatch) => {
      dispatch(startLoading());
      api.get<SearchPerson>(`/Person/${cpf}`, {})
         .then(async ({ data: payload }) => {
            api.get(`/Address/cities/${payload.data.personAddress.idCity}`, {})
               // eslint-disable-next-line @typescript-eslint/no-explicit-any
               .then(async (cityData: any) => {
                  const city = cityData.data.data;
                  dispatch(searchCities(city.name, city.uf));
                  if (payload.data.entities) {
                     dispatch(
                        searchEntities(
                           // eslint-disable-next-line @typescript-eslint/no-explicit-any
                           payload.data.entities.map((el: any) => {
                              return el.name;
                           }),
                        ),
                     );
                  }
                  const data = {
                     ...payload.data,
                     hasMail: !payload.data.hasMail,
                     personAddress: {
                        uf: city.uf,
                        neighborhood: payload.data.personAddress.neighborhood,
                        cep: payload.data.personAddress.cep,
                        complement: payload.data.personAddress.complement,
                        idCity: city.id,
                        idStreetType: payload.data.personAddress.idStreetType,
                        street: payload.data.personAddress.street,
                        number: payload.data.personAddress.number,
                     },
                     entities: payload.data.entities
                        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          payload.data.entities.map((el: any) => {
                             return el.id;
                          })
                        : [],
                     otherName: !!payload.data.socialName,
                     disability: payload.data.idDisability !== null,
                  };

                  dispatch(disableCpf());

                  // eslint-disable-next-line no-unused-expressions
                  data.disability === true
                     ? dispatch(enableDisability())
                     : dispatch(disableDisability());
                  dispatch(getPerson(data));
                  dispatch(await handleEntrepreneurs(data.cpf));
                  dispatch(await getSocprofileService(data.cpf));
                  api.get(`/Person/${cpf}/lgpdConsent`, {})
                     // eslint-disable-next-line @typescript-eslint/no-explicit-any
                     .then((lgpdData: any) => {
                        const dataLgpd = {
                           ...data,
                           accept: lgpdData.data.data.accept,
                           acceptDate: lgpdData.data.data.acceptDate,
                           uf: lgpdData.data.data.uf,
                           userCpfAccept:
                              lgpdData.data.data.usernameRegisteredConsent,
                        };
                        dispatch(getLgpdConsentAction(dataLgpd));
                        dispatch(endLoading());
                     })
                     // eslint-disable-next-line
                     .catch(err => {
                        dispatch(endLoading());
                     });
               });
         })
         // eslint-disable-next-line
         .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
            const data = {
               cpf,
               entities: [],
               name: '',
               birthDate: '',
               socialName: '',
               idDisability: null,
               idScholarity: null,
               idEthnicity: null,
               sex: '',
               personAddress: {
                  neighborhood: '',
                  cep: '',
                  complement: '',
                  idCity: '',
                  idStreetType: null,
                  street: '',
                  number: '',
                  uf: '',
               },
               cellphone: '',
               cellphone2: '',
               telephone: '',
               telephone2: '',
               mail: null,
               hasMail: false,
               acceptDate: '',
               uf: '',
               state: '',
               idPersonType: null,
               accept: null,
               userCpfAccept: null,
               usernameRegisteredConsent: null,
               otherName: false,
               disability: false,
            };
            dispatch(getPerson(data));
            dispatch(endLoading());
            dispatch(enableCpf());
         });
   };
};

export const clearPerson = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearPersonAction());
   };
};

export const setPerson = async (
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   data: any,
   formData: FormData,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   resetForm: any,
   entrepreneurs: Entrepreneurs,
   socprofile: SocProfile,
) => {
   return async (dispatch: AppDispatch) => {
      await api
         .post<FormData>(`/Person`, data)
         .then(async () => {
            // eslint-disable-next-line no-console
            if (
               socprofile.idHowDidKnow !== 0 &&
               socprofile.idCurrentOccupation !== 0
            ) {
               dispatch(await postSocprofile(socprofile, data.cpf));
            }
            if (
               entrepreneurs.haveOwner !== null &&
               entrepreneurs.idDreams.length !== 0
            ) {
               dispatch(await postEntrepreneurs(entrepreneurs, data.cpf));
            }

            if (
               formData.get('accept') !== 'undefined' &&
               formData.get('accept') !== 'null'
            ) {
               await api
                  .post<FormData>(`/Person/${data.cpf}/lgpdConsent`, formData)
                  .then(async ({ data: payloadTerm }) => {
                     // eslint-disable-next-line no-console
                     console.log('setTerm', payloadTerm);
                     if (formData.get('file') !== 'null') {
                        message.success({
                           content:
                              'Termo de consentimento LGPD salvo com sucesso',
                           className: 'success',
                           duration: 10,
                        });
                     }
                  }) // eslint-disable-next-line
                  .catch((error: any) => {
                     // eslint-disable-next-line no-console
                     console.log(error);
                     message.error({
                        content: 'Erro ao salvar o termo de consentimento LGPD',
                        className: 'error',
                        duration: 10,
                     });
                  });
            }

            message.success({
               content: 'Pessoa física salva com sucesso',
               className: 'success',
               duration: 10,
            });
            resetForm();
            dispatch(clearPerson());
         })
         // eslint-disable-next-line
         .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            message.error({
               content: 'Erro ao salvar o cadastro',
               className: 'error',
               duration: 10,
            });
         });
   };
};

export const searchPerson = (
   name = '',
   cpf = '',
   pageNumber = 1,
   pageSize = 5,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchPersons>('/Person', {
         params: {
            Name: name,
            Cpf: cpf,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getPersons(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoadingSearch(true));
   };
};

export default { searchPersonCpf };
