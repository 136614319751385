/* eslint-disable @typescript-eslint/prefer-as-const */
import {
   setSelectedEntityId,
   setSelectedEntityName,
} from 'actions/inscribeAction';
import { Button, Col, Input, Layout, PageHeader, Row, Table, Form } from 'antd';
import Utils from 'helpers/Utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { searchInscribeEntities } from 'services/inscribeService';
import { RootState } from '../../config/store';
import './styles.scss';

const { Content } = Layout;

interface FormErrors {
   cnpj: null | string;
}

const InscribeEntityList = () => {
   const history = useHistory();
   const dispatch = useDispatch();

   const { entityList, loadingEntityList } = useSelector(
      (state: RootState) => state.inscribe,
   );
   const [page, setPage] = useState(1);
   const [serachName, setSearchName] = useState('');
   const [serachCnpj, setSearchCnpj] = useState('');
   const [formErrors, setFormErrors] = useState<FormErrors>({ cnpj: null });

   const columns = [
      {
         title: 'Nome da Entidade',
         dataIndex: 'name',
         key: 'name',
         width: '30%',
         align: 'center' as 'center',
      },
      {
         title: 'Razão Social',
         dataIndex: 'corporateName',
         key: 'products',
         width: '30%',
         align: 'center' as 'center',
      },
      {
         title: 'CNPJ',
         dataIndex: 'cnpj',
         key: 'cnpj',
         width: '30%',
         align: 'center' as 'center',
         render: (cnpj: string) => {
            if (cnpj) {
               return `${Utils.formatCnpj(cnpj)}`;
            }
            return '';
         },
      },
      {
         title: '',
         dataIndex: '',
         key: 'button',
         width: '10%',
         render: (entity: { name: string; id: string }) => {
            return (
               <Button
                  onClick={() => {
                     dispatch(setSelectedEntityId(entity.id));
                     dispatch(setSelectedEntityName(entity.name));
                     history.push(`inscribePerson/list/${entity.id}`);
                  }}
               >
                  Selecionar
               </Button>
            );
         },
         align: 'center' as 'center',
      },
   ];

   const handleSearchEntities = (currentPage: number) => {
      dispatch(searchInscribeEntities(serachName, serachCnpj, currentPage));
   };

   useEffect(() => {
      dispatch(searchInscribeEntities('', '', page));
      dispatch(setSelectedEntityName(''));
      dispatch(setSelectedEntityId(''));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch]);

   return (
      <div className="inscribe-entity-list-container">
         <Layout>
            <Content className="site-layout-background">
               <PageHeader title="Inscrever Colaborador ou Participante da Entidade" />
               <p>Antes de inscrever um participante selecione a entidade</p>
               <Form
                  onFinish={() => {
                     setPage(1);
                     handleSearchEntities(1);
                  }}
               >
                  <Row
                     className="search-input-row"
                     align="top"
                     justify="space-between"
                  >
                     <Col className="search-input-name-column">
                        <p className="search-input-label">Nome da Entidade</p>
                        <Form.Item name="name">
                           <Input
                              onChange={e => setSearchName(e.target.value)}
                              placeholder="Digite aqui..."
                              size="large"
                           />
                        </Form.Item>
                     </Col>
                     <Col className="search-input-cnpj-column">
                        <p className="search-input-label">CNPJ</p>
                        <Form.Item name="cnpj">
                           <Input
                              onChange={e => {
                                 Utils.validateCPFandCNPJSearchSize(
                                    e.target.value,
                                    'cnpj',
                                    formErrors,
                                    setFormErrors,
                                 );
                                 setSearchCnpj(e.target.value);
                              }}
                              placeholder="Digite aqui..."
                              size="large"
                           />
                           <div className="form-error-message">
                              {formErrors.cnpj}
                           </div>
                        </Form.Item>
                     </Col>
                     <Col
                        className="search-input-button-column"
                        span={24}
                        md={2}
                     >
                        <Form.Item>
                           <Button
                              htmlType="submit"
                              className="search-button"
                              disabled={formErrors.cnpj !== null}
                           >
                              Pesquisar
                           </Button>
                        </Form.Item>
                     </Col>
                  </Row>
               </Form>
               <Table
                  columns={columns}
                  dataSource={entityList?.data}
                  loading={loadingEntityList}
                  pagination={{
                     current: page,
                     onChange: current => {
                        setPage(current);
                        handleSearchEntities(current);
                     },
                     total: entityList?.totalRecords,
                     showSizeChanger: false,
                  }}
               />
            </Content>
         </Layout>
      </div>
   );
};

export default InscribeEntityList;
