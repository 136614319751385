import { clearEntrepreneurs } from 'actions/entrepreneursAction';
import {
   getHowDidKnowOptionsList,
   getMaritalStatusOptionsList,
   getTargetAudienceOptionsList,
   getMonthlyIncomeRangeOptionsList,
   getRegisteredCadUnicoOptionsList,
   getFinancialBenefitOptionsList,
   getCurrentOccupationOptionsList,
   clearSocprofile,
   getSocprofile,
} from '../actions/socprofilesAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

export interface Option {
   id: string;
   name: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   data: any[];
}

export interface OptionList {
   success: boolean;
   message: string;
   data: Option[];
}

export interface GetSocProfile {
   success: boolean;
   message: string;
   data: SocProfile;
}

export interface SocProfile {
   idHowDidKnow: number;
   idMaritalStatus: number;
   idMonthlyIncomeRange: number;
   idRegisteredCadUnico: number;
   idFinancialBenefit: number;
   idCurrentOccupation: number;
   haveChildren: boolean | null | undefined;
   numberChildren: number;
   numberPeopleLive: number;
   numberPeopleWork: number | null;
   propertyOwner: boolean | null;
   egressPrisonSystem: boolean | null | undefined;
   egressCasaFoundation: boolean | null | undefined;
   descriptionHowDidKnow: string;
   descriptionCurrentOccupation: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   idsTargetAudiences: string[];
   descriptionTargetAudiences: string;
}

export const getHowDidKnow = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/HowDidKnow', {})
         .then(async ({ data: payload }) => {
            dispatch(getHowDidKnowOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getMaritalStatus = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/MaritalStatus', {})
         .then(async ({ data: payload }) => {
            dispatch(getMaritalStatusOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getTargetAudience = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/TargetAudience', {})
         .then(async ({ data: payload }) => {
            dispatch(getTargetAudienceOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getMonthlyIncomeRange = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/MonthlyIncomeRange', {})
         .then(async ({ data: payload }) => {
            dispatch(getMonthlyIncomeRangeOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getRegisteredCadUnico = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/RegisteredCadUnico', {})
         .then(async ({ data: payload }) => {
            dispatch(getRegisteredCadUnicoOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getFinancialBenefit = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/FinancialBenefit', {})
         .then(async ({ data: payload }) => {
            dispatch(getFinancialBenefitOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getCurrentOccupation = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/CurrentOccupation', {})
         .then(async ({ data: payload }) => {
            dispatch(getCurrentOccupationOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const postSocprofile = async (socprofile: SocProfile, cpf: string) => {
   const finalScorprofile = {
      ...socprofile,
      idsTargetAudiences: socprofile.idsTargetAudiences.map((id: string) => {
         return Number(id);
      }),
   };
   return async (dispatch: AppDispatch) => {
      await api
         .post<FormData>(
            `/Person/${cpf}/SocioeconomicProfile`,
            finalScorprofile,
         )
         .then(async () => {
            dispatch(clearSocprofile());
            dispatch(clearEntrepreneurs());
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const getSocprofileService = async (cpf: string) => {
   return async (dispatch: AppDispatch) => {
      await api
         .get<GetSocProfile>(`/Person/${cpf}/SocioeconomicProfile`, {})
         .then(async ({ data: payload }) => {
            const finalSocprofile = {
               ...payload.data,
               idsTargetAudiences: payload.data.idsTargetAudiences.map(
                  (id: string) => {
                     return id.toString();
                  },
               ),
            };
            dispatch(getSocprofile(finalSocprofile));
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};
