import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchTypeProfiles } from '../services/typeProfilesService';

interface InitialStateType {
   typeProfiles: SearchTypeProfiles;
}

const INITIAL_STATE: InitialStateType = {
   typeProfiles: {
      data: [],
      success: true,
   }, // {} as SearchTypeProfiles,
};

export const getTypeProfiles =
   createAction<SearchTypeProfiles>('GET_TYPE_PROFILE');

export default createReducer(INITIAL_STATE, {
   [getTypeProfiles.type]: (
      state,
      action: PayloadAction<SearchTypeProfiles>,
   ) => {
      return {
         ...state,
         typeProfiles: action.payload,
      };
   },
});
