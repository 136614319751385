import { useFormikContext } from 'formik';
import { ReactNode } from 'react';

interface FieldProps {
   title: string;
   children: ReactNode;
   name: string;
}

export const Field = ({ title, children, name }: FieldProps) => {
   const { touched, errors } = useFormikContext<never>();
   return (
      <>
         <div className="title">{title}</div>
         {children}
         <div className="errorText">{touched[name] && errors[name]}</div>
      </>
   );
};

export default Field;
