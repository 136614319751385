import { Select } from 'formik-antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SyntheticEvent } from 'react';
import { Spin } from 'antd';

const { Option } = Select;

// interface OnPopupScrollProps {
//    target: {
//       scrollTop: number;
//       offsetHeight: number;
//       scrollHeight: number;
//    };
// }

interface ObjectProps {
   id: string;
   name: string;
}
interface MultiSelectProps {
   nameSelect: string;
   testid: string;
   placeholder: string;
   onTheEnd: () => void;
   handleSearch: (value: string) => void;
   showArrow: boolean;
   onChange: (e: string) => void;
   disabled: boolean;
   sizeSelect: SizeType;
   className: string;
   horizontal: boolean;
   itens: object[];
   listValues: ObjectProps[];
   allowClear?: boolean;
   loading?: boolean;
   loadingArrow?: boolean;
   filterOption?: boolean;
   notFoundContent?: boolean;
}

export const MultiSelect = ({
   nameSelect,
   placeholder,
   onTheEnd,
   handleSearch,
   showArrow,
   filterOption = showArrow,
   notFoundContent = showArrow,
   onChange,
   disabled,
   sizeSelect,
   className,
   horizontal,
   itens,
   listValues,
   testid,
   allowClear,
   loading,
   loadingArrow = true,
}: MultiSelectProps) => {
   return (
      <div className={className}>
         <Select
            name={nameSelect}
            data-testid={testid}
            style={{
               width: '100%',
               textAlign: 'left',
               fontSize: 20,
            }}
            placeholder={placeholder}
            size={sizeSelect}
            onPopupScroll={(e: SyntheticEvent<HTMLDivElement>) => {
               const { currentTarget } = e;
               if (
                  currentTarget.scrollTop + currentTarget.offsetHeight ===
                  currentTarget.scrollHeight
               ) {
                  onTheEnd();
               }
            }}
            onSearch={handleSearch}
            showSearch
            showArrow={showArrow}
            filterOption={filterOption}
            notFoundContent={notFoundContent}
            onChange={onChange}
            disabled={disabled}
            allowClear={allowClear}
            loading={loadingArrow}
         >
            {loading ? (
               <Option value="loading">
                  <Spin />
               </Option>
            ) : (
               listValues.map(form => {
                  return <Option value={form.id}>{form.name}</Option>;
               })
            )}
         </Select>
         <div
            className="div-cards"
            style={{
               display: horizontal ? 'inline-block' : 'block',
            }}
         >
            {itens?.map(item => {
               return (
                  <div className="productView">
                     <div className="productViewSpace">{item}</div>
                  </div>
               );
            })}
         </div>
      </div>
   );
};
export default MultiSelect;
