import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Option, Entrepreneurs } from 'services/entrepreneursService';

interface InitialStateType {
   entrepreneurs: Entrepreneurs;
   businessSectorOptionsList: Option[];
   businessPurposeOptionsList: Option[];
   withoutCNPJReasonOptionsList: Option[];
   haventBusinessOptionsList: Option[];
   whosOwnerOptionsList: Option[];
   businessHelperOptionsList: Option[];
   businessDesireOptionsList: Option[];
   businessMotivationOptionsList: Option[];
   dreamsOptionsList: Option[];
   gaveUpBusinessOptionsList: Option[];
   reasonHaveBusinessOptionsList: Option[];
   loading: boolean;
}

const INITIAL_STATE: InitialStateType = {
   businessSectorOptionsList: [],
   businessPurposeOptionsList: [],
   withoutCNPJReasonOptionsList: [],
   haventBusinessOptionsList: [],
   whosOwnerOptionsList: [],
   businessHelperOptionsList: [],
   businessDesireOptionsList: [],
   businessMotivationOptionsList: [],
   dreamsOptionsList: [],
   gaveUpBusinessOptionsList: [],
   reasonHaveBusinessOptionsList: [],
   entrepreneurs: {
      idBusinessSector: null,
      idBusinessPurpose: null,
      idWithoutCNPJReason: null,
      idHaventBusiness: null,
      idBusinessMotivation: [],
      haveOwner: null,
      idWhosOwner: [],
      idBusinessHelper: null,
      idBusinessDesire: null,
      idDreams: [],
      idGaveUpBusiness: [],
      idReasonHaveBusiness: null,
      cnpjDescription: '',
      businessPurposeDescription: '',
      businessMotivationDescription: '',
      gaveUpBusinessDescription: '',
      dreamsDescription: '',
      reasonHaveBusinessDescription: '',
      numberOwners: 0,
   },
   loading: true,
};

export const getBusinessSectorOptionsList = createAction<Option[]>(
   'GET_BUSINESS_SECTOR',
);
export const getBusinessPurposeOptionsList = createAction<Option[]>(
   'GET_BUSINESS_PURPOSE',
);
export const getWithoutCNPJReasonOptionsList = createAction<Option[]>(
   'GET_WITHOUT_CNPJ_REASON',
);
export const getWhosOwnerOptionsList = createAction<Option[]>('GET_WHOS_OWNER');
export const getHaventBusinessOptionsList = createAction<Option[]>(
   'GET_HAVENT_BUSINESS',
);
export const getBusinessHelperOptionsList = createAction<Option[]>(
   'GET_BUSINESS_HELPER',
);
export const getBusinessDesireOptionsList = createAction<Option[]>(
   'GET_BUSINESS_DESIRE',
);
export const getBusinessMotivationOptionsList = createAction<Option[]>(
   'GET_BUSINESS_MOTIVATION',
);
export const getDreamsOptionsList = createAction<Option[]>('GET_DREAMS');
export const getGaveUpBusinessOptionsList = createAction<Option[]>(
   'GET_GAVE_UP_BUSINESS',
);
export const getReasonHaveBusinessOptionsList = createAction<Option[]>(
   'GET_REASON_HAVE_BUSINESS',
);
export const getEntrepreneurs =
   createAction<Entrepreneurs>('GET_ENTREPRENEURS');
export const clearEntrepreneurs = createAction('CLEAR_ENTREPRENEURS');
export const setLoading = createAction<boolean>('SET_LOADING');

export default createReducer(INITIAL_STATE, {
   [getBusinessSectorOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         businessSectorOptionsList: action.payload,
      };
   },
   [getBusinessPurposeOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         businessPurposeOptionsList: action.payload,
      };
   },
   [getWithoutCNPJReasonOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         withoutCNPJReasonOptionsList: action.payload,
      };
   },
   [getHaventBusinessOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         haventBusinessOptionsList: action.payload,
      };
   },
   [getWhosOwnerOptionsList.type]: (state, action: PayloadAction<Option[]>) => {
      return {
         ...state,
         whosOwnerOptionsList: action.payload,
      };
   },
   [getBusinessHelperOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         businessHelperOptionsList: action.payload,
      };
   },
   [getBusinessDesireOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         businessDesireOptionsList: action.payload,
      };
   },
   [getBusinessMotivationOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         businessMotivationOptionsList: action.payload,
      };
   },
   [getDreamsOptionsList.type]: (state, action: PayloadAction<Option[]>) => {
      return {
         ...state,
         dreamsOptionsList: action.payload,
      };
   },
   [getGaveUpBusinessOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         gaveUpBusinessOptionsList: action.payload,
      };
   },
   [getReasonHaveBusinessOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         reasonHaveBusinessOptionsList: action.payload,
      };
   },
   [getEntrepreneurs.type]: (state, action: PayloadAction<Entrepreneurs>) => {
      return {
         ...state,
         entrepreneurs: action.payload,
      };
   },
   [clearEntrepreneurs.type]: state => {
      return {
         ...state,
         entrepreneurs: INITIAL_STATE.entrepreneurs,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
      };
   },
});
