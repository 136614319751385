import InscribeEntityCollaboratorParticipant from 'features/inscribeEntityCollaboratorParticipant';
import InscribeEntityList from 'features/inscribeEntityList';
import InscribeEntityProductDetail from 'features/inscribeEntityProductDetail';
import InscribeClassDetails from 'features/inscribeClassDetails';
import ProfilesEnum from 'helpers/enum/ProfilesEnum';
import Relatory from 'features/relatory';
import Dashboard from '../features/dashboard';
import Programs from '../features/programs';
import Add from '../features/programs/add';
import Terms from '../features/terms';
import Profiles from '../features/profiles';
import AddProfiles from '../features/profiles/add';
import Entities from '../features/entities';
import AddEntities from '../features/entities/add';
import AddEntrepreneurs from '../features/entrepreneurs/add';
import AddSocProfiles from '../features/socprofiles/add';
import programAdhesionTerm from '../features/programAdhesionTerm';
import NaturalPerson from '../features/naturalPerson';
import AddNaturalPerson from '../features/naturalPerson/add';

const routes = [
   {
      path: '/',
      exact: true,
      page: Dashboard,
      mainPage: true,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/programs',
      exact: true,
      page: Programs,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/programs/edit/:id',
      exact: true,
      page: Add,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/programs/add',
      exact: true,
      page: Add,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/terms',
      exact: true,
      page: Terms,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/profiles',
      exact: true,
      page: Profiles,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/profiles/add',
      exact: true,
      page: AddProfiles,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/profiles/edit/:id',
      exact: true,
      page: AddProfiles,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE],
   },
   {
      path: '/entities',
      exact: true,
      page: Entities,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE, ProfilesEnum.REPRESENTATIVE],
   },
   {
      path: '/entities/add',
      exact: true,
      page: AddEntities,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE, ProfilesEnum.REPRESENTATIVE],
   },
   {
      path: '/entities/edit/:id',
      exact: true,
      page: AddEntities,
      onlyAuthorized: true,
      profiles: [ProfilesEnum.SEBRAE, ProfilesEnum.REPRESENTATIVE],
   },
   {
      path: '/inscribePerson',
      exact: true,
      page: InscribeEntityList,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/inscribePerson/list/:id',
      exact: true,
      page: InscribeEntityCollaboratorParticipant,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/inscribePerson/productDetail/:id',
      exact: true,
      page: InscribeEntityProductDetail,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/inscribePerson/classDetail/:id',
      exact: true,
      page: InscribeClassDetails,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/socprofiles',
      exact: true,
      page: AddSocProfiles,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/socprofiles/add',
      exact: true,
      page: AddSocProfiles,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/socprofiles/edit/:id',
      exact: true,
      page: AddSocProfiles,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/entrepreneurs',
      exact: true,
      page: AddEntrepreneurs,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/entrepreneurs/add',
      exact: true,
      page: AddEntrepreneurs,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/entrepreneurs/edit/:id',
      exact: true,
      page: AddEntrepreneurs,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/programAdhesionTerm/:id',
      exact: true,
      page: programAdhesionTerm,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/naturalperson/',
      exact: true,
      page: NaturalPerson,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/naturalperson/add',
      exact: true,
      page: AddNaturalPerson,
      onlyAuthorized: true,
      firstSession: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/naturalperson/edit/:cpf',
      exact: true,
      page: AddNaturalPerson,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
   {
      path: '/relatory',
      exact: true,
      page: Relatory,
      onlyAuthorized: true,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
   },
];

export default routes;
