/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import './styles.scss';
import { useEffect } from 'react';
import { Form, Input, SubmitButton, Radio } from 'formik-antd';
import { Button, Layout, Col, Row, Modal, Typography } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RootState } from 'config/store';
import { useSelector } from 'react-redux';
import { getGiveUpReason, setGiveUpReason } from 'services/relatoryService';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

type MotiveModalProps = {
   isMotiveModalVisible: boolean;
   setIsMotiveModalVisible: Function;
   classInscriptionId: string | undefined;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   modalRecord: any;
};

const MotiveModal = ({
   isMotiveModalVisible,
   setIsMotiveModalVisible,
   classInscriptionId,
   modalRecord,
}: MotiveModalProps) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   useEffect(() => {
      dispatch(getGiveUpReason());
   }, [dispatch]);
   const { giveUpReasonList, loadingModal } = useSelector((state: RootState) => state.relatory);

   const schema = Yup.object({
      giveUpReasonId: Yup.number(),
   });

   const SubmitContainer = () => {
      if (modalRecord.statusId === 5){
         return (
            <Typography>
               {t(Labels.RELATORY_CANCELED_INFO)}
            </Typography>
         )
      }
         return (
            <Button.Group>
               <SubmitButton
                  loading={loadingModal}
                  className="submit-button"
               >
                  {t(Labels.COMMON_BUTTON_SAVE)}
               </SubmitButton>
            </Button.Group>
         )
   }

   return (
      <Modal
         className="motive-modal"
         centered
         visible={isMotiveModalVisible}
         title="Explique-nos o motivo da desistência: "
         onCancel={() => {
            setIsMotiveModalVisible(false);
         }}
         footer={null}
      >
         <div className="motive-modal-container">
            <Layout className="site-layout">
               <Content className="site-layout-background">
                  <Formik
                     // eslint-disable-next-line
                     onSubmit={async (values: any) => {
                        // eslint-disable-next-line no-var
                        var formData = new FormData();

                        formData.append('giveUpReasonId', values.giveUpReasonId);
                        formData.append('classInscriptionId', classInscriptionId as string);

                        if (values.otherGiveUpReasonDescription !== null) {
                           formData.append('giveUpReasonDescription', values.otherGiveUpReasonDescription);
                        }

                        await dispatch(await setGiveUpReason(formData));
                        setIsMotiveModalVisible(false);
                     }}
                     initialValues={
                        modalRecord
                     }
                     enableReinitialize
                     validateOnBlur
                     validationSchema={schema}
                     render={() => {
                        return (
                           <Form style={{ width: '100%' }} className="motive-modal-form">
                              <Row className="row-vertical">
                                 <Radio.Group
                                    name="giveUpReasonId"
                                    className="options-container"
                                    disabled={modalRecord.statusId === 5}>
                                    {giveUpReasonList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (
                                             giveUpReasonList.length -
                                             1 ===
                                             i
                                          ) {
                                             return (
                                                <div className='text-left'>
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"

                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                   <Input.TextArea
                                                      name="otherGiveUpReasonDescription"
                                                      className="input-checkbox"
                                                      maxLength={150}
                                                      rows={4}
                                                      disabled={modalRecord.statusId === 5}
                                                   />
                                                </div>
                                             );
                                          }

                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row>
                                 <Col span={24} className="submit-button-container">
                                    <SubmitContainer />
                                 </Col>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               </Content>
            </Layout>
         </div>
      </Modal>
   );
};

export default MotiveModal;
