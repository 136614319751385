/* eslint-disable prettier/prettier */
import { SubmitButton, Form, Input, FormItem } from 'formik-antd';
import { Table, Button, Row, Col, Layout, PageHeader, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import './styles.scss';
import InputMask from 'react-input-mask';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { searchPerson } from 'services/naturalPersonService';
import Utils from 'helpers/Utils';
import { EditIconSVG } from '../../components/common/icons/icons';
import Labels from '../../helpers/enum/Labels';
import { RootState } from '../../config/store';
import { useAppDispatch } from '../../hooks/hooks';

const { Title } = Typography;
const { Content } = Layout;

interface Table {
   key: number;
   name: string;
}
interface Values {
   name: string;
   cpf: string;
}
interface FormErrors {
   cpf: null | string;
}
const NaturalPerson = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { persons, loadingSearch } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const [nameValue, setNameValue] = useState<string>();
   const [cpfValue, setCpfValue] = useState<string>();
   const [formErrors, setFormErrors] = useState<FormErrors>({ cpf: null });

   const columns: ColumnsType<object> = [
      {
         title: t(Labels.COMMON_NAME).toUpperCase(),
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: t(Labels.COMMON_CPF),
         dataIndex: 'cpf',
         key: 'cpf',
         render: cpf => {
            return `${Utils.formatCpf(cpf)}`;
         },
      },
      {
         title: t(Labels.COMMON_ENTITY).toUpperCase(),
         dataIndex: 'entities',
         key: 'entities',
         render: entity => {
            if (entity.length > 1) {
               return entity.map((e: { name: string }) => {
                  return `${e.name}, `;
               });
            }
            return entity.map((e: { name: string }) => {
               return e.name;
            });
         },
      },
      {
         title: '',
         dataIndex: 'cpf',
         key: 'cpf',
         render: cpf => {
            return (
               <EditIconSVG
                  onClick={() => {
                     history.push(`/naturalPerson/edit/${cpf}`);
                  }}
               />
            );
         },
      },
   ];

   const handleChangePage = (value: number) => {
      dispatch(searchPerson(nameValue, cpfValue, value, persons.pageSize));
   };

   useEffect(() => {
      dispatch(searchPerson());
   }, [dispatch]);

   const list = persons.data?.map(person => {
      return person;
   });

   const onSubmit = (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>,
   ) => {
      setSubmitting(true);
      dispatch(
         searchPerson(
            values.name,
            Utils.stripDotsParenthesisWhitespacesAndHifens(values.cpf),
         ),
      );
      setNameValue(values.name);
      setCpfValue(Utils.stripDotsParenthesisWhitespacesAndHifens(values.cpf));
      setSubmitting(false);
   };

   return (
      <div className="naturalperson-search-container">
         <Layout className="site-layout">
            <PageHeader title={t(Labels.NATURALPERSON_SEARCH_TITLE)} />
            <Content className="site-layout-background">
               <Formik<Values>
                  initialValues={{
                     name: '',
                     cpf: '',
                  }}
                  onSubmit={onSubmit}
               >
                  {({ setFieldValue }) => {
                     return (
                        <Form
                           data-testid="naturalperson-list-form"
                           className="form-naturalperson"
                        >
                           <Row justify="space-between" align="top">
                              <Col span={24} md={8}>
                                 <FormItem name="name" label="" required>
                                    <div className="title-search">
                                       {`${t(Labels.COMMON_NAME)}`}
                                    </div>
                                    <Input
                                       name="name"
                                       placeholder="Nome"
                                       className="default-field"
                                       data-testid="name"
                                       role="textbox"
                                    />
                                 </FormItem>
                              </Col>
                              <Col span={24} md={6}>
                                 <FormItem
                                    name="cpf"
                                    label=""
                                    required
                                    className="default-field-container title-search"
                                 >
                                    {`${t(Labels.PROFILE_SEARCH_CPF)}`}
                                    <InputMask
                                       alwaysShowMask={false}
                                       mask="999.999.999-99"
                                       onChange={e => {
                                          const { value } = e.target || '';
                                          const { name } = e.target;
                                          Utils.validateCPFandCNPJSearchSize(
                                             e.target.value,
                                             'cpf',
                                             formErrors,
                                             setFormErrors,
                                          );
                                          setFieldValue(name, value);
                                       }}
                                    >
                                       {() => (
                                          <Input
                                             name="cpf"
                                             title="cpf"
                                             placeholder="CPF"
                                             className="default-field"
                                             data-testid="cpf"
                                             role="textbox"
                                          />
                                       )}
                                    </InputMask>
                                    <div className="form-error-message">
                                       {formErrors.cpf}
                                    </div>
                                 </FormItem>
                              </Col>
                              <Col span={8}>
                                 <Button.Group className="button-group">
                                    <SubmitButton
                                       data-testid="submitButton"
                                       icon={<SearchOutlined />}
                                       disabled={formErrors.cpf !== null}
                                    >
                                       {t(Labels.BUTTON)}
                                    </SubmitButton>
                                    <Button
                                       type="primary"
                                       onClick={() =>
                                          history.push('/naturalPerson/add')
                                       }
                                    >
                                       {t(Labels.BUTTON_NEW_REGISTER)}
                                    </Button>
                                 </Button.Group>
                              </Col>
                           </Row>
                           <div className="search-result-table">
                              <Title level={4}>Resultados da pesquisa:</Title>
                              <Table
                                 dataSource={list}
                                 columns={columns}
                                 className="default-table"
                                 locale={
                                    loadingSearch
                                       ? {
                                          emptyText: t(Labels.LOADING),
                                       }
                                       : {
                                          emptyText: t(Labels.NO_DATA),
                                       }
                                 }
                                 pagination={{
                                    current: persons.pageNumber,
                                    onChange: handleChangePage,
                                    pageSize: persons.pageSize,
                                    total: persons.totalRecords,
                                    showSizeChanger: false,
                                 }}
                                 loading={loadingSearch}
                              />
                           </div>
                        </Form>
                     );
                  }}
               </Formik>
            </Content>
         </Layout>
      </div>
   );
};

export default NaturalPerson;
