import { SubmitButton, Form, FormItem, Input } from 'formik-antd';
import {
   Table,
   Button,
   Row,
   Col,
   Layout,
   PageHeader,
   Typography,
   Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import './styles.scss';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import Utils from 'helpers/Utils';
import InputText from 'components/common/input';
import entitiesService from 'services/entitiesService';
import { EditIconSVG } from '../../components/common/icons/icons';
import Labels from '../../helpers/enum/Labels';
import { RootState } from '../../config/store';
import { useAppDispatch } from '../../hooks/hooks';

const { Title } = Typography;
const { Content } = Layout;

interface Table {
   key: number;
   name: string;
}

interface Errors {
   cpf: null | string;
   cnpj: null | string;
}

const Entities = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { entities } = useSelector((state: RootState) => state.entity);
   const { loading } = useSelector((state: RootState) => state.entity);
   const [nameValue, setNameValue] = useState<string>();
   const [socialReasonValue, setSocialReasonValue] = useState<string>();
   const [cpfValue, setCpfValue] = useState<string>();
   const [cnpjValue, setCnpjValue] = useState<string>();
   const [formErrors, setFormErrors] = useState<Errors>({
      cpf: null,
      cnpj: null,
   });

   const columns: ColumnsType<Table> = [
      {
         title: t(Labels.ENTITY_NAME),
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: t(Labels.COMMON_CNPJ),
         dataIndex: 'cnpj',
         key: 'cnpj',
         render: cnpj => {
            return Utils.formatCnpj(cnpj);
         },
      },
      {
         title: t(Labels.ENTITY_REPRESENTATIVE),
         dataIndex: 'userName',
         key: 'userName',
      },
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         render: id => {
            return (
               <Tooltip title="Editar cadastro / Aderir aos programas">
                  <EditIconSVG
                     onClick={() => {
                        history.push(`/entities/edit/${id}`);
                     }}
                  />
               </Tooltip>
            );
         },
      },
   ];

   const handleChangePage = (value: number) => {
      dispatch(
         entitiesService.searchEntities(
            nameValue,
            cpfValue,
            cnpjValue,
            socialReasonValue,
            value,
            entities.pageSize,
         ),
      );
   };

   useEffect(() => {
      dispatch(entitiesService.searchEntities());
   }, [dispatch]);

   const list = entities.data?.map(entityList => {
      return entityList;
   });

   return (
      <div className="entities-search-container">
         <Layout className="site-layout">
            <PageHeader title={t(Labels.ENTITY_TITLE)} />
            <Content className="site-layout-background">
               <Formik
                  initialValues={{
                     name: '',
                     cpf: '',
                     cnpj: '',
                     corporateName: '',
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                     setSubmitting(true);
                     dispatch(
                        entitiesService.searchEntities(
                           values.name,
                           Utils.stripDotsParenthesisWhitespacesAndHifens(
                              values.cpf,
                           ),
                           Utils.stripDotsParenthesisWhitespacesAndHifens(
                              values.cnpj,
                           ),
                           values.corporateName,
                        ),
                     );
                     setNameValue(values.name);
                     setSocialReasonValue(values.corporateName);
                     setCpfValue(
                        Utils.stripDotsParenthesisWhitespacesAndHifens(
                           values.cpf,
                        ),
                     );
                     setCnpjValue(
                        Utils.stripDotsParenthesisWhitespacesAndHifens(
                           values.cnpj,
                        ),
                     );
                     setSubmitting(false);
                  }}
               >
                  {({ setFieldValue }) => {
                     return (
                        <div>
                           <Form
                              data-testid="programs-list-form"
                              className="form-entities"
                           >
                              <Row className="form-container-entities">
                                 <Col span={6}>
                                    <FormItem
                                       name="name"
                                       label=""
                                       required
                                       className="default-field-container title-search"
                                    >
                                       <InputText
                                          name="name"
                                          title={t(Labels.ENTITY_NAME)}
                                          placeholder={t(Labels.ENTITY_NAME)}
                                          className="default-field"
                                          data-testid="name"
                                          role="textbox"
                                       />
                                    </FormItem>
                                 </Col>
                                 <Col span={6}>
                                    <FormItem
                                       name="corporateName"
                                       label=""
                                       required
                                       className="default-field-container title-search"
                                    >
                                       <InputText
                                          name="corporateName"
                                          title={t(
                                             Labels.ENTITY_CORPORATE_NAME,
                                          )}
                                          placeholder={t(
                                             Labels.ENTITY_CORPORATE_NAME,
                                          )}
                                          className="default-field"
                                          data-testid="corporateName"
                                          role="textbox"
                                       />
                                    </FormItem>
                                 </Col>
                                 <Col span={6}>
                                    <FormItem
                                       name="cpf"
                                       label=""
                                       required
                                       className="default-field-container title-search"
                                    >
                                       {`${t(
                                          Labels.ENTITY_REPRESENTATIVE_CPF,
                                       )}`}
                                       <InputMask
                                          alwaysShowMask={false}
                                          mask="999.999.999-99"
                                          onChange={e => {
                                             const { value } = e.target || '';
                                             const { name } = e.target;
                                             Utils.validateCPFandCNPJSearchSize(
                                                e.target.value,
                                                'cpf',
                                                formErrors,
                                                setFormErrors,
                                             );
                                             setFieldValue(name, value);
                                          }}
                                       >
                                          {() => (
                                             <Input
                                                name="cpf"
                                                title="cpf"
                                                placeholder={t(
                                                   Labels.ENTITY_REPRESENTATIVE_CPF,
                                                )}
                                                className="default-field"
                                                data-testid="cpf"
                                                role="textbox"
                                             />
                                          )}
                                       </InputMask>
                                       <div className="form-error-message">
                                          {formErrors.cpf}
                                       </div>
                                    </FormItem>
                                 </Col>
                                 <Col span={24} md={6}>
                                    <FormItem
                                       name="cnpj"
                                       label=""
                                       required
                                       className="default-field-container title-search"
                                    >
                                       {`${t(Labels.COMMON_CNPJ)}`}
                                       <InputMask
                                          alwaysShowMask={false}
                                          mask="99.999.999/9999-99"
                                          onChange={e => {
                                             const { value } = e.target || '';
                                             const { name } = e.target;
                                             Utils.validateCPFandCNPJSearchSize(
                                                e.target.value,
                                                'cnpj',
                                                formErrors,
                                                setFormErrors,
                                             );
                                             setFieldValue(name, value);
                                          }}
                                       >
                                          {() => (
                                             <Input
                                                name="cnpj"
                                                title="cnpj"
                                                placeholder="CNPJ"
                                                className="default-field"
                                                data-testid="cnpj"
                                                role="textbox"
                                             />
                                          )}
                                       </InputMask>
                                       <div className="form-error-message">
                                          {formErrors.cnpj}
                                       </div>
                                    </FormItem>
                                 </Col>

                                 <Col span={6}>
                                    <Button.Group>
                                       <SubmitButton
                                          id="entities-search-entity-button"
                                          data-testid="submitButton"
                                          icon={<SearchOutlined />}
                                          disabled={
                                             formErrors.cpf !== null ||
                                             formErrors.cnpj !== null
                                          }
                                       >
                                          {t(Labels.BUTTON)}
                                       </SubmitButton>
                                       <Button
                                          id="entities-new-entity-button"
                                          type="primary"
                                          onClick={() =>
                                             history.push('/entities/add')
                                          }
                                       >
                                          {t(Labels.BUTTON_NEW_PROFILE)}
                                       </Button>
                                    </Button.Group>
                                 </Col>
                              </Row>
                           </Form>
                           <div className="table">
                              <Title level={4}>Resultados da pesquisa:</Title>
                              <Table<Table>
                                 dataSource={list}
                                 columns={columns}
                                 className="default-table"
                                 locale={
                                    loading
                                       ? { emptyText: t(Labels.LOADING) }
                                       : { emptyText: t(Labels.NO_DATA) }
                                 }
                                 pagination={{
                                    current: entities?.pageNumber,
                                    onChange: handleChangePage,
                                    pageSize: entities?.pageSize,
                                    total: entities?.totalRecords,
                                    showSizeChanger: false,
                                 }}
                                 loading={loading}
                              />
                           </div>
                        </div>
                     );
                  }}
               </Formik>
            </Content>
         </Layout>
      </div>
   );
};

export default Entities;
