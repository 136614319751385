import './styles.scss';
import { useState } from 'react';
import { Modal, Row, Checkbox, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import {
   AdheredProgram,
   putAdheredPrograms,
} from 'services/programAdhesionService';
import { useAppDispatch } from 'hooks/hooks';
import Labels from '../../helpers/enum/Labels';

type DeleteProgramsModalProps = {
   isDeleteModalVisible: boolean;
   setIsDeleteModalVisible: Function;
   isDeleteReasonModalVisible: boolean;
   setIsDeleteReasonModalVisible: Function;
   selectedAdheredPrograms: AdheredProgram[];
   setSelectedAdheredPrograms: Function;
   adheredProgramsRefresh?: boolean;
};

const DeleteProgramsModals = ({
   isDeleteModalVisible,
   setIsDeleteModalVisible,
   setIsDeleteReasonModalVisible,
   isDeleteReasonModalVisible,
   selectedAdheredPrograms,
   setSelectedAdheredPrograms,
   adheredProgramsRefresh,
}: DeleteProgramsModalProps) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const { TextArea } = Input;
   const [confirmDeletePrograms, setConfirmDeletePrograms] = useState(false);
   const [selectedOption, setSelectedOption] = useState(0);
   const [deleteReason, setDeleteReason] = useState('');
   const [otherOption, setOtherOption] = useState('');

   function handleOnSave() {
      const removedAdheredPrograms = selectedAdheredPrograms.map(
         (program: AdheredProgram) => {
            return {
               ...program,
               isCanceled: true,
               cancelReason: selectedOption === 4 ? otherOption : deleteReason,
            };
         },
      );
      dispatch(
         putAdheredPrograms(removedAdheredPrograms, adheredProgramsRefresh),
      );
      setSelectedAdheredPrograms([]);
   }

   return (
      <>
         <Modal
            className="confirm-delete-modal"
            centered
            visible={isDeleteModalVisible}
            onCancel={() => {
               setIsDeleteModalVisible(false);
               setConfirmDeletePrograms(false);
            }}
            footer={null}
         >
            <p>{t(Labels.PROGRAMS_ADHESION_CONFIRM_DELETE_MODAL)}</p>
            <Row justify="center">
               <Checkbox
                  checked={confirmDeletePrograms}
                  onChange={() => setConfirmDeletePrograms(true)}
               >
                  {t(Labels.COMMON_YES)}
               </Checkbox>
               <Checkbox
                  checked={!confirmDeletePrograms}
                  onChange={() => setConfirmDeletePrograms(false)}
               >
                  {t(Labels.COMMON_NO)}
               </Checkbox>
            </Row>
            <Button
               className="modal-button"
               onClick={() => {
                  if (confirmDeletePrograms) {
                     setIsDeleteModalVisible(false);
                     setConfirmDeletePrograms(false);
                     setIsDeleteReasonModalVisible(true);
                  } else {
                     setIsDeleteModalVisible(false);
                     setConfirmDeletePrograms(false);
                  }
               }}
            >
               Continuar
            </Button>
         </Modal>
         <Modal
            className="delete-reason-modal"
            centered
            visible={isDeleteReasonModalVisible}
            onCancel={() => {
               setIsDeleteReasonModalVisible(false);
               setSelectedOption(0);
               setDeleteReason('');
            }}
            footer={null}
         >
            <p>
               {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_1)}
               <span>sim</span>
               {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_2)}
            </p>
            <Row>
               <Checkbox
                  checked={selectedOption === 1}
                  onChange={() => {
                     setSelectedOption(1);
                     setDeleteReason(
                        t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_1),
                     );
                  }}
               >
                  {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_1)}
               </Checkbox>
            </Row>
            <Row>
               <Checkbox
                  checked={selectedOption === 2}
                  onChange={() => {
                     setSelectedOption(2);
                     setDeleteReason(
                        t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_2),
                     );
                  }}
               >
                  {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_2)}
               </Checkbox>
            </Row>
            <Row>
               <Checkbox
                  checked={selectedOption === 3}
                  onChange={() => {
                     setSelectedOption(3);
                     setDeleteReason(
                        t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_3),
                     );
                  }}
               >
                  {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_3)}
               </Checkbox>
            </Row>
            <Row>
               <Checkbox
                  checked={selectedOption === 4}
                  onChange={() => {
                     setSelectedOption(4);
                  }}
               >
                  {t(Labels.PROGRAMS_ADHESION_DELETE_REASON_OPTION_4)}
               </Checkbox>
            </Row>
            <Row justify="center">
               <TextArea
                  placeholder="Motivo..."
                  value={otherOption}
                  rows={4}
                  disabled={selectedOption !== 4}
                  onChange={element => setOtherOption(element.target.value)}
               />
            </Row>
            <Row justify="center">
               <Button
                  className="modal-button"
                  onClick={() => {
                     setIsDeleteReasonModalVisible(false);
                     setSelectedOption(0);
                     setDeleteReason('');
                     handleOnSave();
                  }}
                  disabled={selectedOption === 0}
               >
                  {t(Labels.COMMON_BUTTON_SAVE)}
               </Button>
            </Row>
         </Modal>
      </>
   );
};

export default DeleteProgramsModals;
