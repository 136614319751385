import { getServiceProfile } from '../actions/serviceProfileAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchServiceProfile {
   data: any[];
   success: boolean;
}
export type ServiceProfile = {
   id: string;
   name: string;
};

export const searchServiceProfile = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchServiceProfile>('/ServiceProfile', {})
         .then(async ({ data: payload }) => {
            dispatch(getServiceProfile(payload));
         })
         .catch(console.log);
   };
};

export default { searchServiceProfile };
