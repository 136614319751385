/* eslint-disable prettier/prettier */
import './styles.scss';
import { useSelector } from 'react-redux';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
   Form,
   Input,
   SubmitButton,
   Checkbox,
   DatePicker,
   Select,
   Radio,
   FormItem,
} from 'formik-antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import {
   Button,
   Layout,
   PageHeader,
   Col,
   Row,
   message,
   Upload,
   Spin,
} from 'antd';
import * as Yup from 'yup';
import {
   clearCityOptionsList,
   enableCpf,
   enableDisability,
   getPerson,
} from 'actions/naturalPersonAction';
import { Formik, FormikHelpers } from 'formik';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import {
   DownloadOutlined,
   UploadOutlined,
} from '@ant-design/icons';
import {
   getDisabilities,
   StateAddressOption,
   Option as APIOptions,
   searchCities,
   searchEntities,
   Entity,
   setPerson,
   clearPerson,
   searchPersonCpf,
} from 'services/naturalPersonService';
import Utils from 'helpers/Utils';
import moment from 'moment';
import { BeforeUploadFileType, RcFile } from 'rc-upload/lib/interface';
import { isUndefined } from 'lodash';
import { getSocprofileService } from 'services/socprofilesService';
import { handleEntrepreneurs } from 'services/entrepreneursService';
import { clearSocprofile } from 'actions/socprofilesAction';
import { clearEntrepreneurs } from 'actions/entrepreneursAction';
import jwtDecode from 'jwt-decode';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../config/store';
import Field from '../../components/common/fieldEntity';
import Fieldset from '../../components/common/fieldSet';
import DropDown from '../../components/common/dropdownEntity';
import AddSocProfiles from '../socprofiles/add';
import AddEntrepreneurs from '../entrepreneurs/add';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

export interface SearchEntities {
   data: Array<any>;
}

interface DecodedToken {
   cpf: string;
   dataNascimento: string;
   email: string;
   genero: string;
   name: string;
}

const { Option } = Select;

const AddNaturalPerson = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { cpf } = useParams<{ cpf?: string }>();
   const [isAddSocProfilesVisible, setIsAddSocProfilesVisible] =
      useState(false);
   const [isAddEntrepreneursVisible, setIsAddEntrepreneursVisible] =
      useState(false);

   const searchDisabilities = AwesomeDebouncePromise(() => {
      dispatch(getDisabilities());
      dispatch(enableCpf());
   }, 1000);

   useEffect(() => {
      searchDisabilities();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch]);
   const { scholaritiesOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { ethnicitiesOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { disabilitiesOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { streetTypeOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { stateAddressOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { cityOptionsList, isLoadingCities } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { personTypeOptionsList } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { entityOptionsList, isLoadingEntities } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { isLoading, disableCpf } = useSelector(
      (state: RootState) => state.naturalPerson,
   );
   const { lastLgpdConsentTerm } = useSelector(
      (state: RootState) => state.terms,
   );
   const { token, login } = useSelector((state: RootState) => state.auth);

   const [stateAddressSelect, setStateAddressSelect] = useState('');
   const [uploadDisable, setUploadDisable] = useState(false);
   const [socialNameDisable, setSocialNameDisable] = useState(true);
   const [disabilityDisable, setDisabilityDisable] = useState(true);
   const [cellphone2Disable, setCellphone2Disable] = useState(true);
   const [showUploadList, setShowUploadList] = useState(true);
   const [fileList, setFileList] = useState([] as any);
   const { person } = useSelector((state: RootState) => state.naturalPerson);
   const { socprofile } = useSelector((state: RootState) => state.socprofiles);
   const { entrepreneurs } = useSelector(
      (state: RootState) => state.entrepreneurs,
   );
   const location = useLocation();
   const myRef = useRef(null) as unknown as MutableRefObject<HTMLDivElement>;

   const executeScroll = () => {
      if (myRef.current !== null) {
         myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   };

   useEffect(() => {
      return function cleanup() {
         dispatch(clearPerson());
      };
   }, [dispatch]);

   useEffect(() => {
      if (location.search === '?redirect=true') {
         console.log('redirect');
         executeScroll();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location, isLoading]);

   useEffect(() => {
      if (person.otherName === true) {
         setSocialNameDisable(false);
      }
   }, [dispatch, person.otherName]);

   useEffect(() => {
      if (person.disability === true) {
         setDisabilityDisable(false);
      }
      if(person.socialName !== null) {
         setSocialNameDisable(false);
      }
      if(person.socialName === null || person.socialName === '') {
         setSocialNameDisable(true);
      }
   }, [dispatch, person.disability, person.socialName]);

   useEffect(() => {
      async function fetchData() {
         dispatch(clearEntrepreneurs());
         dispatch(clearSocprofile());
         if (cpf) {
            await dispatch(await searchPersonCpf(cpf));
            await dispatch(await handleEntrepreneurs(cpf));
            await dispatch(await getSocprofileService(cpf));
         }

         if (token) {
            const decodedToken: DecodedToken = jwtDecode(token);
            if (decodedToken) {
               message.warning({
                  content:
                     'Cadastro incompleto, por favor termine-o neste formulário',
                  className: 'warning',
                  duration: 10,
               });
               const data = {
                  cpf: decodedToken.cpf,
                  birthDate: decodedToken.dataNascimento,
                  mail: decodedToken.email,
                  name: decodedToken.name,
                  hasMail: false,
                  sex: decodedToken.genero === 'masculino' ? 'm' : 'f',
                  entities: [],
                  socialName: '',
                  idDisability: null,
                  idScholarity: null,
                  idEthnicity: null,
                  personAddress: {
                     neighborhood: '',
                     cep: '',
                     complement: '',
                     idCity: '',
                     idStreetType: null,
                     street: '',
                     number: '',
                     uf: '',
                  },
                  cellphone: '',
                  cellphone2: '',
                  telephone: '',
                  telephone2: '',
                  acceptDate: '',
                  uf: '',
                  state: '',
                  idPersonType: null,
                  accept: null,
                  userCpfAccept: null,
                  usernameRegisteredConsent: null,
                  otherName: false,
                  disability: false,
               };
               dispatch(getPerson(data));
            }
         }
      }
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, cpf, token]);

   const yesNo = [
      {
         name: 'sim',
         value: true,
         label: t(Labels.COMMON_YES),
      },
      {
         name: 'nao',
         value: false,
         label: t(Labels.COMMON_NO),
      },
   ];

   const genderData = [
      {
         name: 'female',
         value: 'f',
         label: t(Labels.COMMON_GENDER_FEMALE),
      },
      {
         name: 'male',
         value: 'm',
         label: t(Labels.COMMON_GENDER_MALE),
      },
   ];

   const handleSearchCity = (value: any) => {
      dispatch(searchCities(value, stateAddressSelect));
   };

   const handleSearchEntities = (value: any) => {
      dispatch(searchEntities(value));
   };

   const searchAPIDebounced = AwesomeDebouncePromise(handleSearchCity, 800);

   const searchAPIDebouncedEntities = AwesomeDebouncePromise(
      handleSearchEntities,
      500,
   );

   const dummyRequest = ({ onSuccess }: any) => {
      setTimeout(() => {
         onSuccess('ok');
      }, 0);
   };

   const schema = Yup.object().shape(
      {
         name: Yup.string().nullable().required(),
         idScholarity: Yup.number().nullable().required(),
         idEthnicity: Yup.number().nullable().required(),
         cellphone: Yup.string()
            .nullable()
            .when('telephone', {
               is: (telephone: string) => !telephone || telephone.length === 0,
               then: Yup.string().required().typeError('*Campo obrigatório'),
               otherwise: Yup.string().nullable(),
            }),
         cellphone2: Yup.string().nullable(),
         mail: Yup.string()
            .nullable()
            .when('hasMail', {
               is: (hasMail: boolean) => {
                  return !hasMail;
               },
               then: Yup.string().nullable().required(),
            }),
         hasMail: Yup.boolean().nullable(),
         telephone: Yup.string()
            .nullable()
            .when('cellphone', {
               is: (cellphone: string) => !cellphone || cellphone.length === 0,
               then: Yup.string().required().typeError('*Campo obrigatório'),
               otherwise: Yup.string().nullable(),
            }),
         cpf: Yup.string()
            .transform(value =>
               Utils.stripDotsParenthesisWhitespacesAndHifens(value),
            )
            .required()
            .min(11, '*CPF invalido')
            .test('cpf-valid', '*CPF invalido', value =>
               Utils.isValidCPF(value),
            ),
         birthDate: Yup.date().nullable().required(),
         idDisability: Yup.number().nullable(),
         socialName: Yup.string().nullable(),
         sex: Yup.string().nullable().required(),
         personAddress: Yup.object().shape({
            cep: Yup.string().nullable().required(),
            neighborhood: Yup.string().nullable().required(),
            complement: Yup.string().nullable().required(),
            idCity: Yup.string().nullable().required(),
            idStreetType: Yup.string().nullable().required(),
            street: Yup.string().nullable().required(),
            number: Yup.string().nullable().required(),
            uf: Yup.string().nullable().required(),
         }),
         acceptDate: Yup.date().nullable(),
         accept: Yup.boolean().nullable(),
         uf: Yup.string().nullable(),
         entities: Yup.array().min(1, '*Campo obrigatório').required(),
         idPersonType: Yup.number().nullable().required(),
      },
      [['telephone', 'cellphone']],
   );

   const handleSubmit = async (
      values: any,
      { resetForm }: FormikHelpers<any>,
   ) => {
      // eslint-disable-next-line no-var
      var formData = new FormData();

      formData.append('file', !isUndefined(values.file) ? values.file : null);
      formData.append('accept', values.accept);
      formData.append('acceptDate', values.acceptDate);
      formData.append('uf', values.uf);
      formData.append('userCpfAccept', values.userCpfAccept);

      const personData = {
         idScholarity: values.idScholarity,
         idEthnicity: values.idEthnicity,
         cellphone: Utils.stripDotsParenthesisWhitespacesAndHifens(
            values.cellphone,
         ),
         cellphone2: Utils.stripDotsParenthesisWhitespacesAndHifens(
            values.cellphone2,
         ),
         mail: values.mail ? values.mail : null,
         hasMail: !values.hasMail,
         telephone: Utils.stripDotsParenthesisWhitespacesAndHifens(
            values.telephone,
         ),
         telephone2: Utils.stripDotsParenthesisWhitespacesAndHifens(
            values.telephone2,
         ),
         cpf: Utils.stripDotsParenthesisWhitespacesAndHifens(values.cpf),
         birthDate: values.birthDate,
         idDisability: values.idDisability,
         name: values.name,
         socialName: values.socialName,
         sex: values.sex,
         address: {
            cep: Utils.stripDotsParenthesisWhitespacesAndHifens(
               values.personAddress.cep,
            ),
            neighborhood: values.personAddress.neighborhood,
            complement: values.personAddress.complement,
            idCity: values.personAddress.idCity as number,
            idStreetType: values.personAddress.idStreetType,
            street: values.personAddress.street,
            number: values.personAddress.number,
            uf: values.personAddress.uf.toUpperCase(),
         },
         acceptDate: values.acceptDate,
         uf: values.uf,
         idEntities: values.entities,
         idPersonType: values.idPersonType,
      };
      if (
         person.accept === undefined ||
         person.accept === null ||
         (person.accept === false && values.accept === true)
      ) {
         if (values.accept === true && values.file === undefined) {
            message.error({
               content:
                  'Obrigatório anexar o Termo de Consentimento (LGPD) assinado, em caso de aceite desse Termo.',
               className: 'error',
               duration: 10,
            });
         } else {
            await dispatch(
               await setPerson(
                  personData,
                  formData,
                  resetForm,
                  entrepreneurs,
                  socprofile,
               ),
            );
         }
      } else {
         await dispatch(
            await setPerson(
               personData,
               formData,
               resetForm,
               entrepreneurs,
               socprofile,
            ),
         );
      }
   };

   document.querySelectorAll('.ant-select-selector input').forEach(e => {
      e.setAttribute('autocomplete', 'stopAutocomplete');
   });

   return (
      <div className="naturalperson-search-container">
         <Layout className="site-layout">
            <PageHeader
               title={
                  cpf
                     ? t(Labels.NATURALPERSON_EDIT_NAME)
                     : t(Labels.NATURALPERSON_ADD_NAME)
               }
            />
            <div className="subtitle">{t(Labels.COMMON_REQUIRED_TITLE)}</div>
            <Content style={{ marginTop: 0, paddingTop: 0, marginLeft: 0 }}>
               <Formik
                  // eslint-disable-next-line
                  onSubmit={handleSubmit}
                  initialValues={person}
                  enableReinitialize
                  validationSchema={schema}
                  render={({ setFieldValue, values, errors }) => {
                     console.log(values);

                     const config = {
                        customRequest: dummyRequest,

                        onlyPdfType: (file: RcFile): BeforeUploadFileType => {
                           if (file.type !== 'application/pdf') {
                              message.error({
                                 content: `${file.name} não é um arquivo PDF`,
                                 className: 'error',
                                 duration: 10,
                              });
                           }
                           return file.type === 'application/pdf'
                              ? true
                              : Upload.LIST_IGNORE;
                        },

                        beforeUpload: (file: any) => {
                           if (file.type !== 'application/pdf') {
                              message.error({
                                 content: `${file.name} ${t(
                                    Labels.PROGRAMS_ADD_REQUIRED_PDF,
                                 )}`,
                                 className: 'error',
                                 duration: 10,
                              });
                           }
                           return file.type === 'application/pdf'
                              ? true
                              : Upload.LIST_IGNORE;
                        },
                        onChange(info: any) {
                           const { status } = info.file;
                           if (status === 'done') {
                              console.log(
                                 `${info.file.name}: Arquivo foi enviado com sucesso.`,
                              );
                           } else if (status === 'error') {
                              message.error({
                                 content: `${info.file.name}: Erro no envio do arquivo.`,
                                 className: 'error',
                                 duration: 10,
                              });
                           }
                           setFieldValue('file', info.file.originFileObj);
                           setFileList([
                              {
                                 uid: '1',
                                 name: info.file.name,
                                 status: 'done',
                              },
                           ]);
                        },
                     };

                     return isLoading ? (
                        <Spin size="large" />
                     ) : (
                        <Form style={{ width: '100%' }}>
                           <Fieldset title="Identificação">
                              <Row className="row np-div">
                                 <Col span={24} md={12} xl={8}>
                                    <div className="identify-field">
                                       <Field
                                          name="name"
                                          title={t(
                                             Labels.NATURALPERSON_ADD_FULL_NAME,
                                          )}
                                          errors={errors.name}
                                          value={values.name}
                                          width={undefined}
                                          required
                                       />
                                    </div>
                                 </Col>
                                 <Col span={24} md={12} xl={8}>
                                    <FormItem
                                       name="cpf"
                                       label=""
                                       required
                                       className="identify-field"
                                    >
                                       <div className="title title-add">
                                          {t(Labels.COMMON_CPF).toUpperCase()} *
                                       </div>
                                       <InputMask
                                          alwaysShowMask={false}
                                          mask="999.999.999-99"
                                          onChange={e => {
                                             const { value } = e.target || '';
                                             const { name } = e.target;
                                             setFieldValue(name, value);
                                          }}
                                          value={values.cpf}
                                          disabled={disableCpf}
                                       >
                                          {() => (
                                             <Input
                                                name="cpf"
                                                placeholder={t(
                                                   Labels.COMMON_CPF,
                                                )}
                                                className="default-field"
                                                data-testid="cpf"
                                                role="textbox"
                                                disabled={disableCpf}
                                             />
                                          )}
                                       </InputMask>
                                    </FormItem>
                                 </Col>
                                 <Col span={24} md={12} xl={8}>
                                    <div className="title">{`${t(
                                       Labels.NATURALPERSON_ADD_BIRTH_DATE,
                                    ).toUpperCase()} *`}</div>
                                    <DatePicker
                                       name="birthDate"
                                       placeholder={t(
                                          Labels.NATURALPERSON_ADD_BIRTH_DATE,
                                       )}
                                       locale={locale}
                                       format="DD/MM/YYYY"
                                       style={{
                                          height: 40,
                                          fontSize: 20,
                                          width: 250,
                                       }}
                                    />
                                    <div className="errorText">
                                       {errors.birthDate}
                                    </div>
                                 </Col>
                              </Row>

                              <Row className="row np-div">
                                 <Col span={24} md={12} xl={8}>
                                    <div className="title identify-field">
                                       {t(
                                          Labels.NATURALPERSON_ADD_OTHER_NAME,
                                       ).toUpperCase()}
                                    </div>
                                    <Radio.Group
                                       name="otherName"
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                       defaultValue={false}
                                       onChange={e => {
                                          if (e.target.value === true) {
                                             setSocialNameDisable(false);
                                          } else {
                                             setSocialNameDisable(true);
                                             setFieldValue('socialName', '');
                                          }
                                       }}
                                    >
                                       {yesNo?.map(
                                          (radio: {
                                             value: boolean;
                                             name: string;
                                             label: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.value}
                                                   style={{
                                                      marginBottom: 10,
                                                   }}
                                                >
                                                   {radio.label}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                 </Col>
                                 {/* id="add-form-sm-col" */}
                                 <Col span={24} md={12} xl={8}>
                                    <div className="identify-field">
                                       <Field
                                          name="socialName"
                                          title={t(
                                             Labels.NATURALPERSON_ADD_YES_OTHER,
                                          )}
                                          value={values.socialName}
                                          errors={errors.socialName}
                                          width={undefined}
                                          disabled={socialNameDisable}
                                          onChange={(e: any) => {
                                             if (e.target.value !== '') {
                                                setFieldValue(
                                                   'otherName',
                                                   true,
                                                );
                                             } else {
                                                setFieldValue(
                                                   'otherName',
                                                   false,
                                                );
                                             }
                                          }}
                                       />
                                    </div>
                                 </Col>
                                 <Col span={24} md={12} xl={8}>
                                    <div className="title">
                                       {`${t(
                                          Labels.NATURALPERSON_ADD_GENDER,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <Radio.Group
                                       name="sex"
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                    >
                                       {genderData?.map(
                                          (radio: {
                                             value: string;
                                             name: string;
                                             label: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.value}
                                                   style={{
                                                      marginBottom: 10,
                                                   }}
                                                >
                                                   {radio.label}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                    <div className="errorText">
                                       {errors.sex}
                                    </div>
                                 </Col>
                              </Row>

                              <Row className="row np-div">
                                 <Col span={24} md={12} xl={8}>
                                    <div className="title">
                                       {`${t(
                                          Labels.NATURALPERSON_ADD_COLOR_ETHNICITY,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <Radio.Group
                                       name="idEthnicity"
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                    >
                                       {ethnicitiesOptionsList?.map(
                                          (radio: {
                                             id: number;
                                             name: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.id}
                                                   style={{
                                                      marginBottom: 10,
                                                   }}
                                                >
                                                   {radio.name}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                    <div className="errorText">
                                       {errors.idEthnicity}
                                    </div>
                                    {/* <RadioButton
                                       name="color_ethnicity"
                                       vertical
                                       data={colorEthnicityData}
                                    /> */}
                                 </Col>
                                 {/* className="col" id="add-form-md-col" */}
                                 <Col span={24} md={12} xl={8}>
                                    <div className="identify-field">
                                       <div className="title">
                                          {`${t(
                                             Labels.NATURALPERSON_ADD_EDUCATIONAL_LEVEL,
                                          ).toUpperCase()} *`}
                                       </div>
                                       <DropDown
                                          name="idScholarity"
                                          placeholder={t(
                                             Labels.NATURALPERSON_ADD_EDUCATIONAL_LEVEL,
                                          )}
                                          disabled={false}
                                          allowClear
                                       >
                                          {scholaritiesOptionsList?.map(
                                             (scholarityOption: any) => {
                                                return (
                                                   <Option
                                                      value={
                                                         scholarityOption.id
                                                      }
                                                   >
                                                      {scholarityOption.name}
                                                   </Option>
                                                );
                                             },
                                          )}
                                       </DropDown>
                                       <div className="errorText">
                                          {errors.idScholarity}
                                       </div>
                                    </div>
                                 </Col>
                                 <Col span={24} md={12} xl={8}>
                                    <div className="title">
                                       {t(
                                          Labels.NATURALPERSON_ADD_DISABILITY,
                                       ).toUpperCase()}
                                    </div>
                                    <Radio.Group
                                       name="disability"
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                       defaultValue={false}
                                       onChange={e => {
                                          if (e.target.value === true) {
                                             dispatch(enableDisability());
                                             setDisabilityDisable(false);
                                          } else {
                                             setFieldValue(
                                                'idDisability',
                                                null,
                                             );
                                             setDisabilityDisable(true);
                                          }
                                       }}
                                    >
                                       {yesNo?.map(
                                          (radio: {
                                             value: boolean;
                                             name: string;
                                             label: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.value}
                                                   style={{
                                                      marginBottom: 10,
                                                   }}
                                                >
                                                   {radio.label}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                    <div className="title">
                                       {t(
                                          Labels.NATURALPERSON_ADD_YES_OTHER,
                                       ).toUpperCase()}
                                    </div>
                                    <Radio.Group
                                       name="idDisability"
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                       }}
                                       disabled={disabilityDisable}
                                       onChange={(e: any) => {
                                          if (e.target.value !== null) {
                                             setFieldValue('disability', true);
                                          } else {
                                             setFieldValue('disability', false);
                                          }
                                       }}
                                    >
                                       {disabilitiesOptionsList?.map(
                                          (radio: {
                                             id: number;
                                             name: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.id}
                                                   style={{
                                                      marginBottom: 10,
                                                   }}
                                                >
                                                   {radio.name}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                    {/* <RadioButton
                                       name="disability"
                                       vertical
                                       data={disabilityData}
                                    /> */}
                                 </Col>
                              </Row>
                           </Fieldset>

                           <Fieldset title="Endereço">
                              <Row>
                                 <Col id="add-form-xs-col">
                                    <FormItem
                                       name="personAddress.cep"
                                       label=""
                                       required
                                       className="default-field-container"
                                    >
                                       <div className="title title-add">
                                          {t(
                                             Labels.COMMON_ADRESS_ZIPCODE,
                                          ).toUpperCase()}{' '}
                                          *
                                       </div>
                                       <InputMask
                                          alwaysShowMask={false}
                                          mask="99999-999"
                                          onChange={e => {
                                             const { value } = e.target || '';
                                             const { name } = e.target;
                                             setFieldValue(name, value);
                                          }}
                                          value={values.personAddress?.cep}
                                       >
                                          {() => (
                                             <Input
                                                name="personAddress.cep"
                                                placeholder={t(
                                                   Labels.COMMON_ADRESS_ZIPCODE,
                                                )}
                                                className="default-field"
                                                data-testid="personAddress.cep"
                                                role="textbox"
                                             />
                                          )}
                                       </InputMask>
                                    </FormItem>
                                 </Col>
                                 <Col id="add-form-xs-col">
                                    <div className="title">
                                       {`${t(
                                          Labels.COMMON_PUBLIC_PLACE,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <DropDown
                                       name="personAddress.idStreetType"
                                       placeholder={t(
                                          Labels.COMMON_PUBLIC_PLACE,
                                       )}
                                       disabled={false}
                                       filterOption={(
                                          inputValue: string,
                                          option: {
                                             key: number | null;
                                             value: number | string;
                                             children: string;
                                          },
                                       ) => {
                                          return option.children
                                             .toLowerCase()
                                             .includes(
                                                inputValue.toLowerCase(),
                                             );
                                       }}
                                       allowClear
                                    >
                                       {streetTypeOptionsList?.map(
                                          (option: APIOptions) => {
                                             return (
                                                <Option value={option.id}>
                                                   {option.name}
                                                </Option>
                                             );
                                          },
                                       )}
                                    </DropDown>
                                    <div className="errorText">
                                       {errors?.personAddress?.idStreetType}
                                    </div>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col id="add-form-extra-large-col">
                                    <Field
                                       name="personAddress.street"
                                       title={t(Labels.COMMON_ADRESS_ADRESS)}
                                       errors={errors.personAddress?.street}
                                       value={values.personAddress?.street}
                                       width={undefined}
                                       required
                                    />
                                 </Col>
                              </Row>
                              <Row>
                                 <Col id="add-form-large-col">
                                    <Field
                                       name="personAddress.complement"
                                       title={t(
                                          Labels.COMMON_ADRESS_COMPLEMENT,
                                       )}
                                       errors={errors.personAddress?.complement}
                                       value={values.personAddress?.complement}
                                       width={undefined}
                                       required
                                    />
                                 </Col>
                                 <Col id="add-form-small-col" className="col">
                                    <Field
                                       name="personAddress.number"
                                       title={`${t(
                                          Labels.COMMON_ADRESS_NUMBER,
                                       )} *`}
                                       value={values.personAddress?.number}
                                       errors={errors.personAddress?.number}
                                       width={undefined}
                                    />
                                 </Col>
                              </Row>
                              <Row>
                                 <Col
                                    id="add-form-extra-small-col"
                                    className="col"
                                 >
                                    <div className="title">
                                       {`${t(
                                          Labels.COMMON_ADRESS_STATE,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <DropDown
                                       name="personAddress.uf"
                                       placeholder={t(
                                          Labels.COMMON_ADRESS_STATE,
                                       )}
                                       disabled={false}
                                       onChange={(value: string) => {
                                          setStateAddressSelect(value);
                                          dispatch(clearCityOptionsList());
                                       }}
                                       allowClear
                                    >
                                       {stateAddressOptionsList?.map(
                                          (option: StateAddressOption) => {
                                             return (
                                                <Option value={option.initials}>
                                                   {option.initials}
                                                </Option>
                                             );
                                          },
                                       )}
                                    </DropDown>
                                    <div className="errorText">
                                       {errors.personAddress?.uf}
                                    </div>
                                 </Col>
                                 <Col id="add-form-md-col" className="col">
                                    <div className="title">
                                       {`${t(
                                          Labels.COMMON_ADRESS_CITY,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <DropDown
                                       name="personAddress.idCity"
                                       placeholder={t(
                                          Labels.COMMON_ADRESS_CITY_PLACEHOLDER,
                                       )}
                                       disabled={false}
                                       onSearch={(value: any) => {
                                          searchAPIDebounced(value);
                                       }}
                                       loading={isLoadingCities}
                                       filterOption={(
                                          inputValue: string,
                                          option: {
                                             key: number | null;
                                             value: number | string;
                                             children: string;
                                          },
                                       ) => {
                                          return option?.children
                                             ?.toLowerCase()
                                             .includes(
                                                inputValue.toLowerCase(),
                                             );
                                       }}
                                       allowClear
                                       notFoundContent={
                                          isLoadingCities ? (
                                             <Spin />
                                          ) : (
                                             'Nenhum dado encontrado'
                                          )
                                       }
                                    >
                                       {cityOptionsList?.map(
                                          (option: APIOptions) => {
                                             return (
                                                <Option value={option.id}>
                                                   {option.name}
                                                </Option>
                                             );
                                          },
                                       )}
                                    </DropDown>
                                    <div className="errorText">
                                       {errors?.personAddress?.idCity}
                                    </div>
                                 </Col>
                                 <Col id="add-form-xs-col" className="col">
                                    <Field
                                       name="personAddress.neighborhood"
                                       title={`${t(
                                          Labels.COMMON_ADRESS_NEIGHBORHOOD,
                                       )} *`}
                                       errors={
                                          errors.personAddress?.neighborhood
                                       }
                                       value={
                                          values.personAddress?.neighborhood
                                       }
                                       width={undefined}
                                    />
                                 </Col>
                              </Row>
                           </Fieldset>

                           <Fieldset title="Contato">
                              <Row id="add-form-sm-col" className="np-div">
                                 <FormItem
                                    name="telephone"
                                    label=""
                                    className="default-field-container"
                                 >
                                    <div className="title title-add">
                                       {t(
                                          Labels.COMMON_CONTACT_PHONE,
                                       ).toUpperCase()}
                                    </div>
                                    <InputMask
                                       alwaysShowMask={false}
                                       mask="(99) 9999-9999"
                                       onChange={e => {
                                          const { value } = e.target || '';
                                          const { name } = e.target;
                                          setFieldValue(name, value);
                                       }}
                                       value={values.telephone}
                                    >
                                       {() => (
                                          <Input
                                             name="telephone"
                                             placeholder={t(
                                                Labels.COMMON_CONTACT_PHONE,
                                             )}
                                             className="default-field"
                                             data-testid="telephone"
                                             role="textbox"
                                          />
                                       )}
                                    </InputMask>
                                 </FormItem>
                              </Row>
                              <Row id="add-form-sm-col" className="contact-div">
                                 <FormItem
                                    name="cellphone"
                                    label=""
                                    className="default-field-container"
                                 >
                                    <div className="title title-add">
                                       {t(
                                          Labels.COMMON_CONTACT_MOBILE_PHONE1,
                                       ).toUpperCase()}
                                    </div>
                                    <InputMask
                                       alwaysShowMask={false}
                                       mask="(99) 99999-9999"
                                       onChange={e => {
                                          const { value } = e.target || '';
                                          const { name } = e.target;
                                          setFieldValue(name, value);

                                          if (e.target.value !== '') {
                                             setCellphone2Disable(false);
                                          } else {
                                             setCellphone2Disable(true);
                                          }
                                       }}
                                       value={values.cellphone}
                                    >
                                       {() => (
                                          <Input
                                             name="cellphone"
                                             placeholder={t(
                                                Labels.COMMON_CONTACT_MOBILE_PHONE1,
                                             )}
                                             className="default-field"
                                             data-testid="cellphone"
                                             role="textbox"
                                          />
                                       )}
                                    </InputMask>
                                 </FormItem>
                                 <FormItem
                                    name="cellphone2"
                                    label=""
                                    className="default-field-container"
                                 >
                                    <div className="title title-add">
                                       {t(
                                          Labels.COMMON_CONTACT_MOBILE_PHONE2,
                                       ).toUpperCase()}
                                    </div>
                                    <InputMask
                                       alwaysShowMask={false}
                                       mask="(99) 99999-9999"
                                       onChange={e => {
                                          const { value } = e.target || '';
                                          const { name } = e.target;
                                          setFieldValue(name, value);
                                       }}
                                       value={values.cellphone2}
                                       disabled={cellphone2Disable}
                                    >
                                       {() => (
                                          <Input
                                             name="cellphone2"
                                             placeholder={t(
                                                Labels.COMMON_CONTACT_MOBILE_PHONE2,
                                             )}
                                             className="default-field"
                                             data-testid="cellphone2"
                                             role="textbox"
                                             disabled={cellphone2Disable}
                                          />
                                       )}
                                    </InputMask>
                                 </FormItem>
                              </Row>
                              <Row>
                                 <Col id="add-form-large-col" className="col">
                                    <div className="title">
                                       {`${t(
                                          Labels.COMMON_CONTACT_EMAIL,
                                       ).toUpperCase()} *`}
                                    </div>
                                    <Input
                                       name="mail"
                                       placeholder={t(
                                          Labels.COMMON_CONTACT_EMAIL,
                                       )}
                                       disabled={values.hasMail}
                                    />
                                    <div className="errorText">
                                       {errors.mail}
                                    </div>
                                    <Checkbox
                                       name="hasMail"
                                       className="checkbox"
                                       onChange={e => {
                                          if (e.target.checked) {
                                             setFieldValue('mail', '');
                                             setFieldValue('hasMail', true);
                                          } else {
                                             setFieldValue('hasMail', false);
                                          }
                                       }}
                                    >
                                       {t(Labels.COMMON_CONTACT_NO_EMAIL)}
                                    </Checkbox>
                                 </Col>
                              </Row>
                           </Fieldset>

                           <Fieldset
                              title={t(Labels.NATURALPERSON_ADD_LGPD_TERM)}
                           >
                              <div className="np-div subtitle" ref={myRef}>
                                 {t(Labels.NATURALPERSON_ADD_LGPD_TERM_TEXT)}
                                 <Row style={{ marginTop: '10px' }}>
                                    <Col className="col">
                                       <div className="title-term">
                                          {t(
                                             Labels.NATURALPERSON_ADD_LGPD_TERM_ACCEPT,
                                          ).toUpperCase()}
                                       </div>
                                       <Radio.Group
                                          name="accept"
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                          }}
                                          onChange={e => {
                                             if (e.target.value === true) {
                                                setUploadDisable(false);
                                                setFieldValue(
                                                   'userCpfAccept',
                                                   login.cpf,
                                                );
                                                setFieldValue('uf', 'SP');
                                                setFieldValue(
                                                   'acceptDate',
                                                   moment().format(),
                                                );
                                                setShowUploadList(true);
                                             } else {
                                                setUploadDisable(true);
                                                setFieldValue('uf', 'SP');
                                                setFieldValue(
                                                   'userCpfAccept',
                                                   login.cpf,
                                                );
                                                setFieldValue(
                                                   'acceptDate',
                                                   moment().format(),
                                                );
                                                setFieldValue(
                                                   'file',
                                                   undefined,
                                                );
                                                setShowUploadList(false);
                                                setFileList([]);
                                             }
                                          }}
                                       >
                                          {yesNo?.map(
                                             (radio: {
                                                value: boolean;
                                                name: string;
                                                label: string;
                                             }) => {
                                                return (
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.value}
                                                      style={{
                                                         marginBottom: 10,
                                                      }}
                                                   >
                                                      {radio.label}
                                                   </Radio>
                                                );
                                             },
                                          )}
                                       </Radio.Group>
                                    </Col>
                                    <Col className="col">
                                       <div className="title-term">
                                          {t(
                                             Labels.NATURALPERSON_ADD_LGPD_TERM_DATE,
                                          ).toUpperCase()}
                                       </div>
                                       <DatePicker
                                          name="acceptDate"
                                          placeholder={t(
                                             Labels.NATURALPERSON_ADD_LGPD_TERM_DATE,
                                          )}
                                          locale={locale}
                                          format="DD/MM/YYYY HH:mm"
                                          style={{
                                             height: 40,
                                             fontSize: 20,
                                             width: 250,
                                          }}
                                          disabled
                                       />
                                       <div className="errorText">
                                          {errors.acceptDate}
                                       </div>
                                    </Col>
                                    <Col id="add-form-uf-col" className="col">
                                       <div className="title-term">UF</div>
                                       <DropDown
                                          name="uf"
                                          placeholder="UF"
                                          disabled
                                          showArrow={false}
                                          allowClear
                                       >
                                          {stateAddressOptionsList?.map(
                                             (option: StateAddressOption) => {
                                                return (
                                                   <Option
                                                      value={option.initials}
                                                   >
                                                      {option.initials}
                                                   </Option>
                                                );
                                             },
                                          )}
                                       </DropDown>
                                       <div className="errorText">
                                          {errors.uf}
                                       </div>
                                    </Col>
                                    <Col className="col">
                                       <div className="title-term">
                                          {t(
                                             Labels.NATURALPERSON_ADD_LGPD_TERM_USER,
                                          ).toUpperCase()}
                                       </div>
                                       <DropDown
                                          name="userCpfAccept"
                                          placeholder={t(
                                             Labels.NATURALPERSON_ADD_LGPD_TERM_USER,
                                          )}
                                          disabled
                                       >
                                          <Option value={login.cpf}>
                                             {login.name.toUpperCase()}
                                          </Option>
                                          {person.userCpfAccept && (
                                             <Option value="">
                                                {person.userCpfAccept}
                                             </Option>
                                          )}
                                       </DropDown>

                                       <div className="errorText">
                                          {errors.acceptDate}
                                       </div>
                                    </Col>
                                    <Button
                                       className="np-button"
                                       icon={<DownloadOutlined />}
                                       // disabled={!values.adhesionTerm}
                                       onClick={() => {
                                          window.open(
                                             lastLgpdConsentTerm?.data.fileUrl,
                                             '_blank',
                                          );
                                       }}
                                    >
                                       {t(
                                          Labels.NATURALPERSON_ADD_LGPD_TERM_BUTTON_DOWNLOAD,
                                       )}
                                    </Button>
                                    <Col id="col-upload">
                                       <Upload
                                          {...config}
                                          multiple={false}
                                          maxCount={1}
                                          disabled={uploadDisable}
                                          showUploadList={showUploadList}
                                          fileList={fileList}
                                          beforeUpload={() => {
                                             setFieldValue(
                                                'userCpfAccept',
                                                login.cpf,
                                             );
                                             return true;
                                          }}
                                       >
                                          <Button
                                             className="np-button"
                                             icon={<UploadOutlined />}
                                          >
                                             {t(
                                                Labels.NATURALPERSON_ADD_LGPD_TERM_BUTTON_UPLOAD,
                                             )}
                                          </Button>
                                       </Upload>
                                    </Col>
                                 </Row>
                              </div>
                           </Fieldset>

                           <Fieldset title={t(Labels.NATURALPERSON_ADD_FORMS)}>
                              <div className="np-div">
                                 <Row>
                                    <Button
                                       className="np-button"
                                       onClick={() => {
                                          setIsAddSocProfilesVisible(true);
                                       }}
                                    >
                                       {t(
                                          Labels.NATURALPERSON_ADD_FORMS_OPTION_2,
                                       )}
                                    </Button>
                                    <Button
                                       className="np-button"
                                       onClick={() => {
                                          setIsAddEntrepreneursVisible(true);
                                       }}
                                    >
                                       {t(
                                          Labels.NATURALPERSON_ADD_FORMS_OPTION_1,
                                       )}
                                    </Button>
                                 </Row>
                              </div>
                           </Fieldset>
                           <Fieldset
                              title={t(Labels.NATURALPERSON_ADD_SEVERAL)}
                           >
                              <div className="np-div">
                                 <Row>
                                    <Col id="add-form-md-col" className="col">
                                       <div className="title">
                                          {`${t(
                                             Labels.NATURALPERSON_ADD_PARTNER_ENTITIES,
                                          ).toUpperCase()}: *`}
                                       </div>
                                       <DropDown
                                          // TODO modificar name
                                          name="entities"
                                          placeholder={t(
                                             Labels.NATURALPERSON_ADD_TYPE_ENTITIE,
                                          )}
                                          disabled={false}
                                          onSearch={(value: any) => {
                                             searchAPIDebouncedEntities(value);
                                          }}
                                          loading={isLoadingEntities}
                                          mode="multiple"
                                          filterOption={(
                                             inputValue: string,
                                             option: {
                                                key: number | null;
                                                value: number | string;
                                                children: string;
                                             },
                                          ) => {
                                             return option.children
                                                .toLowerCase()
                                                .includes(
                                                   inputValue.toLowerCase(),
                                                );
                                          }}
                                          allowClear
                                          notFoundContent={
                                             isLoadingEntities ? (
                                                <Spin />
                                             ) : (
                                                'Nenhum dado encontrado'
                                             )
                                          }
                                       >
                                          {entityOptionsList?.map(
                                             (entity: Entity) => {
                                                return (
                                                   <Option value={entity.id}>
                                                      {`${
                                                         entity.name
                                                      } - cnpj: ${
                                                         entity.cnpj ||
                                                         'não possui cnpj'
                                                      }`}
                                                   </Option>
                                                );
                                             },
                                          )}
                                       </DropDown>
                                       <div className="errorText">
                                          {errors.entities}
                                       </div>
                                    </Col>
                                    <Col id="add-form-md-col" className="col">
                                       <div className="title">
                                          {`${t(
                                             Labels.NATURALPERSON_ADD_ADD_TYPES,
                                          ).toUpperCase()}: *`}
                                       </div>
                                       <DropDown
                                          // TODO modificar name
                                          name="idPersonType"
                                          placeholder={t(
                                             Labels.NATURALPERSON_ADD_ADD_TYPES,
                                          )}
                                          disabled={false}
                                          allowClear
                                       >
                                          {personTypeOptionsList?.map(
                                             (radio: {
                                                id: number;
                                                name: string;
                                             }) => {
                                                return (
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      style={{
                                                         marginBottom: 10,
                                                      }}
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                );
                                             },
                                          )}
                                       </DropDown>
                                       <div className="errorText">
                                          {errors.idPersonType}
                                       </div>
                                    </Col>
                                 </Row>
                              </div>
                           </Fieldset>

                           <Row>
                              <Button.Group>
                                 <Button
                                    onClick={() => {
                                       history.goBack();
                                    }}
                                    className="button-goback"
                                    type="primary"
                                 >
                                    {t(Labels.COMMON_BUTTON_BACK)}
                                 </Button>
                                 <SubmitButton
                                    className="default-button"
                                    disabled={false}
                                 >
                                    {t(Labels.COMMON_BUTTON_SAVE)}
                                 </SubmitButton>
                              </Button.Group>
                           </Row>
                        </Form>
                     );
                  }}
               />
            </Content>
         </Layout>
         <AddEntrepreneurs
            isEntrepreneursModalVisible={isAddEntrepreneursVisible}
            setIsEntrepreneursModalVisible={setIsAddEntrepreneursVisible}
         />
         <AddSocProfiles
            isSocprofileModalVisible={isAddSocProfilesVisible}
            setIsSocprofileModalVisible={setIsAddSocProfilesVisible}
         />
      </div>
   );
};

export default AddNaturalPerson;
