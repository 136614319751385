/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import {
   getClasses,
   setLoading,
   setClassDetails,
   setEntityPersonListTotalRecords,
   setEntityPersonsList,
   setKitClassDetails,
   setKitClasses,
   setLoadingClassDetails,
   setLoadingEntityPersonList,
   setInscribeClassErrorMessage,
   setLoadingInscribeClass,
   setAreYouSureModal,
   setErrorModal,
   setReloadClassDetails,
   setPaymentWarningMessage,
} from 'actions/classesAction';
import api from 'config/httpRequest/api';
import { AppDispatch } from 'config/store';

interface Classes {
   idProduct: string;
   idClass: number;
   endDate: string;
   initialDate: string;
   place: string;
   modality: string;
}

export interface SearchClasses {
   data: Classes[];
   pageNumber: number;
   pageSize: number;
   totalRecords: number;
}

export interface ClassDetails {
   idProduct: number | null;
   idClass: number | null;
   initialDate: string | null;
   endDate: string | null;
   city: string | null;
   neighborhood: string | null;
   street: string | null;
   streetType: string | null;
   number: number | null;
   modality: string | null;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   executionDates: any[];
}

export interface KitClassDetails {
   idKit: number;
   idExecutionKit: number;
   initialDate: string;
   endDate: string;
   placeDescription: string;
   city: string;
   neighborhood: string;
   street: string;
   streetType: string;
   number: number;
   modality: string;
   classes: any[];
}

export interface ApiResponse {
   success: boolean;
   message: string;
   data: any;
   totalRecords: number;
}

export interface EntityPerson {
   cpf: string;
   name: string;
   hasInscription: boolean;
}

export const searchClasses = (
   idProduct = '',
   city: null | number = null,
   pageNumber = 1,
   pageSize = 10,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchClasses>('/Classes/ByProductId', {
         params: {
            IdProduct: idProduct,
            IdCity: city,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getClasses(payload));
         })
         .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoading(false));
         });
      dispatch(setLoading(true));
   };
};

export const searchClassesByKit = (
   idKit = '',
   city: null | number = null,
   pageNumber = 1,
   pageSize = 10,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchClasses>('/ExecutionKit/ByKitId', {
         params: {
            IdKit: idKit,
            IdCity: city,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getClasses(payload));
         })
         .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoading(false));
         });
      dispatch(setLoading(true));
   };
};

export const getClassDetails = (id: string | undefined) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingClassDetails(true));
      await api
         .get<ApiResponse>(`Classes/${id}`, {})
         .then(async ({ data: payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newArray: any[] = [];
            payload.data.executionDates.forEach((executionDate: any) => {
               const split = executionDate.initialDate.split(' ');
               const split2 = executionDate.endDate.split(' ');
               newArray.push({
                  ...executionDate,
                  initialDate: split[0],
                  local: payload.data.city,
                  time: `${split[1]} às ${split2[1]}`,
               });
            });
            const newClassDetails = {
               ...payload.data,
               executionDates: newArray,
            };
            dispatch(setClassDetails(newClassDetails));
            dispatch(setLoadingClassDetails(false));
         })
         // eslint-disable-next-line
         .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoadingClassDetails(false));
            // dispatch(setClassDetails({}));
         });
   };
};

export const getKitClassDetails = (id: string) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingClassDetails(true));
      api.get<ApiResponse>(`/ExecutionKit/${id}`, {})
         .then(async ({ data: payload }) => {
            const kitClasses: any[] = [];
            payload.data.classes.forEach((classKit: any) => {
               kitClasses.push({
                  idProduct: classKit.class.idProduct,
                  productName: classKit.class.productName,
                  idClass: classKit.class.idClass,
                  executionDates: classKit.class.executionDates.map(
                     (executionDate: any) => {
                        const split = executionDate.initialDate.split(' ');
                        const split2 = executionDate.endDate.split(' ');
                        return {
                           initialDate: split[0],
                           endDate: executionDate.endDate,
                           time: `${split[1]} às ${split2[1]}`,
                           local: classKit.class.city,
                           neighborhood: classKit.class.neighborhood,
                           number: classKit.class.number,
                           street: classKit.class.street,
                        };
                     },
                  ),
               });
            });
            dispatch(setKitClassDetails(payload.data));
            dispatch(setKitClasses(kitClasses));
            dispatch(setLoadingClassDetails(false));
         })
         .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoadingClassDetails(false));
            // dispatch(setClassDetails({}));
         });
   };
};

export const searchPersonByEntity = (
   name = '',
   cpf = '',
   idEntity: string,
   idClass: string,
   pageNumber: number,
   idExecutionKit = '',
) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingEntityPersonList(true));
      api.get<ApiResponse>('/Person/InscriptionInClass', {
         params: {
            name,
            cpf,
            idEntity,
            idClass,
            idExecutionKit,
            pageNumber,
            pageSize: 10,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(setEntityPersonsList(payload.data));
            dispatch(setEntityPersonListTotalRecords(payload.totalRecords));
            dispatch(setLoadingEntityPersonList(false));
         })
         .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoadingEntityPersonList(false));
         });
   };
};

export const postInscriptionInClass = (
   cpf: string,
   data: {
      idEntity: string;
      idProgram: string;
      idProduct: number;
      idClass: number;
   },
   reloadClass: boolean,
) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingInscribeClass(true));
      api.post<ApiResponse>(`/Person/${cpf}/InscriptionInClass`, data)
         .then(async ({ data: payload }) => {
            dispatch(setLoadingInscribeClass(false));
            dispatch(setReloadClassDetails(!reloadClass));
            dispatch(setAreYouSureModal(false));
            if (payload.data.warningMessage) {
               dispatch(setPaymentWarningMessage(payload.data.warningMessage));
            }
            message.success({
               content: payload.message,
               className: 'success',
               duration: 10,
            });
         })
         .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoadingInscribeClass(false));
            dispatch(setAreYouSureModal(false));
            if (error.response.status >= 400 && error.response.status < 500) {
               dispatch(
                  setInscribeClassErrorMessage(error.response.data.error[0]),
               );
               dispatch(setErrorModal(true));
            }
         });
   };
};
