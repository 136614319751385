import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import buildYupLocale from 'config/language/buildYupLocale';
import { store } from 'config/store';
import AuthService from 'services/authService';
import { Spin } from 'antd';
import Authenticated from './Authenticated';
import { useAppDispatch } from './hooks/hooks';
import 'antd/dist/antd.css';
import './styles/global.scss';

function App() {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const [keyclokerInitialize, setkkeyclokerInitialize] = useState(false);
   useEffect(() => {
      buildYupLocale(null, t);
   }, [t]);
   useEffect(() => {
      AuthService.initKeycloak(dispatch, setkkeyclokerInitialize);
   }, [dispatch]);
   if (!keyclokerInitialize) {
      return <Spin size="large" className="spin" />;
   }
   return (
      <Router>
         <Provider store={store}>
            <Authenticated />
         </Provider>
      </Router>
   );
}

// AuthService.initKeycloak();

export default App;
