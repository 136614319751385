import { useState } from 'react';
import { Menu, Layout } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './Menu.scss';
import { useTranslation } from 'react-i18next';
import usePermission from 'hooks/usePermission';
import { menus } from 'config/menuPaths';
import Labels from '../../../helpers/enum/Labels';

const { SubMenu } = Menu;
const { Sider } = Layout;

export const MenuComponent = () => {
   const [collapsed, setCollapsed] = useState(true);
   const { t } = useTranslation();
   const { hasPermission } = usePermission();

   const verifyPermissionMenu = (profiles: string[]) =>
      hasPermission(profiles) ? '' : 'notPermission';

   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   return (
      <Sider
         collapsible
         trigger={null}
         collapsed={collapsed}
         width="280px"
         onMouseEnter={toggleCollapsed}
         onMouseLeave={toggleCollapsed}
         data-testid="sider"
      >
         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<HomeOutlined />}>
               <Link to="/" role="link">
                  {t(Labels.MENU_HOME)}
               </Link>
            </Menu.Item>

            {menus.map(route => {
               return route.subRoutes ? (
                  <SubMenu
                     key={route.id}
                     icon={route.icon()}
                     title={t(route.title())}
                     className={`${verifyPermissionMenu(route.profiles)}`}
                  >
                     {route.subRoutes?.map(subMenu => (
                        <Link
                           to={subMenu.path}
                           role="link"
                           className="ant-menu-item ant-menu-item-only-child"
                        >
                           <Menu.Item key={subMenu.id}>
                              {t(subMenu.label())}
                           </Menu.Item>
                        </Link>
                     ))}
                  </SubMenu>
               ) : (
                  <Menu.Item
                     key={route.id}
                     icon={route.icon()}
                     className={`${verifyPermissionMenu(
                        route.profiles,
                     )} ant-menu-submenu menu-item-margin`}
                  >
                     <Link to={route.path ? route.path : '/'} role="link">
                        {t(route.route[0].label())}
                     </Link>
                  </Menu.Item>
               );
            })}
         </Menu>
      </Sider>
   );
};
