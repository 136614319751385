import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchPaginated, Profile } from '../services/profilesService';

interface InitialStateType {
   profiles: SearchPaginated;
   loading: boolean;
   profile: Profile;
   success: boolean;
   message: string;
}
interface Response {
   success: boolean;
   message: string;
}

const INITIAL_STATE: InitialStateType = {
   profiles: {} as SearchPaginated,
   loading: true,
   profile: {
      name: '',
      profileId: null,
      entityList: [],
      entities: [],
      toEntities: [],
      entityText: '',
      cnpj: '',
      cpf: '',
      entity: [],
   },
   success: false,
   message: '',
};

export const getProfiles = createAction<SearchPaginated>('GET_PROFILES');

export const setLoading = createAction<boolean>('SET_LOADING');

export const setProfile = createAction<Response>('SET_PROFILE');

export const clearProfile = createAction('CLEAR_PROFILE');

export const getProfile = createAction<Profile>('GET_PROFILE');

export default createReducer(INITIAL_STATE, {
   [getProfiles.type]: (state, action: PayloadAction<SearchPaginated>) => {
      return {
         ...state,
         profiles: action.payload,
         loading: false,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
         loading: true,
      };
   },
   [clearProfile.type]: state => {
      return {
         ...state,
         profile: INITIAL_STATE.profile,
      };
   },
   [setProfile.type]: (state, action: PayloadAction<Response>) => {
      return {
         ...state,
         success: action.payload.success,
         message: action.payload.message,
         loading: false,
      };
   },
   [getProfile.type]: (state, action: PayloadAction<Profile>) => {
      return {
         ...state,
         profile: action.payload,
         loading: false,
      };
   },
});
