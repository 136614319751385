import Icon from '@ant-design/icons';
import SipLogo from '../../../assets/images/logo-SIP.png';
import SebraeLogo from '../../../assets/images/sebrae-logo.png';
import { ReactComponent as KeyIcon } from '../../../assets/images/Key.svg';
import { ReactComponent as RegisterColaboratorIcon } from '../../../assets/images/RegisterColaborator.svg';
import { ReactComponent as RegisterEntityIcon } from '../../../assets/images/RegisterEntity.svg';
import { ReactComponent as FilesIcon } from '../../../assets/images/Files.svg';
import { ReactComponent as InscribeColaboratorIcon } from '../../../assets/images/InscribeColaborator.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/Edit-Icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/Upload-Icon.svg';

interface EditIconProps {
   onClick: (param?: React.MouseEvent<HTMLElement>) => void;
}

export const SipLogoPNG = () => <img src={SipLogo} alt="Logo SIP" />;
export const SebraeLogoPNG = () => <img src={SebraeLogo} alt="Sebrae" />;
export const KeyIconSVG = () => <Icon component={KeyIcon} />;
export const FilesIconSVG = () => <Icon component={FilesIcon} />;
export const InscribeColaboratorIconSVG = () => (
   <Icon component={InscribeColaboratorIcon} />
);
export const RegisterColaboratorIconSVG = () => (
   <Icon component={RegisterColaboratorIcon} />
);
export const RegisterEntityIconSVG = () => (
   <Icon component={RegisterEntityIcon} />
);
export const EditIconSVG = (props: EditIconProps) => (
   <Icon component={EditIcon} {...props} />
);
export const UploadIconSVG = () => <Icon component={UploadIcon} />;
