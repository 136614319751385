import { TFunction } from 'i18next';
import { setLocale } from 'yup';
import Labels from '../../helpers/enum/Labels';

export default function buildYupLocale(_: unknown, t: TFunction): void {
   setLocale({
      mixed: {
         required: t(Labels.COMMON_REQUIRED),
      },
   });
}
