// Values
const FIRST_VACCINE_DATE = '01/17/2021';
const AGENT_PROFILE_ID = 2;
const CITIZEN_PROFILE_ID = 1;
const JSON_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

// Masks

const CPF_MASK = '999.999.999-99';
const CNPJ_MASK = '99.999.999/9999-99';
const CEP_MASK = '99999-999';
// Datepicker directions

const ANCHOR_LEFT = 'left';
const ANCHOR_RIGHT = 'right';

const OPEN_DOWN = 'down';
const OPEN_UP = 'up';

const LIST_PROFILE_ID = [1, 2];

export default {
   FIRST_VACCINE_DATE,
   AGENT_PROFILE_ID,
   CITIZEN_PROFILE_ID,
   CPF_MASK,
   CEP_MASK,
   CNPJ_MASK,
   ANCHOR_LEFT,
   ANCHOR_RIGHT,
   OPEN_DOWN,
   OPEN_UP,
   JSON_DATE_FORMAT,
   LIST_PROFILE_ID,
};
