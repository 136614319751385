import { Col } from 'antd';
import { Input } from 'formik-antd';

interface FieldProps {
   title: string;
   name: string;
   required?: boolean;
   errors: string | undefined | false;
   width: number | undefined;
   minWidth?: number;
   disabled?: boolean;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   value?: any;
   type?: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onChange?: any;
}

export const Field = (props: FieldProps) => {
   const {
      name,
      title,
      required,
      errors,
      width,
      disabled,
      value,
      type,
      onChange,
      minWidth,
   } = props;
   const titleCap = title.toUpperCase();
   return (
      <Col className="col">
         <div className="title title-add">
            {`${titleCap}${required ? ' *' : ''}`}
         </div>
         <Input
            name={name}
            placeholder={title}
            disabled={disabled}
            style={{
               height: 40,
               fontSize: 20,
               minWidth: 0 || minWidth,
               maxWidth: width,
            }}
            value={value}
            type={type}
            onChange={onChange}
         />
         <div className="errorText">{errors}</div>
      </Col>
   );
};

export default Field;
