import Labels from 'helpers/enum/Labels';
import ProfilesEnum from 'helpers/enum/ProfilesEnum';
import {
   FilesIconSVG,
   InscribeColaboratorIconSVG,
   KeyIconSVG,
   RegisterColaboratorIconSVG,
   RegisterEntityIconSVG,
} from 'components/common/icons/icons';
import {
   subRoutesAdmin,
   subRoutesEntity,
   subRoutesEntrepreneur,
   routeRegisterColaborator,
   subRoutesInscribePerson,
} from './subRoutes';

export const menus = [
   {
      title: () => Labels.MENU_OPTION_1,
      icon: KeyIconSVG,
      id: 1000,
      profiles: [ProfilesEnum.SEBRAE],
      subRoutes: subRoutesAdmin,
   },
   {
      title: () => Labels.MENU_OPTION_2,
      icon: RegisterEntityIconSVG,
      path: '/entities',
      id: 1010,
      profiles: [ProfilesEnum.SEBRAE, ProfilesEnum.REPRESENTATIVE],
      // REMOVER SUBROTA
      route: subRoutesEntity,
   },
   {
      title: () => Labels.MENU_OPTION_3,
      icon: RegisterColaboratorIconSVG,
      path: '/naturalPerson',
      id: 1020,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
      route: routeRegisterColaborator,
   },
   {
      title: () => Labels.MENU_OPTION_4,
      icon: InscribeColaboratorIconSVG,
      path: '/inscribePerson',
      id: 1030,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
      // REMOVER ROTA
      route: subRoutesInscribePerson,
   },
   {
      title: () => Labels.MENU_OPTION_5,
      icon: FilesIconSVG,
      path: '/relatory',
      id: 1040,
      profiles: [
         ProfilesEnum.SEBRAE,
         ProfilesEnum.REPRESENTATIVE,
         ProfilesEnum.COLLABORATOR,
      ],
      // REMOVER ROTA
      route: subRoutesEntrepreneur,
   },
];
