import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
   Form,
   Input,
   SubmitButton,
   Select,
   Checkbox,
   DatePicker,
} from 'formik-antd';
import { Button, Layout, PageHeader, Col, Row, message, Spin } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Utils from 'helpers/Utils';
import entitiesService from 'services/entitiesService';
import InputMask from 'react-input-mask';
import { isNull, isUndefined } from 'lodash';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../config/store';
import Field from '../../components/common/fieldEntity';
import Fieldset from '../../components/common/fieldSet';
import RadioButton from '../../components/common/radioButtonEntity';
import DropDown from '../../components/common/dropdownEntity';
import { Form as FormType } from '../../services/formsService';
import {
   searchClassificationEntities,
   setEntity,
   getSearchToEntities,
   getEntitys,
   EntityObject,
   updateEntity,
   clearEntityReduce,
} from '../../services/entitesSelectService';
import { searchEquipaments } from '../../services/equipamentsService';
import { searchInfrastructureCapacities } from '../../services/infrastructureCapacitiesService';
import { searchPeopleServed } from '../../services/peopleServedService';
import { searchServiceProfile } from '../../services/serviceProfileService';
import { searchSocialCause } from '../../services/socialCauseService';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
const { Option } = Select;

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

type PreEntityProps = {
   id: string;
   name: string;
   cnpj?: string;
};

export interface SearchEntities {
   data: Array<any>;
}
interface ClassificationEntities {
   name: string;
   id: string;
   hasDescription?: boolean;
}

interface SocialCausesProp {
   idSocialCause: number;
   description: string;
}
interface ServiceProfilesProp {
   idServiceProfile: number;
   description: string;
}

interface CheckboxProps {
   name: string;
   label: string;
}

const AddEntities = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: any }>();
   const { login } = useSelector((state: RootState) => state.auth);

   const [sc, setSC] = useState([] as any);
   const [sp, setSP] = useState([] as any);
   const [eq, setEQ] = useState([] as any);

   useEffect(() => {
      dispatch(searchClassificationEntities());

      dispatch(searchEquipaments());
      dispatch(searchInfrastructureCapacities());
      dispatch(searchPeopleServed());
      dispatch(searchServiceProfile());
      dispatch(searchSocialCause());
      dispatch(getSearchToEntities());
      if (id) dispatch(getEntitys(id));
      dispatch(
         entitiesService.searchEntities(
            '',
            Utils.stripDotsParenthesisWhitespacesAndHifens(login.cpf),
            '',
            '',
         ),
      );
   }, [dispatch, login, id]);

   useEffect(() => {
      if (isUndefined(id)) dispatch(clearEntityReduce());
   }, [dispatch, id]);

   const {
      classificationEntities,
      entity,
      preSelectEntities,
      success,
      message: messageResponse,
      loading,
   } = useSelector((state: RootState) => state.entities);

   const [reload, setReload] = useState(false);

   useEffect(() => {
      if (success && reload) {
         message.success({
            content: messageResponse,
            className: 'success',
            duration: 10,
         });
         if (!id) {
            dispatch(clearEntityReduce());
         }
         setReload(false);
      }
   }, [dispatch, id, messageResponse, reload, success]);
   // eslint-disable-next-line
   var othersSocialCauses = '';
   // eslint-disable-next-line
   var othersServiceProfile = '';

   useEffect(() => {
      // eslint-disable-next-line
      let auxSC = sc;
      const auxA = entity?.profile?.socialCauses?.map((e: SocialCausesProp) => {
         if (e.description) {
            auxSC.push(`socialCause_Outros`);
         } else {
            auxSC.push(`socialCause_${e.idSocialCause}`);
         }
         return e;
      });
      // eslint-disable-next-line
      let auxSP = sp;
      const auxB = entity?.profile?.serviceProfiles?.map(
         (e: ServiceProfilesProp) => {
            if (e.description) {
               auxSP.push(`serviceProfile_Outros`);
            } else {
               auxSP.push(`serviceProfile_${e.idServiceProfile}`);
            }
            return e;
         },
      );

      console.log(auxA, auxB);
      if (sc.length === 0 && sp.length === 0) {
         setSC(auxSC);
         setSP(auxSP);
      }
   }, [entity, sc, sp]);
   const socialCausesAux = entity?.profile?.socialCauses?.map(
      (e: SocialCausesProp) => {
         if (e.description) {
            othersSocialCauses = e.description;
            return `socialCause_Outros`;
         }
         return `socialCause_${e.idSocialCause}`;
      },
   );
   const serviceProfilesAux = entity?.profile?.serviceProfiles?.map(
      (e: ServiceProfilesProp) => {
         if (e.description) {
            othersServiceProfile = e.description;
            return `serviceProfile_Outros`;
         }
         return `serviceProfile_${e.idServiceProfile}`;
      },
   );
   const equipamentAux = entity?.infrastructure?.idsEquipment?.map(e => {
      return `equipaments_${e}`;
   });

   // eslint-disable-next-line
   var teste: any = {};
   const auxC = socialCausesAux?.forEach(e => {
      teste[e] = true;
      return teste[e];
   });
   const auxD = serviceProfilesAux?.forEach(e => {
      teste[e] = true;
      return teste[e];
   });
   const auxE = equipamentAux?.forEach(e => {
      teste[e] = true;
      return teste[e];
   });
   console.log(auxC, auxD, auxE);

   const entityAux: EntityObject = {
      ...entity,
      ...teste,
      othersSocialCauses,
      othersServiceProfile,
   };
   const { socialCause } = useSelector((state: RootState) => state.socialCause);
   const { serviceProfile } = useSelector(
      (state: RootState) => state.serviceProfile,
   );
   const { equipaments } = useSelector((state: RootState) => state.equipaments);

   const { peopleServed } = useSelector(
      (state: RootState) => state.peopleServed,
   );

   const { infrastructureCapacities } = useSelector(
      (state: RootState) => state.infrastructureCapacity,
   );

   const perfilColuna1 = socialCause?.data
      .filter((e: ClassificationEntities) => {
         return parseFloat(e.id) < 13;
      })
      .map((e: ClassificationEntities) => {
         return {
            name: `socialCause_${e.id}`,
            label: e.name,
            hasDescription: e.hasDescription,
         };
      });
   const perfilColuna2 = socialCause?.data
      .filter((e: ClassificationEntities) => {
         return parseFloat(e.id) >= 13;
      })
      .map((e: ClassificationEntities) => {
         if (e.name === 'Outros') {
            return {
               name: `socialCause_Outros`,
               label: e.name,
               hasDescription: e.hasDescription,
            };
         }
         return {
            name: `socialCause_${e.id}`,
            label: e.name,
            hasDescription: e.hasDescription,
         };
      });

   const perfilColuna3 = serviceProfile?.data
      .filter((e: ClassificationEntities) => {
         return parseFloat(e.id) < 13;
      })
      .map((e: ClassificationEntities) => {
         return {
            name: `serviceProfile_${e.id}`,
            label: e.name,
            hasDescription: e.hasDescription,
         };
      });

   const perfilColuna4 = serviceProfile?.data
      .filter((e: ClassificationEntities) => {
         return parseFloat(e.id) >= 13;
      })
      .map((e: ClassificationEntities) => {
         if (e.name === 'Outros') {
            return {
               name: `serviceProfile_Outros`,
               label: e.name,
               hasDescription: e.hasDescription,
            };
         }

         return {
            name: `serviceProfile_${e.id}`,
            label: e.name,
            hasDescription: e.hasDescription,
         };
      });
   const perfilColuna5 = peopleServed?.data.map((e: ClassificationEntities) => {
      return {
         name: `peopleServed_${e.id}`,
         value: e.id,
         label: e.name,
      };
   });

   const perfilColuna6 = equipaments?.data.map((e: ClassificationEntities) => {
      return {
         name: `equipaments_${e.id}`,
         label: e.name,
      };
   });

   const perfilColuna7 = infrastructureCapacities?.data.map(
      (e: ClassificationEntities) => {
         return {
            name: `infrastructureCapacity_${e.id}`,
            value: e.id,
            label: e.name,
         };
      },
   );

   const preferences = [
      { id: '1', name: 'Nova Entidade' },
      { id: '2', name: 'Entidade Pre-Cadastrada' },
   ];

   const [classificationEntitiesList, setclassificationEntitiesList] = useState(
      [] as ClassificationEntities[],
   );

   useEffect(() => {
      if (classificationEntities?.data?.length > 0)
         setclassificationEntitiesList(classificationEntities.data);
   }, [classificationEntities]);

   const [preSelectEntitiesList, setpreSelectEntitiesList] = useState(
      [] as PreEntityProps[],
   );
   useEffect(() => {
      if (preSelectEntities?.data?.length > 0)
         setpreSelectEntitiesList(preSelectEntities.data);
   }, [preSelectEntities.data]);

   const schema = Yup.object().shape({
      cnpj: Yup.mixed().test({
         message: 'CNPJ invalido',
         test: value => {
            if (
               isUndefined(value) ||
               Utils.isValidCNPJ(
                  Utils.stripDotsParenthesisWhitespacesAndHifens(value),
               ) ||
               isNull(value)
            )
               return true;

            return false;
         },
      }),
      corporateName: Yup.string().when('cnpj', {
         is: (cnpj: string) =>
            Utils.stripDotsParenthesisWhitespacesAndHifens(cnpj)?.length > 0,
         then: Yup.string().required(),
         otherwise: Yup.string(),
      }),
      idEntityClassification: Yup.number()
         .typeError(t(Labels.COMMON_REQUIRED))
         .required(),
      name: Yup.string().typeError(t(Labels.COMMON_REQUIRED)).required(),
      address: Yup.object().shape({
         cep: Yup.string()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required()
            .test({
               message: 'CEP ivalido',
               test: value => {
                  if (isUndefined(value)) return false;
                  if (
                     Utils.stripDotsParenthesisWhitespacesAndHifens(value)
                        ?.length === 8
                  )
                     return true;

                  return false;
               },
            }),
         street: Yup.string().typeError(t(Labels.COMMON_REQUIRED)).required(),
         number: Yup.string().typeError(t(Labels.COMMON_REQUIRED)).required(),
         neighborhood: Yup.string()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
         city: Yup.string().typeError(t(Labels.COMMON_REQUIRED)).required(),
         state: Yup.string().typeError(t(Labels.COMMON_REQUIRED)).required(),
      }),
      contact: Yup.object().shape(
         {
            hasMail: Yup.boolean().typeError(t(Labels.COMMON_REQUIRED)),
            mail: Yup.mixed().when('hasMail', {
               is: (hasMail: boolean) => {
                  return hasMail;
               },
               then: Yup.mixed().required(),
            }),
            telephone: Yup.string().when('cellphone1', {
               is: (cellphone1: string) =>
                  !cellphone1 || cellphone1.length === 0,
               then: Yup.string()
                  .required()
                  .test({
                     message: 'Telefone ivalido',
                     test: value => {
                        if (isUndefined(value)) return false;
                        if (
                           Utils.stripDotsParenthesisWhitespacesAndHifens(value)
                              ?.length === 10
                        )
                           return true;

                        return false;
                     },
                  }),
               otherwise: Yup.string(),
            }),
            cellphone1: Yup.string().when('telephone', {
               is: (telephone: string) => !telephone || telephone.length === 0,
               then: Yup.string()
                  .required()
                  .test({
                     message: 'Celular ivalido',
                     test: value => {
                        if (isUndefined(value)) return false;
                        if (
                           Utils.stripDotsParenthesisWhitespacesAndHifens(value)
                              ?.length === 11
                        )
                           return true;

                        return false;
                     },
                  }),
               otherwise: Yup.string(),
            }),
         },
         [['telephone', 'cellphone1']],
      ),
      profile: Yup.object().shape({
         idPeopleServed: Yup.string()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
      }),
      infrastructure: Yup.object().shape({
         hasRoom: Yup.boolean().typeError(t(Labels.COMMON_REQUIRED)).required(),
         haveTables: Yup.boolean()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
         idInfrastructureCapacity: Yup.number()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
         hasVentilation: Yup.boolean()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
         isAdapted: Yup.boolean()
            .typeError(t(Labels.COMMON_REQUIRED))
            .required(),
      }),
      othersSocialCauses: Yup.mixed().when('socialCause_Outros', {
         // eslint-disable-next-line camelcase
         is: (socialCause_Outros: boolean) => {
            // eslint-disable-next-line camelcase
            return socialCause_Outros;
         },
         then: Yup.mixed().required(),
      }),
      othersServiceProfile: Yup.mixed().when('serviceProfile_Outros', {
         // eslint-disable-next-line camelcase
         is: (serviceProfile_Outros: boolean) => {
            // eslint-disable-next-line camelcase
            return serviceProfile_Outros;
         },
         then: Yup.mixed().required(),
      }),
      socialCauses: Yup.mixed().test({
         message: t(Labels.COMMON_REQUIRED),
         test: () => {
            if (sc.length > 0) return true;

            return false;
         },
      }),
      serviceProfile: Yup.mixed().test({
         message: t(Labels.COMMON_REQUIRED),
         test: () => {
            if (sp.length > 0) return true;

            return false;
         },
      }),
      equipement: Yup.mixed().test({
         message: t(Labels.COMMON_REQUIRED),
         test: () => {
            if (eq.length > 0) return true;

            return false;
         },
      }),
   });

   const yesNo = [
      {
         name: 'sim',
         value: true,
         label: t(Labels.COMMON_YES),
      },
      {
         name: 'nao',
         value: false,
         label: t(Labels.COMMON_NO),
      },
   ];

   return (
      <div className="entities-search-container">
         <Layout className="site-layout">
            <PageHeader
               title={
                  id
                     ? t(Labels.ENTITIES_EDIT_NAME)
                     : t(Labels.ENTITIES_ADD_NAME)
               }
            />
            <div className="subtitle">{t(Labels.COMMON_REQUIRED_TITLE)}</div>
            <Content style={{ marginTop: 0, paddingTop: 0, marginLeft: 0 }}>
               {id && loading && !reload ? (
                  <Spin size="large" className="spin" />
               ) : (
                  <Formik
                     // eslint-disable-next-line
                     onSubmit={async (e: any, { resetForm }: any) => {
                        const socialCauses = socialCause?.data
                           ?.filter((socialCause1: ClassificationEntities) => {
                              if (socialCause1.name === 'Outros') {
                                 return e.othersSocialCauses;
                              }
                              return e[`socialCause_${socialCause1.id}`];
                           })
                           ?.map((s: ClassificationEntities) => {
                              return {
                                 idSocialCause: s.id,
                                 description: s.hasDescription
                                    ? e.othersSocialCauses
                                    : '',
                              };
                           });

                        const serviceProfile1 = serviceProfile?.data
                           ?.filter((s: ClassificationEntities) => {
                              if (s.name === 'Outros') {
                                 return e.othersServiceProfile;
                              }
                              return e[`serviceProfile_${s.id}`];
                           })
                           ?.map((s: ClassificationEntities) => {
                              return {
                                 idServiceProfile: s.id,
                                 description: s.hasDescription
                                    ? e.othersServiceProfile
                                    : '',
                              };
                           });

                        const equipments = equipaments?.data
                           ?.filter((s: ClassificationEntities) => {
                              return e[`equipaments_${s.id}`];
                           })
                           ?.map((s: ClassificationEntities) => {
                              return s.id;
                           });

                        const a = {
                           idToEntities: e.idToEntities,
                           idEntityClassification: e.idEntityClassification,
                           name: e.name,
                           cnpj: Utils.stripDotsParenthesisWhitespacesAndHifens(
                              e.cnpj,
                           ),
                           corporateName: e.corporateName,
                           createdAt: e.createdAt,
                           profile: {
                              idPeopleServed: e.profile.idPeopleServed,
                              socialCauses,
                              serviceProfiles: serviceProfile1,
                           },
                           infrastructure: {
                              idInfrastructureCapacity:
                                 e.infrastructure.idInfrastructureCapacity,
                              hasRoom: e.infrastructure.hasRoom,
                              haveTables: e.infrastructure.haveTables,
                              hasVentilation: e.infrastructure.hasVentilation,
                              isAdapted: e.infrastructure.isAdapted,
                              idsEquipment: equipments,
                           },
                           address: {
                              cep: Utils.stripDotsParenthesisWhitespacesAndHifens(
                                 e.address.cep,
                              ),
                              street: e.address.street,
                              number: e.address.number,
                              neighborhood: e.address.neighborhood,
                              city: e.address.city,
                              state: e.address.state,
                              complement: e.address.complement,
                           },
                           contact: {
                              telephone:
                                 Utils.stripDotsParenthesisWhitespacesAndHifens(
                                    e.contact.telephone,
                                 )?.toString(),
                              cellphone1:
                                 Utils.stripDotsParenthesisWhitespacesAndHifens(
                                    e.contact.cellphone1,
                                 )?.toString(),
                              cellphone2:
                                 Utils.stripDotsParenthesisWhitespacesAndHifens(
                                    e.contact.cellphone2,
                                 )?.toString(),
                              mail: e.contact.mail ? e.contact.mail : '',
                              hasMail: e.contact.hasMail,
                              site: e.contact.site,
                           },
                        };
                        if (id) {
                           await dispatch(await updateEntity(id, a));
                           setReload(true);
                        } else {
                           await dispatch(
                              await setEntity(a, (response: boolean) => {
                                 if (response) resetForm();
                              }),
                           );
                           setReload(true);
                        }
                     }}
                     initialValues={entityAux}
                     enableReinitialize
                     validationSchema={schema}
                     render={({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        setFieldTouched,
                     }) => {
                        const condition =
                           values.preference === '2' ||
                           login.role_id.toString() !== '3';
                        return (
                           <Form style={{ width: '100%' }}>
                              <Fieldset title="Identificação">
                                 <Row>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(
                                             Labels.ENTITIES_ADD_CLASSIFICATION,
                                          ).toUpperCase()} *`}
                                       </div>
                                       <DropDown
                                          name="idEntityClassification"
                                          placeholder={t(
                                             Labels.ENTITIES_ADD_CLASSIFICATION,
                                          )}
                                          disabled={!isUndefined(id)}
                                          notShowSearch
                                          allowClear
                                       >
                                          {classificationEntitiesList?.map(
                                             (form: FormType) => {
                                                return (
                                                   <Option value={form.id}>
                                                      {form.name}
                                                   </Option>
                                                );
                                             },
                                          )}
                                       </DropDown>
                                       <div className="errorText">
                                          {touched.idEntityClassification &&
                                             errors.idEntityClassification}
                                       </div>
                                    </Col>
                                    {login.role_id.toString() === '3' &&
                                       isUndefined(id) && (
                                          <Col className="col">
                                             <div className="title title-add">
                                                {`${t(
                                                   Labels.ENTITIES_ADD_PREFERENCE,
                                                ).toUpperCase()} *`}
                                             </div>
                                             <DropDown
                                                name="preference"
                                                placeholder={t(
                                                   Labels.ENTITIES_ADD_PREFERENCE,
                                                )}
                                                disabled={false}
                                                notShowSearch
                                                allowClear
                                             >
                                                {preferences?.map(
                                                   (form: PreEntityProps) => {
                                                      return (
                                                         <Option
                                                            value={form.id}
                                                         >
                                                            {form.name}
                                                         </Option>
                                                      );
                                                   },
                                                )}
                                             </DropDown>
                                             <div className="errorText">
                                                {touched.idEntityClassification &&
                                                   errors.idEntityClassification}
                                             </div>
                                          </Col>
                                       )}
                                    {condition && isUndefined(id) ? (
                                       <Col className="col">
                                          <div className="title title-add title-extend">
                                             {`${t(
                                                Labels.ENTITIES_ADD_ENTITY_NAME,
                                             ).toUpperCase()} *`}
                                          </div>
                                          <DropDown
                                             name="name"
                                             placeholder={t(
                                                Labels.ENTITIES_ADD_ENTITY_NAME,
                                             )}
                                             disabled={!isUndefined(id)}
                                             notShowSearch
                                             allowClear
                                             onChange={(e: string) => {
                                                preSelectEntitiesList.forEach(
                                                   (p: PreEntityProps) => {
                                                      if (p.id === e) {
                                                         setFieldValue(
                                                            'cnpj',
                                                            p.cnpj,
                                                         );
                                                         setFieldValue(
                                                            'name',
                                                            p.name,
                                                         );
                                                         setFieldValue(
                                                            'idToEntities',
                                                            p.id,
                                                         );
                                                      }
                                                   },
                                                );
                                             }}
                                          >
                                             {preSelectEntitiesList?.map(
                                                (form: PreEntityProps) => {
                                                   return (
                                                      <Option value={form.id}>
                                                         {form.name}
                                                      </Option>
                                                   );
                                                },
                                             )}
                                          </DropDown>
                                          <div className="errorText">
                                             {touched.idEntityClassification &&
                                                errors.idEntityClassification}
                                          </div>
                                       </Col>
                                    ) : (
                                       <Field
                                          name="name"
                                          title={`${t(
                                             Labels.ENTITIES_ADD_ENTITY_NAME,
                                          )}`}
                                          required
                                          errors={touched.name && errors.name}
                                          value={values.name}
                                          minWidth={300}
                                          width={400}
                                          disabled={id}
                                       />
                                    )}
                                    <Col className="col">
                                       <div className="title title-add">{`${t(
                                          Labels.ENTITIES_ADD_CREATION_DATE,
                                       ).toUpperCase()}`}</div>
                                       <DatePicker
                                          name="createdAt"
                                          placeholder={t(
                                             Labels.ENTITIES_ADD_CREATION_DATE,
                                          )}
                                          format="DD/MM/YYYY"
                                          style={{
                                             height: 40,
                                             fontSize: 20,
                                             width: 200,
                                          }}
                                          disabled={id}
                                       />
                                       <div className="errorText">
                                          {touched.createdAt &&
                                             errors.createdAt}
                                       </div>
                                    </Col>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(Labels.COMMON_CNPJ)}`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="99.999.999/9999-99"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={values.cnpj || ''}
                                             disabled={id}
                                          >
                                             {() => (
                                                <Input
                                                   name="cnpj"
                                                   placeholder={t(
                                                      Labels.COMMON_CNPJ,
                                                   )}
                                                   className="input"
                                                   data-testid="cnpj"
                                                   role="textbox"
                                                   disabled={id}
                                                />
                                             )}
                                          </InputMask>
                                       </div>

                                       <div className="errorText">
                                          {touched.cnpj && errors.cnpj}
                                       </div>
                                    </Col>
                                    <Field
                                       name="corporateName"
                                       title={t(
                                          Labels.ENTITIES_ADD_CORPORATE_NAME,
                                       )}
                                       errors={
                                          touched.corporateName &&
                                          errors.corporateName
                                       }
                                       minWidth={300}
                                       width={400}
                                       value={values.corporateName}
                                       disabled={id}
                                    />
                                 </Row>
                              </Fieldset>
                              <Fieldset title="Endereço">
                                 <Row>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(
                                             Labels.COMMON_ADRESS_ZIPCODE,
                                          )} *`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="99999-999"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={values?.address?.cep || ''}
                                          >
                                             {() => (
                                                <Input
                                                   name="address.cep"
                                                   placeholder={t(
                                                      Labels.COMMON_ADRESS_ZIPCODE,
                                                   )}
                                                   className="input"
                                                   data-testid="address.cep"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                       </div>
                                       <div className="errorText">
                                          {touched.address?.cep &&
                                             errors.address?.cep}
                                       </div>
                                    </Col>
                                    <Field
                                       name="address.street"
                                       title={t(Labels.COMMON_ADRESS_ADRESS)}
                                       errors={
                                          touched.address?.street &&
                                          errors?.address?.street
                                       }
                                       width={400}
                                       required
                                       value={values?.address?.street}
                                    />
                                    <Field
                                       name="address.number"
                                       title={t(Labels.COMMON_ADRESS_NUMBER)}
                                       errors={
                                          touched.address?.number &&
                                          errors?.address?.number
                                       }
                                       width={180}
                                       required
                                       value={values?.address?.number}
                                    />
                                    <Field
                                       name="address.complement"
                                       title={t(
                                          Labels.COMMON_ADRESS_COMPLEMENT,
                                       )}
                                       errors={
                                          touched.address?.complement &&
                                          errors?.address?.complement
                                       }
                                       width={300}
                                       value={values?.address?.complement}
                                    />
                                    <Field
                                       name="address.neighborhood"
                                       title={t(
                                          Labels.COMMON_ADRESS_NEIGHBORHOOD,
                                       )}
                                       errors={
                                          touched.address?.neighborhood &&
                                          errors?.address?.neighborhood
                                       }
                                       width={260}
                                       required
                                       value={values?.address?.neighborhood}
                                    />
                                    <Field
                                       name="address.city"
                                       title={t(Labels.COMMON_ADRESS_CITY)}
                                       errors={
                                          touched.address?.city &&
                                          errors?.address?.city
                                       }
                                       width={260}
                                       required
                                       value={values?.address?.city}
                                    />
                                    <Field
                                       name="address.state"
                                       title={t(Labels.COMMON_ADRESS_STATE)}
                                       errors={
                                          touched.address?.state &&
                                          errors?.address?.state
                                       }
                                       width={260}
                                       required
                                       value={values?.address?.state}
                                    />
                                 </Row>
                              </Fieldset>
                              <Fieldset title="Contato">
                                 <Row>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(Labels.COMMON_CONTACT_PHONE)}`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="(99)9999-9999"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={
                                                values?.contact?.telephone || ''
                                             }
                                          >
                                             {() => (
                                                <Input
                                                   name="contact.telephone"
                                                   placeholder={t(
                                                      Labels.COMMON_CONTACT_PHONE,
                                                   )}
                                                   className="input"
                                                   data-testid="contact.telephone"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                       </div>

                                       <div className="errorText">
                                          {touched.contact?.telephone &&
                                             errors.contact?.telephone}
                                       </div>
                                    </Col>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(
                                             Labels.COMMON_CONTACT_MOBILE_PHONE1,
                                          )}`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="(99)99999-9999"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={
                                                values?.contact?.cellphone1 ||
                                                ''
                                             }
                                          >
                                             {() => (
                                                <Input
                                                   name="contact.cellphone1"
                                                   placeholder={t(
                                                      Labels.COMMON_CONTACT_MOBILE_PHONE1,
                                                   )}
                                                   className="input"
                                                   data-testid="contact.cellphone1"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                       </div>

                                       <div className="errorText">
                                          {touched.contact?.cellphone1 &&
                                             errors.contact?.cellphone1}
                                       </div>
                                    </Col>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(
                                             Labels.COMMON_CONTACT_MOBILE_PHONE2,
                                          )}`}
                                       </div>
                                       <div className="custom-div">
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="(99)99999-9999"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                             value={
                                                values?.contact?.cellphone2 ||
                                                ''
                                             }
                                          >
                                             {() => (
                                                <Input
                                                   name="contact.cellphone2"
                                                   placeholder={t(
                                                      Labels.COMMON_CONTACT_MOBILE_PHONE2,
                                                   )}
                                                   className="input"
                                                   data-testid="contact.cellphone2"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                       </div>

                                       <div className="errorText">
                                          {touched.contact?.cellphone2 &&
                                             errors.contact?.cellphone2}
                                       </div>
                                    </Col>
                                    <Col className="col">
                                       <div className="title title-add">
                                          {`${t(
                                             Labels.COMMON_CONTACT_EMAIL,
                                          ).toUpperCase()} *`}
                                       </div>
                                       <Input
                                          name="contact.mail"
                                          placeholder={t(
                                             Labels.COMMON_CONTACT_EMAIL,
                                          )}
                                          style={{
                                             height: 40,
                                             fontSize: 20,
                                             width: 300,
                                          }}
                                          disabled={!values?.contact?.hasMail}
                                          onChange={e => {
                                             if (e.target.checked) {
                                                setFieldValue(
                                                   'contact.mail',
                                                   null,
                                                );
                                             }
                                          }}
                                       />
                                       <div className="errorText">
                                          {touched.contact?.mail &&
                                             errors?.contact?.mail}
                                       </div>
                                       <Checkbox
                                          name="contact.hasMail"
                                          className="checkbox"
                                          onChange={e => {
                                             if (e.target.checked) {
                                                setFieldValue(
                                                   'contact.mail',
                                                   '',
                                                );
                                                setFieldValue(
                                                   'contact.hasMail',
                                                   false,
                                                );
                                             } else {
                                                setFieldValue(
                                                   'contact.hasMail',
                                                   true,
                                                );
                                             }
                                          }}
                                          checked={!values?.contact?.hasMail}
                                       >
                                          {t(Labels.COMMON_CONTACT_NO_EMAIL)}
                                       </Checkbox>
                                    </Col>
                                    <Field
                                       name="contact.site"
                                       title={t(Labels.COMMON_CONTACT_SITE)}
                                       errors={
                                          touched.contact?.site &&
                                          errors?.contact?.site
                                       }
                                       width={400}
                                       value={values?.contact?.site}
                                    />
                                 </Row>
                              </Fieldset>
                              <Fieldset title="Perfil">
                                 <div className="title-questions">
                                    {t(Labels.ENTITIES_ADD_ENTITY_PERFORMANCE)}
                                 </div>
                                 <Row className="row">
                                    <Col className="col" span={24} md={12}>
                                       {perfilColuna1.map(
                                          (e: CheckboxProps) => {
                                             return (
                                                <Checkbox
                                                   name={e.name}
                                                   className="checkbox"
                                                   onChange={d => {
                                                      setFieldTouched(
                                                         'socialCauses',
                                                         true,
                                                      );
                                                      if (d.target.checked) {
                                                         // eslint-disable-next-line
                                                         let auxSC = sc;
                                                         auxSC.push(e.name);
                                                         setSC(auxSC);
                                                      } else {
                                                         // eslint-disable-next-line
                                                         let auxSC = sc;
                                                         auxSC.splice(
                                                            auxSC.indexOf(
                                                               e.name,
                                                            ),
                                                            1,
                                                         );
                                                         setSC(auxSC);
                                                      }
                                                   }}
                                                >
                                                   {t(e.label)}
                                                </Checkbox>
                                             );
                                          },
                                       )}
                                       <div className="errorTextInfrastructure">
                                          {touched.socialCauses &&
                                             errors.socialCauses}
                                       </div>
                                    </Col>
                                    <Col className="col" span={24} md={10}>
                                       {perfilColuna2.map(
                                          (e: CheckboxProps, i: number) => {
                                             if (
                                                perfilColuna2.length - 1 ===
                                                i
                                             ) {
                                                return (
                                                   <div className="other-option-checkbox">
                                                      <Checkbox
                                                         name={e.name}
                                                         className="checkbox"
                                                         onChange={d => {
                                                            setFieldTouched(
                                                               'socialCauses',
                                                               true,
                                                            );
                                                            if (
                                                               d.target.checked
                                                            ) {
                                                               if (
                                                                  e.name ===
                                                                  'socialCause_Outros'
                                                               ) {
                                                                  setFieldValue(
                                                                     'socialCause_Outros',
                                                                     true,
                                                                  );
                                                               }
                                                               // eslint-disable-next-line
                                                               let auxSC = sc;
                                                               auxSC.push(
                                                                  e.name,
                                                               );
                                                               setSC(auxSC);
                                                            } else {
                                                               if (
                                                                  e.name ===
                                                                  'socialCause_Outros'
                                                               ) {
                                                                  setFieldValue(
                                                                     'socialCause_Outros',
                                                                     false,
                                                                  );
                                                               }
                                                               // eslint-disable-next-line
                                                               let auxSC = sc;
                                                               auxSC.splice(
                                                                  auxSC.indexOf(
                                                                     e.name,
                                                                  ),
                                                                  1,
                                                               );
                                                               setSC(auxSC);
                                                            }
                                                         }}
                                                      >
                                                         {t(e.label)}
                                                      </Checkbox>
                                                      <div className="otherfield">
                                                         <Input
                                                            name="othersSocialCauses"
                                                            className="input-checkbox"
                                                         />
                                                         <div className="errorText">
                                                            {
                                                               errors.othersSocialCauses
                                                            }
                                                         </div>
                                                      </div>
                                                   </div>
                                                );
                                             }

                                             return (
                                                <Checkbox
                                                   name={e.name}
                                                   className="checkbox"
                                                   onChange={d => {
                                                      setFieldTouched(
                                                         'socialCauses',
                                                         true,
                                                      );
                                                      if (d.target.checked) {
                                                         // eslint-disable-next-line
                                                         let auxSC = sc;
                                                         auxSC.push(e.name);
                                                         setSC(auxSC);
                                                      } else {
                                                         // eslint-disable-next-line
                                                         let auxSC = sc;
                                                         auxSC.splice(
                                                            auxSC.indexOf(
                                                               e.name,
                                                            ),
                                                            1,
                                                         );
                                                         setSC(auxSC);
                                                      }
                                                   }}
                                                >
                                                   {t(e.label)}
                                                </Checkbox>
                                             );
                                          },
                                       )}
                                    </Col>
                                 </Row>
                                 <div className="title-questions">
                                    {t(Labels.ENTITIES_ADD_ENTITY_SERVICE)}
                                 </div>
                                 <Row className="row">
                                    <Col className="col" span={24} md={12}>
                                       {perfilColuna3.map(
                                          (e: CheckboxProps) => {
                                             return (
                                                <Checkbox
                                                   name={e.name}
                                                   className="checkbox"
                                                   onChange={d => {
                                                      setFieldTouched(
                                                         'serviceProfile',
                                                         true,
                                                      );
                                                      if (d.target.checked) {
                                                         // eslint-disable-next-line
                                                         let auxSC = sp;
                                                         auxSC.push(e.name);
                                                         setSP(auxSC);
                                                      } else {
                                                         // eslint-disable-next-line
                                                         let auxSC = sp;
                                                         auxSC.splice(
                                                            auxSC.indexOf(
                                                               e.name,
                                                            ),
                                                            1,
                                                         );
                                                         setSP(auxSC);
                                                      }
                                                   }}
                                                >
                                                   {t(e.label)}
                                                </Checkbox>
                                             );
                                          },
                                       )}
                                       <div className="errorTextInfrastructure">
                                          {touched.serviceProfile &&
                                             errors.serviceProfile}
                                       </div>
                                    </Col>

                                    <Col className="col" span={24} md={10}>
                                       {perfilColuna4.map(
                                          (e: CheckboxProps, i: number) => {
                                             if (
                                                perfilColuna4.length - 1 ===
                                                i
                                             ) {
                                                return (
                                                   <div className="other-option-checkbox">
                                                      <Checkbox
                                                         name={e.name}
                                                         className="checkbox"
                                                         onChange={d => {
                                                            setFieldTouched(
                                                               'serviceProfile',
                                                               true,
                                                            );
                                                            if (
                                                               d.target.checked
                                                            ) {
                                                               if (
                                                                  e.name ===
                                                                  'serviceProfile_Outros'
                                                               ) {
                                                                  setFieldValue(
                                                                     'serviceProfile_Outros',
                                                                     true,
                                                                  );
                                                               }
                                                               // eslint-disable-next-line
                                                               let auxSC = sp;
                                                               auxSC.push(
                                                                  e.name,
                                                               );
                                                               setSP(auxSC);
                                                            } else {
                                                               if (
                                                                  e.name ===
                                                                  'serviceProfile_Outros'
                                                               ) {
                                                                  setFieldValue(
                                                                     'serviceProfile_Outros',
                                                                     false,
                                                                  );
                                                               }
                                                               // eslint-disable-next-line
                                                               let auxSC = sp;
                                                               auxSC.splice(
                                                                  auxSC.indexOf(
                                                                     e.name,
                                                                  ),
                                                                  1,
                                                               );
                                                               setSP(auxSC);
                                                            }
                                                         }}
                                                      >
                                                         {t(e.label)}
                                                      </Checkbox>
                                                      <div className="otherfield">
                                                         <Input
                                                            name="othersServiceProfile"
                                                            className="input-checkbox"
                                                         />
                                                         <div className="errorText">
                                                            {
                                                               errors.othersServiceProfile
                                                            }
                                                         </div>
                                                      </div>
                                                   </div>
                                                );
                                             }
                                             return (
                                                <Checkbox
                                                   name={e.name}
                                                   className="checkbox"
                                                   onChange={d => {
                                                      setFieldTouched(
                                                         'serviceProfile',
                                                         true,
                                                      );
                                                      if (d.target.checked) {
                                                         // eslint-disable-next-line
                                                         let auxSC = sp;
                                                         auxSC.push(e.name);
                                                         setSP(auxSC);
                                                      } else {
                                                         // eslint-disable-next-line
                                                         let auxSC = sp;
                                                         auxSC.splice(
                                                            auxSC.indexOf(
                                                               e.name,
                                                            ),
                                                            1,
                                                         );
                                                         setSP(auxSC);
                                                      }
                                                   }}
                                                >
                                                   {t(e.label)}
                                                </Checkbox>
                                             );
                                          },
                                       )}
                                    </Col>
                                 </Row>
                                 <div className="title-questions">
                                    {t(Labels.ENTITIES_ADD_PEOPLE_SERVED)}
                                 </div>
                                 <Row className="row-vertical">
                                    <RadioButton
                                       name="profile.idPeopleServed"
                                       vertical
                                       data={perfilColuna5}
                                       t={t}
                                       value={values?.profile?.idPeopleServed}
                                    />
                                 </Row>
                                 <div className="errorText">
                                    {touched.profile?.idPeopleServed &&
                                       errors?.profile?.idPeopleServed}
                                 </div>
                              </Fieldset>
                              <Fieldset
                                 title={t(
                                    Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_INFRASTRUCTURE,
                                 )}
                              >
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {t(
                                          Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_ROOMS,
                                       )}
                                    </div>
                                    <RadioButton
                                       name="infrastructure.hasRoom"
                                       data={yesNo}
                                       value={values?.infrastructure?.hasRoom}
                                    />
                                    <div className="errorTextInfrastructure">
                                       {touched.infrastructure?.hasRoom &&
                                          errors?.infrastructure?.hasRoom}
                                    </div>
                                 </Row>
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {t(
                                          Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_TABLES,
                                       )}
                                    </div>
                                    <RadioButton
                                       name="infrastructure.haveTables"
                                       data={yesNo}
                                       value={
                                          values?.infrastructure?.haveTables
                                       }
                                    />
                                    <div className="errorTextInfrastructure">
                                       {touched.infrastructure?.haveTables &&
                                          errors?.infrastructure?.haveTables}
                                    </div>
                                 </Row>
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {t(
                                          Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_ENTITY_CAPACITY,
                                       )}
                                    </div>
                                    <RadioButton
                                       name="infrastructure.idInfrastructureCapacity"
                                       vertical
                                       data={perfilColuna7}
                                       value={
                                          values?.infrastructure
                                             ?.idInfrastructureCapacity
                                       }
                                    />
                                    <div className="errorTextInfrastructure">
                                       {touched.infrastructure
                                          ?.idInfrastructureCapacity &&
                                          errors?.infrastructure
                                             ?.idInfrastructureCapacity}
                                    </div>
                                 </Row>
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {t(
                                          Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_VENTILATION,
                                       )}
                                    </div>
                                    <RadioButton
                                       name="infrastructure.hasVentilation"
                                       data={yesNo}
                                       value={
                                          values?.infrastructure?.hasVentilation
                                       }
                                    />
                                    <div className="errorTextInfrastructure">
                                       {touched.infrastructure
                                          ?.hasVentilation &&
                                          errors?.infrastructure
                                             ?.hasVentilation}
                                    </div>
                                 </Row>
                                 <div className="title-questions line-field-height">
                                    {t(
                                       Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_EQUIPMENT,
                                    )}
                                 </div>
                                 <Row className="row-vertical">
                                    <Col className="col" span={24} md={6}>
                                       {perfilColuna6.map(
                                          (e: CheckboxProps) => {
                                             return (
                                                <Checkbox
                                                   name={e.name}
                                                   className="checkbox"
                                                   onChange={d => {
                                                      setFieldTouched(
                                                         'equipement',
                                                         true,
                                                      );
                                                      if (d.target.checked) {
                                                         // eslint-disable-next-line
                                                         let auxSC = eq;
                                                         auxSC.push(e.name);
                                                         setEQ(auxSC);
                                                      } else {
                                                         // eslint-disable-next-line
                                                         let auxSC = eq;
                                                         auxSC.splice(
                                                            auxSC.indexOf(
                                                               e.name,
                                                            ),
                                                            1,
                                                         );
                                                         setEQ(auxSC);
                                                      }
                                                   }}
                                                >
                                                   {t(e.label)}
                                                </Checkbox>
                                             );
                                          },
                                       )}
                                       <div className="errorTextInfrastructure">
                                          {touched.equipement &&
                                             errors.equipement}
                                       </div>
                                    </Col>
                                 </Row>
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {t(
                                          Labels.ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_DISABLED_PEOPLE,
                                       )}
                                    </div>
                                    <RadioButton
                                       name="infrastructure.isAdapted"
                                       data={yesNo}
                                       value={values?.infrastructure?.isAdapted}
                                    />
                                    <div className="errorTextInfrastructure">
                                       {touched.infrastructure?.isAdapted &&
                                          errors?.infrastructure?.isAdapted}
                                    </div>
                                 </Row>
                              </Fieldset>
                              <Row>
                                 <Button.Group>
                                    <Button
                                       onClick={() => {
                                          dispatch(clearEntityReduce());
                                          history.goBack();
                                       }}
                                       className="button-goback"
                                       id="entities-add-go-back-button"
                                       type="primary"
                                    >
                                       {t(Labels.COMMON_BUTTON_BACK)}
                                    </Button>
                                    <SubmitButton className="default-button">
                                       {t(Labels.COMMON_BUTTON_SAVE)}
                                    </SubmitButton>
                                    {id && (
                                       <Button
                                          onClick={() => {
                                             history.push(
                                                `/programAdhesionTerm/${id}`,
                                             );
                                          }}
                                          className="adession-button"
                                          type="primary"
                                          id="entities-add-program-adhesion-button"
                                       >
                                          Aderir aos Programas
                                       </Button>
                                    )}
                                 </Button.Group>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               )}
            </Content>
         </Layout>
      </div>
   );
};

export default AddEntities;
