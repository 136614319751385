import {
   getEntities,
   getClassificationEntities,
   getEntity,
   getSearchToEntity,
   clearEntity,
   responseEntity,
   startLoadding,
   endLoadding,
} from '../actions/entitiesSelectAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchEntities {
   data: any[];
}

export type Entity = {
   id: string;
   name: string;
};
interface SetProfileResponse {
   success: boolean;
   message: string;
   error: string;
}
export interface SearchEntity {
   data: any;
}
export interface EntityObject {
   idEntityClassification: number | null;
   name: string;
   cnpj: string;
   corporateName: string;
   preference?: string;
   createdAt: string | null;
   socialCauses: Array<string> | null;
   serviceProfile: Array<string> | null;
   equipement: Array<string> | null;
   othersSocialCauses: string | null;
   othersServiceProfile: string | null;
   profile: {
      idPeopleServed: number | null;
      socialCauses: [
         {
            idSocialCause: number;
            description: string;
         },
      ];
      serviceProfiles: [
         {
            idServiceProfile: number;
            description: string;
         },
      ];
   };
   infrastructure: {
      idInfrastructureCapacity: number | null;
      hasRoom: boolean | null;
      haveTables: boolean | null;
      hasVentilation: boolean | null;
      isAdapted: boolean | null;
      idsEquipment: Array<number>;
   };
   address: {
      cep: string;
      street: string;
      number: string;
      neighborhood: string;
      city: string;
      state: string;
      complement: string;
   };
   contact: {
      telephone: string;
      cellphone1: string;
      cellphone2: string;
      mail: string;
      hasMail: boolean | null;
      site: string;
   };
}

export const searchEntities = (
   initials = '',
   pageNumber = 1,
   pageSize = 10,
) => {
   return async (dispatch: AppDispatch) => {
      dispatch(startLoadding());
      api.get<SearchEntities>('/Entity/EntitiesAndToEntities', {
         params: {
            Name: initials,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getEntities(payload));
         })
         .catch(() => endLoadding());
   };
};

export const searchClassificationEntities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchEntities>('/EntityClassification', {})
         .then(async ({ data: payload }) => {
            dispatch(getClassificationEntities(payload));
         })
         .catch(console.log);
   };
};

export const setEntity = async (data: any, callback: Function) => {
   return async (dispatch: AppDispatch) => {
      await api
         .post<SetProfileResponse>('/Entity', data)
         .then(async ({ data: payload }) => {
            await dispatch(
               await responseEntity({
                  success: payload.success,
                  message: payload.message,
               }),
            );
            callback(payload.success);
         })
         .catch(async e => {
            await dispatch(
               await responseEntity({
                  success: e.response.data.success,
                  message: e.response.data.error[0],
               }),
            );
         });
   };
};
export const updateEntity = async (id: number, data: any) => {
   return async (dispatch: AppDispatch) => {
      await api
         .put<SetProfileResponse>(`/Entity/${id}`, data)
         .then(async ({ data: payload }) => {
            await dispatch(
               await responseEntity({
                  success: payload.success,
                  message: payload.message,
               }),
            );
            console.log('updateEntity', payload);
         })
         .catch(async e => {
            await dispatch(
               await responseEntity({
                  success: e.response.data.success,
                  message: e.response.data.error[0],
               }),
            );
         });
   };
};
export const getSearchToEntities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchEntities>(`/Entity/ToEntities`, {})
         .then(async ({ data: payload }) => {
            dispatch(getSearchToEntity(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const getEntitys = (id: string) => {
   return async (dispatch: AppDispatch) => {
      dispatch(startLoadding());
      api.get<SearchEntity>(`/Entity/${id}`, {})
         .then(async ({ data: payload }) => {
            dispatch(getEntity(payload.data));
         })
         // eslint-disable-next-line
         .catch(() => endLoadding());
   };
};

export const clearEntityReduce = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearEntity());
   };
};

export default { searchEntities, searchClassificationEntities };
