/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/prefer-as-const */
import {
   Layout,
   PageHeader,
   Row,
   Typography,
   Form,
   Input,
   Button,
   Table,
   Card,
} from 'antd';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   getClassDetails,
   getKitClassDetails,
   searchPersonByEntity,
} from 'services/classesService';
import { setAreYouSureModal } from 'actions/classesAction';
import Utils from 'helpers/Utils';
import InscribeClassDetailsModals from './modals';
import { RootState } from '../../config/store';
import './styles.scss';

const { Title } = Typography;
const { Content } = Layout;

interface FormErrors {
   cpf: null | string;
}

const InscribeClassDetails = () => {
   const [searchPersonPage, setSearchPersonPage] = useState(1);
   const [searchPersonName, setSearchPersonName] = useState('');
   const [searchPersonCPF, setSearchPersonCPF] = useState('');
   const [inscriptionData, setInscriptionData] = useState({});
   const [cpfInscription, setCPFInscription] = useState('');
   const [formErrors, setFormErrors] = useState<FormErrors>({
      cpf: null,
   });
   const dispatch = useDispatch();
   const history = useHistory();
   const { id } = useParams<{ id: string }>();

   const {
      classDetails,
      loadingClassDetails,
      kitClasses,
      entityPersonsList,
      loadingEntityPersonList,
      entityPersonListTotalRecords,
      reloadClassDetails,
   } = useSelector((state: RootState) => state.classes);
   const { selected } = useSelector((state: RootState) => state.inscribe);

   const columnsClass = [
      {
         title: 'DATA',
         dataIndex: 'initialDate',
         key: 'name',
         align: 'center' as 'center',
      },
      {
         title: 'LOCAL',
         dataIndex: 'local',
         key: 'age',
         align: 'center' as 'center',
         render: () => {
            return classDetails.city === null ? `Online` : classDetails.city;
         },
      },
      {
         title: 'HORÁRIO',
         dataIndex: 'time',
         key: 'time',
         align: 'center' as 'center',
      },
      {
         title: 'ENDEREÇO',
         dataIndex: '',
         key: 'address',
         align: 'center' as 'center',
         render: () => {
            return classDetails.city === null ? (
               `-`
            ) : (
               <div>
                  {classDetails.street}, {classDetails.number} -{' '}
                  {classDetails.neighborhood}
               </div>
            );
         },
      },
   ];

   const kitColumnsClass = [
      {
         title: 'DATA',
         dataIndex: 'initialDate',
         key: 'name',
         align: 'center' as 'center',
      },
      {
         title: 'LOCAL',
         dataIndex: 'local',
         key: 'age',
         align: 'center' as 'center',
         render: (local: string) => {
            return local === null ? `Online` : local;
         },
      },
      {
         title: 'HORÁRIO',
         dataIndex: 'time',
         key: 'time',
         align: 'center' as 'center',
      },
      {
         title: 'ENDEREÇO',
         dataIndex: '',
         key: 'address',
         align: 'center' as 'center',
         render: (placeDetails: {
            local: string;
            street: string;
            number: string;
            neighborhood: string;
         }) => {
            return placeDetails.local === null ? (
               `-`
            ) : (
               <div>
                  {placeDetails.street}, {placeDetails.number} -{' '}
                  {placeDetails.neighborhood}
               </div>
            );
         },
      },
   ];

   const columns = [
      {
         title: 'NOME',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'CPF',
         dataIndex: 'cpf',
         key: 'age',
         align: 'center' as 'center',
         render: (cpf: string) => {
            return `${Utils.formatCpf(cpf)}`;
         },
      },
      {
         title: 'ENTIDADE',
         dataIndex: 'entity',
         key: 'address',
         align: 'center' as 'center',
         render: () => selected.entityName,
      },
      {
         title: '',
         dataIndex: '',
         key: 'action',
         align: 'center' as 'center',
         render: (obj: { cpf: string; hasInscription: boolean }) => {
            // eslint-disable-next-line react/destructuring-assignment
            if (obj.hasInscription === false) {
               return (
                  <Button
                     onClick={() => {
                        if (selected.isKit)
                           setInscriptionData({
                              idEntity: selected.entityId,
                              idProgram: selected.programId,
                              idExecutionKit: Number(id),
                              idProduct: selected.productId,
                              idClass: kitClasses[0]?.idClass,
                           });
                        else
                           setInscriptionData({
                              idEntity: selected.entityId,
                              idProgram: selected.programId,
                              idProduct: Number(selected.productId),
                              idClass: Number(id),
                           });
                        setCPFInscription(obj.cpf);
                        dispatch(setAreYouSureModal(true));
                     }}
                     className="inscribe-button"
                  >
                     Inscrever
                  </Button>
               );
            }
            return (
               <>
                  <Row justify="center" align="middle">
                     <CheckOutlined />
                     <div>Inscrito</div>
                  </Row>
               </>
            );
         },
      },
   ];

   const handleSearchPersonEntity = (
      name: string,
      cpf: string,
      page: number,
   ) => {
      if (selected.isKit)
         dispatch(
            searchPersonByEntity(
               name,
               cpf,
               selected.entityId,
               kitClasses[0]?.idClass,
               page,
               id,
            ),
         );
      else
         dispatch(searchPersonByEntity(name, cpf, selected.entityId, id, page));
   };

   useEffect(() => {
      if (selected.isKit) {
         dispatch(getKitClassDetails(id));
         dispatch(
            searchPersonByEntity(
               '',
               '',
               selected.entityId,
               kitClasses[0]?.idClass,
               1,
               id,
            ),
         );
      } else {
         dispatch(getClassDetails(id));
         dispatch(searchPersonByEntity('', '', selected.entityId, id, 1));
      }
      setSearchPersonPage(1);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, selected.isKit, selected.entityId, id, reloadClassDetails]);

   return (
      <div className="inscribe-class-details-container">
         <Layout>
            <Content
               id="inscribe-class-details-content"
               className="site-layout-background"
            >
               <div className="title">
                  <ArrowLeftOutlined
                     onClick={() => {
                        history.goBack();
                     }}
                  />
                  <PageHeader
                     title={
                        selected.isKit
                           ? `Detalhes da Execução Kit ${id}`
                           : `Detalhes da Turma ${id}`
                     }
                  />
               </div>
               <Title level={5}>
                  Você Selecionou: {selected.entityName} /{' '}
                  {selected.programName} / {selected.productName}
               </Title>
               {!selected.isKit && (
                  <Card title={selected.productName}>
                     <Table
                        className="class-information-table"
                        columns={columnsClass}
                        dataSource={classDetails.executionDates}
                        pagination={false}
                        loading={loadingClassDetails}
                     />
                  </Card>
               )}
               {selected.isKit &&
                  kitClasses.map(kitProduct => {
                     // const executionDatesWithAddress =
                     // kitProduct.executionDates.map((executionDate: any) => {
                     //    return {
                     //       ...executionDate,
                     //       address: `${kitProduct.street}, ${kitProduct.number} - ${kitProduct.neighborhood}`,
                     //    };
                     // });
                     return (
                        <Card
                           title={`Turma ${kitProduct.idClass} - ${kitProduct.productName}`}
                        >
                           <Table
                              className="class-information-table"
                              columns={kitColumnsClass}
                              dataSource={kitProduct.executionDates}
                              pagination={false}
                              loading={loadingClassDetails}
                           />
                        </Card>
                     );
                  })}
               <h3>Pesquisar pessoa física para a incrição</h3>
               <div className="form-and-table-container">
                  <Form
                     onFinish={() => {
                        handleSearchPersonEntity(
                           searchPersonName,
                           searchPersonCPF,
                           1,
                        );
                     }}
                  >
                     <Row align="top">
                        <Form.Item
                           name="name"
                           className="search-person-name"
                           label="Nome"
                           labelCol={{ span: 24 }}
                        >
                           <Input
                              onChange={value =>
                                 setSearchPersonName(value.target.value)
                              }
                              placeholder="Digite aqui..."
                           />
                        </Form.Item>
                        <Form.Item
                           name="cpf"
                           className="search-person-cpf"
                           label="CPF"
                           labelCol={{ span: 24 }}
                        >
                           <Input
                              onChange={value => {
                                 setSearchPersonCPF(value.target.value);
                                 Utils.validateCPFandCNPJSearchSize(
                                    value.target.value,
                                    'cpf',
                                    formErrors,
                                    setFormErrors,
                                 );
                              }}
                              placeholder="Digite aqui..."
                           />
                           <div className="form-error-message">
                              {formErrors.cpf}
                           </div>
                        </Form.Item>
                        <Form.Item className="search-button-form-item">
                           <Button
                              className="search-person-search-button"
                              htmlType="submit"
                              disabled={formErrors.cpf !== null}
                           >
                              Pesquisar
                           </Button>
                        </Form.Item>
                        <Form.Item className="new-button-form-item">
                           <Button
                              className="search-person-new-button"
                              onClick={() => history.push('/naturalPerson/add')}
                           >
                              Novo Cadastro
                           </Button>
                        </Form.Item>
                     </Row>
                  </Form>
                  <h4>Resultado da pesquisa:</h4>
                  <Table
                     className="class-search-table"
                     columns={columns}
                     dataSource={entityPersonsList}
                     bordered={false}
                     loading={loadingEntityPersonList}
                     pagination={{
                        current: searchPersonPage,
                        onChange: current => {
                           setSearchPersonPage(current);
                           handleSearchPersonEntity(
                              searchPersonName,
                              searchPersonCPF,
                              current,
                           );
                        },
                        total: entityPersonListTotalRecords,
                     }}
                  />
               </div>
            </Content>
         </Layout>
         <InscribeClassDetailsModals
            cpf={cpfInscription}
            inscriptionData={inscriptionData}
         />
      </div>
   );
};

export default InscribeClassDetails;
