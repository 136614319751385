import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchForms } from '../services/formsService';

interface InitialStateType {
   forms: SearchForms; // SearchPaginated;
}

const INITIAL_STATE: InitialStateType = {
   forms: {} as SearchForms, // SearchPaginated,
};

export const getForms = createAction<SearchForms>('GET_FORMS');

export default createReducer(INITIAL_STATE, {
   [getForms.type]: (state, action: PayloadAction<SearchForms>) => {
      return {
         ...state,
         forms: action.payload,
      };
   },
});
