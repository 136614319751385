/* eslint-disable prettier/prettier */
import { SubmitButton, Form, Input, FormItem } from 'formik-antd';
import { Table, Button, Row, Col, Layout, PageHeader, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import './styles.scss';
import { ColumnsType } from 'antd/lib/table';
import profilesService from 'services/profilesService';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { searchEntities, Entity } from 'services/entitesSelectService';
import Utils from 'helpers/Utils';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { EditIconSVG } from '../../components/common/icons/icons';
import Labels from '../../helpers/enum/Labels';
import { RootState } from '../../config/store';
import { useAppDispatch } from '../../hooks/hooks';
import EntityView from '../../components/common/multiSelect';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

const { Title } = Typography;
const { Content } = Layout;

interface Table {
   key: number;
   name: string;
}

// interface Entities {
//    data: {
//       name: string;
//    }[];
// }

interface MultiSelectProps {
   name: string;
   remove: (idEntity: string) => void;
   idEntity: string;
}

export const MultiSelect = ({ name, idEntity, remove }: MultiSelectProps) => {
   return (
      <>
         <div className="productViewFirstLevel">
            {name}
            <div
               style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: 6,
                  marginRight: 5,
               }}
            />
            <div className="productView3">
               <Icon
                  style={{ fontSize: 14 }}
                  component={Close}
                  onClick={() => remove(idEntity)}
               />
            </div>
         </div>
      </>
   );
};

const Profiles = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { profiles } = useSelector((state: RootState) => state.profiles);
   const { loading } = useSelector((state: RootState) => state.profiles);
   const { entities } = useSelector((state: RootState) => state.entities);
   const [valuesList, setValuesList] = useState([] as Entity[]);
   const [ nameValue, setNameValue ] = useState<string>();
   const [ cpfValue, setCpfValue ] = useState<string>();
   const [ entityValue, setEntityValue ] = useState([]);

   const columns: ColumnsType<Table> = [
      {
         title: t(Labels.PROFILE_SEARCH_CPF),
         dataIndex: 'cpf',
         key: 'cpf',
         render: cpf => {
            return Utils.formatCpf(cpf);
         },
      },
      {
         title: t(Labels.PROFILE_SEARCH_NAME),
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: t(Labels.PROFILE_SEARCH_PROFILE),
         dataIndex: 'profile',
         key: 'profile',
      },
      {
         title: t(Labels.PROFILE_SEARCH_ENTITY),
         dataIndex: 'entity',
         key: 'entity',
         render: entity => {
            if (entity.length > 1) {
               return entity.map((e: { name: string }) => {
                  return `${e.name}, `;
               });
            }
            return entity.map((e: { name: string }) => {
               return e.name;
            });
         },
      },
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         render: id => {
            return (
               <EditIconSVG
                  onClick={() => {
                     history.push(`/profiles/edit/${id}`);
                  }}
               />
            );
         },
      },
   ];

   useEffect(() => {
      if (entities?.data) setValuesList(entities?.data);
   }, [entities.data]);

   const handleChangePage = (value: number) => {
      dispatch(
         profilesService.searchProfiles(nameValue, cpfValue, entityValue, value, profiles.pageSize),
      );
   };

   useEffect(() => {
      dispatch(profilesService.searchProfiles());
   }, [dispatch]);

   /* eslint-disable @typescript-eslint/no-explicit-any */
   const list = profiles?.data?.map((program: any) => {
      return program;
   });

   const handleSearch = (value: string) => {
      dispatch(searchEntities(value));
      if (value) {
         if (value.length > 1) {
            const teste: Entity[] = entities?.data;
            setValuesList(teste);
         } else {
            setValuesList([]);
         }
      }
   };

   const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 500);

   return (
      <div className="profiles-search-container">
         <Layout className="site-layout">
            <PageHeader title="Perfis e Acessos" />
            <Content className="site-layout-background">
               <Formik
                  initialValues={{
                     name: '',
                     cpf: '',
                     entity: [],
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                     setSubmitting(true);
                     dispatch(
                        profilesService.searchProfiles(
                           values.name,
                           Utils.stripDotsParenthesisWhitespacesAndHifens(
                              values.cpf,
                           ),
                           values.entity,
                        ),
                     );
                     setNameValue(values.name);
                     setCpfValue(Utils.stripDotsParenthesisWhitespacesAndHifens(
                        values.cpf,
                     ),)
                     setEntityValue(values.entity)
                     setSubmitting(false);
                  }}
               >
                  {({ setFieldValue, values, errors, touched }) => {
                     const onChangeSelect = (e: string) => {
                        dispatch(searchEntities(e));
                        const finder = valuesList.find(
                           element => element.id === e,
                        );
                        if (finder) {
                           if (values?.entity) {
                              setFieldValue('entity', [
                                 ...values?.entity,
                                 finder,
                              ]);
                           } else {
                              setFieldValue('entity', [finder]);
                           }
                        }
                     };
                     return (
                        <div>
                           <Form
                              data-testid="programs-list-form"
                              className="form-profiles"
                           >
                              <div>
                                 <Row className="form-container-profiles">
                                    <Col md={8} span={24}>
                                       <FormItem
                                          name="name"
                                          label=""
                                          required
                                          className="default-field-container"
                                       >
                                          <div className="title-profiles">
                                             {`${t(
                                                Labels.PROFILE_SEARCH_NAME,
                                             )}`}
                                          </div>
                                          <Input
                                             name="name"
                                             placeholder="Nome"
                                             className="default-field"
                                             data-testid="name"
                                             role="textbox"
                                          />
                                       </FormItem>
                                    </Col>
                                    <Col md={8} span={24}>
                                       <FormItem
                                          name="cpf"
                                          label=""
                                          required
                                          className="default-field-container"
                                       >
                                          <div className="title-profiles">
                                             {`${t(Labels.PROFILE_SEARCH_CPF)}`}
                                          </div>
                                          <InputMask
                                             alwaysShowMask={false}
                                             mask="999.999.999-99"
                                             onChange={e => {
                                                const { value } =
                                                   e.target || '';
                                                const { name } = e.target;
                                                setFieldValue(name, value);
                                             }}
                                          >
                                             {() => (
                                                <Input
                                                   name="cpf"
                                                   placeholder="CPF"
                                                   className="default-field"
                                                   data-testid="cpf"
                                                   role="textbox"
                                                />
                                             )}
                                          </InputMask>
                                       </FormItem>
                                    </Col>
                                    <Col md={8} span={24}>
                                       <FormItem
                                          name="entity"
                                          label=""
                                          required
                                          className="default-field-container"
                                       >
                                          <div className="title-profiles">
                                             {`${t(
                                                Labels.PROFILE_SEARCH_ENTITY,
                                             )}`}
                                          </div>
                                          <EntityView
                                             nameSelect="entityList"
                                             placeholder={t(
                                                Labels.PROFILE_SEARCH_ENTITY_PLACEHOLDER,
                                             )}
                                             onTheEnd={() =>
                                                // eslint-disable-next-line no-console
                                                console.log('teste')
                                             }
                                             handleSearch={searchAPIDebounced}
                                             showArrow
                                             filterOption={false}
                                             notFoundContent={false}
                                             loadingArrow={false}
                                             onChange={onChangeSelect}
                                             disabled={false}
                                             sizeSelect="large"
                                             className="search-select"
                                             testid="search-select"
                                             horizontal
                                             itens={values?.entity?.map(
                                                (p: any) => {
                                                   return (
                                                      <MultiSelect
                                                         name={p.name}
                                                         idEntity={p.id}
                                                         remove={(
                                                            idEntity: string,
                                                         ) => {
                                                            const last =
                                                               values.entity.filter(
                                                                  (
                                                                     element: any,
                                                                  ) =>
                                                                     element.id !==
                                                                     idEntity,
                                                               );
                                                            setFieldValue(
                                                               'entity',
                                                               last,
                                                            );
                                                         }}
                                                      />
                                                   );
                                                },
                                             )}
                                             listValues={valuesList}
                                             allowClear
                                          />
                                          <div className="errorText">
                                             {touched.entity && errors.entity}
                                          </div>
                                       </FormItem>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col md={6} span={24}>
                                       <Button.Group>
                                          <SubmitButton
                                             id="profile-search-button"
                                             data-testid="submitButton"
                                             icon={<SearchOutlined />}
                                          >
                                             {t(Labels.BUTTON)}
                                          </SubmitButton>
                                          <Button
                                             id="profile-new-program"
                                             type="primary"
                                             onClick={() =>
                                                history.push('/profiles/add')
                                             }
                                          >
                                             {t(Labels.BUTTON_NEW_PROFILE)}
                                          </Button>
                                       </Button.Group>
                                    </Col>
                                 </Row>
                              </div>
                           </Form>
                           <div className="table">
                              <Title level={4}>Resultados da pesquisa:</Title>
                              <Table<Table>
                                 dataSource={list}
                                 columns={columns}
                                 className="default-table"
                                 locale={
                                    loading
                                       ? {
                                            emptyText: t(Labels.LOADING),
                                         }
                                       : {
                                            emptyText: t(Labels.NO_DATA),
                                         }
                                 }
                                 pagination={{
                                    current: profiles?.pageNumber,
                                    onChange: handleChangePage,
                                    pageSize: profiles?.pageSize,
                                    total: profiles?.totalRecords,
                                    showSizeChanger: false,
                                 }}
                                 loading={loading}
                              />
                           </div>
                        </div>
                     );
                  }}
               </Formik>
            </Content>
         </Layout>
      </div>
   );
};

export default Profiles;
