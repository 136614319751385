import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Input, SubmitButton, Select, Radio } from 'formik-antd';
import {
   Upload,
   message,
   Button,
   Layout,
   PageHeader,
   Col,
   Row,
   Spin,
} from 'antd';
import Icon from '@ant-design/icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import ProductsView from 'components/common/multiSelect';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Labels from '../../helpers/enum/Labels';
import { ReactComponent as Anexo } from '../../assets/icons/anexo.svg';
import { useAppDispatch } from '../../hooks/hooks';
import { RootState } from '../../config/store';
import formsService, { Form as FormType } from '../../services/formsService';
import {
   Product,
   searchProducts,
   searchProductsByKit,
} from '../../services/productsService';
import { searchTypeProducts } from '../../services/typeProductsService';
import {
   searchTypePrograms,
   TypeProgram,
} from '../../services/typeProgramsService';

import {
   getProgram,
   setProgram,
   updateProgram,
   clearProgramReduce,
} from '../../services/programsService';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
const { Option } = Select;

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

export interface ProductSelect {
   id: string;
   isKit: boolean;
   name: string;
}
interface Products {
   data: {
      name: string;
   }[];
   idKit: string;
}

interface MultiSelectProps {
   name: string;
   category: string;
   idKit: string;
   productList: Products[];
   isKit: boolean;
   remove: (idProduct: string) => void;
}

export const MultiSelect = ({
   name,
   category,
   idKit,
   productList,
   isKit,
   remove,
}: MultiSelectProps) => {
   const { t } = useTranslation();
   const list = productList
      .find((element: any) => element.idKit === idKit)
      ?.data?.map((element: any) => {
         return element.name;
      })
      .toString();

   return (
      <>
         <div className="productViewFirstLevel">
            {name}
            <div
               style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: 6,
                  marginRight: 10,
               }}
            >
               {!isUndefined(isKit) && (
                  <div className="productView2">
                     {isKit ? t(Labels.PROGRAMS_ADD_KIT) : category}
                  </div>
               )}
            </div>
            <div className="productView3">
               <Icon
                  style={{ fontSize: 14 }}
                  component={Close}
                  onClick={() => remove(idKit)}
               />
            </div>
         </div>

         <div
            style={{
               color: '#8E8E8E',
               display: 'flex',
               flex: 1,
               flexDirection: 'row',
               fontSize: 16,
               textAlign: 'left',
            }}
         >
            {list?.replaceAll(',', ' | ')}
         </div>
      </>
   );
};

export const DropDown = (props: any) => {
   const {
      name,
      placeholder,
      handleSearch,
      onChange,
      showArrow,
      children,
      disabled,
      multiple,
   } = props;

   return (
      <Select
         name={name}
         mode={multiple}
         className="select"
         placeholder={placeholder}
         size="large"
         // onPopupScroll={(e: any) => {
         //    const { target } = e;
         //    if (target.scrollTop + target.offsetHeight === target.scrollHeight)
         // }}
         onSearch={handleSearch}
         showSearch
         showArrow={showArrow}
         filterOption={showArrow}
         notFoundContent={showArrow}
         onChange={onChange}
         disabled={disabled}
      >
         {children}
      </Select>
   );
};

export const RadioButton = (props: any) => {
   const { name, defaultValue, data } = props;

   return (
      <Radio.Group name={name} defaultValue={defaultValue}>
         {data.map((radio: any) => {
            return (
               <Radio name={radio.name} value={radio.value}>
                  {radio.label}
               </Radio>
            );
         })}
      </Radio.Group>
   );
};

const AddProgram = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: any }>();
   const { forms } = useSelector((state: RootState) => state.forms);
   const { program } = useSelector((state: RootState) => state.programs);
   const { products, product, loadingEditProgram, isLoading } = useSelector(
      (state: RootState) => state.products,
   );

   const { typePrograms } = useSelector(
      (state: RootState) => state.typePrograms,
   );

   const [valuesList, setValuesList] = useState([] as Product[]);
   const [fileList, setFileList] = useState([] as any);

   useEffect(() => {
      dispatch(formsService.searchForms());
      dispatch(searchTypeProducts());
      dispatch(searchTypePrograms());
      return function cleanup() {
         dispatch(clearProgramReduce());
      };
   }, [dispatch]);

   useEffect(() => {
      if (id) dispatch(getProgram(id));
   }, [dispatch, id]);

   useEffect(() => {
      if (products?.data) setValuesList(products?.data);
   }, [products.data, program]);

   useEffect(() => {
      if (program?.accessionTerm?.length > 0) {
         const a = {
            uid: '1',
            name: program.accessionTermName,
            status: 'done',
            url: program.accessionTerm,
         };
         const f = program.accessionTerm.length > 0 ? [a] : [];
         setFileList(f);
      }
      // const a = program?.products?.forEach((p: ProductSelect) => {
      //    if (p.isKit) dispatch(searchProductsByKit(p.id));
      // });
   }, [dispatch, program]);

   const handleSearch = (value: string) => {
      dispatch(searchProducts(value));

      if (value) {
         // fetch(value, data => this.setState({ data }));
         if (value.length > 1) {
            const teste: Product[] = products?.data;
            setValuesList(teste);
         } else {
            setValuesList([]);
         }
      } else {
         // this.setState({ data: [] });
      }
   };
   const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 500);

   const dummyRequest = ({ onSuccess }: any) => {
      setTimeout(() => {
         onSuccess('ok');
      }, 0);
   };

   const schema = Yup.object().shape({
      name: Yup.string().required(),
      products: Yup.array().min(1, t(Labels.COMMON_REQUIRED)).required(),
      file: Yup.mixed().when('adhesionTerm', {
         is: (adhesionTerm: boolean) => {
            return adhesionTerm && fileList.length === 0;
         },
         then: Yup.mixed().required(),
      }),
      forms: Yup.array().when('moreAdhesionTerm', {
         is: (moreAdhesionTerm: boolean) => moreAdhesionTerm,
         then: Yup.array().min(1, t(Labels.COMMON_REQUIRED)).required(),
         // otherwise: Yup.array().notRequired(),
      }),
      isActive: Yup.boolean().required(),
   });

   return (
      <div className="programs-search-container">
         <Layout className="site-layout">
            <PageHeader
               title={
                  id ? t(Labels.PROGRAMS_ADD_EDIT) : t(Labels.PROGRAMS_ADD_ADD)
               }
            />
            <div id="programs-add-subtitle" className="subtitle-prog">
               {t(Labels.COMMON_REQUIRED_TITLE)}
            </div>
            <Content
               className="site-layout-background"
               style={{ marginTop: 0, paddingTop: 0 }}
            >
               <Spin spinning={loadingEditProgram}>
                  <Formik
                     onSubmit={async (e: any, { resetForm }) => {
                        // eslint-disable-next-line
                        var formData = new FormData();
                        if (id) {
                           formData.append('Id', id);
                           if (fileList.length > 0) {
                              if (isUndefined(e.file)) {
                                 formData.append(
                                    'OldAccessionTerm',
                                    e.accessionTermName,
                                 );
                              } else {
                                 formData.append('NewAccessionTerm', e.file);
                              }
                           }
                        } else {
                           formData.append(
                              'AccessionTerm',
                              !isUndefined(e.file) ? e.file : null,
                           );
                        }
                        formData.append('Name', e.name);
                        e.products.forEach((prod: Product, i: number) => {
                           formData.append(`Products[${i}][id]`, prod.id);
                           formData.append(
                              `Products[${i}][isKit]`,
                              String(prod.isKit),
                           );
                        });

                        if (e.forms.length > 0) {
                           e.forms.forEach((form: string, i: number) => {
                              formData.append(`Forms[${i}]`, form);
                           });
                        }
                        formData.append('IsActive', e.isActive);
                        formData.append('IdProgCategory', e.idProgCategory);

                        if (id) {
                           await dispatch(await updateProgram(formData));
                        } else {
                           await dispatch(await setProgram(formData));
                           dispatch(clearProgramReduce());
                           resetForm({});
                           setFileList([]);
                        }
                     }}
                     initialValues={program}
                     enableReinitialize
                     validationSchema={schema}
                     render={({ setFieldValue, values, errors, touched }) => {
                        const onChangeSelect = (e: string) => {
                           const finder = valuesList.find(
                              element => element.id === e,
                           );

                           if (finder?.isKit) {
                              dispatch(searchProductsByKit(e));
                           }

                           if (finder) {
                              if (values?.products) {
                                 setFieldValue('products', [
                                    ...values?.products,
                                    finder,
                                 ]);
                              } else {
                                 setFieldValue('products', [finder]);
                              }
                           }
                        };

                        const config = {
                           customRequest: dummyRequest,

                           beforeUpload: (file: any) => {
                              if (file.type !== 'application/pdf') {
                                 message.error({
                                    content: `${file.name} ${t(
                                       Labels.PROGRAMS_ADD_REQUIRED_PDF,
                                    )}`,
                                    className: 'error',
                                    duration: 10,
                                 });
                              }
                              return file.type === 'application/pdf'
                                 ? true
                                 : Upload.LIST_IGNORE;
                           },
                           onChange(info: any) {
                              if (info.file.status !== 'removed') {
                                 setFileList([
                                    {
                                       uid: '1',
                                       name: info.file.name,
                                       status: 'done',
                                    },
                                 ]);
                              }
                              setFieldValue('file', info.file.originFileObj);
                           },
                           onRemove(info: any) {
                              const newFileList = fileList.filter(
                                 (element: any) => {
                                    return element.uid !== info.uid;
                                 },
                              );
                              setFileList(newFileList);
                           },
                        };

                        return (
                           <Form style={{ width: '100%' }}>
                              <Row className="line-field-height">
                                 <Col className="col">
                                    <div className="title">
                                       {`${t(Labels.PROGRAMS_ADD_NAME)} *`}
                                    </div>
                                    <Input
                                       name="name"
                                       placeholder={t(Labels.PROGRAMS_ADD_NAME)}
                                       style={{
                                          height: 40,
                                          fontSize: 20,
                                       }}
                                    />
                                    <div className="errorText">
                                       {touched.name && errors.name}
                                    </div>
                                 </Col>
                              </Row>
                              <Row className="row line-field-height">
                                 <Col className="col">
                                    <div className="title">
                                       {`${t(
                                          Labels.PROGRAMS_ADD_TYPE_PROGRAM,
                                       )} *`}
                                    </div>
                                    <DropDown
                                       name="idProgCategory"
                                       placeholder={t(
                                          Labels.PROGRAMS_ADD_TYPE_PROGRAM,
                                       )}
                                    >
                                       {typePrograms?.data?.map(
                                          (form: TypeProgram) => {
                                             return (
                                                <Option value={form.id}>
                                                   {form.categoryDesc}
                                                </Option>
                                             );
                                          },
                                       )}
                                    </DropDown>
                                 </Col>
                              </Row>
                              <Row className="row line-field-height">
                                 <div className="title">
                                    {t(Labels.PROGRAMS_ADD_ADHESION_TERM)}
                                 </div>
                                 <RadioButton
                                    name="adhesionTerm"
                                    defaultValue={false}
                                    data={[
                                       {
                                          name: 'sim',
                                          value: true,
                                          label: t(Labels.COMMON_YES),
                                       },
                                       {
                                          name: 'nao',
                                          value: false,
                                          label: t(Labels.COMMON_NO),
                                       },
                                    ]}
                                 />
                              </Row>
                              <Row className="row line-field-height">
                                 <div className="title">
                                    {t(Labels.PROGRAMS_ADD_MODEL_ADHESION_TERM)}
                                 </div>
                                 <Upload {...config} fileList={fileList}>
                                    <Button
                                       className="adhesion-term-button"
                                       disabled={!values.adhesionTerm}
                                    >
                                       {t(Labels.COMMON_BUTTON_ATTACH)}
                                       <Icon component={Anexo} />
                                    </Button>
                                 </Upload>
                                 <div className="errorText">
                                    {touched.accessionTerm &&
                                       errors.accessionTerm}
                                 </div>
                              </Row>
                              <Row className="row line-field-height">
                                 <div className="title">
                                    {t(Labels.PROGRAMS_ADD_MORE_ADHESION_TERM)}
                                 </div>
                                 <RadioButton
                                    name="moreAdhesionTerm"
                                    defaultValue={false}
                                    data={[
                                       {
                                          name: 'sim',
                                          value: true,
                                          label: t(Labels.COMMON_YES),
                                       },
                                       {
                                          name: 'nao',
                                          value: false,
                                          label: t(Labels.COMMON_NO),
                                       },
                                    ]}
                                 />
                              </Row>
                              <Row className="row line-field-height">
                                 <Col span={16} className="col">
                                    <div className="title">
                                       {t(Labels.PROGRAMS_ADD_FORMS_AVAILABLES)}
                                    </div>
                                    <DropDown
                                       name="forms"
                                       placeholder={t(
                                          Labels.PROGRAMS_ADD_FORMS_AVAILABLES,
                                       )}
                                       disabled={!values.moreAdhesionTerm}
                                       multiple="multiple"
                                    >
                                       {forms?.data?.map((form: FormType) => {
                                          return (
                                             <Option value={form.id}>
                                                {form.name}
                                             </Option>
                                          );
                                       })}
                                    </DropDown>
                                    <div className="errorText">
                                       {touched.forms && errors.forms}
                                    </div>
                                 </Col>
                              </Row>
                              <Row className="line-field-height">
                                 <Col className="col">
                                    <div className="title">{`${t(
                                       Labels.PROGRAMS_ADD_PRODUCT,
                                    )}/${t(Labels.PROGRAMS_ADD_KIT)} *`}</div>
                                    <ProductsView
                                       nameSelect="productsList"
                                       testid="products select"
                                       placeholder={`${t(Labels.TYPE_TO_SEARCH)}
                                       ${t(Labels.PROGRAMS_ADD_PRODUCT)}/${t(
                                          Labels.PROGRAMS_ADD_KIT,
                                       )}`}
                                       onTheEnd={() => console.log('')}
                                       handleSearch={(text: string) => {
                                          searchAPIDebounced(text);
                                       }}
                                       showArrow={false}
                                       onChange={onChangeSelect}
                                       disabled={false}
                                       sizeSelect="large"
                                       className="select"
                                       horizontal={false}
                                       loading={isLoading}
                                       itens={values?.products?.map(
                                          (p: any) => {
                                             return (
                                                <MultiSelect
                                                   name={`${p.id} - ${p.name}`}
                                                   category={p.category}
                                                   idKit={p.id}
                                                   isKit={p.isKit}
                                                   productList={product}
                                                   remove={(
                                                      idProduct: string,
                                                   ) => {
                                                      const last =
                                                         values.products.filter(
                                                            (element: any) =>
                                                               element.id !==
                                                               idProduct,
                                                         );
                                                      setFieldValue(
                                                         'products',
                                                         last,
                                                      );
                                                   }}
                                                />
                                             );
                                          },
                                       )}
                                       listValues={valuesList}
                                       allowClear
                                    />
                                    <div className="errorText">
                                       {touched.productsList && errors.products}
                                    </div>
                                 </Col>
                              </Row>
                              <Row className="row line-field-height">
                                 <div className="title">
                                    {`${t(Labels.COMMON_STATUS)} *`}
                                 </div>
                                 <RadioButton
                                    name="isActive"
                                    defaultValue={!false}
                                    data={[
                                       {
                                          name: 'ativo',
                                          value: true,
                                          label: t(Labels.COMMON_ACTIVE),
                                       },
                                       {
                                          name: 'inativo',
                                          value: false,
                                          label: t(Labels.COMMON_INACTIVE),
                                       },
                                    ]}
                                 />

                                 <div className="errorText">
                                    {touched.isActive && errors.isActive}
                                 </div>
                              </Row>
                              <Row>
                                 <Button.Group>
                                    <Button
                                       onClick={() => {
                                          history.goBack();
                                       }}
                                       className="button-goback"
                                       type="primary"
                                    >
                                       {t(Labels.COMMON_BUTTON_BACK)}
                                    </Button>
                                    <SubmitButton className="default-button">
                                       {t(Labels.COMMON_BUTTON_SAVE)}
                                    </SubmitButton>
                                 </Button.Group>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               </Spin>
            </Content>
         </Layout>
      </div>
   );
};

export default AddProgram;
