import { getSocialCause } from '../actions/socialCauseAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchSocialCause {
   data: any[];
   success: boolean;
}
export type SocialCause = {
   id: string;
   name: string;
};

export const searchSocialCause = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchSocialCause>('/SocialCause', {})
         .then(async ({ data: payload }) => {
            dispatch(getSocialCause(payload));
         })
         .catch(console.log);
   };
};

export default { searchSocialCause };
