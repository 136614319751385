import { RcFile } from 'antd/lib/upload';
import { getLastTerm } from 'actions/termsAction';
import { AppDispatch } from '../config/store';
import api from '../config/httpRequest/api';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface UploadTerms {
   file: RcFile;
}

export interface GetTermResponse {
   success: boolean;
   message: string;
   data: {
      id: string;
      filename: string;
      createdAt: string;
      createdBy: string;
      fileUrl: string;
   };
}

export const uploadTerms = async (file: FormData) => {
   file.forEach((e: any) => console.log(e));
   return async () => {
      await api
         .post('/LGPDConsent', file)
         .then(async ({ data: payload }) => {
            console.log(payload);
         })
         .catch(console.log);
   };
};

export const getLastLgpdConsentTerm = () => {
   return async (dispatch: AppDispatch) => {
      await api
         .get('/LGPDConsent')
         .then(async ({ data: payload }) => {
            dispatch(getLastTerm(payload));
         })
         .catch(console.log);
   };
};

export default { uploadTerms };
