import { getTypeProfiles } from '../actions/typeProfilesAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchTypeProfiles {
   data: any[];
   success: boolean;
}
export type TypeProfile = {
   id: string;
   name: string;
};

export const searchTypeProfiles = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchTypeProfiles>('/Profile', {})
         .then(async ({ data: payload }) => {
            dispatch(getTypeProfiles(payload));
         })
         .catch(console.log);
   };
};

export default { searchTypeProfiles };
