import {
   getBusinessSectorOptionsList,
   getBusinessPurposeOptionsList,
   getWithoutCNPJReasonOptionsList,
   getHaventBusinessOptionsList,
   getWhosOwnerOptionsList,
   getBusinessHelperOptionsList,
   getBusinessDesireOptionsList,
   getBusinessMotivationOptionsList,
   getDreamsOptionsList,
   getGaveUpBusinessOptionsList,
   getReasonHaveBusinessOptionsList,
   clearEntrepreneurs,
   getEntrepreneurs,
} from '../actions/entrepreneursAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

export interface Option {
   id: string;
   name: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   data: any[];
}

export interface OptionList {
   success: boolean;
   message: string;
   data: Option[];
}

export interface GetEntrepreneurs {
   success: boolean;
   message: string;
   data: {
      idBusinessSector: number | null;
      idBusinessPurpose: number | null;
      idWithoutCNPJReason: number | null;
      idHaventBusiness: number | null;
      haveOwner: boolean | null;
      idWhosOwner: Array<number>;
      idBusinessHelper: number | null;
      idBusinessDesire: number | null;
      idReasonHaveBusiness: number | null;
      idDreams: Array<number>;
      idGaveUpBusiness: Array<number>;
      idBusinessMotivation: Array<number>;
      cnpjDescription: string;
      businessPurposeDescription: string;
      businessMotivationDescription: string;
      gaveUpBusinessDescription: string;
      dreamsDescription: string;
      reasonHaveBusinessDescription: string;
      numberOwners: number;
   };
}

export interface Entrepreneurs {
   idBusinessSector: number | null;
   idBusinessPurpose: number | null;
   idWithoutCNPJReason: number | null;
   idHaventBusiness: number | null;
   haveOwner: boolean | null;
   idWhosOwner: Array<string>;
   idBusinessHelper: number | null;
   idBusinessDesire: number | null;
   idReasonHaveBusiness: number | null;
   idDreams: Array<string>;
   idGaveUpBusiness: Array<string>;
   idBusinessMotivation: Array<string>;
   cnpjDescription: string;
   businessPurposeDescription: string;
   businessMotivationDescription: string;
   gaveUpBusinessDescription: string;
   dreamsDescription: string;
   reasonHaveBusinessDescription: string;
   numberOwners: number;
}

export const getBusinessSector = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/BusinessSector/', {})
         .then(async ({ data: payload }) => {
            dispatch(getBusinessSectorOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getBusinessPurpose = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/BusinessPurpose/', {})
         .then(async ({ data: payload }) => {
            dispatch(getBusinessPurposeOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getWithoutCNPJReason = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/WithoutCNPJReason/', {})
         .then(async ({ data: payload }) => {
            dispatch(getWithoutCNPJReasonOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getHaventBusiness = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/HaventBusiness/', {})
         .then(async ({ data: payload }) => {
            dispatch(getHaventBusinessOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getWhosOwner = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/WhosOwner/', {})
         .then(async ({ data: payload }) => {
            dispatch(getWhosOwnerOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getBusinessHelper = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/BusinessHelper/', {})
         .then(async ({ data: payload }) => {
            dispatch(getBusinessHelperOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getBusinessDesire = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/BusinessDesire/', {})
         .then(async ({ data: payload }) => {
            dispatch(getBusinessDesireOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getBusinessMotivation = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/BusinessMotivation/', {})
         .then(async ({ data: payload }) => {
            dispatch(getBusinessMotivationOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getDreams = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/Dreams/', {})
         .then(async ({ data: payload }) => {
            dispatch(getDreamsOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getGaveUpBusiness = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/GaveUpBusiness/', {})
         .then(async ({ data: payload }) => {
            dispatch(getGaveUpBusinessOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

export const getReasonHaveBusiness = () => {
   return async (dispatch: AppDispatch) => {
      api.get<OptionList>('/ReasonHaveBusiness/', {})
         .then(async ({ data: payload }) => {
            dispatch(getReasonHaveBusinessOptionsList(payload.data));
         })
         // eslint-disable-next-line no-console
         .catch(console.log);
   };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleEntrepreneurs = async (cpf: string) => {
   return async (dispatch: AppDispatch) => {
      await api
         .get<GetEntrepreneurs>(`/Person/${cpf}/EntrepreneurProfile`, {})
         .then(async ({ data: payload }) => {
            const sendEntrepreneurs = {
               ...payload.data,
               idDreams: payload.data.idDreams?.map((id: number) =>
                  id.toString(),
               ),
               idGaveUpBusiness: payload.data.idGaveUpBusiness?.map(
                  (id: number) => id.toString(),
               ),
               idBusinessMotivation: payload.data.idBusinessMotivation?.map(
                  (id: number) => id.toString(),
               ),
               idWhosOwner: payload.data.idWhosOwner?.map((id: number) =>
                  id.toString(),
               ),
            };
            dispatch(getEntrepreneurs(sendEntrepreneurs));
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const postEntrepreneurs = async (
   entrepreneurs: Entrepreneurs,
   cpf: string,
) => {
   const finalEntrepreneus = {
      ...entrepreneurs,
      idDreams: entrepreneurs.idDreams.map((id: string) => Number(id)),
      idGaveUpBusiness: entrepreneurs.idGaveUpBusiness
         ? entrepreneurs.idGaveUpBusiness.map((id: string) => Number(id))
         : null,
      idBusinessMotivation: entrepreneurs.idBusinessMotivation
         ? entrepreneurs.idBusinessMotivation.map((id: string) => Number(id))
         : null,
      idWhosOwner: entrepreneurs.idWhosOwner
         ? entrepreneurs.idWhosOwner.map((id: string) => Number(id))
         : null,
   };
   return async (dispatch: AppDispatch) => {
      await api
         .post<FormData>(
            `/Person/${cpf}/EntrepreneurProfile`,
            finalEntrepreneus,
         )
         .then(async () => {
            dispatch(clearEntrepreneurs());
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};
