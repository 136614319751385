/* eslint-disable prettier/prettier */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Input, SubmitButton, FormItem } from 'formik-antd';
import {
   Button,
   Layout,
   PageHeader,
   Col,
   Row,
   message,
   List,
   Typography,
   Collapse,
   Tag,
   Table,
   Spin,
} from 'antd';
import Icon, {
   MinusOutlined,
   PlusOutlined,
   SearchOutlined,
   CloseOutlined,
   DeleteOutlined,
   PaperClipOutlined,
} from '@ant-design/icons';
import { Formik, FormikHelpers } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import programsService, { SearchProgram } from 'services/programsService';
import api from 'config/httpRequest/api';
import {
   setProgramAdhesion,
   getAdheredPrograms,
   AdheredProduct,
   AdheredProgram,
   putAdheredPrograms,
} from 'services/programAdhesionService';
import { UploadPdfFileButton } from 'components/common';
import { RootState } from '../../config/store';
import { clearProgramReduce } from '../../services/profilesService';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import DeleteProgramsModals from './DeleteProgramsModals';
import { useAppDispatch } from '../../hooks/hooks';
import Labels from '../../helpers/enum/Labels';

const { Text } = Typography;
const { Content } = Layout;

export type Props = {
   placeholder: string;
   name: string;
};

export interface EntitySelect {
   cnpj: string;
   name: string;
}

interface BoxProps {
   name: string;
   cnpj: string;
   remove: (name: string, cnpj?: string) => void;
   id: string;
}

export const Box = ({ name, cnpj, remove, id }: BoxProps) => {
   const { t } = useTranslation();

   return (
      <>
         <div className="spacing-top">
            <div className="productViewFirstLevel">
               <div className="spacing-ent">{name}</div>
               {cnpj?.length > 0 && (
                  <>
                     <div>{`  | ${t(Labels.COMMON_CNPJ)}: ${cnpj}`}</div>
                  </>
               )}

               <div className="productView3">
                  <Icon
                     style={{ fontSize: 14 }}
                     component={Close}
                     onClick={() => (id ? remove(id) : remove(name, cnpj))}
                  />
               </div>
            </div>
         </div>
      </>
   );
};

interface ProgramProducts {
   id: string;
   name: string;
}
interface programObject {
   isActive: boolean;
   name: string;
   products: ProgramProducts[];
   id: string;
}

interface productObject {
   // idEntidade: string;
   idPrograms: string;
   name: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   products: any;
}

const ProgramAdhesionTerm = () => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: string }>();
   const { success, message: messageResponse } = useSelector(
      (state: RootState) => state.profiles,
   );
   const { programs } = useSelector((state: RootState) => state.programs);
   const { loading } = useSelector((state: RootState) => state.programs);
   const { adheredPrograms } = useSelector(
      (state: RootState) => state.programAdhesion,
   );
   const { adheredProgramsLoading } = useSelector(
      (state: RootState) => state.programAdhesion,
   );
   const { adheredProgramsRefresh } = useSelector(
      (state: RootState) => state.programAdhesion,
   );
   const [reload, setReload] = useState(false);
   const [selectedAdheredPrograms, setSelectedAdheredPrograms] = useState<
      AdheredProgram[]
   >([]);
   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
   const [selected, setSelected] = useState<programObject[]>([]);
   const [loadingProgram, setLoadingProgram] = useState(false);
   const [selectedProducts, setSelectedProducts] = useState<productObject[]>(
      [],
   );

   const [isDeleteReasonModalVisible, setIsDeleteReasonModalVisible] =
      useState(false);

   useEffect(() => {
      return function cleanup() {
         dispatch(clearProgramReduce());
      };
   }, [dispatch]);

   useEffect(() => {
      if (success && reload) {
         message.success({
            content: messageResponse,
            className: 'success',
            duration: 10,
         });
         if (!id) {
            dispatch(clearProgramReduce());
         }
         setReload(false);
      }
   }, [dispatch, id, messageResponse, reload, success]);

   useEffect(() => {
      dispatch(programsService.searchActivePrograms());
   }, [dispatch]);

   useEffect(() => {
      dispatch(getAdheredPrograms(id));
   }, [dispatch, id, adheredProgramsRefresh]);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   const data = programs.data?.map(programslist => {
      return programslist;
   });

   const handleChangePage = (value: number) => {
      dispatch(
         programsService.searchActivePrograms(
            '',
            true,
            value,
            programs.pageSize,
         ),
      );
   };
   interface Values {
      initials: string;
   }

   const onSubmitSearch = (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>,
   ) => {
      setSubmitting(true);
      dispatch(programsService.searchActivePrograms(values.initials, true));
      setSubmitting(false);
   };

   const onSubmit = async (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>,
   ) => {
      setSubmitting(true);

      if (selectedProducts.length === 0) {
         message.warning({
            content: 'Selecione um produto antes de salvar',
            className: 'warning',
            duration: 10,
         });
      } else {
         await dispatch(
            await setProgramAdhesion(
               selectedProducts,
               id as string,
               !adheredProgramsRefresh,
            ),
         );
         setSelected([]);
         setSelectedProducts([]);
      }
      setSubmitting(false);
   };

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const handleClick = async (item: any) => {
      setLoadingProgram(true);
      api.get<SearchProgram>(`/Programs/${item.id}`, {})
         .then(async ({ data: payload }) => {
            const found = selected.some(el => payload.data.id === el.id);
            if (!found) {
               const newClicks = [...selected, payload.data];
               setSelected(newClicks);

               if (payload.data.idProgCategory === 1) {
                  const newClickProducts = [
                     ...selectedProducts,
                     {
                        idPrograms: payload.data.id,
                        name: payload.data.name,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        products: payload.data.products,
                     },
                  ];
                  setSelectedProducts(newClickProducts);
               }
            }
            setLoadingProgram(false);
         })
         // eslint-disable-next-line
         .catch(console.log);
   };

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const handleClickProduct = (item: any, products: any) => {
      let found = false;

      if (item.idProgCategory !== 1) {
         selectedProducts.forEach(element => {
            if (element.idPrograms === item.id) {
               const foundProduct = element.products.some(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (el: any) => products.id === el.id,
               );

               if (!foundProduct) {
                  element.products = [
                     ...element.products,
                     { id: products.id, isKit: products.isKit },
                  ];
               } else {
                  const index = element.products
                     // eslint-disable-next-line @typescript-eslint/no-explicit-any
                     .map((elementProd: any) => {
                        return elementProd.id;
                     })
                     .indexOf(products.id);

                  if (element.products?.length !== 0) {
                     const newList = element.products?.splice(index, 1);
                     if (newList.idPrograms !== undefined) {
                        setSelectedProducts(newList);
                     }
                  }
               }

               found = true;
            }
         });

         if (!found) {
            const newClick = [
               ...selectedProducts,
               {
                  idPrograms: item.id,
                  name: item.name,
                  products: [{ id: products.id, isKit: products.isKit }],
               },
            ];
            setSelectedProducts(newClick);
         }
      }
   };

   const removeSelected = (index: number) => {
      const newSelected = [...selected];
      const newSelectedProducts = [...selectedProducts];
      if (index !== 1) {
         newSelected.splice(index, 1);
         newSelectedProducts.splice(index, 1);
         setSelected(newSelected);
         setSelectedProducts(newSelectedProducts);
      }
   };

   const columns = [
      {
         title: 'Nome do Programa',
         dataIndex: 'programs',
         render: (rowPrograms: { name: string }) => <p>{rowPrograms.name}</p>,
      },
      {
         title: 'Produtos',
         dataIndex: 'adheredProduct',
         render: (adheredProduct: { products: AdheredProduct }[]) => {
            return (
               <div>
                  {adheredProduct?.map(product => {
                     return <p>{product?.products.name}</p>;
                  })}
               </div>
            );
         },
      },
      {
         title: 'Termo de adesão',
         dataIndex: 'programs',
         render: (rowPrograms: {
            name: string;
            accessionTerm: string | null;
         }) => {
            if (rowPrograms.accessionTerm) {
               return (
                  <a
                     target="_blank"
                     rel="noopener noreferrer"
                     href={rowPrograms.accessionTerm}
                  >
                     Baixar Termo de Adesão
                  </a>
               );
            }
            return <p> - </p>;
         },
      },
      {
         title: 'Status',
         dataIndex: '',
         render: (program: AdheredProgram) => {
            if (program.programs.accessionTerm && !program.signedTerm) {
               return (
                  <div className="program-adhesion-ok-pending">
                     Assinatura do termo pendente
                  </div>
               );
            }
            return <div className="program-adhesion-ok-status">OK</div>;
         },
      },
      {
         title: '',
         dataIndex: '',
         render: (program: AdheredProgram) => {
            if (program.programs.accessionTerm) {
               return (
                  <UploadPdfFileButton
                     serviceFunction={putAdheredPrograms}
                     serviceFunctionParams={{
                        adheredProgramsList: [program],
                        booleano: !adheredProgramsRefresh,
                     }}
                  >
                     <Tag className="edit-term-tag">Anexar termo assinado</Tag>
                  </UploadPdfFileButton>
                  // <Upload {...props} showUploadList={false}>
                  //    <Tag className="edit-term-tag">Anexar termo assinado</Tag>
                  // </Upload>
               );
            }
            return <div />;
         },
      },
   ];

   const { Panel } = Collapse;
   const history = useHistory();

   const [selectionType] = useState<'checkbox' | 'radio'>('checkbox');

   return (
      <div className="profiles-search-container">
         <Layout className="site-layout">
            <PageHeader
               className="site-page-header"
               title={t(Labels.PROGRAMS_ADHESION_PAGE_TITLE)}
            />
            <div className="subtitle">
               {t(Labels.PROGRAMS_ADHESION_PAGE_SUBTITLE)}
            </div>
            <Content
               className="site-layout-background"
               style={{ marginTop: 0, paddingTop: 0 }}
            >
               <Formik<Values>
                  initialValues={{
                     initials: '',
                  }}
                  onSubmit={onSubmitSearch}
               >
                  <Form
                     data-testid="programs-list-form"
                     className="form-programAdhesionTerm"
                  >
                     <Row className="form-container-programAdhesionTerm">
                        <Col span={16}>
                           <FormItem
                              name="initials"
                              label=""
                              required
                              className="default-field-container"
                           >
                              <Input
                                 name="initials"
                                 placeholder="Nome do Programa"
                                 className="default-field"
                                 data-testid="initials"
                                 role="textbox"
                              />
                           </FormItem>
                        </Col>
                        <Col span={10}>
                           <Button.Group>
                              <SubmitButton
                                 data-testid="submitButton"
                                 icon={<SearchOutlined />}
                              >
                                 {t(Labels.BUTTON)}
                              </SubmitButton>
                           </Button.Group>
                        </Col>
                     </Row>
                     <Row className="programs-list-programAdhesionTerm">
                        <List
                           className="list-programAdhesionTerm"
                           bordered
                           loading={loading}
                           locale={
                              loading
                                 ? {
                                    emptyText: t(Labels.LOADING),
                                 }
                                 : {
                                    emptyText: t(Labels.NO_DATA),
                                 }
                           }
                           pagination={{
                              current: programs.pageNumber,
                              onChange: handleChangePage,
                              pageSize: 5,
                              total: programs.totalRecords,
                              showSizeChanger: false,
                           }}
                           dataSource={data}
                           renderItem={item => (
                              <List.Item onClick={() => handleClick(item)}>
                                 {item.name}
                                 {selected.find(el => item.id === el.id) ? (
                                    <MinusOutlined />
                                 ) : (
                                    <PlusOutlined />
                                 )}
                              </List.Item>
                           )}
                        />
                     </Row>
                  </Form>
               </Formik>
               <Formik initialValues={{ initials: '' }} onSubmit={onSubmit}>
                  <Form
                     data-testid="programs-list-form"
                     className="form-programAdhesionTerm"
                  >
                     <Row className="products-list-programAdhesionTerm">
                        <Col span={12}>
                           <h2>{t(Labels.PROGRAMS_ADHESION_PRODUCTS_LIST)}</h2>
                        </Col>
                        <Col span={24}>
                           <Collapse accordion>
                              {!loadingProgram ? (
                                 // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                 selected?.map((item: any, index: number) => {
                                    return (
                                       <Panel
                                          showArrow={false}
                                          header={
                                             // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                                             <div className="products-list-header">
                                                <p>{item.name}</p>
                                                <div>
                                                   {item.accessionTerm ? (
                                                      <Button
                                                         icon={
                                                            <PaperClipOutlined />
                                                         }
                                                         color="#6E1850"
                                                         href={
                                                            item.accessionTerm
                                                         }
                                                         target="_blank"
                                                         className="tag-button"
                                                      >
                                                         {t(
                                                            Labels.PROGRAMS_ADHESION_DOWNLOAD_BUTTON,
                                                         )}
                                                      </Button>
                                                   ) : (
                                                      ''
                                                   )}

                                                   <Button
                                                      icon={<CloseOutlined />}
                                                      color="#E33323"
                                                      className="cancel-tag tag-button"
                                                      onClick={() =>
                                                         removeSelected(index)
                                                      }
                                                   >
                                                      {t(
                                                         Labels.PROGRAMS_ADHESION_CANCEL_BUTTON,
                                                      )}
                                                   </Button>
                                                </div>
                                             </div>
                                          }
                                          key={index}
                                       >
                                          {item.products.map(
                                             (
                                                products: ProgramProducts,
                                                indexProducts: string,
                                             ) => {
                                                return (
                                                   <div
                                                      className={
                                                         item.idProgCategory ===
                                                            1
                                                            ? 'products products-dependente'
                                                            : 'products products-avulso'
                                                      }
                                                   >
                                                      <p>{products.name}</p>
                                                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                                                      <p
                                                         className="adhesionProductButton"
                                                         onClick={
                                                            item.idProgCategory !==
                                                               1
                                                               ? () => {
                                                                  handleClickProduct(
                                                                     item,
                                                                     products,
                                                                  );
                                                               }
                                                               : () => {
                                                                  return null;
                                                               }
                                                         }
                                                         id={indexProducts}
                                                      >
                                                         {selectedProducts.some(
                                                            el => {
                                                               let isEqual =
                                                                  false;
                                                               const aux =
                                                                  el.products?.forEach(
                                                                     (
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        element: any,
                                                                     ) => {
                                                                        if (
                                                                           element.id ===
                                                                           products.id
                                                                        ) {
                                                                           isEqual =
                                                                              true;
                                                                        }
                                                                        return isEqual;
                                                                     },
                                                                  );
                                                               // eslint-disable-next-line no-console
                                                               console.log(aux);

                                                               return isEqual;
                                                            },
                                                         ) ? (
                                                            <div
                                                               id={
                                                                  indexProducts
                                                               }
                                                            >
                                                               <MinusOutlined />
                                                               {t(
                                                                  Labels.PROGRAMS_ADHESION_CANCEL_SELECTION_BUTTON,
                                                               )}
                                                            </div>
                                                         ) : (
                                                            <div
                                                               id={
                                                                  indexProducts
                                                               }
                                                            >
                                                               <PlusOutlined />
                                                               {t(
                                                                  Labels.PROGRAMS_ADHESION_ADHESION_BUTTON,
                                                               )}
                                                            </div>
                                                         )}
                                                      </p>
                                                   </div>
                                                );
                                             },
                                          )}
                                          {item.idProgCategory === 1 ? (
                                             <Text type="danger">
                                                {t(
                                                   Labels.PROGRAMS_ADHESION_DEPENDENTE,
                                                )}
                                             </Text>
                                          ) : (
                                             <Text type="success">
                                                {t(
                                                   Labels.PROGRAMS_ADHESION_AVULSO,
                                                )}
                                             </Text>
                                          )}
                                          {item.accessionTerm ? (
                                             <Text type="warning">
                                                {t(
                                                   Labels.PROGRAMS_ADHESION_TERM_WARNING,
                                                )}
                                             </Text>
                                          ) : (
                                             ''
                                          )}
                                       </Panel>
                                    );
                                 })
                              ) : (
                                 <Panel
                                    showArrow={false}
                                    header={<Spin />}
                                    className="spin-panel"
                                    key="1"
                                 />
                              )}
                           </Collapse>
                        </Col>
                     </Row>
                     <Row className="row-btn">
                        <Button.Group>
                           <SubmitButton className="default-button">
                              {t(Labels.PROGRAMS_ADHESION_SAVE_BUTTON)}
                           </SubmitButton>
                        </Button.Group>
                     </Row>
                  </Form>
               </Formik>
               <Row className="table-programAdhesionTerm">
                  <Col span={24}>
                     <h2>Programas Aderidos:</h2>
                     <Tag
                        onClick={() => {
                           setIsDeleteModalVisible(true);
                        }}
                        icon={<DeleteOutlined />}
                        className="delete-tag"
                     >
                        {t(Labels.PROGRAMS_ADHESION_DELETE_BUTTON)}
                     </Tag>
                  </Col>
                  <Table
                     rowSelection={{
                        type: selectionType,
                        onChange: (selectedRowKeys, selectedRows) => {
                           setSelectedAdheredPrograms(selectedRows);
                        },
                        // ...rowSelection,
                     }}
                     rowKey="id"
                     columns={columns}
                     dataSource={adheredPrograms}
                     loading={adheredProgramsLoading}
                     rowClassName="none"
                  />
                  <Row>
                     <Button.Group>
                        <Button
                           onClick={() => {
                              history.goBack();
                           }}
                           className="button-goback"
                           type="primary"
                        >
                           {t(Labels.COMMON_BUTTON_BACK)}
                        </Button>
                     </Button.Group>
                  </Row>
               </Row>
            </Content>
         </Layout>
         <DeleteProgramsModals
            isDeleteModalVisible={isDeleteModalVisible}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            isDeleteReasonModalVisible={isDeleteReasonModalVisible}
            setIsDeleteReasonModalVisible={setIsDeleteReasonModalVisible}
            selectedAdheredPrograms={selectedAdheredPrograms}
            setSelectedAdheredPrograms={setSelectedAdheredPrograms}
            adheredProgramsRefresh={!adheredProgramsRefresh}
         />
      </div>
   );
};

export default ProgramAdhesionTerm;
