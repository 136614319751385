import { getEquipaments } from '../actions/equipamentsAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchEquipaments {
   data: any[];
   success: boolean;
}
export type Equipament = {
   id: string;
   name: string;
};

export const searchEquipaments = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchEquipaments>('/Equipment', {})
         .then(async ({ data: payload }) => {
            dispatch(getEquipaments(payload));
         })
         .catch(console.log);
   };
};

export default { searchEquipaments };
