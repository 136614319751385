import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchEntities, EntityObject } from 'services/entitesSelectService';

interface InitialStateType {
   entities: SearchEntities;
   classificationEntities: SearchEntities;
   entity: EntityObject;
   preSelectEntities: SearchEntities;
   success: boolean;
   message: string;
   loading: boolean;
}
export interface Response {
   success: boolean;
   message: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const INITIAL_STATE: InitialStateType = {
   entities: {
      data: [],
   },
   classificationEntities: {
      data: [],
   },
   entity: {
      idEntityClassification: null,
      name: '',
      cnpj: '',
      corporateName: '',
      preference: '',
      createdAt: null,
      socialCauses: null,
      serviceProfile: null,
      equipement: null,
      othersSocialCauses: null,
      othersServiceProfile: null,
      profile: {
         idPeopleServed: null,
         socialCauses: [] as any,
         serviceProfiles: [] as any,
      },
      infrastructure: {
         idInfrastructureCapacity: null,
         hasRoom: null,
         haveTables: null,
         hasVentilation: null,
         isAdapted: null,
         idsEquipment: [],
      },
      address: {
         cep: '',
         street: '',
         number: '',
         neighborhood: '',
         city: '',
         state: '',
         complement: '',
      },
      contact: {
         telephone: '',
         cellphone1: '',
         cellphone2: '',
         mail: '',
         hasMail: false,
         site: '',
      },
   } as EntityObject,
   preSelectEntities: {
      data: [],
   },
   success: false,
   message: '',
   loading: false,
};

export const getEntities = createAction<SearchEntities>('GET_ENTITIES');
export const getEntity = createAction<EntityObject>('GET_ENTITY');
export const getClassificationEntities =
   createAction<SearchEntities>('GET_CLASSIFICATION');

export const getSearchToEntity = createAction<SearchEntities>(
   'GET_SEARCH_TO_ENTITY',
);
export const clearEntity = createAction('CLEAR_ENTITY');

export const responseEntity = createAction<Response>('RESPONSE_ENTITY');

export const startLoadding = createAction('START_LOADDING');
export const endLoadding = createAction('END_LOADDING');

export default createReducer(INITIAL_STATE, {
   [getEntities.type]: (state, action: PayloadAction<SearchEntities>) => {
      return {
         ...state,
         entities: action.payload,
         loading: false,
      };
   },
   [getClassificationEntities.type]: (
      state,
      action: PayloadAction<SearchEntities>,
   ) => {
      return {
         ...state,
         classificationEntities: action.payload,
         loading: false,
      };
   },
   [getEntity.type]: (state, action: PayloadAction<EntityObject>) => {
      return {
         ...state,
         entity: action.payload,
         loading: false,
      };
   },
   [getSearchToEntity.type]: (state, action: PayloadAction<SearchEntities>) => {
      return {
         ...state,
         preSelectEntities: action.payload,
         loading: false,
      };
   },
   [clearEntity.type]: state => {
      return {
         ...state,
         entity: INITIAL_STATE.entity,
         loading: false,
      };
   },
   [responseEntity.type]: (state, action: PayloadAction<Response>) => {
      return {
         ...state,
         success: action.payload.success,
         message: action.payload.message,
         loading: false,
      };
   },
   [startLoadding.type]: state => {
      return {
         ...state,
         loading: true,
      };
   },
   [endLoadding.type]: state => {
      return {
         ...state,
         loading: false,
      };
   },
});
