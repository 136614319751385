import { message } from 'antd';
import {
   adheredProgramsRefresh,
   getAdheredPrograms as getAdheredProgramsAction,
   setAdheredProgramsLoading,
} from 'actions/programAdhesionAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

interface Products {
   id: number;
   isKit: boolean;
}
interface Adhesion {
   idEntity: string;
   idPrograms: string;
   products: Products[];
}
interface DataType {
   accessionProgramsDtos: Adhesion[];
}

interface NumberProduct {
   0: number;
   data: DataType;
   accessionProgramsDtos: Adhesion[];
}

interface productObject {
   0: NumberProduct;
   idPrograms: string;
   name: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   products: any;
   warningMessage: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

export interface AdheredProduct {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   products: any;
   id: string;
   name: string;
   isKit: boolean;
}

export interface AdheredProgram {
   id: string;
   idEntities: string;
   idPrograms: string;
   programs: {
      name: string;
      accessionTerm: string;
   };
   adheredProduct: AdheredProduct[];
   signedTerm: string | null;
   isCanceled: boolean;
   cancelReason: string | null | undefined;
}

export interface ApiResponseAdheredProducts {
   success: boolean;
   message: string;
   data: AdheredProgram[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setProgramAdhesion = async (
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   data: any,
   id: string,
   refresh: boolean,
) => {
   return async (dispatch: AppDispatch) => {
      try {
         await api
            .post<productObject>(`Entity/${id}/Accession`, {
               createAccessionProgramsRequest: data,
            })
            .then(async ({ data: payload }) => {
               // eslint-disable-next-line
               console.log(
                  'setProgramAdhesion',
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  payload[0].data.accessionProgramsDtos.map((el: any) => {
                     return el.warningMessage
                        ? message.warning({
                             content: el.warningMessage,
                             className: 'warning',
                             duration: 10,
                          })
                        : // eslint-disable-next-line no-console
                          console.log(el);
                  }),
               );
               message.success({
                  content: 'Programa cadastrado com sucesso',
                  className: 'success',
                  duration: 10,
               });
            })
            // eslint-disable-next-line
            .catch(console.log);
      } finally {
         setTimeout(() => {
            dispatch(adheredProgramsRefresh(refresh));
         }, 1000);
      }
   };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAdheredPrograms = (id: string | undefined) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setAdheredProgramsLoading(true));
      await api
         .get<ApiResponseAdheredProducts>(`Entity/${id}/AdheredPrograms`, {})
         .then(async ({ data: payload }) => {
            dispatch(getAdheredProgramsAction(payload.data));
            dispatch(setAdheredProgramsLoading(false));
         })
         // eslint-disable-next-line
         .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(getAdheredProgramsAction([]));
            dispatch(setAdheredProgramsLoading(false));
         });
   };
};

export const putAdheredPrograms = (
   adheredProgramsList: AdheredProgram[],
   refresh: boolean | undefined,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   signedTerm?: any,
) => {
   return async (dispatch: AppDispatch) => {
      try {
         adheredProgramsList.forEach(async (adheredProgram: AdheredProgram) => {
            const formData = new FormData();
            formData.append('idAccession', adheredProgram.id);
            formData.append('idPrograms', adheredProgram.idPrograms);
            formData.append('isCanceled', adheredProgram.isCanceled.toString());
            formData.append(
               'cancelReason',
               adheredProgram.cancelReason?.toString() || '',
            );
            if (signedTerm) {
               formData.append('signedTerm', signedTerm);
            } else {
               formData.append('signedTerm', adheredProgram.signedTerm || '');
            }
            await api
               .put(`Entity/Accession`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
               })
               // eslint-disable-next-line no-console
               .catch(console.log);
         });
      } finally {
         setTimeout(() => {
            dispatch(adheredProgramsRefresh(refresh));
         }, 1000);
      }
   };
};
