/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import './styles.scss';
import { useEffect, useState } from 'react';
import { Form, Input, SubmitButton, Radio } from 'formik-antd';
import { Button, Layout, Col, Row, Modal } from 'antd';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
   getBusinessSector,
   getBusinessPurpose,
   getWithoutCNPJReason,
   getHaventBusiness,
   getWhosOwner,
   getBusinessHelper,
   getBusinessDesire,
   getBusinessMotivation,
   getDreams,
   getGaveUpBusiness,
   getReasonHaveBusiness,
} from 'services/entrepreneursService';
import { RootState } from 'config/store';
import { useSelector } from 'react-redux';
import { getEntrepreneurs } from 'actions/entrepreneursAction';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

interface CheckboxProps {
   id: string;
   name: string;
}

type EntrepreneursModalProps = {
   isEntrepreneursModalVisible: boolean;
   setIsEntrepreneursModalVisible: Function;
};

const yesNo = [
   {
      name: 'yes',
      value: true,
      label: Labels.ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS_OPTIONS_YES_HOW_MANY,
   },
   {
      name: 'no',
      value: false,
      label: Labels.COMMON_NO,
   },
];

const AddEntrepreneurs = ({
   isEntrepreneursModalVisible,
   setIsEntrepreneursModalVisible,
}: EntrepreneursModalProps) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: any }>();
   const [haveOwnerChecked, setHaveOwnerChecked] = useState(false);
   const [haventBusinessChecked, setHaventBusinessChecked] = useState(false);
   const [businessDesireChecked, setBusinessDesireChecked] = useState(false);
   useEffect(() => {
      dispatch(getBusinessSector());
      dispatch(getBusinessPurpose());
      dispatch(getWithoutCNPJReason());
      dispatch(getHaventBusiness());
      dispatch(getWhosOwner());
      dispatch(getBusinessHelper());
      dispatch(getBusinessDesire());
      dispatch(getBusinessMotivation());
      dispatch(getDreams());
      dispatch(getGaveUpBusiness());
      dispatch(getReasonHaveBusiness());
   }, [dispatch]);
   const {
      entrepreneurs,
      businessSectorOptionsList,
      businessPurposeOptionsList,
      withoutCNPJReasonOptionsList,
      haventBusinessOptionsList,
      whosOwnerOptionsList,
      businessHelperOptionsList,
      businessDesireOptionsList,
      businessMotivationOptionsList,
      dreamsOptionsList,
      gaveUpBusinessOptionsList,
      reasonHaveBusinessOptionsList,
   } = useSelector((state: RootState) => state.entrepreneurs);

   const dreams = dreamsOptionsList.map((e: CheckboxProps) => {
      return {
         id: `${e.id}`,
         label: e.name,
      };
   });

   const idBusinessMotivation = businessMotivationOptionsList.map(
      (e: CheckboxProps) => {
         return {
            id: `${e.id}`,
            label: e.name,
         };
      },
   );

   const idGaveUpBusiness = gaveUpBusinessOptionsList.map(
      (e: CheckboxProps) => {
         return {
            id: `${e.id}`,
            label: e.name,
         };
      },
   );

   const whosOwner = whosOwnerOptionsList.map((e: CheckboxProps) => {
      return {
         id: `${e.id}`,
         label: e.name,
      };
   });

   const schema = Yup.object({
      idBusinessSector: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      idBusinessPurpose: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      businessPurposeDescription: Yup.mixed().when('idBusinessPurpose', {
         is: 7,
         then: Yup.string().required(t(Labels.COMMON_REQUIRED)),
      }),
      idWithoutCNPJReason: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      cnpjDescription: Yup.mixed().when('idWithoutCNPJReason', {
         is: 6,
         then: Yup.string().required(t(Labels.COMMON_REQUIRED)),
      }),
      idHaventBusiness: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      idBusinessMotivation: Yup.array().when('idHaventBusiness', {
         is: (idHaventBusiness: number) =>
            idHaventBusiness === 1 || idHaventBusiness === 2,
         then: Yup.array().required().min(1, t(Labels.COMMON_REQUIRED)),
      }),
      businessMotivationDescription: Yup.mixed().when('idBusinessMotivation', {
         is: (idBusinessMotivationYup: any) => {
            if (idBusinessMotivationYup?.indexOf('5') !== -1) {
               return true;
            }
            return false;
         },
         then: Yup.mixed().required(),
      }),
      idGaveUpBusiness: Yup.array().when('idHaventBusiness', {
         is: (idHaventBusiness: number) =>
            idHaventBusiness === 1 || idHaventBusiness === 2,
         then: Yup.array().required().min(1, t(Labels.COMMON_REQUIRED)),
      }),
      gaveUpBusinessDescription: Yup.mixed().when('idGaveUpBusiness', {
         is: (idGaveUpBusinessYup: any) => {
            if (idGaveUpBusinessYup?.indexOf('7') !== -1) {
               return true;
            }
            return false;
         },
         then: Yup.mixed().required(),
      }),
      haveOwner: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      numberOwners: Yup.mixed().when('haveOwner', {
         is: true,
         then: Yup.number()
            .required(t(Labels.COMMON_REQUIRED))
            .min(1, t(Labels.SOCPROFILES_ADD_NO_0_VALIDATION)),
      }),
      idWhosOwner: Yup.array().when('haveOwner', {
         is: true,
         then: Yup.array().required().min(1, t(Labels.COMMON_REQUIRED)),
      }),
      idBusinessHelper: Yup.mixed().when('haveOwner', {
         is: true,
         then: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      }),
      idDreams: Yup.array().required().min(1, t(Labels.COMMON_REQUIRED)),
      dreamsDescription: Yup.mixed().when('idDreams', {
         is: (idDreams: any) => {
            if (idDreams?.indexOf('12') !== -1) {
               return true;
            }
            return false;
         },
         then: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      }),
      idBusinessDesire: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      idReasonHaveBusiness: Yup.mixed().when('idBusinessDesire', {
         is: (idBusinessDesire: any) => {
            if (idBusinessDesire !== 1) {
               return true;
            }
            return false;
         },
         then: Yup.mixed().required(),
      }),
      reasonHaveBusinessDescription: Yup.mixed().when('idReasonHaveBusiness', {
         is: 5,
         then: Yup.string().required(t(Labels.COMMON_REQUIRED)),
      }),
   });

   useEffect(() => {
      if (
         entrepreneurs.idHaventBusiness &&
         (entrepreneurs?.idHaventBusiness === 1 ||
            entrepreneurs?.idHaventBusiness === 2)
      ) {
         setHaventBusinessChecked(true);
      }
      if (entrepreneurs.haveOwner) {
         setHaveOwnerChecked(true);
      }
      if (
         entrepreneurs.idBusinessDesire &&
         entrepreneurs?.idBusinessDesire > 1
      ) {
         setBusinessDesireChecked(true);
      }
   }, [entrepreneurs]);

   function warning() {
      Modal.warning({
         okButtonProps: {
            style: { backgroundColor: '#faad14', borderColor: "rgba(0,0,0,0)" },
         },
         title: 'Atenção!',
         content: 'Para salvar o formulário é necessário, também, salvar o cadastro.',
      });
   }
   return (
      <Modal
         className="entrepreneurs-modal"
         centered
         visible={isEntrepreneursModalVisible}
         title={
            id
               ? t(Labels.ENTREPRENEURS_EDIT_NAME)
               : t(Labels.ENTREPRENEURS_ADD_NAME)
         }
         onCancel={() => {
            setIsEntrepreneursModalVisible(false);
         }}
         footer={null}
      >
         <div className="entrepreneurs-search-container">
            <Layout className="site-layout">
               <div className="subtitle">{t(Labels.COMMON_REQUIRED_TITLE)}</div>
               <Content className="site-layout-background">
                  <Formik
                     // eslint-disable-next-line
                     onSubmit={(values: any) => {
                        const entrepreneursObject = {
                           idBusinessSector: values.idBusinessSector,
                           idBusinessPurpose: values.idBusinessPurpose,
                           idWithoutCNPJReason: values.idWithoutCNPJReason,
                           idHaventBusiness: values.idHaventBusiness,
                           idBusinessMotivation: haventBusinessChecked
                              ? values.idBusinessMotivation
                              : null,
                           idGaveUpBusiness: haventBusinessChecked
                              ? values.idGaveUpBusiness
                              : null,
                           haveOwner: values.haveOwner,
                           idWhosOwner: haveOwnerChecked
                              ? values.idWhosOwner
                              : null,
                           idBusinessHelper: haveOwnerChecked
                              ? values.idBusinessHelper
                              : null,
                           idDreams: values.idDreams,
                           idBusinessDesire: values.idBusinessDesire,
                           idReasonHaveBusiness: businessDesireChecked
                              ? values.idReasonHaveBusiness
                              : null,
                           cnpjDescription: values.cnpjDescription,
                           businessPurposeDescription:
                              values.businessPurposeDescription,
                           businessMotivationDescription: haventBusinessChecked
                              ? values.businessMotivationDescription
                              : null,
                           gaveUpBusinessDescription: haventBusinessChecked
                              ? values.gaveUpBusinessDescription
                              : null,
                           dreamsDescription: values.dreamsDescription,
                           reasonHaveBusinessDescription: businessDesireChecked
                              ? values.reasonHaveBusinessDescription
                              : null,
                           numberOwners: values.numberOwners,
                        };
                        setIsEntrepreneursModalVisible(false);
                        dispatch(getEntrepreneurs(entrepreneursObject));
                        warning()
                     }}
                     initialValues={entrepreneurs}
                     validateOnBlur
                     enableReinitialize
                     validationSchema={schema}
                     render={({ errors, touched }) => {
                        return (
                           <Form style={{ width: '100%' }}>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_BUSINESS_SECTOR,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="idBusinessSector">
                                    {businessSectorOptionsList?.map(
                                       (radio: {
                                          id: string;
                                          name: string;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.idBusinessSector &&
                                    touched.idBusinessSector && (
                                       <div className="errorText">
                                          {errors?.idBusinessSector}
                                       </div>
                                    )}
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_BUSINESS_GOAL,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="idBusinessPurpose">
                                    {businessPurposeOptionsList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (
                                             businessPurposeOptionsList.length -
                                             2 ===
                                             i
                                          ) {
                                             return (
                                                <div className="display-input">
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                   <Input
                                                      name="businessPurposeDescription"
                                                      className="input-checkbox"
                                                   />
                                                   {errors.businessPurposeDescription && (
                                                      <div className="errorText">
                                                         {
                                                            errors?.businessPurposeDescription
                                                         }
                                                      </div>
                                                   )}
                                                </div>
                                             );
                                          }
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.idBusinessPurpose &&
                                    touched.idBusinessPurpose && (
                                       <div className="errorText">
                                          {errors?.idBusinessPurpose}
                                       </div>
                                    )}
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_BUSINESS_WITHOUT_CNPJ,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="idWithoutCNPJReason">
                                    {withoutCNPJReasonOptionsList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (
                                             withoutCNPJReasonOptionsList.length -
                                             2 ===
                                             i
                                          ) {
                                             return (
                                                <div className="display-input">
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                   <Input
                                                      name="cnpjDescription"
                                                      className="input-checkbox"
                                                   />
                                                   {errors.cnpjDescription && (
                                                      <div className="errorText">
                                                         {
                                                            errors?.cnpjDescription
                                                         }
                                                      </div>
                                                   )}
                                                </div>
                                             );
                                          }
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.idWithoutCNPJReason &&
                                    touched.idWithoutCNPJReason && (
                                       <div className="errorText">
                                          {errors?.idWithoutCNPJReason}
                                       </div>
                                    )}
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_STARTED_BUSINESS,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="idHaventBusiness">
                                    {haventBusinessOptionsList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (i !== 2) {
                                             return (
                                                <div>
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"
                                                      onChange={() =>
                                                         setHaventBusinessChecked(
                                                            true,
                                                         )
                                                      }
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                </div>
                                             );
                                          }
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                                onChange={() =>
                                                   setHaventBusinessChecked(
                                                      false,
                                                   )
                                                }
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.idHaventBusiness &&
                                    touched.idHaventBusiness && (
                                       <div className="errorText">
                                          {errors?.idHaventBusiness}
                                       </div>
                                    )}
                              </Row>
                              {haventBusinessChecked && (
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {`${t(
                                          Labels.ENTREPRENEURS_ADD_BUSINESS_MOTIVATION,
                                       )} *`}
                                    </div>
                                    <Col className="col checkbox" span={12}>
                                       {idBusinessMotivation.map(
                                          (
                                             e: { id: string; label: string },
                                             i: number,
                                          ) => {
                                             if (
                                                idBusinessMotivation.length -
                                                1 ===
                                                i
                                             ) {
                                                return (
                                                   // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                   <div className="display-input">
                                                      <label className="checkbox">
                                                         <Field
                                                            name="idBusinessMotivation"
                                                            type="checkbox"
                                                            value={`${e.id}`}
                                                         />
                                                         {t(e.label)}
                                                      </label>
                                                      <Input
                                                         name="businessMotivationDescription"
                                                         className="input-checkbox"
                                                      />
                                                      {errors.businessMotivationDescription && (
                                                         <div className="errorText">
                                                            {
                                                               errors?.businessMotivationDescription
                                                            }
                                                         </div>
                                                      )}
                                                   </div>
                                                );
                                             }
                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div>
                                                   <label className="checkbox">
                                                      <Field
                                                         name="idBusinessMotivation"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                </div>
                                             );
                                          },
                                       )}
                                    </Col>
                                    {errors.idBusinessMotivation &&
                                       touched.idBusinessMotivation && (
                                          <div className="errorText">
                                             {errors?.idBusinessMotivation}
                                          </div>
                                       )}
                                 </Row>
                              )}
                              {haventBusinessChecked && (
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {`${t(
                                          Labels.ENTREPRENEURS_ADD_WAIVER,
                                       )} *`}
                                    </div>
                                    <Col className="col checkbox" span={12}>
                                       {idGaveUpBusiness.map(
                                          (
                                             e: { id: string; label: string },
                                             i: number,
                                          ) => {
                                             if (
                                                idGaveUpBusiness.length - 1 ===
                                                i
                                             ) {
                                                return (
                                                   // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                   <div className="display-input">
                                                      <label className="checkbox">
                                                         <Field
                                                            name="idGaveUpBusiness"
                                                            type="checkbox"
                                                            value={`${e.id}`}
                                                         />
                                                         {t(e.label)}
                                                      </label>
                                                      <Input
                                                         name="gaveUpBusinessDescription"
                                                         className="input-checkbox"
                                                      />
                                                      {errors.gaveUpBusinessDescription && (
                                                         <div className="errorText">
                                                            {
                                                               errors?.gaveUpBusinessDescription
                                                            }
                                                         </div>
                                                      )}
                                                   </div>
                                                );
                                             }
                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div>
                                                   <label className="checkbox">
                                                      <Field
                                                         name="idGaveUpBusiness"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                </div>
                                             );
                                          },
                                       )}
                                    </Col>
                                    {errors.idGaveUpBusiness &&
                                       touched.idGaveUpBusiness && (
                                          <div className="errorText">
                                             {errors?.idGaveUpBusiness}
                                          </div>
                                       )}
                                 </Row>
                              )}
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="haveOwner">
                                    {yesNo?.map(
                                       (
                                          radio: {
                                             name: string;
                                             value: boolean;
                                             label: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (i === 0) {
                                             return (
                                                <div>
                                                   <Radio
                                                      name="haveOwner"
                                                      value={radio.value}
                                                      onChange={() =>
                                                         setHaveOwnerChecked(
                                                            true,
                                                         )
                                                      }
                                                   >
                                                      {t(radio.label)}
                                                   </Radio>
                                                   <Field
                                                      type="number"
                                                      name="numberOwners"
                                                      maxLength={150}
                                                      min={0}
                                                      value={null}
                                                   />
                                                </div>
                                             );
                                          }

                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.value}
                                                onChange={() =>
                                                   setHaveOwnerChecked(false)
                                                }
                                                className="other-option-radio"
                                             >
                                                {t(radio.label)}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.haveOwner && touched.haveOwner && (
                                    <div className="errorText">
                                       {errors?.haveOwner}
                                    </div>
                                 )}
                                 {errors.numberOwners &&
                                    touched.numberOwners && (
                                       <div className="errorText">
                                          {errors?.numberOwners}
                                       </div>
                                    )}
                              </Row>
                              {haveOwnerChecked && (
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {`${t(
                                          Labels.ENTREPRENEURS_ADD_IF_YES_WHO,
                                       )} *`}
                                    </div>
                                    <Col className="col checkbox" span={12}>
                                       {whosOwner.map(
                                          (e: {
                                             id: string;
                                             label: string;
                                          }) => {
                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div>
                                                   <label className="checkbox">
                                                      <Field
                                                         name="idWhosOwner"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                </div>
                                             );
                                          },
                                       )}
                                    </Col>
                                    {errors.idWhosOwner &&
                                       touched.idWhosOwner && (
                                          <div className="errorText">
                                             {errors?.idWhosOwner}
                                          </div>
                                       )}
                                 </Row>
                              )}
                              {haveOwnerChecked && (
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {`${t(
                                          Labels.ENTREPRENEURS_ADD_HELP_WITH_BUSINESS,
                                       )} *`}
                                    </div>
                                    <Radio.Group name="idBusinessHelper">
                                       {businessHelperOptionsList?.map(
                                          (radio: {
                                             id: string;
                                             name: string;
                                          }) => {
                                             return (
                                                <Radio
                                                   name="idBusinessHelper"
                                                   value={radio.id}
                                                   className="other-option-radio"
                                                >
                                                   {radio.name}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                    {errors.idBusinessHelper &&
                                       touched.idBusinessHelper && (
                                          <div className="errorText">
                                             {errors?.idBusinessHelper}
                                          </div>
                                       )}
                                 </Row>
                              )}
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_PERSONAL_WISHES,
                                    )} *`}
                                 </div>
                                 <Col className="col checkbox" span={12}>
                                    {dreams.map(
                                       (
                                          e: { id: string; label: string },
                                          i: number,
                                       ) => {
                                          if (dreams.length - 2 === i) {
                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div
                                                   className="display-input"
                                                   key={i}
                                                >
                                                   <label className="checkbox">
                                                      <Field
                                                         name="idDreams"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                   <Input
                                                      name="dreamsDescription"
                                                      className="input-checkbox"
                                                   />
                                                   {errors.dreamsDescription && (
                                                      <div className="errorText">
                                                         {
                                                            errors?.dreamsDescription
                                                         }
                                                      </div>
                                                   )}
                                                </div>
                                             );
                                          }
                                          return (
                                             // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                             <div key={i}>
                                                <label className="checkbox">
                                                   <Field
                                                      name="idDreams"
                                                      type="checkbox"
                                                      value={`${e.id}`}
                                                   />
                                                   {t(e.label)}
                                                </label>
                                             </div>
                                          );
                                       },
                                    )}
                                 </Col>
                                 {errors.idDreams && touched.idDreams && (
                                    <div className="errorText">
                                       {errors?.idDreams}
                                    </div>
                                 )}
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {`${t(
                                       Labels.ENTREPRENEURS_ADD_OPEN_OWN_BUSINESS,
                                    )} *`}
                                 </div>
                                 <Radio.Group name="idBusinessDesire">
                                    {businessDesireOptionsList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (i !== 0) {
                                             return (
                                                <Radio
                                                   name="idBusinessDesire"
                                                   value={radio.id}
                                                   className="other-option-radio"
                                                   onChange={() =>
                                                      setBusinessDesireChecked(
                                                         true,
                                                      )
                                                   }
                                                >
                                                   {radio.name}
                                                </Radio>
                                             );
                                          }
                                          return (
                                             <Radio
                                                name="idBusinessDesire"
                                                value={radio.id}
                                                className="other-option-radio"
                                                onChange={() =>
                                                   setBusinessDesireChecked(
                                                      false,
                                                   )
                                                }
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.idBusinessDesire &&
                                    touched.idBusinessDesire && (
                                       <div className="errorText">
                                          {errors?.idBusinessDesire}
                                       </div>
                                    )}
                              </Row>
                              {businessDesireChecked && (
                                 <Row className="row-vertical">
                                    <div className="title-questions">
                                       {`${t(
                                          Labels.ENTREPRENEURS_ADD_MAIN_MOTIVATION,
                                       )} *`}
                                    </div>
                                    <Radio.Group name="idReasonHaveBusiness">
                                       {reasonHaveBusinessOptionsList?.map(
                                          (
                                             radio: {
                                                id: string;
                                                name: string;
                                             },
                                             i: number,
                                          ) => {
                                             if (
                                                reasonHaveBusinessOptionsList.length -
                                                1 ===
                                                i
                                             ) {
                                                return (
                                                   <div className="display-input">
                                                      <Radio
                                                         name="idReasonHaveBusiness"
                                                         value={radio.id}
                                                         className="other-option-radio"
                                                      >
                                                         {radio.name}
                                                      </Radio>
                                                      <Input
                                                         name="reasonHaveBusinessDescription"
                                                         className="input-checkbox"
                                                      />
                                                      {errors.reasonHaveBusinessDescription && (
                                                         <div className="errorText">
                                                            {
                                                               errors?.reasonHaveBusinessDescription
                                                            }
                                                         </div>
                                                      )}
                                                   </div>
                                                );
                                             }
                                             return (
                                                <Radio
                                                   name="idReasonHaveBusiness"
                                                   value={radio.id}
                                                   className="other-option-radio"
                                                >
                                                   {radio.name}
                                                </Radio>
                                             );
                                          },
                                       )}
                                       {errors.idReasonHaveBusiness &&
                                          touched.idReasonHaveBusiness && (
                                             <div className="errorText">
                                                {errors?.idReasonHaveBusiness}
                                             </div>
                                          )}
                                    </Radio.Group>
                                 </Row>
                              )}
                              <Row>
                                 <Button.Group>
                                    <Button
                                       onClick={() => {
                                          setIsEntrepreneursModalVisible(false);
                                       }}
                                       className="button-goback"
                                       type="primary"
                                    >
                                       {t(Labels.COMMON_BUTTON_BACK)}
                                    </Button>
                                    <SubmitButton
                                       loading={false}
                                       className="default-button"
                                    >
                                       {t(Labels.COMMON_BUTTON_OK)}
                                    </SubmitButton>
                                 </Button.Group>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               </Content>
            </Layout>
         </div>
      </Modal>
   );
};

export default AddEntrepreneurs;
