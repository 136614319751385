const RoutesList = [
   {
      path: '/',
      breadcrumb: 'Home',
   },
   {
      path: '/programs',
      breadcrumb: 'Pesquisar Programas',
   },
   {
      path: '/programs/add',
      breadcrumb: 'Cadastrar Programas',
   },
   {
      path: '/programs/edit',
      breadcrumb: 'Editar Programas',
   },
   {
      path: '/terms',
      breadcrumb: 'Configurar Termo de Consentimento LGPD',
   },
   {
      path: '/profiles',
      breadcrumb: 'Perfis e Acessos',
   },
   {
      path: '/profiles/add',
      breadcrumb: 'Cadastrar Perfil e Acesso',
   },
   {
      path: '/profiles/edit',
      breadcrumb: 'Editar Perfil e Acesso',
   },
   {
      path: '/entities',
      breadcrumb: 'Pesquisar Entidades',
   },
   {
      path: '/entities/add',
      breadcrumb: 'Cadastrar Entidades',
   },
   {
      path: '/entities/edit',
      breadcrumb: 'Editar Entidades',
   },
   {
      path: '/naturalPerson',
      breadcrumb: 'Pesquisar Pessoa Física',
   },
   {
      path: '/naturalPerson/add',
      breadcrumb: 'Cadastrar Pessoa Física',
   },
   {
      path: '/naturalPerson/edit',
      breadcrumb: 'Editar Pessoa Física',
   },
   {
      path: '/inscribePerson',
      breadcrumb: 'Inscrever Colaborador ou Participante da Entidade',
   },
   {
      path: '/inscribePerson/list',
      breadcrumb: 'Listagem de Programas',
   },
   {
      path: '/inscribePerson/productDetail',
      breadcrumb: 'Detalhes do produto',
   },
   {
      path: '/inscribePerson/classDetail',
      breadcrumb: 'Detalhes da classe',
   },
   {
      path: '/relatory',
      breadcrumb: 'Relatório e Monitoramento',
   },
];

export default { RoutesList };
