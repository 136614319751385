import { message, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useAppDispatch } from 'hooks/hooks';
import {
   BeforeUploadFileType,
   RcFile,
   UploadRequestOption,
} from 'rc-upload/lib/interface';

type UploadPdfFileButtonProps = {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   serviceFunction?: any;
   children: React.ReactNode;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   serviceFunctionParams?: any;
};

const UploadPdfFileButton = ({
   children,
   serviceFunction,
   serviceFunctionParams,
}: UploadPdfFileButtonProps) => {
   const dispatch = useAppDispatch();

   const onlyPdfType = (file: RcFile): BeforeUploadFileType => {
      if (file.type !== 'application/pdf') {
         message.error({
            content: `${file.name} não é um arquivo PDF`,
            className: 'error',
            duration: 10,
         });
      }
      return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE;
   };

   const onChange = (info: UploadChangeParam) => {
      const { status } = info.file;
      if (status === 'done') {
         message.success({
            content: `${info.file.name}: Arquivo foi enviado com sucesso.`,
            className: 'success',
            duration: 10,
         });
      } else if (status === 'error') {
         message.error({
            content: `${info.file.name}: Erro no envio do arquivo.`,
            className: 'error',
            duration: 10,
         });
      }
   };

   const customRequest = async ({ file, onSuccess }: UploadRequestOption) => {
      // const formData = new FormData();
      const { adheredProgramsList, booleano } = serviceFunctionParams;
      await dispatch(
         await serviceFunction(adheredProgramsList, booleano, file),
      );
      if (onSuccess) {
         onSuccess('Ok', new XMLHttpRequest());
      }
   };

   return (
      <Upload
         name="file"
         multiple={false}
         maxCount={1}
         onChange={onChange}
         beforeUpload={onlyPdfType}
         customRequest={customRequest}
         showUploadList={false}
      >
         {children}
      </Upload>
   );
};

export default UploadPdfFileButton;
