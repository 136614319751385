import { Input as InputFormik } from 'formik-antd';
import { Field } from 'components/common/field';
import { InputProps as AntdInputProps } from 'antd';

interface InputProps extends AntdInputProps {
   title: string;
   name: string;
}

export const Input = ({ name, title, ...props }: InputProps) => {
   return (
      <Field name={name} title={title}>
         <InputFormik name={name} {...props} />
      </Field>
   );
};

export default Input;
