import { Breadcrumb, PageHeader } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Routes from '../../../helpers/enum/Breadcrumb';
import './Breadcrumb.scss';

export const BreadcrumbComponent = () => {
   const location = useLocation();

   const breadcrumb = (
      <Breadcrumb>
         {Routes.RoutesList.map(val =>
            location.pathname.includes(val.path) ||
            val.breadcrumb === 'Home' ? (
               <Link to={val.path}>
                  <Breadcrumb.Item>{val.breadcrumb}</Breadcrumb.Item>
               </Link>
            ) : (
               ''
            ),
         )}
      </Breadcrumb>
   );

   return (
      <PageHeader
         className={location.pathname === '/' ? 'home' : ''}
         breadcrumb={breadcrumb}
      />
   );
};
