import { SubmitButton, Form, Input, FormItem } from 'formik-antd';
import { Table, Button, Row, Col, Layout, PageHeader, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import './styles.scss';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { EditIconSVG } from '../../components/common/icons/icons';
import Labels from '../../helpers/enum/Labels';
import { RootState } from '../../config/store';
import { useAppDispatch } from '../../hooks/hooks';
import programsService from '../../services/programsService';

const { Title } = Typography;
const { Content } = Layout;

interface Table {
   key: number;
   name: string;
}
interface Values {
   initials: string;
}
const Programs = () => {
   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useAppDispatch();
   const { programs } = useSelector((state: RootState) => state.programs);
   const { loading } = useSelector((state: RootState) => state.programs);
   const [nameValue, setNameValue] = useState<string>();

   const columns: ColumnsType<object> = [
      {
         title: t(Labels.PROGRAMS_SEARCH_NAME),
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         render: id => {
            return (
               <EditIconSVG
                  onClick={() => {
                     history.push(`/programs/edit/${id}`);
                  }}
               />
            );
         },
      },
   ];

   const handleChangePage = (value: number) => {
      dispatch(
         programsService.searchPrograms(nameValue, value, programs.pageSize),
      );
   };

   useEffect(() => {
      dispatch(programsService.searchPrograms());
   }, [dispatch]);

   const list = programs.data?.map(program => {
      return program;
   });

   const onSubmit = (
      values: Values,
      { setSubmitting }: FormikHelpers<Values>,
   ) => {
      setSubmitting(true);
      dispatch(programsService.searchPrograms(values.initials));
      setNameValue(values.initials);
      setSubmitting(false);
   };

   return (
      <div className="programs-search-container">
         <Layout className="site-layout">
            <PageHeader title={t(Labels.PROGRAMS_SEARCH_TITLE)} />
            <Content className="site-layout-background">
               <Formik<Values>
                  initialValues={{
                     initials: '',
                  }}
                  onSubmit={onSubmit}
               >
                  <Form
                     data-testid="programs-list-form"
                     className="form-programs"
                  >
                     <Row className="form-container-programs">
                        <Col span={8}>
                           <FormItem
                              name="initials"
                              label=""
                              required
                              className="default-field-container"
                           >
                              <Input
                                 name="initials"
                                 placeholder="Nome do Programa"
                                 className="default-field"
                                 data-testid="initials"
                                 role="textbox"
                              />
                           </FormItem>
                        </Col>
                        <Col md={10} span={24}>
                           <Button.Group>
                              <SubmitButton
                                 id="programs-search-button"
                                 data-testid="submitButton"
                                 icon={<SearchOutlined />}
                              >
                                 {t(Labels.BUTTON)}
                              </SubmitButton>
                              <Button
                                 id="programs-new-program"
                                 type="primary"
                                 onClick={() => history.push('/programs/add')}
                              >
                                 {t(Labels.BUTTON_NEW_PROGRAM)}
                              </Button>
                           </Button.Group>
                        </Col>
                     </Row>
                     <Title level={4}>Resultados da pesquisa:</Title>
                     <Table
                        dataSource={list}
                        columns={columns}
                        className="default-table"
                        locale={
                           loading
                              ? {
                                   emptyText: t(Labels.LOADING),
                                }
                              : {
                                   emptyText: t(Labels.NO_DATA),
                                }
                        }
                        pagination={{
                           current: programs.pageNumber,
                           onChange: handleChangePage,
                           pageSize: programs.pageSize,
                           total: programs.totalRecords,
                           showSizeChanger: false,
                        }}
                        loading={loading}
                     />
                  </Form>
               </Formik>
            </Content>
         </Layout>
      </div>
   );
};

export default Programs;
