import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchTypeProducts } from '../services/typeProductsService';

interface InitialStateType {
   typeProducts: SearchTypeProducts;
}

const INITIAL_STATE: InitialStateType = {
   typeProducts: {
      data: [
         { id: 1, name: 'Consultoria' },
         { id: 2, name: 'Coletivo' },
      ],
      success: true,
   }, // {} as SearchTypeProducts,
};

export const getTypeProducts =
   createAction<SearchTypeProducts>('GET_TYPE_PRODUCTS');

export default createReducer(INITIAL_STATE, {
   [getTypeProducts.type]: (
      state,
      action: PayloadAction<SearchTypeProducts>,
   ) => {
      return {
         ...state,
         typeProducts: action.payload,
      };
   },
});
