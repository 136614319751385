import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchPeopleServed } from '../services/peopleServedService';

interface InitialStateType {
   peopleServed: SearchPeopleServed;
}

const INITIAL_STATE: InitialStateType = {
   peopleServed: {
      data: [],
      success: true,
   }, // {} as SearchPeopleServed,
};

export const getPeopleServed =
   createAction<SearchPeopleServed>('GET_PEOPLE_SERVED');

export default createReducer(INITIAL_STATE, {
   [getPeopleServed.type]: (
      state,
      action: PayloadAction<SearchPeopleServed>,
   ) => {
      return {
         ...state,
         peopleServed: action.payload,
      };
   },
});
