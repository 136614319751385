import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
   SearchProducts,
   SearchProduct,
   ProductById,
} from '../services/productsService';

interface InitialStateType {
   products: SearchProducts;
   product: SearchProduct[];
   productById: ProductById;
   loadingProducts: boolean;
   loadingEditProgram: boolean;
   isLoading: boolean;
}

const INITIAL_STATE: InitialStateType = {
   products: {
      data: [],
      success: true,
      idKit: '',
   },
   product: [
      {
         data: [],
         idKit: '',
      },
   ],
   productById: {
      id: '',
      name: '',
      idCategory: null,
      category: '',
      isKit: false,
      workload: '',
      description: '',
      price: null,
   },
   loadingProducts: true,
   loadingEditProgram: false,
   isLoading: false,
};

export const getProducts = createAction<SearchProducts>('GET_PRODUCTS');

export const getProductsByKit = createAction<SearchProduct>(
   'GET_PRODUCTS_BY_KIT',
);

export const clearProduct = createAction('CLEAR_PRODUCT');

export const setLoading = createAction<boolean>('SET_PRODUCTS_LOADING');

export const getProductById = createAction<ProductById>('GET_PRODUCT');

export const startLoadingProducts = createAction('START_LOADING_PRODUCTS');

export const endLoadingProducts = createAction('END_LOADING_PRODUCTS');

export const setLoadingEditProgram = createAction<boolean>(
   'SET_LOADING_EDIT_PROGRAM',
);

export default createReducer(INITIAL_STATE, {
   [getProducts.type]: (state, action: PayloadAction<SearchProducts>) => {
      return {
         ...state,
         products: action.payload,
         loadingProducts: false,
      };
   },
   [getProductsByKit.type]: (state, action: PayloadAction<SearchProducts>) => {
      return {
         ...state,
         product: [
            ...state.product,
            {
               data: action.payload.data,
               idKit: action.payload.idKit,
            },
         ],
         loadingProducts: false,
      };
   },
   [clearProduct.type]: state => {
      return {
         ...state,
         product: INITIAL_STATE.product,
      };
   },
   [setLoading.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingProducts: action.payload,
      };
   },
   [getProductById.type]: (state, action: PayloadAction<ProductById>) => {
      const data = {
         ...action.payload,
         workload: `${
            (parseFloat(action.payload.workload) / 60).toString().split('.')[0]
         }:${
            parseFloat(action.payload.workload) % 60 === 0
               ? '00'
               : (parseFloat(
                    (parseFloat(action.payload.workload) / 60)
                       .toString()
                       .split('.')[1],
                 ) *
                    60) /
                 100
         }`,
      };
      return {
         ...state,
         productById: data,
         loadingProducts: false,
      };
   },
   [setLoadingEditProgram.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingEditProgram: action.payload,
      };
   },
   [startLoadingProducts.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'isLoading' : payload]: true,
      };
   },
   [endLoadingProducts.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'isLoading' : payload]: false,
   }),
});
