import { Col, PageHeader, Row, Upload, message } from 'antd';
import Icon from '@ant-design/icons';
import { UploadIconSVG } from 'components/common/icons/icons';
import { useTranslation } from 'react-i18next';
import {
   RcFile,
   UploadRequestOption,
   BeforeUploadFileType,
} from 'rc-upload/lib/interface';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../config/store';
import Labels from '../../helpers/enum/Labels';
import { ReactComponent as Anexo } from '../../assets/icons/anexo.svg';
import termsService, {
   getLastLgpdConsentTerm,
} from '../../services/termsService';
import { useAppDispatch } from '../../hooks/hooks';
import './styles.scss';

const { Dragger } = Upload;

const Terms = () => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const [updateTerm, setUpdateTerm] = useState(false);

   useEffect(() => {
      dispatch(getLastLgpdConsentTerm());
   }, [dispatch, updateTerm]);

   const lastLgpdConsentTerm = useSelector(
      (state: RootState) => state.terms.lastLgpdConsentTerm,
   );

   const customRequest = async ({ file, onSuccess }: UploadRequestOption) => {
      const formData = new FormData();
      formData.append('file', file);
      await dispatch(await termsService.uploadTerms(formData));
      if (onSuccess) {
         onSuccess('Ok', new XMLHttpRequest());
         setUpdateTerm(!updateTerm);
      }
   };

   const onlyPdfType = (file: RcFile): BeforeUploadFileType => {
      if (file.type !== 'application/pdf') {
         message.error({
            content: `${file.name} não é um arquivo PDF`,
            className: 'error',
            duration: 10,
         });
      }
      return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE;
   };

   const onChangeDragger = (info: UploadChangeParam) => {
      const { status } = info.file;
      if (status === 'done') {
         message.success({
            content: `${info.file.name}: Arquivo foi enviado com sucesso.`,
            className: 'success',
            duration: 10,
         });
      } else if (status === 'error') {
         message.error({
            content: `${info.file.name}: Erro no envio do arquivo.`,
            className: 'error',
            duration: 10,
         });
      }
   };

   return (
      <div className="terms-container">
         <PageHeader title={t(Labels.TERMS_PAGE_TITLE)} />
         <Row>
            <Col>
               <Dragger
                  name="file"
                  multiple={false}
                  maxCount={1}
                  showUploadList={{ showRemoveIcon: false }}
                  customRequest={customRequest}
                  beforeUpload={onlyPdfType}
                  onChange={onChangeDragger}
                  data-testid="dragger"
               >
                  <p className="ant-upload-drag-icon">
                     <UploadIconSVG />
                  </p>
                  <p className="ant-upload-text">
                     {t(Labels.TERMS_BUTTON_TEXT)}
                  </p>
               </Dragger>
               {lastLgpdConsentTerm && (
                  <Row justify="center" align="bottom">
                     <a
                        id="last-lgpd-signed-term"
                        target="_blank"
                        href={lastLgpdConsentTerm.data.fileUrl || ''}
                        rel="noreferrer"
                     >
                        <Icon component={Anexo} /> Visualizar Termo LGPD atual
                     </a>
                  </Row>
               )}
            </Col>
         </Row>
      </div>
   );
};
export default Terms;
