import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import buildYupLocale from './buildYupLocale';

const availableLanguages = ['en-US', 'pt-BR'];

i18n
   .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

   .use(LanguageDetector) // detect user language

   .use(initReactI18next) // pass the i18n instance to react-i18next.

   .init(
      {
         fallbackLng: 'pt-BR', // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
         debug: false,
         whitelist: availableLanguages,
         ns: ['translation'],
         // ns: ['dashboard', 'loginForm', 'translation'],
         defaultNS: 'translation',
         interpolation: {
            escapeValue: false,
         },
      },
      buildYupLocale,
   );

export default i18n;

const t = i18n.t.bind(i18n);
export { t };
