import {
   setFirstSession,
   setLoading,
   setLogin,
   setTokenFirstSession,
} from 'actions/authAction';
import { AppDispatch } from 'config/store';
import api from 'config/httpRequest/api';
import Keycloak from 'keycloak-js';

interface LoginData {
   token: string;
   isRegistered: boolean;
}

export interface SearchPaginated {
   name: string;
   cpf: string;
   role: string;
   // eslint-disable-next-line camelcase
   role_id: number;
   data: LoginData;
   message: string;
   success: boolean;
}

const { env } = window;

const keycloak = Keycloak({
   url: env.REACT_APP_KEYCLOAK_URL,
   realm: env.REACT_APP_KEYCLOAK_REALM as string,
   clientId: env.REACT_APP_KEYCLOAK_CLIENTID as string,
});

export const setLoginService = (token: string) => {
   const newToken = `"${token}"`;

   return async (dispatch: AppDispatch) => {
      api.post<SearchPaginated>('/Account/Login', newToken)
         .then(async ({ data: payload }) => {
            if (payload.data.isRegistered === false) {
               dispatch(setFirstSession(true));
               dispatch(setTokenFirstSession(token));
            }
            dispatch(setLogin(payload));
            dispatch(setLoading(false));
         })
         .catch(e => {
            // eslint-disable-next-line no-console
            console.log('error,setLoginService', e);
         });
      dispatch(setLoading(true));
   };
};

export const initKeycloak = (
   dispatch: AppDispatch,
   setkkeyclokerInitialize: (flag: boolean) => void,
) => {
   keycloak
      .init({
         onLoad: 'login-required',
      })
      .then(authenticated => {
         setkkeyclokerInitialize(true);
         const { token } = keycloak;
         if (authenticated && token) {
            dispatch(setLoginService(token));
         }
      })
      .catch(e => {
         // eslint-disable-next-line no-console
         console.log('error', e);
      });
};

const doLogout = keycloak.logout;

export default { setLoginService, initKeycloak, doLogout };
