import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchEntitiesApiResponse } from 'services/inscribeService';
import { AdheredProgram } from 'services/programAdhesionService';

interface InitialStateType {
   entityList: SearchEntitiesApiResponse | null;
   adheredPrograms: AdheredProgram[] | undefined;
   loadingEntityList: boolean;
   loadingAdheredPrograms: boolean;
   selected: {
      programName: string;
      programId: string;
      entityName: string;
      entityId: string;
      productId: string;
      productName: string;
      isKit: boolean;
   };
}

const INITIAL_STATE: InitialStateType = {
   entityList: null,
   adheredPrograms: undefined,
   loadingEntityList: false,
   loadingAdheredPrograms: false,
   selected: {
      programName: '',
      programId: '',
      entityName: '',
      entityId: '',
      productId: '',
      productName: '',
      isKit: false,
   },
};

export const getInscribeEntityList = createAction<SearchEntitiesApiResponse>(
   'GET_INSCRIBE_ENTITY_LIST',
);
export const setAdheredPrograms = createAction<AdheredProgram[]>(
   'SET_INSCRIBE_ADHERED_PROGRAMS',
);
export const setLoadingEntityList = createAction<boolean>(
   'SET_LOADING_ENTITY_LIST',
);
export const setLoadingAdheredPrograms = createAction<boolean>(
   'SET_INSCRIBE_LOADING_ADHERED_PROGRAMS',
);
export const setSelectedProgramName = createAction<string>(
   'SET_SELECTED_PROGRAM_NAME',
);
export const setSelectedIsKit = createAction<boolean>('SET_SELECTED_IS_KIT');
export const setSelectedEntityId = createAction<string>(
   'SET_SELECTED_ENTITY_ID',
);
export const setSelectedEntityName = createAction<string>(
   'SET_SELECTED_ENTITY_NAME',
);
export const setSelectedProgramId = createAction<string>(
   'SET_SELECTED_PROGRAM_ID',
);
export const setSelectedProductName = createAction<string>(
   'SET_SELECTED_PRODUCT_NAME',
);
export const setSelectedProductId = createAction<string>(
   'SET_SELECTED_PRODUCT_ID',
);

export default createReducer(INITIAL_STATE, {
   [getInscribeEntityList.type]: (
      state,
      action: PayloadAction<SearchEntitiesApiResponse>,
   ) => {
      return {
         ...state,
         entityList: action.payload,
      };
   },
   [setAdheredPrograms.type]: (
      state,
      action: PayloadAction<AdheredProgram[]>,
   ) => {
      return {
         ...state,
         adheredPrograms: action.payload,
      };
   },
   [setLoadingEntityList.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingEntityList: action.payload,
      };
   },
   [setLoadingAdheredPrograms.type]: (
      state,
      action: PayloadAction<boolean>,
   ) => {
      return {
         ...state,
         loadingAdheredPrograms: action.payload,
      };
   },
   [setSelectedProgramName.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, programName: action.payload },
      };
   },
   [setSelectedIsKit.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         selected: { ...state.selected, isKit: action.payload },
      };
   },
   [setSelectedEntityId.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, entityId: action.payload },
      };
   },
   [setSelectedEntityName.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, entityName: action.payload },
      };
   },
   [setSelectedProgramId.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, programId: action.payload },
      };
   },
   [setSelectedProductName.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, productName: action.payload },
      };
   },
   [setSelectedProductId.type]: (state, action: PayloadAction<string>) => {
      return {
         ...state,
         selected: { ...state.selected, productId: action.payload },
      };
   },
});
