import {
   clearProduct,
   endLoadingProducts,
   getProductById,
   getProducts,
   getProductsByKit,
   setLoading,
   startLoadingProducts,
} from 'actions/productsAction';

import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchProducts {
   data: any[];
   success: boolean;
   idKit: string;
}
export interface SearchProduct {
   data: any[];
   idKit: string;
}

export type Product = {
   id: string;
   name: string;
   isKit: boolean;
   description: string;
};

export type ProductById = {
   id: string;
   isKit: boolean;
   idCategory: number | null;
   category: string;
   name: string;
   workload: string;
   description: string;
   price: number | null;
};

export interface GetProduct {
   // eslint-disable-next-line
   data: ProductById;
   message: string;
   success: boolean;
}

export const searchProducts = (
   initials = '',
   pageNumber = 1,
   pageSize = 10,
) => {
   return async (dispatch: AppDispatch) => {
      dispatch(startLoadingProducts());
      api.get<SearchProducts>('/Products', {
         params: {
            Name: initials,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getProducts(payload));
            dispatch(endLoadingProducts());
         })
         .catch(err => {
            console.log(err);
            dispatch(endLoadingProducts());
         });
   };
};

export const searchProductsByKit = (idKit: string) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoading(true));
      api.get<SearchProducts>(`/Products/ByKitId/${idKit}`, {})
         .then(async ({ data: payload }) => {
            dispatch(getProductsByKit({ ...payload, idKit }));
            dispatch(setLoading(false));
         })
         .catch(error => {
            console.log(error);
            dispatch(setLoading(false));
         });
   };
};

export const getProduct = (id: string, isKit: boolean) => {
   return async (dispatch: AppDispatch) => {
      api.get<GetProduct>(`/Products/${id}`, {
         params: {
            isKit,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getProductById(payload.data));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const clearProductReduce = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearProduct());
   };
};

export default { searchProducts };
