import { setLoadingEditProgram } from 'actions/productsAction';
import { message } from 'antd';
import {
   getPrograms,
   getProgram as getProgram_,
   clearProgram,
   setLoading,
   setLoadingProducts,
   getProgramsWithProducts,
} from '../actions/programsAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface ProductSelect {
   id: string;
   isKit: boolean;
   name: string;
   list: string;
}

interface ProgramsProducts {
   id: string;
   name: string;
   idCategory: number;
   category: string;
   isKit: boolean;
   workload: string;
   description: string;
   price: number;
}
export interface Program {
   id: string;
   name: string;
   products: ProductSelect[];
   adhesionTerm: boolean;
   accessionTerm: string;
   accessionTermName: string;
   moreAdhesionTerm: boolean;
   forms: object[];
   idCategory: number;
   isActive: boolean;
   productsList: number | null;
   idProgCategory: number;
}

export interface ProgramsWithProducts {
   id: string;
   name: string;
   isActive: boolean;
   idCategory: number;
   accessionTerm: string;
   accessionTermName: string;
   idProgCategory: number;
   forms: object[];
   products: ProgramsProducts[];
}
export interface SearchProgram {
   // eslint-disable-next-line
   data: Program;
   message: string;
   success: boolean;
}
export interface SearchPaginated {
   // eslint-disable-next-line
   data: Program[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

export interface SearchProgramsWithProductsPaginated {
   // eslint-disable-next-line
   data: ProgramsWithProducts[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
   success: boolean;
   message: string;
}

export const searchPrograms = (initials = '', pageNumber = 1, pageSize = 5) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchPaginated>('/Programs', {
         params: {
            Initials: initials,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getPrograms(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const searchActivePrograms = (
   initials = '',
   isActive = true,
   pageNumber = 1,
   pageSize = 5,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchPaginated>('/Programs', {
         params: {
            Initials: initials,
            IsActive: isActive,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getPrograms(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const searchProgramsWithProducts = (
   initials = '',
   isActive = true,
   pageNumber = 1,
   pageSize = 5,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchProgramsWithProductsPaginated>('/Programs/WithProducts', {
         params: {
            Initials: initials,
            IsActive: isActive,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getProgramsWithProducts(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const getProgram = (id: string) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingEditProgram(true));
      api.get<SearchProgram>(`/Programs/${id}`, {})
         .then(async ({ data: payload }) => {
            console.log(payload.data);

            dispatch(getProgram_(payload.data));
            dispatch(setLoadingEditProgram(false));
         })
         // eslint-disable-next-line
         .catch(error => {
            console.log(console.error);
            dispatch(setLoadingEditProgram(false));
         });
      dispatch(setLoadingProducts(true));
   };
};

export const setProgram = async (data: FormData) => {
   return async () => {
      await api
         .post<SearchProgram>(`/Programs`, data)
         .then(async ({ data: payload }) => {
            message.success({
               content: payload.message,
               className: 'success',
               duration: 10,
            });
            console.log('setProgram', payload);
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const updateProgram = async (data: FormData) => {
   return async () => {
      await api
         .put<SearchProgram>(`/Programs`, data)
         .then(async ({ data: payload }) => {
            message.success({
               content: payload.message,
               className: 'success',
               duration: 10,
            });
            console.log('updateProgram', payload);
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const clearProgramReduce = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearProgram());
   };
};

export default {
   searchPrograms,
   searchActivePrograms,
   searchProgramsWithProducts,
};
