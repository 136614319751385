import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import termsAction from 'actions/termsAction';
import profilesAction from 'actions/profilesAction';
import entitiesSelectAction from 'actions/entitiesSelectAction';
import authAction from 'actions/authAction';
import naturalPersonAction from 'actions/naturalPersonAction';
import programAdhesionAction from 'actions/programAdhesionAction';
import entrepreneursAction from 'actions/entrepreneursAction';
import inscribeAction from 'actions/inscribeAction';
import classesAction from 'actions/classesAction';
import relatoryAction from 'actions/relatoryAction';
import programsAction from '../actions/programsAction';
import formsAction from '../actions/formsAction';
import productsAction from '../actions/productsAction';
import typeProductsAction from '../actions/typeProductsAction';
import typeProgramsAction from '../actions/typeProgramsAction';
import typeProfilesAction from '../actions/typeProfilesAction';
import entitiesAction from '../actions/entitiesAction';
import socprofilesAction from '../actions/socprofilesAction';

import equipamentsAction from '../actions/equipamentsAction';
import infrastructureCapacityAction from '../actions/infrastructureCapacityAction';
import peopleServedAction from '../actions/peopleServedAction';
import serviceProfileAction from '../actions/serviceProfileAction';
import socialCauseAction from '../actions/socialCauseAction';

export const store = configureStore({
   reducer: {
      programs: programsAction,
      forms: formsAction,
      products: productsAction,
      typeProducts: typeProductsAction,
      terms: termsAction,
      profiles: profilesAction,
      entities: entitiesSelectAction,
      typeProfiles: typeProfilesAction,
      entity: entitiesAction,
      auth: authAction,
      programAdhesion: programAdhesionAction,
      typePrograms: typeProgramsAction,
      equipaments: equipamentsAction,
      infrastructureCapacity: infrastructureCapacityAction,
      peopleServed: peopleServedAction,
      serviceProfile: serviceProfileAction,
      socialCause: socialCauseAction,
      naturalPerson: naturalPersonAction,
      socprofiles: socprofilesAction,
      entrepreneurs: entrepreneursAction,
      inscribe: inscribeAction,
      classes: classesAction,
      relatory: relatoryAction,
   },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
