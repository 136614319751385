import { getInfrastructureCapacities } from '../actions/infrastructureCapacityAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchInfrastructureCapacities {
   data: any[];
   success: boolean;
}
export type InfrastructureCapacity = {
   id: string;
   name: string;
};

export const searchInfrastructureCapacities = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchInfrastructureCapacities>('/InfrastructureCapacity', {})
         .then(async ({ data: payload }) => {
            dispatch(getInfrastructureCapacities(payload));
         })
         .catch(console.log);
   };
};

export default { searchInfrastructureCapacities };
