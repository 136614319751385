import axios from 'axios';
import { addInterceptors } from './interceptors';

declare global {
   interface Window {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      env: any;
   }
}

const { env } = window;
const baseURL = env && env.REACT_APP_URL_API;

const getInstance = () => {
   const config = {
      baseURL: baseURL || 'https://colossus.solutis.net.br:5201/api',
      headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
      },
   };

   let newInstance = axios.create(config);
   newInstance = addInterceptors(newInstance);
   return newInstance;
};

export default getInstance();
