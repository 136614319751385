import { Redirect, Route, Switch } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'config/store';
import routes from '../../config/routes';
import PrivateRoute from './PrivateRoute';

const Routes = () => {
   const { firstSession } = useSelector((state: RootState) => state.auth);

   const authenticatedRoutes = routes.filter
      ? routes.filter(r => r.onlyAuthorized)
      : [];

   return (
      <Switch>
         {authenticatedRoutes.map(
            (route, index) =>
               route.path && (
                  <PrivateRoute
                     key={index}
                     path={route.path}
                     exact={isUndefined(route.exact) || route.exact}
                     component={route.page}
                     profiles={route.profiles}
                  />
               ),
         )}
         <Route
            path={firstSession ? '/naturalperson/add' : '/'}
            exact
            component={
               firstSession
                  ? () => <Redirect to="/naturalperson/add" />
                  : () => (
                       <Redirect
                          to={routes.find(r => r.mainPage)?.path || ''}
                       />
                    )
            }
         />
         <Redirect to="/not-found" />
      </Switch>
   );
};

// const FirstSession = () => {
//    const { login } = useSelector((state: RootState) => state.auth);
//    console.log(login);

//    const authenticatedRoutes = routes.filter
//       ? routes.filter(r => r.onlyAuthorized)
//       : [];
//    return (
//       <Switch>
//          {authenticatedRoutes.map(
//             (route, index) =>
//                route.path && (
//                   <Route
//                      path="/naturalPerson/add"
//                      exact
//                      component={() => (
//                         <Redirect
//                            to={routes.find(r => r.firstSession)?.path || ''}
//                         />
//                      )}
//                   />
//                ),
//          )}
//          <Redirect to="/not-found" />
//       </Switch>
//    );
// };

export default { Routes };
