import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { GetTermResponse, UploadTerms } from '../services/termsService';

interface InitialStateType {
   terms: UploadTerms;
   lastLgpdConsentTerm: GetTermResponse | null;
}

const INITIAL_STATE: InitialStateType = {
   terms: {} as UploadTerms,
   lastLgpdConsentTerm: null,
};

export const postTerm = createAction<UploadTerms>('POST_TERM');
export const getLastTerm = createAction<GetTermResponse>('GET_LAST_TERM');

export default createReducer(INITIAL_STATE, {
   [postTerm.type]: (state, action: PayloadAction<UploadTerms>) => {
      return {
         ...state,
         programs: action.payload,
      };
   },
   [getLastTerm.type]: (state, action: PayloadAction<GetTermResponse>) => {
      return {
         ...state,
         lastLgpdConsentTerm: action.payload,
      };
   },
});
