import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchInfrastructureCapacities } from '../services/infrastructureCapacitiesService';

interface InitialStateType {
   infrastructureCapacities: SearchInfrastructureCapacities;
}

const INITIAL_STATE: InitialStateType = {
   infrastructureCapacities: {
      data: [],
      success: true,
   }, // {} as SearchInfrastructureCapacities,
};

export const getInfrastructureCapacities =
   createAction<SearchInfrastructureCapacities>(
      'GET_INFRASTRUCTURE_CAPACITIES',
   );

export default createReducer(INITIAL_STATE, {
   [getInfrastructureCapacities.type]: (
      state,
      action: PayloadAction<SearchInfrastructureCapacities>,
   ) => {
      return {
         ...state,
         infrastructureCapacities: action.payload,
      };
   },
});
