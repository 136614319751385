import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { cleanLocalStorage, setToken } from 'helpers/Utils';
import jwtDecode from 'jwt-decode';
import { SearchPaginated } from 'services/authService';

export const setLogin = createAction<SearchPaginated>('SET_LOGIN');

export const setLoading = createAction<boolean>('SET_LOADING');

export const setFirstSession = createAction<boolean>('SET_FIRST_SESSION');

export const setTokenFirstSession = createAction<string>(
   'SET_TOKEN_FIRST_SESSION',
);

interface InitialStateType {
   login: SearchPaginated;
   token: string;
   loading: boolean;
   firstSession: boolean;
}

const INITIAL_STATE: InitialStateType = {
   login: {} as SearchPaginated,
   token: '',
   loading: true,
   firstSession: false,
};

export default createReducer(INITIAL_STATE, {
   [setLogin.type]: (state, action: PayloadAction<SearchPaginated>) => {
      const decodedToken = jwtDecode(action.payload.data.token.toString());
      if (action.payload.data) {
         setToken(action.payload.data.token.toString());
      } else {
         cleanLocalStorage();
      }

      return {
         ...state,
         login: decodedToken as SearchPaginated,
         loading: false,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
         loading: false,
      };
   },
   [setFirstSession.type]: state => {
      return {
         ...state,
         firstSession: true,
      };
   },
   [setTokenFirstSession.type]: (state, action: PayloadAction<string>) => {
      // console.log(`token: ${action.payload}`);

      return {
         ...state,
         token: action.payload,
      };
   },
});
