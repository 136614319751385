import { getPeopleServed } from '../actions/peopleServedAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchPeopleServed {
   data: any[];
   success: boolean;
}
export type PeopleServed = {
   id: string;
   name: string;
};

export const searchPeopleServed = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchPeopleServed>('/PeopleServed', {})
         .then(async ({ data: payload }) => {
            dispatch(getPeopleServed(payload));
         })
         .catch(console.log);
   };
};

export default { searchPeopleServed };
