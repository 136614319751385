/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import './styles.scss';
import { useEffect } from 'react';
import { Form, Input, SubmitButton, Radio } from 'formik-antd';
import { Button, Layout, Col, Row, Modal } from 'antd';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from 'config/store';
import { useSelector } from 'react-redux';
import {
   getHowDidKnow,
   getMaritalStatus,
   getTargetAudience,
   getMonthlyIncomeRange,
   getRegisteredCadUnico,
   getFinancialBenefit,
   getCurrentOccupation,
} from 'services/socprofilesService';
import { getSocprofile } from 'actions/socprofilesAction';
import Labels from '../../helpers/enum/Labels';
import { useAppDispatch } from '../../hooks/hooks';
import { searchClassificationEntities } from '../../services/entitesSelectService';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */

const { Content } = Layout;
export type Props = {
   placeholder: string;
   name: string;
};

type SocprofileModalProps = {
   isSocprofileModalVisible: boolean;
   setIsSocprofileModalVisible: Function;
};

export interface SearchEntities {
   data: Array<any>;
}

interface CheckboxOptions {
   id: string;
   name: string;
}

const AddSocProfiles = ({
   isSocprofileModalVisible,
   setIsSocprofileModalVisible,
}: SocprofileModalProps) => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const { id } = useParams<{ id?: any }>();
   const { socprofile } = useSelector((state: RootState) => state.socprofiles);
   useEffect(() => {
      dispatch(searchClassificationEntities());
      dispatch(getHowDidKnow());
      dispatch(getMaritalStatus());
      dispatch(getTargetAudience());
      dispatch(getMonthlyIncomeRange());
      dispatch(getRegisteredCadUnico());
      dispatch(getFinancialBenefit());
      dispatch(getCurrentOccupation());
   }, [dispatch]);
   const {
      currentOccupationOptionsList,
      financialBenefitOptionsList,
      registeredCadUnicoOptionsList,
      monthlyIncomeRangeOptionsList,
      howDidKnowOptionsList,
      maritalStatusOptionsList,
      targetAudienceOptionsList,
   } = useSelector((state: RootState) => state.socprofiles);
   const yesNoAny = [
      {
         name: 'yes',
         value: 1,
         label: t(Labels.SOCPROFILES_COMMON_OPTIONS_YES),
      },
      {
         name: 'no',
         value: 2,
         label: t(Labels.SOCPROFILES_COMMON_OPTIONS_NO),
      },
      {
         name: 'rather_not_answer',
         value: 3,
         label: t(Labels.SOCPROFILES_COMMON_OPTIONS_RATHER_NOT_ANSWER),
      },
   ];

   const targetAudience1 = targetAudienceOptionsList
      ?.filter((e: any) => {
         return e.id < 11;
      })
      .map((e: any) => {
         return {
            id: e.id,
            label: e.name,
         };
      });

   const targetAudience2 = targetAudienceOptionsList
      ?.filter((e: any) => {
         return e.id >= 11;
      })
      .map((e: any) => {
         return {
            id: e.id,
            label: e.name,
         };
      });

   const financialBenefits = financialBenefitOptionsList.map(
      (e: CheckboxOptions) => {
         return {
            value: Number(e.id),
            label: e.name,
         };
      },
   );

   const howDidKnow = howDidKnowOptionsList
      ?.map((e: any) => {
         return {
            id: e.id,
            name: e.name,
         };
      });

   const haveChildren = [
      {
         name: 'yes',
         value: 1,
         label: Labels.SOCPROFILES_CHILDREN_OPTIONS_YES_HOW_MANY,
      },
      {
         name: 'no',
         value: 2,
         label: Labels.SOCPROFILES_CHILDREN_OPTIONS_ANY_CHILD,
      },
      {
         name: 'no_knowledge',
         value: 3,
         label: Labels.SOCPROFILES_CHILDREN_OPTIONS_NO_KNOWLEDGE,
      },
   ];

   const schema = Yup.object({
      idHowDidKnow: Yup.number().required().min(1, t(Labels.COMMON_REQUIRED)),
      descriptionHowDidKnow: Yup.mixed().when('idHowDidKnow', {
         is: 6,
         then: Yup.mixed().required(t(Labels.COMMON_REQUIRED)),
      }),
      idMaritalStatus: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      idMonthlyIncomeRange: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      idRegisteredCadUnico: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      idFinancialBenefit: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      idCurrentOccupation: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      descriptionCurrentOccupation: Yup.string().when('idCurrentOccupation', {
         is: 15,
         then: Yup.string().required(t(Labels.COMMON_REQUIRED)),
      }),
      haveChildren: Yup.number().required().min(1, t(Labels.COMMON_REQUIRED)),
      numberChildren: Yup.number().when('haveChildren', {
         is: 1,
         then: Yup.number()
            .min(1, t(Labels.SOCPROFILES_ADD_NO_0_VALIDATION))
            .required(),
      }),
      numberPeopleLive: Yup.number()
         .required(t(Labels.COMMON_REQUIRED))
         .min(1, t(Labels.SOCPROFILES_ADD_NO_0_VALIDATION)),
      numberPeopleWork: Yup.number()
         .typeError(t(Labels.COMMON_REQUIRED))
         .required(t(Labels.COMMON_REQUIRED)),
      propertyOwner: Yup.boolean().required(t(Labels.COMMON_REQUIRED)),
      egressPrisonSystem: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      egressCasaFoundation: Yup.number()
         .required()
         .min(1, t(Labels.COMMON_REQUIRED)),
      idsTargetAudiences: Yup.array()
         .min(1, t(Labels.COMMON_REQUIRED))
         .required(t(Labels.COMMON_REQUIRED)),
      descriptionTargetAudiences: Yup.mixed().when('idsTargetAudiences', {
         is: (idsTargetAudiences: any) => {
            if (idsTargetAudiences.indexOf('18') !== -1) {
               return true;
            }
            return false;
         },
         then: Yup.mixed().required(),
      }),
   });

   function warning() {
      Modal.warning({
         okButtonProps: {
            style: { backgroundColor: '#faad14', borderColor: "rgba(0,0,0,0)" },
         },
         title: 'Atenção!',
         content: 'Para salvar o formulário é necessário, também, salvar o cadastro.',
      });
   }
   return (
      <Modal
         className="socprofiles-modal"
         centered
         visible={isSocprofileModalVisible}
         title={
            id
               ? t(Labels.SOCPROFILES_EDIT_NAME)
               : t(Labels.SOCPROFILES_ADD_NAME)
         }
         onCancel={() => {
            setIsSocprofileModalVisible(false);
         }}
         footer={null}
      >
         <div className="socprofiles-search-container">
            <Layout className="site-layout">
               <div className="subtitle">{t(Labels.COMMON_REQUIRED_TITLE)}</div>
               <Content className="site-layout-background">
                  <Formik
                     // eslint-disable-next-line
                     onSubmit={(values: any) => {
                        const apiObject = {
                           idHowDidKnow: values.idHowDidKnow,
                           idMaritalStatus: values.idMaritalStatus,
                           idMonthlyIncomeRange: values.idMonthlyIncomeRange,
                           idRegisteredCadUnico: values.idRegisteredCadUnico,
                           idFinancialBenefit: Number(
                              values.idFinancialBenefit,
                           ),
                           idCurrentOccupation: values.idCurrentOccupation,
                           haveChildren:
                              values.haveChildren === 1
                                 ? true
                                 : values.haveChildren === 2
                                    ? false
                                    : null,
                           numberChildren: values.numberChildren,
                           numberPeopleLive: values.numberPeopleLive,
                           numberPeopleWork: values.numberPeopleWork,
                           propertyOwner: values.propertyOwner,
                           egressPrisonSystem:
                              values.egressPrisonSystem === 1
                                 ? true
                                 : values.egressPrisonSystem === 2
                                    ? false
                                    : null,
                           egressCasaFoundation:
                              values.egressCasaFoundation === 1
                                 ? true
                                 : values.egressCasaFoundation === 2
                                    ? false
                                    : null,
                           descriptionHowDidKnow: values.descriptionHowDidKnow,
                           descriptionCurrentOccupation:
                              values.descriptionCurrentOccupation,
                           idsTargetAudiences: values.idsTargetAudiences,
                           descriptionTargetAudiences:
                              values.descriptionTargetAudiences,
                        };
                        dispatch(getSocprofile(apiObject));
                        setIsSocprofileModalVisible(false);
                        warning()
                     }}
                     initialValues={{
                        ...socprofile,
                        egressPrisonSystem:
                           socprofile.egressPrisonSystem === undefined
                              ? 0
                              : socprofile.egressPrisonSystem === null
                                 ? 3
                                 : socprofile.egressPrisonSystem === true
                                    ? 1
                                    : 2,
                        egressCasaFoundation:
                           socprofile.egressCasaFoundation === undefined
                              ? 0
                              : socprofile.egressCasaFoundation === null
                                 ? 3
                                 : socprofile.egressCasaFoundation === true
                                    ? 1
                                    : 2,
                        haveChildren:
                           socprofile.haveChildren === undefined
                              ? 0
                              : socprofile.haveChildren === null
                                 ? 3
                                 : socprofile.haveChildren === true
                                    ? 1
                                    : 2,
                     }}
                     enableReinitialize
                     validateOnBlur
                     validationSchema={schema}
                     render={({ errors, touched, setFieldTouched }) => {
                        return (
                           <Form style={{ width: '100%' }}>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_BY_WHOM_PIP)}
                                 </div>
                                 {errors.idHowDidKnow &&
                                    touched.idHowDidKnow && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idHowDidKnow}
                                       </div>
                                    )}
                                 <Radio.Group name="idHowDidKnow">
                                    {howDidKnow?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (
                                             howDidKnow.length -
                                             1 ===
                                             i
                                          ) {
                                             return (
                                                <div>
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"
                                                      onChange={e => {
                                                         if (e.target.checked) {
                                                            setFieldTouched(
                                                               'descriptionHowDidKnow',
                                                               true,
                                                            );
                                                         }
                                                      }}
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                   <Input
                                                      name="descriptionHowDidKnow"
                                                      className="input-checkbox"
                                                      maxLength={150}
                                                   />
                                                   <div style={{ color: 'red' }}>
                                                      {errors.descriptionHowDidKnow}
                                                   </div>
                                                </div>
                                             );
                                          }

                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.descriptionHowDidKnow &&
                                    touched.descriptionHowDidKnow && (
                                       <div style={{ color: 'red' }}>
                                          {errors?.descriptionHowDidKnow}
                                       </div>
                                    )}
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_MARITAL_STATUS)}
                                 </div>
                                 {errors.idMaritalStatus &&
                                    touched.idMaritalStatus && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idMaritalStatus}
                                       </div>
                                    )}
                                 <Radio.Group name="idMaritalStatus">
                                    {maritalStatusOptionsList?.map(
                                       (radio: {
                                          id: string;
                                          name: string;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_CHILDREN)}
                                 </div>
                                 {errors.haveChildren &&
                                    touched.haveChildren && (
                                       <div style={{ color: 'red' }}>
                                          {errors.haveChildren}
                                       </div>
                                    )}
                                 {errors.numberChildren &&
                                    touched.numberChildren && (
                                       <div style={{ color: 'red' }}>
                                          {errors.numberChildren}
                                       </div>
                                    )}
                                 <div className="other-option-radio">
                                    <Radio.Group name="haveChildren">
                                       {haveChildren?.map(
                                          (
                                             radio: {
                                                name: string;
                                                value: number;
                                                label: string;
                                             },
                                             i: number,
                                          ) => {
                                             if (i === 0) {
                                                return (
                                                   <div>
                                                      <Radio
                                                         name={radio.name}
                                                         value={radio.value}
                                                         className="other-option-radio"
                                                         onChange={e => {
                                                            if (e.target.checked) {
                                                               setFieldTouched(
                                                                  'numberChildren',
                                                                  true,
                                                               );
                                                            }
                                                         }}
                                                      >
                                                         {t(radio.label)}
                                                      </Radio>
                                                      <Field
                                                         type="number"
                                                         name="numberChildren"
                                                         className="input-checkbox"
                                                         min={0}
                                                      />
                                                   </div>
                                                );
                                             }

                                             return (
                                                <Radio
                                                   name={radio.name}
                                                   value={radio.value}
                                                   className="other-option-radio"
                                                >
                                                   {t(radio.label)}
                                                </Radio>
                                             );
                                          },
                                       )}
                                    </Radio.Group>
                                 </div>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_RESIDENTS)}
                                 </div>
                                 {errors.numberPeopleLive &&
                                    touched.numberPeopleLive && (
                                       <div style={{ color: 'red' }}>
                                          {errors.numberPeopleLive}
                                       </div>
                                    )}
                                 <Field
                                    type="number"
                                    name="numberPeopleLive"
                                    min="0"
                                 />
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_PROPERTY_OWNER)}
                                 </div>
                                 {errors.propertyOwner &&
                                    touched.propertyOwner && (
                                       <div style={{ color: 'red' }}>
                                          {t(Labels.COMMON_REQUIRED)}
                                       </div>
                                    )}
                                 <Radio.Group name="propertyOwner">
                                    <Radio name="yes" value>
                                       {t(Labels.COMMON_YES)}
                                    </Radio>
                                    <Radio name="no" value={false}>
                                       {t(Labels.COMMON_NO)}
                                    </Radio>
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_PRISON_EGRESS)}
                                 </div>
                                 {errors.egressPrisonSystem &&
                                    touched.egressPrisonSystem && (
                                       <div style={{ color: 'red' }}>
                                          {errors.egressPrisonSystem}
                                       </div>
                                    )}
                                 <Radio.Group name="egressPrisonSystem">
                                    {yesNoAny?.map(
                                       (radio: {
                                          name: string;
                                          label: string;
                                          value: number;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.value}
                                             >
                                                {radio.label}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(
                                       Labels.SOCPROFILES_ADD_INSTITUTION_OWNER,
                                    )}
                                 </div>
                                 {errors.egressCasaFoundation &&
                                    touched.egressCasaFoundation && (
                                       <div style={{ color: 'red' }}>
                                          {errors.egressCasaFoundation}
                                       </div>
                                    )}
                                 <Radio.Group name="egressCasaFoundation">
                                    {yesNoAny?.map(
                                       (radio: {
                                          name: string;
                                          label: string;
                                          value: number;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.value}
                                             >
                                                {radio.label}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <div>
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_PUBLIC_TYPES)}
                                 </div>
                                 {errors.idsTargetAudiences &&
                                    touched.idsTargetAudiences && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idsTargetAudiences}
                                       </div>
                                    )}
                                 <Row className="row">
                                    <Col className="col" span={12}>
                                       {targetAudience1.map(
                                          (e: {
                                             id: number;
                                             label: string;
                                          }) => {
                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div className="target-audience-checkbox-container">
                                                   <label className="checkbox-label">
                                                      <Field
                                                         name="idsTargetAudiences"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                </div>
                                             );
                                          },
                                       )}
                                    </Col>
                                    <Col className="col" span={10}>
                                       {targetAudience2.map(
                                          (
                                             e: {
                                                id: number;
                                                label: string;
                                             },
                                             i: number,
                                          ) => {
                                             if (
                                                targetAudience2.length - 1 ===
                                                i
                                             ) {
                                                return (
                                                   <div
                                                      key={i}
                                                      className="target-audience-checkbox-container"
                                                   >
                                                      <div className="other-option-checkbox">
                                                         <label className="checkbox-label">
                                                            <Field
                                                               name="idsTargetAudiences"
                                                               type="checkbox"
                                                               value={`${e.id}`}
                                                            />
                                                            {t(e.label)}
                                                         </label>
                                                         <Field
                                                            name="descriptionTargetAudiences"
                                                            className="input-checkbox"
                                                            type="text"
                                                         />
                                                      </div>
                                                      <div style={{ color: 'red' }}>
                                                         {errors.descriptionTargetAudiences}
                                                      </div>
                                                   </div>
                                                );
                                             }

                                             return (
                                                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                                                <div
                                                   key={i}
                                                   className="target-audience-checkbox-container"
                                                >
                                                   <label className="checkbox-label">
                                                      <Field
                                                         name="idsTargetAudiences"
                                                         type="checkbox"
                                                         value={`${e.id}`}
                                                      />
                                                      {t(e.label)}
                                                   </label>
                                                </div>
                                             );
                                          },
                                       )}
                                    </Col>
                                 </Row>
                              </div>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_HOME_WORKERS)}
                                 </div>
                                 {errors.numberPeopleWork &&
                                    touched.numberPeopleWork && (
                                       <div style={{ color: 'red' }}>
                                          {errors.numberPeopleWork}
                                       </div>
                                    )}
                                 <Field
                                    type="number"
                                    name="numberPeopleWork"
                                    min="0"
                                 />
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_FAMILY_INCOME)}
                                 </div>
                                 {errors.idMonthlyIncomeRange &&
                                    touched.idMonthlyIncomeRange && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idMonthlyIncomeRange}
                                       </div>
                                    )}
                                 <Radio.Group name="idMonthlyIncomeRange">
                                    {monthlyIncomeRangeOptionsList?.map(
                                       (radio: {
                                          id: string;
                                          name: string;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_CADUNICO)}
                                 </div>
                                 {errors.idRegisteredCadUnico &&
                                    touched.idRegisteredCadUnico && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idRegisteredCadUnico}
                                       </div>
                                    )}
                                 <Radio.Group name="idRegisteredCadUnico">
                                    {registeredCadUnicoOptionsList?.map(
                                       (radio: {
                                          id: string;
                                          name: string;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(
                                       Labels.SOCPROFILES_ADD_FINANCIAL_BENEFIT,
                                    )}
                                 </div>
                                 {errors.idFinancialBenefit &&
                                    touched.idFinancialBenefit && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idFinancialBenefit}
                                       </div>
                                    )}
                                 <Radio.Group name="idFinancialBenefit">
                                    {financialBenefits?.map(
                                       (radio: {
                                          label: string;
                                          value: number;
                                       }) => {
                                          return (
                                             <Radio
                                                name={radio.label}
                                                value={radio.value}
                                                className="other-option-radio"
                                             >
                                                {radio.label}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                              </Row>
                              <Row className="row-vertical">
                                 <div className="title-questions">
                                    {t(Labels.SOCPROFILES_ADD_OCCUPATION)}
                                 </div>
                                 {errors.idCurrentOccupation &&
                                    touched.idCurrentOccupation && (
                                       <div style={{ color: 'red' }}>
                                          {errors.idCurrentOccupation}
                                       </div>
                                    )}
                                 <Radio.Group name="idCurrentOccupation">
                                    {currentOccupationOptionsList?.map(
                                       (
                                          radio: {
                                             id: string;
                                             name: string;
                                          },
                                          i: number,
                                       ) => {
                                          if (
                                             currentOccupationOptionsList.length -
                                             1 ===
                                             i
                                          ) {
                                             return (
                                                <div>
                                                   <Radio
                                                      name={radio.name}
                                                      value={radio.id}
                                                      className="other-option-radio"
                                                      onChange={e => {
                                                         if (e.target.checked) {
                                                            setFieldTouched(
                                                               'descriptionCurrentOccupation',
                                                               true,
                                                            );
                                                         }
                                                      }}
                                                   >
                                                      {radio.name}
                                                   </Radio>
                                                   <Input
                                                      name="descriptionCurrentOccupation"
                                                      className="input-checkbox"
                                                      maxLength={150}
                                                   />
                                                </div>
                                             );
                                          }

                                          return (
                                             <Radio
                                                name={radio.name}
                                                value={radio.id}
                                                className="other-option-radio"
                                             >
                                                {radio.name}
                                             </Radio>
                                          );
                                       },
                                    )}
                                 </Radio.Group>
                                 {errors.descriptionCurrentOccupation &&
                                    touched.descriptionCurrentOccupation && (
                                       <div className="errorText" style={{ color: 'red' }}>
                                          {errors?.descriptionCurrentOccupation}
                                       </div>
                                    )}
                              </Row>
                              <Row>
                                 <Button.Group>
                                    <Button
                                       onClick={() => {
                                          setIsSocprofileModalVisible(false);
                                       }}
                                       className="button-goback"
                                       type="primary"
                                    >
                                       {t(Labels.COMMON_BUTTON_BACK)}
                                    </Button>
                                    <SubmitButton
                                       loading={false}
                                       className="default-button"
                                    >
                                       {t(Labels.COMMON_BUTTON_OK)}
                                    </SubmitButton>
                                 </Button.Group>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               </Content>
            </Layout>
         </div>
      </Modal>
   );
};

export default AddSocProfiles;
