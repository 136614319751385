import { ReactNode } from 'react';

interface FieldProps {
   title: string;
   children: ReactNode;
}

export const Fieldset = (props: FieldProps) => {
   const { title, children } = props;

   return (
      <div className="field-set">
         <div className="field-set-title-div">
            <div className="field-set-title">{title}</div>
         </div>
         <div className="field-set-children">{children}</div>
      </div>
   );
};

export default Fieldset;
