import { useState } from 'react';
import { Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './Menu.scss';
import { useTranslation } from 'react-i18next';
import usePermission from 'hooks/usePermission';
import { menus } from 'config/menuPaths';
import { isUndefined } from 'lodash';
import Labels from '../../../helpers/enum/Labels';

const { SubMenu } = Menu;
interface MenuComponentSmallProps {
   open: Function;
   status?: boolean;
}
export const MenuComponentSmall = (props: MenuComponentSmallProps) => {
   const { status, open } = props;
   const [collapsed, setCollapsed] = useState(true);
   const { t } = useTranslation();
   const { hasPermission } = usePermission();

   const verifyPermissionMenu = (profiles: string[]) =>
      hasPermission(profiles) ? '' : 'notPermission';

   const toggleCollapsed = () => {
      setCollapsed(!collapsed);
   };

   return (
      <Menu
         defaultSelectedKeys={['1']}
         defaultOpenKeys={['sub1']}
         mode="inline"
         theme="dark"
         inlineCollapsed={isUndefined(status) ? collapsed : !status}
         className="menu-color"
      >
         <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="/" role="link" onClick={() => open()}>
               {t(Labels.MENU_HOME)}
            </Link>
         </Menu.Item>

         {menus.map(route => {
            return route.subRoutes ? (
               <SubMenu
                  key={route.id}
                  icon={route.icon()}
                  title={t(route.title())}
                  className={`${verifyPermissionMenu(route.profiles)}`}
                  onTitleClick={toggleCollapsed}
               >
                  {route.subRoutes?.map(subMenu => (
                     <Link
                        to={subMenu.path}
                        role="link"
                        className="ant-menu-item ant-menu-item-only-child"
                        onClick={() => open()}
                     >
                        <Menu.Item key={subMenu.id}>
                           {t(subMenu.label())}
                        </Menu.Item>
                     </Link>
                  ))}
               </SubMenu>
            ) : (
               <Menu.Item
                  key={route.id}
                  icon={route.icon()}
                  className={`${verifyPermissionMenu(
                     route.profiles,
                  )} ant-menu-submenu menu-item-margin`}
               >
                  <Link
                     to={route.path ? route.path : '/'}
                     role="link"
                     onClick={() => open()}
                  >
                     {t(route.route[0].label())}
                  </Link>
               </Menu.Item>
            );
         })}
      </Menu>
      // </Sider>
   );
};
