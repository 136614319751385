import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchTypePrograms } from '../services/typeProgramsService';

interface InitialStateType {
   typePrograms: SearchTypePrograms;
}

const INITIAL_STATE: InitialStateType = {
   typePrograms: {
      data: [
         { id: 1, categoryDesc: 'Dependente' },
         { id: 2, categoryDesc: 'Avulso' },
      ],
      success: true,
   }, // {} as SearchTypePrograms,
};

export const getTypePrograms =
   createAction<SearchTypePrograms>('GET_TYPE_PROGRAMS');

export default createReducer(INITIAL_STATE, {
   [getTypePrograms.type]: (
      state,
      action: PayloadAction<SearchTypePrograms>,
   ) => {
      return {
         ...state,
         typePrograms: action.payload,
      };
   },
});
