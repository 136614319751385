import {
  SubmitButton,
  Form,
  FormItem,
  Input,
  Select,
  DatePicker,
} from 'formik-antd';
import {
  Table,
  Button,
  Row,
  Col,
  Layout,
  PageHeader,
  Typography,
  Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import './styles.scss';
import { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import InputText from 'components/common/input';
import DropDown from 'components/common/dropdownEntity';
import {
  downloadRelatory,
  getProductsList,
  searchRelatory,
  downloadCertificate,
} from 'services/relatoryService';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Utils from 'helpers/Utils';
import Labels from '../../helpers/enum/Labels';
import { RootState } from '../../config/store';
import { useAppDispatch } from '../../hooks/hooks';
import MotiveModal from './motiveModal';

const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

interface Table {
  entityName: string;
  programName: string;
  productName: string;
  personName: string;
  statusDescription: string;
}

interface FormErrors {
  cpf: null | string;
}

const Relatory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    relatories,
    relatoryCsv,
    loading,
    loadingCSV,
    productsOptionsList,
    isLoading,
    downLoading,
  } = useSelector((state: RootState) => state.relatory);
  const [isMotiveModalVisible, setIsMotiveModalVisible] = useState(false);

  const [inscriptionId, setInscriptionId] = useState<string>();
  const [modalRecord, setModalRecord] = useState();
  const [searchValues, setSearchValues] = useState({
    entityName: '',
    personCPF: '',
    programName: '',
    classId: null,
    productId: null,
    initialDate: '',
    endDate: '',
    isKit: false,
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({ cpf: null });

  const modalInscriptionId = (id: string) => {
    setInscriptionId(id);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalRecordSet = (item: any) => {
    setModalRecord(item);
  };

  const columns: ColumnsType<Table> = [
    {
      title: t(Labels.ENTITY_NAME),
      dataIndex: 'entityName',
      key: 'entityName',
    },
    {
      title: 'Programa',
      dataIndex: 'programName',
      key: 'programName',
    },
    {
      title: 'Produto/Kit',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Local',
      dataIndex: 'productPlace',
      key: 'productPlace',
    },
    {
      title: 'Kit',
      dataIndex: 'idExecutionKit',
      key: 'idExecutionKit',
      render: idExecutionKit => {
        const type = idExecutionKit || '-';
        return `${type}`;
      },
    },
    {
      title: 'Turma',
      dataIndex: 'productId',
      key: 'productId',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (productId, record: any) => {
        const idToShow = record.classId || productId;
        return `${idToShow}`;
      },
    },
    {
      title: 'Participantes',
      dataIndex: 'personName',
      key: 'personName',
    },
    {
      title: 'Data',
      dataIndex: ['initialDate', 'endDate'],
      key: 'date',
      width: '250px',
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (initialDate: string, record: any) => {
        return `${moment(record.initialDate).format('DD-MM-YYYY')} à ${moment(
          record.endDate,
        ).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Status',
      dataIndex: 'statusDescription',
      key: 'statusDescription',
    },
    {
      title: 'Presença',
      dataIndex: 'presence',
      key: 'presence',
    },
    {
      title: 'Certificado',
      dataIndex: 'classId', // 'productId',
      key: 'classId', // 'productId',
      align: 'center',
      render: (raeInscriptionId: string, record: any) => {
        return (
          <Button
            className="motive-button"
            onClick={() => {
              dispatch(
                downloadCertificate(
                  record.raeInscriptionId,
                  record.classId,
                  record.isKit,
                  record.idExecutionKit,
                  record.idAuth,
                ),
              );
            }}
            disabled={record.statusId !== 2}
          >
            {downLoading === record.raeInscriptionId ? (
              <>
                {/* <div className="csv-loading-warning">
                  Isso pode levar alguns minutos
                </div> */}
                <Spin />
              </>
            ) : (
              <>
                <DownloadOutlined />
              </>
            )}
          </Button>
        );
        return '';
      },
    },
    {
      title: 'Motivo da desistência',
      dataIndex: ['statusDescription', 'inscriptionId', 'statusId'],
      key: 'statusDescription',
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (statusDescription: string, record: any) => {
        return (
          <Button
            className="motive-button"
            onClick={() => {
              setIsMotiveModalVisible(true);
              modalInscriptionId(record.inscriptionId);
              modalRecordSet(record);
            }}
            // eslint-disable-next-line react/destructuring-assignment
            disabled={record.statusId !== 3 && record.statusId !== 5}
          >
            Motivo
          </Button>
        );
      },
    },
  ];

  const handleChangePage = (value: number) => {
    dispatch(
      searchRelatory(
        searchValues.entityName,
        searchValues.personCPF,
        searchValues.programName,
        searchValues.classId,
        searchValues.productId,
        searchValues.initialDate,
        searchValues.endDate,
        searchValues.isKit,
        value,
        relatories.pageSize,
      ),
    );
  };

  useEffect(() => {
    dispatch(getProductsList());
  }, [dispatch]);

  const list = relatories.data?.map(relatory => {
    return relatory;
  });

  return (
    <div className="relatory-container">
      <Layout className="site-layout">
        <div>
          <PageHeader title="Relatório/Monitoramento" />
          <h4>Filtre sua pesquisa para monitorar os dados:</h4>
        </div>
        <Content className="site-layout-background">
          <Formik
            initialValues={{
              entityName: '',
              personCPF: '',
              programName: '',
              classId: null,
              productId: null,
              initialDate: '',
              endDate: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              let isKit = false;
              // eslint-disable-next-line
              productsOptionsList.forEach((p: any) => {
                if (p.id === values.productId) {
                  isKit = p.isKit;
                }
              });
              setSubmitting(true);
              dispatch(
                searchRelatory(
                  values.entityName,
                  values.personCPF,
                  values.programName,
                  values.classId,
                  values.productId,
                  values.initialDate,
                  values.endDate,
                  isKit,
                ),
              );
              setSearchValues({
                entityName: values.entityName,
                personCPF: values.personCPF,
                programName: values.programName,
                classId: values.classId,
                productId: values.productId,
                initialDate: values.initialDate,
                endDate: values.endDate,
                isKit,
              });

              setSubmitting(false);
            }}
          >
            {({ setFieldValue, values }) => {
              return (
                <div>
                  <Form
                    data-testid="programs-list-form"
                    className="form-relatory"
                  >
                    <Row className="">
                      <Col span={24} md={4}>
                        <FormItem
                          name="entityName"
                          label=""
                          required
                          className="default-field-container title-search"
                        >
                          <InputText
                            name="entityName"
                            title={t(Labels.ENTITY_NAME)}
                            placeholder="Digite o nome da entidade"
                            className="default-field"
                            data-testid="name"
                            role="textbox"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24} md={4}>
                        <FormItem
                          name="personCPF"
                          label=""
                          required
                          className="default-field-container title-search"
                        >
                          <div className="title">Participante (CPF)</div>
                          <InputMask
                            alwaysShowMask={false}
                            mask="999.999.999-99"
                            onChange={e => {
                              const { value } = e.target || '';
                              const { name } = e.target;
                              Utils.validateCPFandCNPJSearchSize(
                                e.target.value,
                                'cpf',
                                formErrors,
                                setFormErrors,
                              );
                              setFieldValue(name, value);
                            }}
                          >
                            {() => (
                              <Input
                                name="personCPF"
                                title="cpf"
                                placeholder="Digite o CPF"
                                className="default-field"
                                data-testid="cpf"
                                role="textbox"
                              />
                            )}
                          </InputMask>
                          <div className="form-error-message">
                            {formErrors.cpf}
                          </div>
                        </FormItem>
                      </Col>
                      <Col span={24} md={4}>
                        <FormItem
                          name="programName"
                          label=""
                          required
                          className="default-field-container title-search"
                        >
                          <InputText
                            name="programName"
                            title="Programa"
                            placeholder="Digite o nome do programa"
                            className="default-field"
                            data-testid="name"
                            role="textbox"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24} md={4}>
                        <FormItem
                          name="classId"
                          label=""
                          required
                          className="default-field-container title-search"
                        >
                          <InputText
                            name="classId"
                            title="Turma/kit"
                            placeholder="Digite o numero da turma"
                            className="default-field"
                            data-testid="name"
                            role="textbox"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col span={24} md={4} className="product-input">
                        <div className="title">Produto</div>
                        <DropDown
                          // TODO modificar name
                          name="productId"
                          placeholder="Selecione um produto"
                          disabled={false}
                          // onSearch={handleProductsSearch}
                          loading={isLoading}
                          filterOption={(
                            inputValue: string,
                            option: {
                              key: number | null;
                              value: number | string;
                              children: string;
                            },
                          ) => {
                            return option.children
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          allowClear
                          notFoundContent={
                            isLoading ? <Spin /> : 'Nenhum dado encontrado'
                          }
                        >
                          {productsOptionsList?.map(
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (products: any) => {
                              return (
                                <Option value={products.id}>
                                  {products.name}
                                </Option>
                              );
                            },
                          )}
                        </DropDown>
                      </Col>
                      <Col
                        span={24}
                        md={3}
                        className="date-picker product-input"
                      >
                        <div className="title">Data Inicial</div>
                        <DatePicker
                          name="initialDate"
                          format="DD/MM/YYYY"
                          placeholder="Selecione uma data"
                          style={{
                            height: 45,
                            fontSize: 20,
                          }}
                        />
                      </Col>
                      <Col span={24} md={3} className="product-input">
                        <div className="title">Data Final</div>
                        <DatePicker
                          name="endDate"
                          format="DD/MM/YYYY"
                          placeholder="Selecione uma data"
                          style={{
                            height: 45,
                            fontSize: 20,
                          }}
                        />
                      </Col>
                      <Col span={24} md={4}>
                        <Button.Group>
                          <SubmitButton
                            data-testid="submitButton"
                            icon={<SearchOutlined />}
                            disabled={formErrors.cpf !== null}
                          >
                            {t(Labels.BUTTON)}
                          </SubmitButton>
                        </Button.Group>
                      </Col>
                    </Row>
                  </Form>
                  <div className="table-full">
                    <div className="download-content">
                      <Title level={4}>Resultados da pesquisa:</Title>
                      {list ? (
                        <CSVLink
                          className="download-button"
                          id="download-button"
                          data={relatoryCsv}
                          target="_blank"
                          filename="Relatório de Acompanhamento de Turmas"
                          asyncOnClick
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onClick={(e: any) => {
                            // eslint-disable-next-line no-unused-expressions
                            relatoryCsv
                              ? ''
                              : dispatch(
                                  downloadRelatory(
                                    values.entityName,
                                    values.personCPF,
                                    values.programName,
                                    values.productId,
                                    values.initialDate,
                                    values.endDate,
                                    values.classId,
                                  ),
                                );
                            // eslint-disable-next-line no-unused-expressions
                            relatoryCsv ? '' : e.preventDefault();
                          }}
                        >
                          {loadingCSV ? (
                            <>
                              <div className="csv-loading-warning">
                                Isso pode levar alguns minutos
                              </div>
                              <Spin />
                            </>
                          ) : (
                            <>
                              <DownloadOutlined /> <p>Exportar CSV</p>
                            </>
                          )}
                        </CSVLink>
                      ) : (
                        <CSVLink
                          className="download-button download-button-disabled"
                          data={relatoryCsv}
                          target="_blank"
                          filename="Relatório de Acompanhamento de Turmas"
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onClick={(e: any) => e.preventDefault()}
                        >
                          <>
                            <DownloadOutlined /> <p>Exportar CSV</p>
                          </>
                        </CSVLink>
                      )}
                    </div>
                    <Table
                      dataSource={list}
                      columns={columns}
                      className="default-table"
                      scroll={{ x: 'max-content' }}
                      locale={
                        loading
                          ? { emptyText: t(Labels.LOADING) }
                          : { emptyText: t(Labels.NO_DATA) }
                      }
                      pagination={{
                        current: relatories?.pageNumber,
                        onChange: handleChangePage,
                        pageSize: relatories?.pageSize,
                        total: relatories?.totalRecords,
                        showSizeChanger: false,
                      }}
                      loading={loading}
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </Content>
      </Layout>
      <MotiveModal
        isMotiveModalVisible={isMotiveModalVisible}
        setIsMotiveModalVisible={setIsMotiveModalVisible}
        classInscriptionId={inscriptionId}
        modalRecord={modalRecord}
      />
    </div>
  );
};

export default Relatory;
