import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchServiceProfile } from '../services/serviceProfileService';

interface InitialStateType {
   serviceProfile: SearchServiceProfile;
}

const INITIAL_STATE: InitialStateType = {
   serviceProfile: {
      data: [],
      success: true,
   }, // {} as SearchServiceProfile,
};

export const getServiceProfile = createAction<SearchServiceProfile>(
   'GET_SERVICE_PROFILE',
);

export default createReducer(INITIAL_STATE, {
   [getServiceProfile.type]: (
      state,
      action: PayloadAction<SearchServiceProfile>,
   ) => {
      return {
         ...state,
         serviceProfile: action.payload,
      };
   },
});
