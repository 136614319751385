import { Select } from 'formik-antd';
import { ReactNode } from 'react';

interface FieldProps {
   name: string;
   placeholder: string;
   children: ReactNode;
   disabled: boolean;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onSearch?: any;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onChange?: any;
   loading?: boolean;
   mode?: 'multiple' | 'tags';
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   filterOption?: any;
   notShowSearch?: boolean;
   showArrow?: boolean;
   allowClear?: boolean;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   notFoundContent?: any;
}

export const DropDown = (props: FieldProps) => {
   const {
      name,
      placeholder,
      children,
      disabled,
      onSearch,
      onChange,
      loading,
      mode,
      filterOption = true,
      notShowSearch,
      showArrow,
      allowClear,
      notFoundContent,
   } = props;

   return (
      <Select
         name={name}
         className="select"
         placeholder={placeholder}
         size="large"
         // onPopupScroll={(e: any) => {
         //    const { target } = e;
         //    if (target.scrollTop + target.offsetHeight === target.scrollHeight)
         // }}
         // onSearch={handleSearch}
         showSearch={!notShowSearch}
         disabled={disabled}
         // eslint-disable-next-line @typescript-eslint/no-empty-function
         onSearch={onSearch}
         onChange={onChange}
         loading={loading}
         mode={mode}
         filterOption={filterOption}
         showArrow={showArrow}
         allowClear={allowClear}
         notFoundContent={notFoundContent}
      >
         {children}
      </Select>
   );
};

export default DropDown;
