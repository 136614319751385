import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Labels from '../../helpers/enum/Labels';
import './styles.scss';

const { Title } = Typography;

const Dashboard = () => {
   const { t } = useTranslation();
   return (
      <div className="dashboard-container">
         <Row className="banner">
            <Title level={2}>{t(Labels.HOME_TITLE)}</Title>
         </Row>
         <Row className="cards-home">
            <Col>
               <Card className="purple-card">
                  <Title level={4}>{t(Labels.HOME_H4)}</Title>
                  <Title level={4}>{t(Labels.HOME_H4_2)}</Title>
                  <Title level={4}>{t(Labels.HOME_H4_3)}</Title>
                  <Title level={4}>{t(Labels.HOME_H4_4)}</Title>
                  <Row justify="end">
                     <Title level={4}>{t(Labels.HOME_H4_5)}</Title>
                  </Row>
               </Card>
            </Col>
         </Row>
      </div>
   );
};
export default Dashboard;
