import { getTypeProducts } from '../actions/typeProductsAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchTypeProducts {
   data: any[];
   success: boolean;
}
export type TypeProduct = {
   id: string;
   name: string;
};

export const searchTypeProducts = () => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchTypeProducts>('/Products/Categories', {})
         .then(async ({ data: payload }) => {
            dispatch(getTypeProducts(payload));
         })
         .catch(console.log);
   };
};

export default { searchTypeProducts };
