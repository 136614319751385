import {
   getInscribeEntityList,
   setAdheredPrograms,
   setLoadingAdheredPrograms,
   setLoadingEntityList,
} from 'actions/inscribeAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';
import { ApiResponseAdheredProducts } from './programAdhesionService';

export interface Entity {
   id: string;
   corporateName: string;
   name: string;
   cnpj: string;
   userName: string | undefined | null;
}

export interface SearchEntitiesApiResponse {
   success: boolean;
   message: string;
   data: Entity[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

export const searchInscribeEntities = (
   entityPartialName: string,
   cnpj: string,
   page: number,
) => {
   const searchEntitiesParams = {
      name: entityPartialName,
      cnpj,
      pageNumber: page,
      pageSize: 10,
   };
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingEntityList(true));
      api.get<SearchEntitiesApiResponse>('/Entity', {
         params: searchEntitiesParams,
      })
         .then(async ({ data: payload }) => {
            dispatch(getInscribeEntityList(payload));
            dispatch(setLoadingEntityList(false));
         })
         .catch(error => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setLoadingEntityList(false));
         });
   };
};

export const getInscribeAdheredPrograms = (id: string | undefined) => {
   return async (dispatch: AppDispatch) => {
      dispatch(setLoadingAdheredPrograms(true));
      await api
         .get<ApiResponseAdheredProducts>(`Entity/${id}/AdheredPrograms`, {})
         .then(async ({ data: payload }) => {
            dispatch(setAdheredPrograms(payload.data));
            dispatch(setLoadingAdheredPrograms(false));
         })
         // eslint-disable-next-line
         .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
            dispatch(setAdheredPrograms([]));
            dispatch(setLoadingAdheredPrograms(false));
         });
   };
};
