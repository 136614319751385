import { Radio } from 'formik-antd';

interface FieldProps {
   name: string;
   defaultValue?: string | boolean;
   data: { value: boolean | null | string; label: string; name: string }[];
   vertical?: boolean;
   t?: Function;
   value?: boolean | number | null;
}

export const RadioButton = (props: FieldProps) => {
   const { name, defaultValue, data, vertical, t, value } = props;

   return (
      <Radio.Group
         name={name}
         defaultValue={defaultValue}
         style={{ display: 'flex', flexDirection: vertical ? 'column' : 'row' }}
         value={value}
      >
         {data.map(
            (radio: {
               value: boolean | null | string;
               label: string;
               name: string;
            }) => {
               return (
                  <Radio
                     name={radio.name}
                     value={radio.value}
                     style={{ marginBottom: vertical ? 10 : 0 }}
                  >
                     {t ? t(radio.label) : radio.label}
                  </Radio>
               );
            },
         )}
      </Radio.Group>
   );
};

export default RadioButton;
