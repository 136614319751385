import {
   getProfiles,
   setLoading,
   clearProfile,
   setProfile as responseProfile,
   getProfile as getProfile_,
} from 'actions/profilesAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ToEntitiesProps {
   name: string;
   cnpj: string;
}

interface SetProfileResponse {
   success: boolean;
   message: string;
   error: string;
}

interface GetProfileResponse {
   data: Profile;
   message: string;
   success: string;
}
export interface Profile {
   name: string;
   entity: EntitySelect[];
   cpf: string;
   cnpj: string;
   entities: string[];
   entityList: string[];
   entityText: string;
   profileId: number | null;
   toEntities: ToEntitiesProps[];
}

export interface EntitySelect {
   id: string;
   name: string;
}

export interface SearchPaginated {
   data: Profile[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}

export const searchProfiles = (
   name = '',
   cpf = '',
   entity = [],
   pageNumber = 1,
   pageSize = 5,
) => {
   return async (dispatch: AppDispatch) => {
      api.post<SearchPaginated>('/Users/GetUsers', {
         Name: name,
         Cpf: cpf,
         Entity: entity,
         PageNumber: pageNumber,
         PageSize: pageSize,
      })
         .then(async ({ data: payload }) => {
            dispatch(getProfiles(payload));
         })
         // eslint-disable-next-line
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const setProfile = async (
   data: Profile,
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   resetForm: Function = () => {},
) => {
   return async (dispatch: AppDispatch) => {
      await api
         .post<SetProfileResponse>('/Users', {
            name: data.name,
            cpf: data.cpf,
            profileId: data.profileId,
            entities: data.entities,
            toEntities: data.toEntities,
         })
         .then(async ({ data: payload }) => {
            dispatch(
               responseProfile({
                  success: payload.success,
                  message: payload.message,
               }),
            );
            resetForm();
         })
         .catch(async e => {
            dispatch(
               responseProfile({
                  success: e.response.data.success,
                  message: e.response.data.error[0],
               }),
            );
         });
   };
};

export const getProfile = (id: string) => {
   return async (dispatch: AppDispatch) => {
      api.get<GetProfileResponse>(`/Users/${id}`, {})
         .then(async ({ data: payload }) => {
            dispatch(getProfile_(payload.data));
         })
         // eslint-disable-next-line
         .catch(console.log);
   };
};

export const updateProfile = async (data: Profile, id: string) => {
   return async (dispatch: AppDispatch) => {
      await api
         .put<SetProfileResponse>(`/Users`, {
            id,
            name: data.name,
            cpf: data.cpf,
            profileId: data.profileId,
            entities: data.entities,
            toEntities: data.toEntities,
         })
         .then(async ({ data: payload }) => {
            dispatch(
               responseProfile({
                  success: payload.success,
                  message: payload.message,
               }),
            );
         })
         .catch(async e => {
            dispatch(
               responseProfile({
                  success: e.response.data.success,
                  message: e.response.data.error[0],
               }),
            );
         });
   };
};

export const clearProgramReduce = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearProfile());
   };
};

export default { searchProfiles };
