/* eslint-disable react/no-danger */
import { Button, Modal, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RootState } from 'config/store';
import { useDispatch, useSelector } from 'react-redux';
import { postInscriptionInClass } from 'services/classesService';
import './styles.scss';
import {
   setAreYouSureModal,
   setErrorModal,
   setPaymentWarningMessage,
} from 'actions/classesAction';
import { useHistory } from 'react-router-dom';

interface InscribeClassDetailsModalsProps {
   cpf: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   inscriptionData: any;
}

const InscribeClassDetailsModals = ({
   cpf,
   inscriptionData,
}: InscribeClassDetailsModalsProps) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const {
      loadingInscribeClass,
      modals,
      inscribeClassErrorMessage,
      reloadClassDetails,
   } = useSelector((state: RootState) => state.classes);
   const errorMsgHtml = { __html: inscribeClassErrorMessage || '' };

   return (
      <>
         <Modal
            className="are-you-sure-modal"
            centered
            visible={modals.areYouSure}
            footer={null}
            closable={false}
            style={{ width: '712px', height: '339px' }}
            confirmLoading={loadingInscribeClass}
         >
            <p>
               Tem certeza que deseja inscrever esta pessoa ao produto
               selecionado?
            </p>
            <Row justify="center">
               <Button
                  onClick={() => {
                     dispatch(
                        postInscriptionInClass(
                           cpf,
                           inscriptionData,
                           reloadClassDetails,
                        ),
                     );
                     // setAreYouSureModalVisible(false);
                  }}
               >
                  <Spin
                     indicator={
                        <LoadingOutlined
                           style={{ color: 'white', marginRight: 5 }}
                        />
                     }
                     spinning={loadingInscribeClass}
                  />
                  SIM
               </Button>
               <Button onClick={() => dispatch(setAreYouSureModal(false))}>
                  NÃO
               </Button>
            </Row>
         </Modal>
         <Modal
            className="document-pending-modal"
            centered
            visible={modals.errorMessage}
            footer={null}
            closable={false}
         >
            <h3>Não é Possível Prosseguir</h3>
            <p dangerouslySetInnerHTML={errorMsgHtml} />
            <Row justify="center">
               {inscribeClassErrorMessage?.includes(
                  'O participante selecionado não',
               ) && (
                  <Button
                     onClick={() => {
                        dispatch(setErrorModal(false));
                        history.push(
                           `/naturalperson/edit/${cpf}?redirect=true`,
                        );
                     }}
                  >
                     Preencher
                  </Button>
               )}
               <Button
                  onClick={() => {
                     dispatch(setErrorModal(false));
                  }}
               >
                  Cancelar
               </Button>
            </Row>
         </Modal>
         <Modal
            className="document-pending-modal"
            centered
            visible={!!modals.paymentWarningMessage}
            footer={null}
            closable={false}
         >
            <h3>Atenção</h3>
            <p>{modals.paymentWarningMessage}</p>
            <Row justify="center">
               <Button
                  className="warning-button"
                  onClick={() => {
                     dispatch(setPaymentWarningMessage(null));
                  }}
               >
                  Ok
               </Button>
            </Row>
         </Modal>
      </>
   );
};

export default InscribeClassDetailsModals;
