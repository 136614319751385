import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchEquipaments } from '../services/equipamentsService';

interface InitialStateType {
   equipaments: SearchEquipaments;
}

const INITIAL_STATE: InitialStateType = {
   equipaments: {
      data: [],
      success: true,
   }, // {} as SearchEquipaments,
};

export const getEquipaments =
   createAction<SearchEquipaments>('GET_EQUIPAMENTS');

export default createReducer(INITIAL_STATE, {
   [getEquipaments.type]: (state, action: PayloadAction<SearchEquipaments>) => {
      return {
         ...state,
         equipaments: action.payload,
      };
   },
});
