import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SearchEntities, SearchEntity } from '../services/entitiesService';

interface InitialStateType {
   entities: SearchEntities;
   entity: SearchEntity[];
   loading: boolean;
}

const INITIAL_STATE: InitialStateType = {
   entities: {} as SearchEntities,
   loading: true,
   entity: [
      {
         data: [],
      },
   ],
};

export const getEntities = createAction<SearchEntities>('GET_ENTITIES');

export const setLoading = createAction<boolean>('SET_LOADING');

export const getEntitiesByKit = createAction<SearchEntity>(
   'GET_ENTITIES_BY_KIT',
);
export const clearEntities = createAction('CLEAR_ENTITIES');

export default createReducer(INITIAL_STATE, {
   [getEntities.type]: (state, action: PayloadAction<SearchEntities>) => {
      return {
         ...state,
         entities: action.payload,
         loading: false,
      };
   },
   [getEntitiesByKit.type]: (state, action: PayloadAction<SearchEntities>) => {
      return {
         ...state,
         entity: [
            ...state.entity,
            {
               data: action.payload.data,
            },
         ],
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
         loading: true,
      };
   },
   [clearEntities.type]: state => {
      return {
         ...state,
         entity: INITIAL_STATE.entity,
      };
   },
});
