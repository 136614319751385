// Commons
const HELLO_WORLD = 'hello_world.label';
const BUTTON = 'common.button.search';
const NO_DATA = 'common.no_data';
const LOADING = 'common.loading';
const TYPE_TO_SEARCH = 'common.type_to_search';
const LOGIN = 'common.login';
const PASSWORD = 'common.password';
const COMMON_CPF = 'common.cpf';
const COMMON_CNPJ = 'common.cnpj';
const COMMON_PROFILE = 'common.profile';
const COMMON_ENTITY = 'common.entity';
const COMMON_NAME = 'common.name';
const COMMON_REQUIRED_TITLE = 'common.field_required_subtitle';
const COMMON_YES = 'common.yes';
const COMMON_NO = 'common.no';
const COMMON_STATUS = 'common.status';
const COMMON_ACTIVE = 'common.active';
const COMMON_INACTIVE = 'common.inactive';
const COMMON_BUTTON_BACK = 'common.button.back';
const COMMON_BUTTON_SAVE = 'common.button.save';
const COMMON_BUTTON_OK = 'common.button.ok';
const COMMON_BUTTON_CONTINUE = 'common.button.continue';
const COMMON_REQUIRED = 'common.field_required';
const COMMON_ADRESS_ZIPCODE = 'common.adress.zipcode';
const COMMON_PUBLIC_PLACE = 'common.adress.public_place';
const COMMON_ADRESS_ADRESS = 'common.adress.adress';
const COMMON_ADRESS_NUMBER = 'common.adress.number';
const COMMON_ADRESS_COMPLEMENT = 'common.adress.complement';
const COMMON_ADRESS_NEIGHBORHOOD = 'common.adress.neighborhood';
const COMMON_ADRESS_CITY = 'common.adress.city';
const COMMON_ADRESS_CITY_PLACEHOLDER = 'common.adress.city-placeholder';
const COMMON_ADRESS_STATE = 'common.adress.state';

const COMMON_CONTACT_PHONE = 'common.contact.phone';
const COMMON_CONTACT_MOBILE_PHONE1 = 'common.contact.mobile_phone1';
const COMMON_CONTACT_MOBILE_PHONE2 = 'common.contact.mobile_phone2';
const COMMON_CONTACT_EMAIL = 'common.contact.email';
const COMMON_CONTACT_NO_EMAIL = 'common.contact.no_email';
const COMMON_CONTACT_SITE = 'common.contact.site';

const COMMON_GENDER_MALE = 'common.gender.male';
const COMMON_GENDER_FEMALE = 'common.gender.female';

// Network Messages
const NETWORK_ERROR = 'http.network_error';
const TOKEN_INVALID = 'http.token_invalid';
const FORBIDDEN = 'http.forbidden';
const METHOD_NOT_ALLOWED = 'http.method_not_allowed';
const INTERNAL_SERVER_ERROR = 'http.internal_server_error';
const SERVICE_UNAVAILABLE = 'http.service_unavailable';
const GATEWAY_TIMEOUT = 'http.gateway_timeout';
const UNAUTHORIZED = 'http.unauthorized';
const SUCCESS = 'http.success';
const ERROR = 'http.error';
const INFO = 'http.info';
const WARNING = 'http.warning';

// Labels Home
const HOME_TITLE = 'home.title';
const HOME_SUBTITLE = 'home.subtitle';
const HOME_H4 = 'home.h4';
const HOME_H4_2 = 'home.h4-2';
const HOME_H4_3 = 'home.h4-3';
const HOME_H4_4 = 'home.h4-4';
const HOME_H4_5 = 'home.h4-5';

// Labels Termos
const TERMS_PAGE_TITLE = 'terms.page_title';
const TERMS_BUTTON_TEXT = 'terms.button_text';
const TERMS_UPLOAD_TEXT = 'terms.upload_text';

// Labels Menu
const MENU_HOME = 'menu.home';
const MENU_OPTION_1 = 'menu.option_1';
const MENU_OPTION_2 = 'menu.option_2';
const MENU_OPTION_3 = 'menu.option_3';
const MENU_OPTION_4 = 'menu.option_4';
const MENU_OPTION_5 = 'menu.option_5';
const SUBMENU_OPTION_1 = 'menu.submenu.option_1';
const SUBMENU_OPTION_2 = 'menu.submenu.option_2';
const SUBMENU_OPTION_3 = 'menu.submenu.option_3';

// Labels tela de pesquisar programas
const PROGRAMS_SEARCH_TITLE = 'programs.search.title';
const BUTTON_NEW_PROGRAM = 'programs.search.button';
const PROGRAMS_SEARCH_NAME = 'programs.search.name';

// Labels tela de pesquisar Perfis
const PROFILE_SEARCH_TITLE = 'profile.search.title';
const BUTTON_NEW_PROFILE = 'profile.search.button';
const PROFILE_SEARCH_NAME = 'profile.search.name';
const PROFILE_SEARCH_CPF = 'profile.search.cpf';
const PROFILE_SEARCH_ENTITY = 'profile.search.entity';
const PROFILE_SEARCH_ENTITY_PLACEHOLDER = 'profile.search.entity-placeholder';
const PROFILE_SEARCH_PROFILE = 'profile.search.profile';

// Labels tela de cadastrar/editar programas

const PROGRAMS_ADD_KIT = 'programs.add.kit';
const PROGRAMS_ADD_PRODUCT = 'programs.add.product';
const PROGRAMS_ADD_REQUIRED = 'programs.add.required';
const PROGRAMS_ADD_EDIT = 'programs.add.edit';
const PROGRAMS_ADD_ADD = 'programs.add.add';
const PROGRAMS_ADD_REQUIRED_PDF = 'programs.add.requiredPdf';
const PROGRAMS_ADD_NAME = 'programs.add.name';
const PROGRAMS_ADD_ADHESION_TERM = 'programs.add.adhesionTerm';
const PROGRAMS_ADD_MODEL_ADHESION_TERM = 'programs.add.modelAdhesionTerm';
const COMMON_BUTTON_ATTACH = 'common.button.attach';
const PROGRAMS_ADD_MORE_ADHESION_TERM = 'programs.add.moreAdhesionTerm';
const PROGRAMS_ADD_FORMS_AVAILABLES = 'programs.add.formsAvailables';
const PROGRAMS_ADD_SAVE = 'programs.add.save';
const PROGRAMS_EDIT_SAVE = 'programs.edit.save';
const PROFILE_ADD_NAME = 'profile.add.name';
const PROFILE_EDIT_NAME = 'profile.edit.name';
const PROGRAMS_ADD_TYPE_PROGRAM = 'programs.add.typeProgram';

const PROFILE_ADD_SAVE = 'profile.add.save';
const PROFILE_EDIT_SAVE = 'profile.edit.save';

// Labels tela de entidades
const ENTITY_TITLE = 'entity.title';
const ENTITY_NAME = 'entity.name';
const ENTITY_REPRESENTATIVE = 'entity.representative';
const ENTITY_CORPORATE_NAME = 'entity.corporate_name';
const ENTITY_REPRESENTATIVE_CPF = 'entity.representative_cpf';
const ENTITY_NEW_BUTTON = 'entity.new_button';

// Labels tela de cadastrar/editar entidades
const ENTITIES_ADD_NAME = 'entities.add.name';
const ENTITIES_EDIT_NAME = 'entities.edit.name';
const ENTITIES_ADD_SAVE = 'entities.add.save';
const ENTITIES_EDIT_SAVE = 'entities.edit.save';
const ENTITIES_ADD_CLASSIFICATION = 'entities.add.classification';
const ENTITIES_ADD_CORPORATE_NAME = 'entities.add.corporate_name';
const ENTITIES_ADD_PREFERENCE = 'entities.add.preference';
const ENTITIES_ADD_ENTITY_NAME = 'entities.add.entity_name';
const ENTITIES_ADD_CREATION_DATE = 'entities.add.creation_date';
const ENTITIES_ADD_ENTITY_PERFORMANCE = 'entities.add.entity_performance';
const ENTITIES_ADD_ENTITY_SERVICE = 'entities.add.entity_service';
const ENTITIES_ADD_PEOPLE_SERVED = 'entities.add.people_served';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_INFRASTRUCTURE =
   'entities.add.available_infrastructure.infrastructure';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_ROOMS =
   'entities.add.available_infrastructure.available_rooms';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_TABLES =
   'entities.add.available_infrastructure.available_tables';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_ENTITY_CAPACITY =
   'entities.add.available_infrastructure.entity_capacity';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_VENTILATION =
   'entities.add.available_infrastructure.ventilation';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_EQUIPMENT =
   'entities.add.available_infrastructure.equipment';
const ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_DISABLED_PEOPLE =
   'entities.add.available_infrastructure.disabled_people';
const ENTITIES_ERROR_SAME_NAME_AND_SAME_CNPJ =
   'entities.add.error_same_name_and_same_cnpj';

// Labels tela de cadastrar/editar perfil socioeconômico
const SOCPROFILES_ADD_NAME = 'socprofiles.add.name';
const SOCPROFILES_EDIT_NAME = 'socprofiles.edit.name';
const SOCPROFILES_COMMON_OPTIONS_YES =
   'socprofiles.add.socprofiles_common_options.yes';
const SOCPROFILES_COMMON_OPTIONS_NO =
   'socprofiles.add.socprofiles_common_options.no';
const SOCPROFILES_COMMON_OPTIONS_RATHER_NOT_ANSWER =
   'socprofiles.add.socprofiles_common_options.rather_not_answer';
const SOCPROFILES_ADD_BY_WHOM_PIP = 'socprofiles.add.by_whom_PIP';
const SOCPROFILES_ADD_MARITAL_STATUS = 'socprofiles.add.marital_status';
const SOCPROFILES_ADD_CHILDREN = 'socprofiles.add.children';
const SOCPROFILES_CHILDREN_OPTIONS_YES_HOW_MANY =
   'socprofiles.add.children_options.yes_how_many';
const SOCPROFILES_CHILDREN_OPTIONS_ANY_CHILD =
   'socprofiles.add.children_options.any_child';
const SOCPROFILES_CHILDREN_OPTIONS_NO_KNOWLEDGE =
   'socprofiles.add.children_options.no_knowledge';
const SOCPROFILES_ADD_RESIDENTS = 'socprofiles.add.residents';
const SOCPROFILES_ADD_PROPERTY_OWNER = 'socprofiles.add.property_owner';
const SOCPROFILES_ADD_PRISON_EGRESS = 'socprofiles.add.prison_egress';
const SOCPROFILES_ADD_INSTITUTION_OWNER = 'socprofiles.add.institution_owner';
const SOCPROFILES_ADD_PUBLIC_TYPES = 'socprofiles.add.public_types';
const SOCPROFILES_ADD_HOME_WORKERS = 'socprofiles.add.home_workers';
const SOCPROFILES_ADD_FINANCIAL_BENEFIT = 'socprofiles.add.financial_benefit';
const SOCPROFILES_ADD_CADUNICO = 'socprofiles.add.cadunico';
const SOCPROFILES_ADD_FAMILY_INCOME = 'socprofiles.add.family_income';
const SOCPROFILES_ADD_OCCUPATION = 'socprofiles.add.occupation';
const SOCPROFILES_ADD_NO_0_VALIDATION = 'socprofiles.add.no_0_validation';

// Labels tela de cadastrar/editar empreendedor
const ENTREPRENEURS_ADD_NAME = 'entrepreneurs.add.name';
const ENTREPRENEURS_EDIT_NAME = 'entrepreneurs.edit.name';
const ENTREPRENEURS_ADD_BUSINESS_SECTOR = 'entrepreneurs.add.business_sector';
const ENTREPRENEURS_ADD_BUSINESS_GOAL = 'entrepreneurs.add.business_goal';
const ENTREPRENEURS_ADD_BUSINESS_WITHOUT_CNPJ =
   'entrepreneurs.add.business_without_CNPJ';
const ENTREPRENEURS_ADD_STARTED_BUSINESS = 'entrepreneurs.add.started_business';
const ENTREPRENEURS_ADD_BUSINESS_MOTIVATION =
   'entrepreneurs.add.business_motivation';
const ENTREPRENEURS_ADD_WAIVER = 'entrepreneurs.add.waiver';
const ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS =
   'entrepreneurs.add.anyone_own_business';
const ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS_OPTIONS_YES_HOW_MANY =
   'entrepreneurs.add.anyone_own_business_options.yes_how_many';
const ENTREPRENEURS_ADD_IF_YES_WHO = 'entrepreneurs.add.if_yes_who';
const ENTREPRENEURS_ADD_PERSONAL_WISHES = 'entrepreneurs.add.personal_wishes';
const ENTREPRENEURS_ADD_HELP_WITH_BUSINESS =
   'entrepreneurs.add.help_with_business';
const ENTREPRENEURS_ADD_OPEN_OWN_BUSINESS =
   'entrepreneurs.add.open_own_business';
const ENTREPRENEURS_ADD_MAIN_MOTIVATION = 'entrepreneurs.add.main_motivation';

// Labels tela de pesquisar colaboradores e participantes de entidades
const NATURALPERSON_SEARCH_TITLE = 'naturalperson.search.title';
const BUTTON_NEW_REGISTER = 'naturalperson.search.button';

// Programs Adhesion
const PROGRAMS_ADHESION_PAGE_TITLE = 'programs_adhesion.page_title';
const PROGRAMS_ADHESION_PAGE_SUBTITLE = 'programs_adhesion.page_subtitle';
const PROGRAMS_ADHESION_PRODUCTS_LIST = 'programs_adhesion.products_list';
const PROGRAMS_ADHESION_DOWNLOAD_BUTTON = 'programs_adhesion.download_button';
const PROGRAMS_ADHESION_CANCEL_BUTTON = 'programs_adhesion.cancel_button';
const PROGRAMS_ADHESION_CANCEL_SELECTION_BUTTON =
   'programs_adhesion.cancel_selection_button';
const PROGRAMS_ADHESION_ADHESION_BUTTON = 'programs_adhesion.adhesion_button';
const PROGRAMS_ADHESION_DEPENDENTE = 'programs_adhesion.dependente';
const PROGRAMS_ADHESION_AVULSO = 'programs_adhesion.avulso';
const PROGRAMS_ADHESION_TERM_WARNING = 'programs_adhesion.term_warning';
const PROGRAMS_ADHESION_SAVE_BUTTON = 'programs_adhesion.save_button';
const PROGRAMS_ADHESION_DELETE_BUTTON = 'programs_adhesion.delete_button';
const PROGRAMS_ADHESION_CONFIRM_DELETE_MODAL =
   'programs_adhesion.confirm_delete_modal';
const PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_1 =
   'programs_adhesion.delete_reason_modal_title_part_1';
const PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_2 =
   'programs_adhesion.delete_reason_modal_title_part_2';
const PROGRAMS_ADHESION_DELETE_REASON_OPTION_1 =
   'programs_adhesion.delete_reason_option_1';
const PROGRAMS_ADHESION_DELETE_REASON_OPTION_2 =
   'programs_adhesion.delete_reason_option_2';
const PROGRAMS_ADHESION_DELETE_REASON_OPTION_3 =
   'programs_adhesion.delete_reason_option_3';
const PROGRAMS_ADHESION_DELETE_REASON_OPTION_4 =
   'programs_adhesion.delete_reason_option_4';
const PROGRAMS_ADHESION_SAVE = 'programs_adhesion.save';

// Labels tela de cadastrar/editar pessoa física
const NATURALPERSON_ADD_NAME = 'naturalperson.add.name';
const NATURALPERSON_EDIT_NAME = 'naturalperson.edit.name';
const NATURALPERSON_ADD_FULL_NAME = 'naturalperson.add.full_name';
const NATURALPERSON_ADD_BIRTH_DATE = 'naturalperson.add.birth_date';
const NATURALPERSON_ADD_OTHER_NAME = 'naturalperson.add.other_name';
const NATURALPERSON_ADD_YES_OTHER = 'naturalperson.add.yes_other';
const NATURALPERSON_ADD_GENDER = 'naturalperson.add.gender';
const NATURALPERSON_ADD_COLOR_ETHNICITY = 'naturalperson.add.color_ethnicity';
const NATURALPERSON_ADD_EDUCATIONAL_LEVEL =
   'naturalperson.add.educational_level';
const NATURALPERSON_ADD_DISABILITY = 'naturalperson.add.disability';
const NATURALPERSON_ADD_LGPD_TERM = 'naturalperson.add.lgpd_term';
const NATURALPERSON_ADD_LGPD_TERM_TEXT = 'naturalperson.add.lgpd_term_text';
const NATURALPERSON_ADD_LGPD_TERM_ACCEPT = 'naturalperson.add.lgpd_term_accept';
const NATURALPERSON_ADD_LGPD_TERM_DATE = 'naturalperson.add.lgpd_term_date';
const NATURALPERSON_ADD_LGPD_TERM_USER = 'naturalperson.add.lgpd_term_user';
const NATURALPERSON_ADD_LGPD_TERM_BUTTON_DOWNLOAD =
   'naturalperson.add.lgpd_term_button_download';
const NATURALPERSON_ADD_LGPD_TERM_BUTTON_UPLOAD =
   'naturalperson.add.lgpd_term_button_upload';
const NATURALPERSON_ADD_FORMS = 'naturalperson.add.forms';
const NATURALPERSON_ADD_FORMS_OPTION_1 = 'naturalperson.add.forms_option_1';
const NATURALPERSON_ADD_FORMS_OPTION_2 = 'naturalperson.add.forms_option_2';
const NATURALPERSON_ADD_SEVERAL = 'naturalperson.add.several';
const NATURALPERSON_ADD_PARTNER_ENTITIES = 'naturalperson.add.partner_entities';
const NATURALPERSON_ADD_ADD_TYPES = 'naturalperson.add.add_types';
const NATURALPERSON_ADD_TYPE_ENTITIE = 'naturalperson.add.type_entitie';

const RELATORY_CANCELED_INFO = 'relatory.canceled_info';

export default {
   HELLO_WORLD,
   BUTTON,
   NO_DATA,
   LOADING,
   TYPE_TO_SEARCH,
   LOGIN,
   PASSWORD,
   NETWORK_ERROR,
   TOKEN_INVALID,
   FORBIDDEN,
   METHOD_NOT_ALLOWED,
   INTERNAL_SERVER_ERROR,
   SERVICE_UNAVAILABLE,
   GATEWAY_TIMEOUT,
   UNAUTHORIZED,
   WARNING,
   INFO,
   ERROR,
   SUCCESS,
   HOME_TITLE,
   HOME_SUBTITLE,
   HOME_H4,
   HOME_H4_2,
   HOME_H4_3,
   HOME_H4_4,
   HOME_H4_5,
   TERMS_PAGE_TITLE,
   TERMS_BUTTON_TEXT,
   TERMS_UPLOAD_TEXT,
   PROGRAMS_SEARCH_TITLE,
   BUTTON_NEW_PROGRAM,
   PROGRAMS_SEARCH_NAME,
   PROFILE_SEARCH_TITLE,
   BUTTON_NEW_PROFILE,
   PROFILE_SEARCH_NAME,
   PROFILE_SEARCH_CPF,
   PROFILE_SEARCH_ENTITY,
   PROFILE_SEARCH_ENTITY_PLACEHOLDER,
   PROFILE_SEARCH_PROFILE,
   MENU_HOME,
   MENU_OPTION_1,
   MENU_OPTION_2,
   MENU_OPTION_3,
   MENU_OPTION_4,
   MENU_OPTION_5,
   SUBMENU_OPTION_1,
   SUBMENU_OPTION_2,
   SUBMENU_OPTION_3,
   PROGRAMS_ADD_KIT,
   PROGRAMS_ADD_PRODUCT,
   PROGRAMS_ADD_REQUIRED,
   PROGRAMS_ADD_EDIT,
   PROGRAMS_ADD_ADD,
   PROGRAMS_ADD_REQUIRED_PDF,
   PROGRAMS_ADD_NAME,
   PROGRAMS_ADD_ADHESION_TERM,
   PROGRAMS_ADD_TYPE_PROGRAM,
   COMMON_YES,
   COMMON_NO,
   PROGRAMS_ADD_MODEL_ADHESION_TERM,
   COMMON_BUTTON_ATTACH,
   PROGRAMS_ADD_MORE_ADHESION_TERM,
   PROGRAMS_ADD_FORMS_AVAILABLES,
   COMMON_STATUS,
   COMMON_ACTIVE,
   COMMON_INACTIVE,
   COMMON_BUTTON_BACK,
   COMMON_BUTTON_SAVE,
   COMMON_BUTTON_OK,
   COMMON_BUTTON_CONTINUE,
   COMMON_REQUIRED,
   PROGRAMS_ADD_SAVE,
   PROGRAMS_EDIT_SAVE,
   PROFILE_ADD_NAME,
   PROFILE_EDIT_NAME,
   COMMON_CPF,
   COMMON_CNPJ,
   COMMON_PROFILE,
   COMMON_ENTITY,
   COMMON_NAME,
   COMMON_REQUIRED_TITLE,
   PROFILE_ADD_SAVE,
   PROFILE_EDIT_SAVE,
   ENTITY_REPRESENTATIVE,
   ENTITY_NAME,
   ENTITY_TITLE,
   ENTITY_CORPORATE_NAME,
   ENTITY_REPRESENTATIVE_CPF,
   ENTITY_NEW_BUTTON,
   ENTITIES_ADD_NAME,
   ENTITIES_EDIT_NAME,
   ENTITIES_ADD_SAVE,
   ENTITIES_EDIT_SAVE,
   ENTITIES_ADD_CLASSIFICATION,
   ENTITIES_ADD_CORPORATE_NAME,
   ENTITIES_ADD_PREFERENCE,
   ENTITIES_ADD_ENTITY_NAME,
   ENTITIES_ADD_CREATION_DATE,
   ENTITIES_ADD_ENTITY_PERFORMANCE,
   ENTITIES_ADD_ENTITY_SERVICE,
   ENTITIES_ADD_PEOPLE_SERVED,
   ENTITIES_ERROR_SAME_NAME_AND_SAME_CNPJ,
   COMMON_ADRESS_ZIPCODE,
   COMMON_PUBLIC_PLACE,
   COMMON_ADRESS_ADRESS,
   COMMON_ADRESS_NUMBER,
   COMMON_ADRESS_COMPLEMENT,
   COMMON_ADRESS_NEIGHBORHOOD,
   COMMON_ADRESS_CITY,
   COMMON_ADRESS_CITY_PLACEHOLDER,
   COMMON_ADRESS_STATE,
   COMMON_CONTACT_PHONE,
   COMMON_CONTACT_MOBILE_PHONE1,
   COMMON_CONTACT_MOBILE_PHONE2,
   COMMON_CONTACT_EMAIL,
   COMMON_CONTACT_NO_EMAIL,
   COMMON_CONTACT_SITE,
   COMMON_GENDER_MALE,
   COMMON_GENDER_FEMALE,

   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_INFRASTRUCTURE,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_ROOMS,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_AVAILABLE_TABLES,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_ENTITY_CAPACITY,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_VENTILATION,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_EQUIPMENT,
   ENTITIES_ADD_PERFIL_AVAILABLE_INFRASTRUCTURE_DISABLED_PEOPLE,

   SOCPROFILES_ADD_NAME,
   SOCPROFILES_EDIT_NAME,

   SOCPROFILES_COMMON_OPTIONS_YES,
   SOCPROFILES_COMMON_OPTIONS_NO,
   SOCPROFILES_COMMON_OPTIONS_RATHER_NOT_ANSWER,

   SOCPROFILES_ADD_BY_WHOM_PIP,

   SOCPROFILES_ADD_MARITAL_STATUS,

   SOCPROFILES_ADD_CHILDREN,
   SOCPROFILES_CHILDREN_OPTIONS_YES_HOW_MANY,
   SOCPROFILES_CHILDREN_OPTIONS_ANY_CHILD,
   SOCPROFILES_CHILDREN_OPTIONS_NO_KNOWLEDGE,

   SOCPROFILES_ADD_RESIDENTS,
   SOCPROFILES_ADD_PROPERTY_OWNER,
   SOCPROFILES_ADD_PRISON_EGRESS,
   SOCPROFILES_ADD_INSTITUTION_OWNER,
   SOCPROFILES_ADD_HOME_WORKERS,
   SOCPROFILES_ADD_CADUNICO,
   SOCPROFILES_ADD_FINANCIAL_BENEFIT,
   SOCPROFILES_ADD_NO_0_VALIDATION,

   SOCPROFILES_ADD_PUBLIC_TYPES,

   SOCPROFILES_ADD_FAMILY_INCOME,

   SOCPROFILES_ADD_OCCUPATION,

   ENTREPRENEURS_ADD_NAME,
   ENTREPRENEURS_EDIT_NAME,

   ENTREPRENEURS_ADD_BUSINESS_SECTOR,
   ENTREPRENEURS_ADD_BUSINESS_GOAL,
   ENTREPRENEURS_ADD_BUSINESS_WITHOUT_CNPJ,
   ENTREPRENEURS_ADD_STARTED_BUSINESS,
   ENTREPRENEURS_ADD_BUSINESS_MOTIVATION,
   ENTREPRENEURS_ADD_WAIVER,

   ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS,
   ENTREPRENEURS_ADD_ANYONE_OWN_BUSINESS_OPTIONS_YES_HOW_MANY,

   ENTREPRENEURS_ADD_IF_YES_WHO,
   ENTREPRENEURS_ADD_HELP_WITH_BUSINESS,
   ENTREPRENEURS_ADD_PERSONAL_WISHES,
   ENTREPRENEURS_ADD_OPEN_OWN_BUSINESS,
   ENTREPRENEURS_ADD_MAIN_MOTIVATION,

   PROGRAMS_ADHESION_PAGE_TITLE,
   PROGRAMS_ADHESION_PAGE_SUBTITLE,
   PROGRAMS_ADHESION_PRODUCTS_LIST,
   PROGRAMS_ADHESION_DOWNLOAD_BUTTON,
   PROGRAMS_ADHESION_CANCEL_BUTTON,
   PROGRAMS_ADHESION_CANCEL_SELECTION_BUTTON,
   PROGRAMS_ADHESION_ADHESION_BUTTON,
   PROGRAMS_ADHESION_DELETE_BUTTON,
   PROGRAMS_ADHESION_SAVE_BUTTON,
   PROGRAMS_ADHESION_TERM_WARNING,
   PROGRAMS_ADHESION_AVULSO,
   PROGRAMS_ADHESION_DEPENDENTE,
   PROGRAMS_ADHESION_CONFIRM_DELETE_MODAL,
   PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_1,
   PROGRAMS_ADHESION_DELETE_REASON_TITLE_PART_2,
   PROGRAMS_ADHESION_DELETE_REASON_OPTION_1,
   PROGRAMS_ADHESION_DELETE_REASON_OPTION_2,
   PROGRAMS_ADHESION_DELETE_REASON_OPTION_3,
   PROGRAMS_ADHESION_DELETE_REASON_OPTION_4,
   PROGRAMS_ADHESION_SAVE,

   NATURALPERSON_SEARCH_TITLE,
   BUTTON_NEW_REGISTER,

   NATURALPERSON_ADD_NAME,
   NATURALPERSON_EDIT_NAME,
   NATURALPERSON_ADD_FULL_NAME,
   NATURALPERSON_ADD_BIRTH_DATE,
   NATURALPERSON_ADD_OTHER_NAME,
   NATURALPERSON_ADD_YES_OTHER,
   NATURALPERSON_ADD_GENDER,
   NATURALPERSON_ADD_COLOR_ETHNICITY,
   NATURALPERSON_ADD_EDUCATIONAL_LEVEL,
   NATURALPERSON_ADD_DISABILITY,
   NATURALPERSON_ADD_LGPD_TERM,
   NATURALPERSON_ADD_LGPD_TERM_TEXT,
   NATURALPERSON_ADD_LGPD_TERM_ACCEPT,
   NATURALPERSON_ADD_LGPD_TERM_DATE,
   NATURALPERSON_ADD_LGPD_TERM_USER,
   NATURALPERSON_ADD_LGPD_TERM_BUTTON_DOWNLOAD,
   NATURALPERSON_ADD_LGPD_TERM_BUTTON_UPLOAD,
   NATURALPERSON_ADD_FORMS,
   NATURALPERSON_ADD_FORMS_OPTION_1,
   NATURALPERSON_ADD_FORMS_OPTION_2,
   NATURALPERSON_ADD_SEVERAL,
   NATURALPERSON_ADD_PARTNER_ENTITIES,
   NATURALPERSON_ADD_TYPE_ENTITIE,

   NATURALPERSON_ADD_ADD_TYPES,

   RELATORY_CANCELED_INFO,
};
