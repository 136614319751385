import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './config/language/i18n';
import { store } from './config/store';

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <Suspense fallback={<div />}>
            <App />
         </Suspense>
      </Provider>
   </React.StrictMode>,
   document.getElementById('root'),
);
