import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
   Entity,
   Option,
   Person,
   SearchPersons,
   StateAddressOption,
} from 'services/naturalPersonService';

interface InitialStateType {
   scholaritiesOptionsList: Option[];
   ethnicitiesOptionsList: Option[];
   disabilitiesOptionsList: Option[];
   streetTypeOptionsList: Option[];
   stateAddressOptionsList: StateAddressOption[];
   cityOptionsList: Option[];
   personTypeOptionsList: Option[];
   entityOptionsList: Entity[];
   person: Person;
   isLoading: boolean;
   disableCpf: boolean;
   disableDisability: boolean;
   enableDisability: boolean;
   loadingEntities: boolean;
   persons: SearchPersons;
   loadingSearch: boolean;
   isLoadingCities: boolean;
   isLoadingEntities: boolean;
}

const INITIAL_STATE: InitialStateType = {
   scholaritiesOptionsList: [],
   ethnicitiesOptionsList: [],
   disabilitiesOptionsList: [],
   streetTypeOptionsList: [],
   stateAddressOptionsList: [],
   cityOptionsList: [],
   personTypeOptionsList: [],
   entityOptionsList: [],
   person: {
      entities: [],
      name: '',
      cpf: '',
      birthDate: '',
      socialName: '',
      idDisability: null,
      idScholarity: null,
      idEthnicity: null,
      sex: '',
      personAddress: {
         neighborhood: '',
         cep: '',
         complement: '',
         idCity: '',
         idStreetType: null,
         street: '',
         number: '',
         uf: '',
      },
      cellphone: '',
      cellphone2: '',
      telephone: '',
      telephone2: '',
      mail: null,
      hasMail: false,
      acceptDate: '',
      uf: '',
      state: '',
      idPersonType: null,
      accept: null,
      userCpfAccept: null,
      usernameRegisteredConsent: null,
      otherName: false,
      disability: false,
   },
   isLoading: false,
   disableCpf: false,
   disableDisability: false,
   enableDisability: true,
   loadingEntities: false,
   persons: {} as SearchPersons,
   loadingSearch: true,
   isLoadingCities: false,
   isLoadingEntities: false,
};

export const getScholaritiesOptionsList =
   createAction<Option[]>('GET_SCHOLARITIES');
export const getEthnicitiesOptionsList =
   createAction<Option[]>('GET_ETHNICITIES');
export const getDisabilitiesOptionsList =
   createAction<Option[]>('GET_DIABILITIES');
export const getStreetTypeOptionsList =
   createAction<Option[]>('GET_STREET_TYPES');
export const getStateAddressOptionsList =
   createAction<StateAddressOption[]>('GET_STATE_ADDRESS');
export const getCityOptionsList = createAction<Option[]>('GET_CITY');
export const getPersonTypeOptionsList =
   createAction<Option[]>('GET_PERSON_TYPE');
export const getEntityOptionsList = createAction<Entity[]>(
   'GET_ENTITIES_OPTIONS_LIST',
);
export const clearCityOptionsList = createAction('CLEAR_CITY');
export const getPerson = createAction<Person>('GET_PERSON');
export const startLoading = createAction('START_LOADING');
export const endLoading = createAction('END_LOADING');
export const disableCpf = createAction('DISABLE_CPF');
export const enableCpf = createAction('ENABLE_CPF');
export const disableDisability = createAction('DISABLE_DISABILITY');
export const enableDisability = createAction('ENABLE_DISABILITY');
export const setLoadingEntities = createAction<boolean>('SET_LOADING_ENTITIES');
export const clearPersonAction = createAction('CLEAR_PERSON_ACTION');
export const getPersons = createAction<SearchPersons>('GET_PERSONS');
export const getLgpdConsentAction = createAction<Person>(
   'GET_LGPD_CONSENT_ACTION',
);
export const setLoadingSearch = createAction<boolean>('SET_LOADING_SEARCH');
export const startLoadingCities = createAction('START_LOADING_CITIES');
export const endLoadingCities = createAction('END_LOADING_CITIES');
export const startLoadingEntities = createAction('START_LOADING_ENTITIES');
export const endLoadingEntities = createAction('END_LOADING_ENTITIES');

export default createReducer(INITIAL_STATE, {
   [getScholaritiesOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         scholaritiesOptionsList: action.payload,
      };
   },
   [getEthnicitiesOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         ethnicitiesOptionsList: action.payload,
      };
   },
   [getDisabilitiesOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         disabilitiesOptionsList: action.payload,
      };
   },
   [getStreetTypeOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         streetTypeOptionsList: action.payload,
      };
   },
   [getStateAddressOptionsList.type]: (
      state,
      action: PayloadAction<StateAddressOption[]>,
   ) => {
      return {
         ...state,
         stateAddressOptionsList: action.payload,
      };
   },
   [getCityOptionsList.type]: (state, action: PayloadAction<Option[]>) => {
      return {
         ...state,
         cityOptionsList: action.payload,
      };
   },
   [clearCityOptionsList.type]: state => {
      return {
         ...state,
         cityOptionsList: [],
      };
   },
   [getPersonTypeOptionsList.type]: (
      state,
      action: PayloadAction<Option[]>,
   ) => {
      return {
         ...state,
         personTypeOptionsList: action.payload,
      };
   },
   [getEntityOptionsList.type]: (state, action: PayloadAction<Entity[]>) => {
      return {
         ...state,
         entityOptionsList: action.payload,
      };
   },
   [getPerson.type]: (state, action: PayloadAction<Person>) => {
      const data = {
         ...action.payload,
      };
      return {
         ...state,
         person: data,
         loadingProducts: false,
      };
   },
   [startLoading.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'isLoading' : payload]: true,
      };
   },
   [endLoading.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'isLoading' : payload]: false,
   }),
   [disableCpf.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'disableCpf' : payload]: true,
      };
   },
   [enableCpf.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'disableCpf' : payload]: false,
   }),
   [disableDisability.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'disableDisability' : payload]: true,
      };
   },
   [enableDisability.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'disableDisability' : payload]: false,
   }),
   [setLoadingEntities.type]: (state, action: PayloadAction<boolean>) => {
      return {
         ...state,
         loadingEntities: action.payload,
      };
   },
   [clearPersonAction.type]: state => {
      return {
         ...state,
         person: INITIAL_STATE.person,
      };
   },
   [getPersons.type]: (state, action: PayloadAction<SearchPersons>) => {
      return {
         ...state,
         persons: action.payload,
         loadingSearch: false,
      };
   },
   [setLoadingSearch.type]: state => {
      return {
         ...state,
         loadingSearch: true,
      };
   },
   [getLgpdConsentAction.type]: (state, action: PayloadAction<Person>) => {
      const data = {
         ...action.payload,
      };
      return {
         ...state,
         person: data,
      };
   },
   [startLoadingCities.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'isLoadingCities' : payload]: true,
      };
   },
   [endLoadingCities.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'isLoadingCities' : payload]: false,
   }),
   [startLoadingEntities.type]: (state, { payload }) => {
      return {
         ...state,
         [!payload ? 'isLoadingEntities' : payload]: true,
      };
   },
   [endLoadingEntities.type]: (state, { payload }) => ({
      ...state,
      [!payload ? 'isLoadingEntities' : payload]: false,
   }),
});
