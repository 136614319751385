import { RootState } from 'config/store';
import { useSelector } from 'react-redux';

const usePermission = () => {
   const { login } = useSelector((state: RootState) => state.auth);

   const hasPermission = (profiles: string[]) => {
      if (profiles.includes(login.role)) return true;
      return false;
   };

   return { hasPermission, profile: login?.role };
};

export default usePermission;
