import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { isNull } from 'lodash';
import {
   SearchPaginated,
   Program,
   SearchProgramsWithProductsPaginated,
} from '../services/programsService';

interface InitialStateType {
   programs: SearchPaginated;
   program: Program;
   loading: boolean;
   loadingProducts: boolean;
   programAndEntityName: ProgramAndEntityName;
}
interface ProgramAndEntityName {
   programName: string;
   entityName: string;
   isKit: boolean;
}
interface InitialStateType {
   programs: SearchPaginated;
   programsWithProducts: SearchProgramsWithProductsPaginated;
   program: Program;
   loading: boolean;
   loadingProducts: boolean;
}

const INITIAL_STATE: InitialStateType = {
   programs: {} as SearchPaginated,
   programsWithProducts: {} as SearchProgramsWithProductsPaginated,
   program: {
      id: '',
      name: '',
      products: [],
      adhesionTerm: false,
      accessionTerm: '',
      accessionTermName: '',
      moreAdhesionTerm: false,
      forms: [],
      idCategory: 1,
      isActive: true,
      productsList: null,
      idProgCategory: 1,
   },
   loading: true,
   loadingProducts: true,
   programAndEntityName: {} as ProgramAndEntityName,
};

export const getPrograms = createAction<SearchPaginated>('GET_PROGRAMS');

export const getProgramsWithProducts =
   createAction<SearchProgramsWithProductsPaginated>(
      'GET_PROGRAMS_WITH_PRODUCTS',
   );

export const getProgram = createAction<Program>('GET_PROGRAM');

export const clearProgram = createAction('CLEAR_PROGRAM');

export const setLoading = createAction<boolean>('SET_LOADING');

export const setLoadingProducts = createAction<boolean>('SET_LOADING_PRODUCTS');

export const getProgramAndEntityName = createAction<ProgramAndEntityName>(
   'GET_PROGRAM_AND_ENTITY_NAME',
);

export default createReducer(INITIAL_STATE, {
   [getPrograms.type]: (state, action: PayloadAction<SearchPaginated>) => {
      return {
         ...state,
         programs: action.payload,
         loading: false,
      };
   },
   [getProgram.type]: (state, action: PayloadAction<Program>) => {
      const data = {
         ...action.payload,
         adhesionTerm: !isNull(action.payload.accessionTerm),
         moreAdhesionTerm: action.payload.forms.length > 0,
         forms: action.payload.forms.map(form => {
            return form;
         }),
      };
      return {
         ...state,
         program: data,
         loadingProducts: false,
      };
   },
   [clearProgram.type]: state => {
      return {
         ...state,
         program: INITIAL_STATE.program,
      };
   },
   [setLoading.type]: state => {
      return {
         ...state,
         loading: true,
      };
   },
   [setLoadingProducts.type]: state => {
      return {
         ...state,
         loadingProducts: true,
      };
   },
   [getProgramsWithProducts.type]: (
      state,
      action: PayloadAction<SearchProgramsWithProductsPaginated>,
   ) => {
      return {
         ...state,
         programsWithProducts: action.payload,
         loading: false,
      };
   },
   [getProgramAndEntityName.type]: (
      state,
      action: PayloadAction<ProgramAndEntityName>,
   ) => {
      const data = {
         ...action.payload,
         programName: action.payload.programName,
         entityName: action.payload.entityName,
         isKit: action.payload.isKit,
      };
      return {
         ...state,
         programAndEntityName: data,
      };
   },
});
