import { Redirect, Route } from 'react-router-dom';
import { ElementType } from 'react';
import usePermission from 'hooks/usePermission';
import { useSelector } from 'react-redux';
import { RootState } from 'config/store';

interface PrivateRouteProps {
   component: ElementType;
   profiles: string[];
   path: string;
   exact: boolean;
}

const PrivateRoute = ({
   component: Component,
   profiles = [],
   ...props
}: PrivateRouteProps) => {
   const { hasPermission } = usePermission();
   const { firstSession } = useSelector((state: RootState) => state.auth);

   if (firstSession) {
      if (!hasPermission(profiles)) return <Redirect to="/naturalPerson/add" />;
   }
   if (!hasPermission(profiles)) return <Redirect to="/" />;
   return (
      <Route
         {...props}
         render={propsRender => <Component {...propsRender} />}
      />
   );
};

export default PrivateRoute;
