import i18next from 'i18next';
import { notification } from 'antd';
import Labels from './enum/Labels';

export const stripDotsParenthesisWhitespacesAndHifens = (value: string) => {
   return value
      ?.replace(/\./g, '')
      ?.replace(/-/g, '')
      ?.replace(/_/g, '')
      ?.replace(/[()]/g, '')
      ?.replace(/\s+/g, '')
      .replace('/', ''); // TODO: melhorar esse regex
};

export const formatCpf = (value: string) => {
   return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
};

export const formatCnpj = (value: string) => {
   return value?.replace(
      /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
      '$1.$2.$3/$4-$5',
   );
};

export const isValidCPF = (cpf: string | undefined) => {
   if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
   ) {
      return false;
   }
   let soma = 0;
   let resto;
   for (let i = 1; i <= 9; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
   }
   resto = (soma * 10) % 11;
   if (resto === 10 || resto === 11) {
      resto = 0;
   }

   if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return false;
   }

   soma = 0;

   for (let i = 1; i <= 10; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
   }

   resto = (soma * 10) % 11;

   if (resto === 10 || resto === 11) {
      resto = 0;
   }
   if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return false;
   }
   return true;
};

export const isValidCNPJ = (value: string) => {
   if (!value) return false;

   // Aceita receber o valor como string, número ou array com todos os dígitos
   const isString = typeof value === 'string';
   const validTypes =
      isString || Number.isInteger(value) || Array.isArray(value);

   // Elimina valor em formato inválido
   if (!validTypes) return false;

   // Filtro inicial para entradas do tipo string
   if (isString) {
      // Limita ao máximo de 18 caracteres, para CNPJ formatado
      if (value.length > 18) return false;

      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value);
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

      // Se o formato é válido, usa um truque para seguir o fluxo da validação
      if (!(digitsOnly || validFormat)) return false;
   }

   // Guarda um array com todos os dígitos do valor
   const match = value.toString().match(/\d/g);
   const numbers = Array.isArray(match) ? match.map(Number) : [];

   // Valida a quantidade de dígitos
   if (numbers.length !== 14) return false;

   // Elimina inválidos com todos os dígitos iguais
   const items = numbers.filter((ele, pos) => {
      return numbers.indexOf(ele) === pos;
   });
   // const items = [...new Set(numbers)];
   if (items.length === 1) return false;

   // Cálculo validador
   const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      // eslint-disable-next-line
      for (let i = x; i >= 1; i--) {
         const n = slice[x - i];
         // eslint-disable-next-line
         sum += n * factor--;
         if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
   };

   // Separa os 2 últimos dígitos de verificadores
   const digits = numbers.slice(12);

   // Valida 1o. dígito verificador
   const digit0 = calc(12);
   if (digit0 !== digits[0]) return false;

   // Valida 2o. dígito verificador
   const digit1 = calc(13);
   return digit1 === digits[1];
};

export const getToken = () => {
   return window.localStorage.getItem('token');
};

export const setToken = (token: string) => {
   window.localStorage.setItem('token', token);
};

export const isEmpty = (value: string | null) => {
   return !value || value.length === 0;
};

export const hasTokenValid = () => {
   return !isEmpty(getToken());
};

export const cleanLocalStorage = () => {
   window.localStorage.removeItem('token');
};

export const showToast = (
   type: 'success' | 'info' | 'warning' | 'error',
   description: string,
   translate = true,
   paramsTranslate = {},
) => {
   const titlesType = {
      success: Labels.SUCCESS,
      error: Labels.ERROR,
      warning: Labels.WARNING,
      info: Labels.INFO,
   };
   let newDescription = description;
   if (!newDescription) return;
   if (translate) {
      const translated = i18next.t(description, paramsTranslate);
      newDescription = translated;
   }
   const title = i18next.t(titlesType[type]);
   notification[type]({
      message: title,
      description: newDescription,
   });
};

export const validateCPFandCNPJSearchSize = (
   value: string,
   errorName: string,
   formErrorObject: object,
   setObjectErrorFunction: Function,
) => {
   const valueWhithoutMask = value.replaceAll(/[_/.-]/gi, '');

   if (valueWhithoutMask.length >= 1 && valueWhithoutMask.length < 3) {
      setObjectErrorFunction({
         ...formErrorObject,
         [errorName]: 'Necessário informar ao menos 3 digitos',
      });
   } else {
      setObjectErrorFunction({
         ...formErrorObject,
         [errorName]: null,
      });
   }
};

export default {
   stripDotsParenthesisWhitespacesAndHifens,
   isValidCNPJ,
   formatCpf,
   isValidCPF,
   showToast,
   hasTokenValid,
   isEmpty,
   setToken,
   getToken,
   cleanLocalStorage,
   formatCnpj,
   validateCPFandCNPJSearchSize,
};
