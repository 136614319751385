import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AdheredProgram } from 'services/programAdhesionService';

interface InitialStateType {
   adheredPrograms: AdheredProgram[];
   adheredProgramsLoading: boolean;
   adheredProgramsRefresh: boolean | undefined;
}

const INITIAL_STATE: InitialStateType = {
   adheredPrograms: [],
   adheredProgramsLoading: false,
   adheredProgramsRefresh: false,
};

export const getAdheredPrograms = createAction<AdheredProgram[]>('POST_TERM');
export const setAdheredProgramsLoading = createAction<boolean>(
   'SET_ADHERED_PROGRAMS_LOADING',
);
export const adheredProgramsRefresh = createAction<boolean | undefined>(
   'SET_ADHERED_PROGRAMS_REFRESH',
);

export default createReducer(INITIAL_STATE, {
   [getAdheredPrograms.type]: (
      state,
      action: PayloadAction<AdheredProgram[]>,
   ) => {
      return {
         ...state,
         adheredPrograms: action.payload,
      };
   },
   [setAdheredProgramsLoading.type]: (
      state,
      action: PayloadAction<boolean>,
   ) => {
      return {
         ...state,
         adheredProgramsLoading: action.payload,
      };
   },
   [adheredProgramsRefresh.type]: (
      state,
      action: PayloadAction<boolean | undefined>,
   ) => {
      return {
         ...state,
         adheredProgramsRefresh: action.payload,
      };
   },
});
