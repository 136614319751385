import { Layout, Typography, Button } from 'antd';
import React, { ReactNode, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'config/store';
import AuthService from 'services/authService';
import {
   LogoutOutlined,
   MenuUnfoldOutlined,
   MenuFoldOutlined,
} from '@ant-design/icons';
import { SipLogoPNG } from '../../common/icons/icons';
import { MenuComponent } from '../menu/menu';
import { MenuComponentSmall } from '../menu/menuSmall';
import { BreadcrumbComponent } from '../breadcrumb/Breadcrumb';
import './BaseLayout.scss';

const { Title } = Typography;
const { Header } = Layout;

interface BaseLayoutProps {
   children: ReactNode;
}

const BaseLayout = ({ children }: BaseLayoutProps) => {
   const { login } = useSelector((state: RootState) => state.auth);
   const { innerWidth: width } = window;
   const [open, setOpen] = useState(false);
   const history = useHistory();

   const logout = () => {
      history.push('/');
      setTimeout(() => AuthService.doLogout(), 500);
   };

   return (
      <Layout className="baseLayout-layout">
         <Header>
            <Link to="/" role="link">
               <SipLogoPNG />
            </Link>
            <div className="userInfo">
               <Title level={5} type="secondary">
                  {login.name}
               </Title>
               <Title level={5} type="secondary">
                  Perfil: {login.role}
               </Title>
               <Title className="logout-button" level={5} onClick={logout}>
                  SAIR
               </Title>
            </div>
         </Header>
         {width > 1024 ? (
            <Layout className="layout-small">
               <MenuComponent />
               <Layout>
                  <BreadcrumbComponent />
                  {children}
               </Layout>
            </Layout>
         ) : (
            <Layout>
               <div className="menu-small">
                  <Button
                     className="ant-btn-menu"
                     onClick={() => setOpen(!open)}
                  >
                     {React.createElement(
                        open ? MenuUnfoldOutlined : MenuFoldOutlined,
                     )}
                  </Button>

                  {open && (
                     <MenuComponentSmall
                        open={() => {
                           setOpen(!open);
                        }}
                        status={open}
                     />
                  )}
               </div>
               {!open && (
                  <Layout>
                     <BreadcrumbComponent />
                     {children}
                  </Layout>
               )}
            </Layout>
         )}
      </Layout>
   );
};

const FirstSessionLayout = ({ children }: BaseLayoutProps) => {
   const { login } = useSelector((state: RootState) => state.auth);
   const { innerWidth: width } = window;
   const [open, setOpen] = useState(false);
   const history = useHistory();

   const logout = () => {
      history.push('/');
      setTimeout(() => AuthService.doLogout(), 500);
   };

   return (
      <Layout className="baseLayout-layout">
         <Header>
            <Link to="/" role="link">
               <SipLogoPNG />
            </Link>
            <div className="userInfo">
               <Title level={5} type="secondary">
                  {login.name}
               </Title>
               <Title level={5} type="secondary">
                  Perfil: {login.role}
               </Title>
               <LogoutOutlined onClick={logout} />
            </div>
         </Header>
         {width > 1024 ? (
            <Layout className="layout-small">
               <MenuComponent />
               <Layout>
                  <BreadcrumbComponent />
                  {children}
               </Layout>
            </Layout>
         ) : (
            <Layout>
               <div className="menu-small">
                  <Button
                     className="ant-btn-menu"
                     onClick={() => setOpen(!open)}
                  >
                     {React.createElement(
                        open ? MenuUnfoldOutlined : MenuFoldOutlined,
                     )}
                  </Button>

                  {open && (
                     <MenuComponentSmall
                        open={() => {
                           setOpen(!open);
                        }}
                        status={open}
                     />
                  )}
               </div>
               {!open && (
                  <Layout>
                     <BreadcrumbComponent />
                     {children}
                  </Layout>
               )}
            </Layout>
         )}
      </Layout>
   );
};

export default { BaseLayout, FirstSessionLayout };
