/* eslint-disable @typescript-eslint/prefer-as-const */
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
   Button,
   Col,
   Layout,
   PageHeader,
   Row,
   Table,
   Typography,
   Card,
   Spin,
} from 'antd';
import { t } from 'config/language/i18n';
import { RootState } from 'config/store';
import Labels from 'helpers/enum/Labels';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { searchClasses, searchClassesByKit } from 'services/classesService';
import {
   searchCities,
   Option as APIOptions,
} from 'services/naturalPersonService';
import { getProduct } from 'services/productsService';
import { Form, Select } from 'formik-antd';
import { Formik } from 'formik';
import { setSelectedProductId } from 'actions/inscribeAction';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import DropDown from '../../components/common/dropdownEntity';
import './styles.scss';

const { Title } = Typography;

const { Content } = Layout;

const { Option } = Select;

const InscribeEntityProductDetail = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const [selectedCity, setSelectedCity] = useState<number | null>(null);

   const { id } = useParams<{ id: string }>();

   const { classes, loading } = useSelector(
      (state: RootState) => state.classes,
   );

   const { productById, loadingProducts } = useSelector(
      (state: RootState) => state.products,
   );

   const { programAndEntityName } = useSelector(
      (state: RootState) => state.programs,
   );

   const { cityOptionsList, isLoadingCities } = useSelector(
      (state: RootState) => state.naturalPerson,
   );

   const columns = [
      {
         title: programAndEntityName.isKit
            ? 'Nº da execução do Kit'
            : 'Nº da Turma',
         dataIndex: programAndEntityName.isKit ? 'idExecutionKit' : 'idClass',
         key: programAndEntityName.isKit ? 'idExecutionKit' : 'idClass',
         width: '20%',
         align: 'center' as 'center',
      },
      {
         title: 'Modalidade',
         dataIndex: 'modality',
         key: 'modality',
         width: '20%',
         align: 'center' as 'center',
      },
      {
         title: 'Data',
         dataIndex: ['initialDate', 'endDate'],
         key: 'date',
         width: '20%',
         align: 'center' as 'center',
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         render: (initialDate: string, record: any) => {
            return <p>{`${record.initialDate} à ${record.endDate}`}</p>;
         },
      },
      {
         title: 'Local',
         dataIndex: 'city',
         key: 'city',
         width: '20%',
         align: 'center' as 'center',
      },
      {
         title: '',
         dataIndex: '',
         key: 'button',
         width: '20%',
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         render: (record: any) => {
            return (
               <Button
                  onClick={() => {
                     if (record.idProduct) {
                        dispatch(setSelectedProductId(record.idProduct));
                        history.push(
                           `/inscribePerson/classDetail/${record.idClass}`,
                        );
                     } else {
                        dispatch(setSelectedProductId(record.idKit));
                        history.push(
                           `/inscribePerson/classDetail/${record.idExecutionKit}`,
                        );
                     }
                  }}
               >
                  Selecionar
               </Button>
            );
         },
         align: 'center' as 'center',
      },
   ];

   useEffect(() => {
      if (programAndEntityName.isKit) {
         dispatch(getProduct(id, true));

         setTimeout(() => {
            dispatch(searchClassesByKit(id));
         }, 1000);
      } else {
         dispatch(getProduct(id, false));

         setTimeout(() => {
            dispatch(searchClasses(id));
         }, 1000);
      }
   }, [dispatch, id, programAndEntityName.isKit]);

   const handleChangePage = (value: number) => {
      if (programAndEntityName.isKit) {
         dispatch(searchClassesByKit(id, selectedCity, value, classes.pageSize));
      } else {
         dispatch(searchClasses(id, selectedCity, value, classes.pageSize));
      }
   };

   const handleOnChange = (city: number) => {
      setSelectedCity(city);
      if (programAndEntityName.isKit) {
         dispatch(searchClassesByKit(id, city));
      } else {
         dispatch(searchClasses(id, city));
      }
   };

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const handleSearch = (value: any) => {
      dispatch(searchCities(value, ''));
   };

   const searchAPIDebounced = AwesomeDebouncePromise(handleSearch, 500);

   const list = classes.data?.map(classList => {
      return classList;
   });

   return (
      <div className="inscribe-entity-product-detail-container">
         <Layout>
            <Content className="site-layout-background">
               <div className="title">
                  <ArrowLeftOutlined
                     onClick={() => {
                        history.goBack();
                     }}
                  />
                  <PageHeader
                     title={
                        programAndEntityName.isKit
                           ? 'Detalhes do Kit Selecionado'
                           : 'Descrição do Produto Selecionado'
                     }
                  />
               </div>
               <Title level={5} className="breadcrumb">
                  Você Selecionou: {programAndEntityName.entityName} /{' '}
                  {programAndEntityName.programName}
               </Title>
               <Card
                  title={programAndEntityName.programName}
                  loading={loadingProducts}
               >
                  <Row className="product-name">
                     <Title level={5}>{productById.name}</Title>
                     <div className="workload-price">
                        <p>CARGA HORÁRIA: {productById.workload}H</p>
                        <p>
                           VALOR:{' '}
                           {productById.price === 0
                              ? 'Gratuito'
                              : `R$ ${productById.price}`}
                        </p>
                     </div>
                  </Row>
                  <Row className="product-description-container">
                     <Title level={5}>
                        {programAndEntityName.isKit
                           ? 'Descrição do Kit'
                           : 'Descrição do Produto'}
                     </Title>
                     <div className="product-description">
                        <p>{productById.description}</p>
                     </div>
                  </Row>
                  <Formik
                     initialValues={{
                        city: '',
                     }}
                     onSubmit={() => {
                        //
                     }}
                     render={() => {
                        return (
                           <Form>
                              <Row className="city-container">
                                 <Col span={24} md={8}>
                                    <Title level={5}>Cidade</Title>
                                    <DropDown
                                       name="city"
                                       placeholder={t(
                                          Labels.COMMON_ADRESS_CITY,
                                       )}
                                       disabled={false}
                                       // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                       onSearch={(value: any) => {
                                          searchAPIDebounced(value);
                                       }}
                                       showArrow
                                       loading={isLoadingCities}
                                       notFoundContent={
                                          isLoadingCities ? (
                                             <Spin />
                                          ) : (
                                             'Nenhum dado encontrado'
                                          )
                                       }
                                       onChange={handleOnChange}
                                       filterOption={(
                                          inputValue: string,
                                          option: {
                                             key: number | null;
                                             value: number | string;
                                             children: string;
                                          },
                                       ) => {
                                          return option.children
                                             .toLowerCase()
                                             .includes(
                                                inputValue.toLowerCase(),
                                             );
                                       }}
                                       allowClear
                                    >
                                       {cityOptionsList?.map(
                                          (option: APIOptions) => {
                                             return (
                                                <Option value={option.id}>
                                                   {option.name}
                                                </Option>
                                             );
                                          },
                                       )}
                                    </DropDown>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col span={24}>
                                    <Title level={5}>
                                       {productById.isKit
                                          ? 'Inscrição no Kit'
                                          : 'Turmas'}
                                    </Title>
                                    <Table
                                       columns={columns}
                                       dataSource={list}
                                       pagination={{
                                          current: classes.pageNumber,
                                          onChange: handleChangePage,
                                          pageSize: classes.pageSize,
                                          total: classes.totalRecords,
                                       }}
                                       loading={loading}
                                       locale={
                                          loading
                                             ? {
                                                emptyText: t(Labels.LOADING),
                                             }
                                             : {
                                                emptyText: t(Labels.NO_DATA),
                                             }
                                       }
                                    />
                                 </Col>
                              </Row>
                           </Form>
                        );
                     }}
                  />
               </Card>
            </Content>
         </Layout>
      </div>
   );
};

export default InscribeEntityProductDetail;
