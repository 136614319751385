import {
   getEntities,
   clearEntities as clearEntities_,
   setLoading,
} from '../actions/entitiesAction';
import api from '../config/httpRequest/api';
import { AppDispatch } from '../config/store';

export type Entities = {
   id: string;
   key: number;
   name: string;
   cpf: string;
   cnpj: string;
   corporateName: string;
};

/* eslint no-console: ["error", { allow: ["log", "error"] }] */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SearchEntities {
   data: Entities[];
   pageNumber: number;
   pageSize: number;
   totalPages: number;
   totalRecords: number;
}
export interface SearchEntity {
   data: any[];
}
export type Entity = {
   id: string;
   name: string;
   cnpj: string;
   description: Array<string>;
};

export const searchEntities = (
   nome = '',
   cpf = '',
   cnpj = '',
   corporateName = '',
   pageNumber = 1,
   pageSize = 10,
) => {
   return async (dispatch: AppDispatch) => {
      api.get<SearchEntities>('/Entity/', {
         params: {
            Name: nome,
            Cpf: cpf,
            Cnpj: cnpj,
            CorporateName: corporateName,
            PageNumber: pageNumber,
            PageSize: pageSize,
         },
      })
         .then(async ({ data: payload }) => {
            dispatch(getEntities(payload));
         })
         .catch(console.log);
      dispatch(setLoading(true));
   };
};

export const clearEntities = () => {
   return async (dispatch: AppDispatch) => {
      dispatch(clearEntities_());
   };
};

export default { searchEntities };
